import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { useState } from 'react';
import { useManagerPartnersList } from 'src/api/bikApi';
import DashboardContainer from 'src/common/DashboardContainer';
import ReportsDashboard from 'src/components/reports-dashboard/ReportsDashboard';

const ManagerStatistics: React.FC = () => {
  const { data, isLoading } = useManagerPartnersList();
  const partners = data?.items || [];
  const [selectedPartnerId, setSelectedPartnerId] = useState<string | 'ALL'>('ALL');

  const handlePartnerChange = (event: SelectChangeEvent<string>) => {
    setSelectedPartnerId(event.target.value);
  };

  return (
    <DashboardContainer title="Statystyki">
      <FormControl sx={{ px: 3, mt: 2 }} fullWidth size="small">
        <InputLabel id="partner-select-label">Dostępni partnerzy</InputLabel>
        <Select
          disabled={isLoading}
          labelId="partner-select-label"
          value={selectedPartnerId}
          onChange={handlePartnerChange}
          displayEmpty
        >
          <MenuItem value="ALL">Dane odnośnie wszystkich partnerów</MenuItem>
          {partners
            .filter((partner) => partner.active)
            .map((partner) => (
              <MenuItem key={partner.id} value={partner.id}>
                {partner.firstName} {partner.lastName} ({partner.email})
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <ReportsDashboard type="ROLE_MANAGER" selectedPartnerId={selectedPartnerId === 'ALL' ? '' : selectedPartnerId} />
    </DashboardContainer>
  );
};

export default ManagerStatistics;

import { Assignment, MonetizationOn } from '@mui/icons-material';
import { Box, CardContent, Typography, useTheme } from '@mui/material';
import { ProductTypeTranslations } from 'src/pages/PartnerReportPreview/translations';
import { StructuredBIK } from 'src/types/StructuredBIK';
import { StyledCard } from './CustomStyles';
import { renderInfoBox } from './renderInfoBox';

interface ServiceBIKTileProps {
  service: StructuredBIK;
  onClick: () => void;
}

export function ServiceCommonTile({ service, onClick }: ServiceBIKTileProps) {
  const theme = useTheme();

  const maxTestUnitProfit =
    service.services.length > 0
      ? Math.max(...service.services.map((s) => ('testMaximumProfit' in s ? s?.testMaximumProfit || 0 : 0)))
      : 0;

  const maxUnitProfit = service.services.length > 0 ? Math.max(...service.services.map((s) => s.maximumProfit)) : 0;

  let glowColor = '';
  if (maxTestUnitProfit > 10000 || maxUnitProfit > 10000)
    glowColor = '#FFD700'; // Gold
  else if (maxTestUnitProfit > 5000 || maxUnitProfit > 5000)
    glowColor = '#8A2BE2'; // Violet
  else if (maxTestUnitProfit > 2500 || maxUnitProfit > 2500)
    glowColor = '#1E90FF'; // Blue
  else if (maxTestUnitProfit > 1000 || maxUnitProfit > 1000) glowColor = '#32CD32'; // Green

  return (
    <StyledCard onClick={onClick} $glowColor={glowColor} data-testid={`service-${service.type}-tile`}>
      <CardContent sx={{ width: '100%' }}>
        <Typography variant="h5" component="h3" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
          {ProductTypeTranslations[service.type]}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, width: '100%' }}>
          {renderInfoBox(
            <MonetizationOn sx={{ width: 30, height: 30, color: theme.palette.success.main }} />,
            theme.palette.success.light,
            'Suma wartości przedmiotu sporu (SUMA WPS BOT):',
            service.maximumProfit.toFixed(2),
            true,
            theme,
            'secondary'
          )}
          {service.testMaximumProfit &&
            renderInfoBox(
              <MonetizationOn sx={{ width: 30, height: 30, color: theme.palette.success.main }} />,
              theme.palette.success.light,
              'Suma wartości przedmiotu sporu (SUMA WPS MODEL):',
              service.testMaximumProfit.toFixed(2),
              true,
              theme,
              'error'
            )}
          {/* {renderInfoBox(
            <ReceiptLong sx={{ width: 30, height: 30, color: theme.palette.success.main }} />,
            theme.palette.warning.light,
            'Maks. jednostkowy zysk (WPS):',
            maxUnitProfit.toFixed(2),
            true,
            theme
          )} */}
          {renderInfoBox(
            <Assignment sx={{ width: 30, height: 30, color: theme.palette.primary.main }} />,
            theme.palette.info.light,
            'Ilość zobowiązań:',
            service.services.length,
            false,
            theme
          )}
        </Box>
      </CardContent>
    </StyledCard>
  );
}

import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { useSelectedService } from 'src/context/useSelectedService';
import { isBIKItem } from 'src/guards/isBIKItem';
import { BIKItem, BIKRequest } from 'src/types/CompanyBIKSummary.type';
import { StructuredBIK, StructuredService, StructuredType } from 'src/types/StructuredBIK';

export const ProductCheckbox = ({
  data,
  type,
  isDisabled,
  isProductChecked,
  isCheckboxHidden,
  customCheckbox,
  customMultiCheckbox,
}: {
  data: StructuredBIK | StructuredService;
  isProductChecked: boolean;
  isDisabled: boolean;
  isCheckboxHidden?: boolean;
  type: StructuredType;
  customMultiCheckbox?: {
    selectedCheckboxIds: string[];
    setSelectedCheckboxIds: (id: string) => void;
    checkboxId: string;
  };
  customCheckbox?: { selectedCheckboxId: string; setSelectedCheckboxId: (id: string) => void; checkboxId: string };
}) => {
  const { setSelectedService } = useSelectedService();

  const onSetSelectedService = (isChecked: boolean) => {
    const isItemsInside = 'items' in data;
    const item = isItemsInside && data.items[0];
    const customSubType = isBIKItem(item as BIKItem | BIKRequest) && (item as BIKItem).type;

    setSelectedService({
      left: isChecked ? data.maximumLeft : 0,
      installment: isChecked ? data.maximumInstallment : 0,
      discountPrice: isChecked ? (data.maximumDiscountPrice ?? data.maximumPrice) : 0,
      items: isItemsInside && isChecked ? data.items : [],
      partnerCommission: isChecked ? data.maximumPartnerCommission : 0,
      discountPartnerCommission: isChecked ? data.maximumPartnerDiscountedCommission || 0 : 0,
      price: isChecked ? data.maximumPrice : 0,
      type: type,
      subType: customSubType ? customSubType : 'CUSTOM',
    });

    if (customCheckbox) {
      if (customCheckbox.selectedCheckboxId === customCheckbox.checkboxId) {
        customCheckbox.setSelectedCheckboxId('');
        return;
      }

      customCheckbox.setSelectedCheckboxId(customCheckbox.checkboxId);
    }

    if (customMultiCheckbox) {
      customMultiCheckbox.setSelectedCheckboxIds(customMultiCheckbox.checkboxId);
    }
  };

  if (isCheckboxHidden) {
    return null;
  }

  return (
    <Box alignItems="center" textAlign="center" ml={2} sx={{ opacity: isDisabled ? 0.5 : 1 }}>
      <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
        Wybierz
      </Typography>

      <FormControlLabel
        control={
          <Switch
            disabled={isDisabled}
            checked={isProductChecked}
            onChange={(e) => onSetSelectedService(e.target.checked)}
            color="success"
            size="medium"
          />
        }
        label=""
      />
    </Box>
  );
};

import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { get, intersection, pick } from 'lodash';
import { FunctionComponent, useCallback, useEffect, useRef } from 'react';
import { CommissionStatus } from 'src/types/TimelineStatus';
import { CommissionTimelineStep } from '../CommissionTimelineStep/CommissionTimelineStep';
import { CommissionReportTimeline } from '../types';
import { TimelineStepsContainer, TimelineStepsContainerInner } from './styles';

const nonTerminalSteps = [
  'initiatedAt',
  'documentsSendAt',
  'processingAt',
  'processingSuccessfullAt',
  'completedAt',
] as const;

const terminalSteps = ['processingFailedAt'] as const;

const steps = [...nonTerminalSteps, ...terminalSteps] as const;

const stepNames: CommissionReportTimeline = {
  completedAt: 'completedAt',
  documentsSendAt: 'documentsSendAt',
  initiatedAt: 'initiatedAt',
  processingAt: 'processingAt',
  processingFailedAt: 'processingFailedAt',
  processingSuccessfullAt: 'processingSuccessfullAt',
};

export interface CommissionTimelineStepsProps {
  commissionTimeline: CommissionReportTimeline;
  commissionStatus: CommissionStatus;
  width: number;
}

const ensureAllSteps = (timeline: CommissionReportTimeline) => {
  // Extract existing steps from the timeline
  const timelineSteps = pick(timeline, nonTerminalSteps);

  // Add missing steps with undefined
  nonTerminalSteps.forEach((step) => {
    if (!(step in timelineSteps)) {
      timelineSteps[step] = undefined;
    }
  });

  return timelineSteps;
};

export const CommissionTimelineSteps: FunctionComponent<CommissionTimelineStepsProps> = ({
  commissionTimeline,
  commissionStatus,
  width,
}) => {
  let timelineSteps = ensureAllSteps(commissionTimeline);
  const hasTerminalStep = Boolean(commissionTimeline.processingFailedAt);

  const ref = useRef<HTMLDivElement | null>(null);

  if (hasTerminalStep) {
    for (const stepName of [...nonTerminalSteps].reverse()) {
      if (timelineSteps[stepName]) {
        break;
      }

      delete timelineSteps[stepName];
    }

    for (const stepName of terminalSteps) {
      const newStepName = stepName as keyof typeof commissionTimeline;

      if (commissionTimeline[newStepName]) {
        timelineSteps = {
          ...timelineSteps,
          [newStepName]: commissionTimeline[newStepName],
        };
      }
    }
  }

  const stepsMapped = intersection(steps, Object.keys(timelineSteps)).map((stepName) => {
    const stepEnum = stepName as keyof typeof timelineSteps;
    return {
      stepName: stepNames[stepEnum]!,
      key: stepName,
      time: commissionTimeline[stepEnum] ? timelineSteps[stepEnum] : undefined,
    };
  });

  const timelineItemMinWidth = 180;
  const minTimelineWidth = timelineItemMinWidth * stepsMapped.length;

  const scrollTimeline = useCallback((left: number) => {
    if (ref.current) {
      ref.current.scroll({
        top: 0,
        left,
        behavior: 'smooth',
      });
    }
  }, []);

  const onTimelineBackward = useCallback(() => {
    if (ref.current) {
      scrollTimeline(ref.current.scrollLeft - ref.current.scrollWidth / stepsMapped.length);
    }
  }, [scrollTimeline, stepsMapped.length]);

  const onTimelineForward = useCallback(() => {
    if (ref.current) {
      scrollTimeline(ref.current.scrollLeft + ref.current.scrollWidth / stepsMapped.length);
    }
  }, [scrollTimeline, stepsMapped.length]);

  useEffect(() => {
    const initialIndex = stepsMapped.findIndex((el) => el.stepName === commissionStatus.toLowerCase());
    if (ref.current) {
      scrollTimeline(initialIndex * timelineItemMinWidth);
    }
  }, [commissionStatus, stepsMapped, scrollTimeline]);

  return (
    <Box display="flex" data-testid="timeline">
      {width < minTimelineWidth && (
        <Box alignSelf="center" mr={1} mt={-3.2}>
          <IconButton size="medium" onClick={onTimelineBackward} data-testid="timeline-navigation-backward">
            <ArrowBack />
          </IconButton>
        </Box>
      )}

      <Box flex="100%" minWidth="0">
        <TimelineStepsContainer ref={ref}>
          <TimelineStepsContainerInner minTimelineWidth={minTimelineWidth}>
            {stepsMapped.map((step, index) => {
              return (
                <CommissionTimelineStep
                  stepsCount={stepsMapped.length}
                  stepName={step.stepName}
                  stepTime={step.time ?? undefined}
                  previousStepTime={get(stepsMapped, `${index - 1}.time`)}
                  key={step.stepName}
                />
              );
            })}
          </TimelineStepsContainerInner>
        </TimelineStepsContainer>
      </Box>

      {width < minTimelineWidth && (
        <Box alignSelf="center" ml={1} mt={-3.2}>
          <IconButton size="medium" onClick={onTimelineForward} data-testid="timeline-navigation-forward">
            <ArrowForward />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

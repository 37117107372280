import { Box } from '@mui/material';
import React from 'react';
import PositionProView from './PositionProView';

type ProDetailsToggleProps = {
  isExpanded: boolean;
  startedAt: string;
  finishedAt?: string | null;
  bikId: string;
  positionId: string;
};

const ProDetailsToggle: React.FC<ProDetailsToggleProps> = ({
  bikId,
  positionId,
  isExpanded,
  finishedAt,
  startedAt,
}) => {
  return (
    <Box mt={2}>
      <Box m={2}>
        {isExpanded && (
          <PositionProView positionId={positionId} bikId={bikId} finishedAt={finishedAt} startedAt={startedAt} />
        )}
      </Box>
    </Box>
  );
};

export default ProDetailsToggle;

import { Event, Inventory, Inventory2Outlined, ShoppingCart } from '@mui/icons-material';
import { Box, Button, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { complateBIKReport, useBIKSummary } from 'src/api/bikApi';
import { handleApiError } from 'src/api/handleApiError';
import { getUserDetailsFromLocalStorage } from 'src/common/roles';
import { useSnackbar } from 'src/common/useSnackbar';
import { useSelectedService } from 'src/context/useSelectedService';
import { isCommission } from 'src/guards/isCommission';
import { isSKD } from 'src/guards/isSKD';
import { isVIBOR } from 'src/guards/isVIBOR';
import { ProductTypeTranslations } from 'src/pages/PartnerReportPreview/translations';
import { BIKSummary } from 'src/types/BIKSummary';
import { CommissionEntry } from 'src/types/CommissionResponse';
import { CompanyBIKSummary } from 'src/types/CompanyBIKSummary.type';
import { RowData } from 'src/types/RowData';
import { SKDEntry } from 'src/types/SDKResponse';
import { SelectedServiceType } from 'src/types/SelectableServiceType';
import { SelectedRow } from 'src/types/SelectedRow';
import { StructuredType } from 'src/types/StructuredBIK';
import { UnwiborEntry } from 'src/types/UnwiborReponse';
import { convertDate } from 'src/utils/date';

import { Info } from '@mui/icons-material';
import { Card, CardContent, Chip, Divider, Paper, Stack } from '@mui/material';

type ReportSummaryProps = {
  data: CompanyBIKSummary | BIKSummary;
  isDisabled: boolean;
  isForcedPromo?: boolean;
};

const getServiceProfit = (service: CommissionEntry | SKDEntry | UnwiborEntry, isWPSModel?: boolean) => {
  if (isCommission(service) && !isWPSModel) {
    return service.possibleCommissionProfit;
  }

  if (isCommission(service) && isWPSModel) {
    return service.wpsProfit;
  }

  if (isSKD(service)) {
    return service.possibleSKDProfit;
  }
  if (isVIBOR(service)) {
    return service.possibleUnwiborProfit;
  }

  return 0;
};

export const ProductDetails = ({
  rowData,
  otherServices,
}: {
  rowData: RowData;
  otherServices: Record<StructuredType, SelectedServiceType | null>;
}) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const renderProductRows = (data: SelectedRow[]) =>
    data.map((row) => {
      const isDiscounted = row.price !== row.discountPrice;

      return (
        <Card
          key={row.id}
          elevation={2}
          sx={{
            background: isDarkMode ? theme.palette.background.default : 'white',
            p: 1.5,
            borderRadius: 2,
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              {/* Left Section */}
              <Box>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: 700,
                    color: 'text.primary',
                    mb: 0.5,
                  }}
                >
                  {row.name}
                </Typography>

                <Typography variant="body2" color="text.secondary">
                  [{row.financingOption}]
                </Typography>
              </Box>

              {/* Right Section - Pricing */}
              <Box sx={{ textAlign: 'right', minWidth: '100px' }}>
                {isDiscounted && (
                  <Typography variant="body2" sx={{ textDecoration: 'line-through', color: 'text.secondary', mb: 0.5 }}>
                    {row.price.toFixed(2)} zł
                  </Typography>
                )}
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: 700,
                    color: 'primary.main',
                  }}
                >
                  {row.discountPrice.toFixed(2)} zł
                  <br /> netto
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      );
    });

  const roles = getUserDetailsFromLocalStorage()?.roles || [];

  const isCommissionDetailsVisible = roles.includes('ROLE_LAW_OFFICE') || roles.includes('ROLE_ADMIN');

  return (
    <Box
      sx={{
        flex: 1,
        overflowY: 'auto',
        minHeight: 0, // 👈 ensures scroll works inside flex column
      }}
    >
      {Object.entries(rowData).map(
        ([reportType, data]) =>
          !!data.totalNumber && (
            <Box key={reportType}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Info color="primary" sx={{ mr: 1 }} />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 600,
                    color: 'primary.main',
                    borderBottom: '2px solid',
                    borderColor: 'primary.main',
                    paddingBottom: 0.5,
                  }}
                >
                  {reportType === 'CUSTOMER'
                    ? 'Optymalizacja BIK - z raportu prywatnego:'
                    : 'Optymalizacja BIK - z raportu firmowego:'}
                </Typography>
              </Box>

              <Stack spacing={1}>
                {data.items.length > 0 && (
                  <>
                    <Typography variant="subtitle2" sx={{ marginBottom: 1 }}>
                      Zobowiązania kredytowe:
                    </Typography>
                    {renderProductRows(data.items)}
                  </>
                )}

                {/* Render requests */}
                {data.requests.length > 0 && (
                  <>
                    <Typography variant="subtitle2" sx={{ marginTop: 2, marginBottom: 1 }}>
                      Zapytania do BIK:
                    </Typography>
                    {renderProductRows(data.requests)}
                  </>
                )}
              </Stack>
            </Box>
          )
      )}
      <Box>
        {Object.entries(otherServices).map(
          ([serviceType, service]) =>
            service &&
            !!service.items.length && (
              <Box key={serviceType} mt={1}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Info color="primary" sx={{ mr: 1 }} />
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 600,
                      color: 'primary.main',
                      borderBottom: '2px solid',
                      borderColor: 'primary.main',
                      paddingBottom: 0.5,
                    }}
                  >
                    {ProductTypeTranslations[serviceType as StructuredType]}
                  </Typography>
                </Box>

                <Stack spacing={1}>
                  {service.items.map((item, index) =>
                    isCommission(item) || isSKD(item) || isVIBOR(item) ? (
                      <Card
                        key={item.id}
                        elevation={2}
                        sx={{
                          background: isDarkMode ? theme.palette.background.default : 'white',
                          p: 1.5,
                          borderRadius: 2,
                        }}
                      >
                        <CardContent>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'flex-start',
                            }}
                          >
                            {/* Left Section */}
                            {isCommissionDetailsVisible ? (
                              <Box>
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    fontWeight: 700,
                                    color: 'text.primary',
                                    mb: 0.5,
                                  }}
                                >
                                  {item.creditor || `Pozycja ${index + 1}`}
                                </Typography>

                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    mb: 1,
                                  }}
                                >
                                  <Event fontSize="small" color="action" />
                                  <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.875rem' }}>
                                    {convertDate(item.startedAt)} - {item.lastInstallmentDate || 'Obecnie'}
                                  </Typography>
                                </Box>

                                <Chip
                                  label={`Kwota startowa: ${item.startingAmount} zł`}
                                  color="primary"
                                  variant="outlined"
                                  size="small"
                                  sx={{ fontWeight: 500 }}
                                />
                              </Box>
                            ) : (
                              <Box>
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    fontWeight: 700,
                                    color: 'text.primary',
                                  }}
                                >
                                  Pozycja nr {index + 1}
                                </Typography>
                                <Chip
                                  label={isCommission(item) ? 'Zwrot Prowizji' : isSKD(item) ? 'SKD' : 'VIBOR'}
                                  color="default"
                                  size="small"
                                  sx={{ mt: 0.5 }}
                                />
                              </Box>
                            )}

                            {/* Right Section - Profit Information */}
                            <Box sx={{ textAlign: 'right', minWidth: '130px' }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'flex-end',
                                  mb: 0.5,
                                }}
                              >
                                <Inventory sx={{ color: 'success.main', fontSize: '1rem', mr: 0.5 }} />
                                <Typography
                                  variant="caption"
                                  sx={{
                                    fontWeight: 700,
                                    color: 'success.main',
                                  }}
                                >
                                  WPS BOT: {getServiceProfit(item).toFixed(2)} zł
                                </Typography>
                              </Box>

                              {isCommission(item) && (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  <Inventory2Outlined sx={{ color: 'error.main', fontSize: '1rem', mr: 0.5 }} />
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      fontWeight: 700,
                                      color: 'error.main',
                                    }}
                                  >
                                    WPS MODEL: {getServiceProfit(item, true).toFixed(2)} zł
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    ) : null
                  )}
                </Stack>
              </Box>
            )
        )}
      </Box>
    </Box>
  );
};

export const ReportSummary = ({ data, isDisabled, isForcedPromo }: ReportSummaryProps) => {
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const [isBIKLoading, setIsBIKLoading] = useState(false);

  const { data: summaryData } = useBIKSummary(data.id);

  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const {
    selectedService,
    selectedBIKServices,
    totalBIKDiscountPrice,
    totalBIKPrice,
    selectedBIKRowsCount,
    getDataForComplateBIKReport,
  } = useSelectedService();

  const totalPrice = (totalBIKPrice?.COMPANY ?? 0) + (totalBIKPrice?.CUSTOMER ?? 0);
  const finalPrice = (totalBIKDiscountPrice?.COMPANY ?? 0) + (totalBIKDiscountPrice?.CUSTOMER ?? 0);
  const isDiscounted = totalPrice !== finalPrice;

  const continueToNextStep = () => {
    if (data.status === 'ITEMS_SELECTED_FOR_REMOVAL') {
      navigate(`/process-bik/${data.id}/add-details`);
      return;
    }

    navigate(`/process-bik/${data.id}/summary`);
  };

  const handleSubmitSelectedRows = useCallback(async () => {
    if (isDisabled) {
      navigate(`/process-bik/${data.id}/summary`);
      return;
    }

    setIsBIKLoading(true);

    if (!summaryData?.id) {
      return;
    }

    try {
      const {
        itemsToSend,
        requestsToSend,
        isReloadRequired,
        areDetailsMissing,
        skdItemsIds,
        wiborItemsIds,
        commissionItemsIds,
      } = getDataForComplateBIKReport(summaryData);

      if (isReloadRequired) {
        await complateBIKReport(summaryData.id, {
          selectedCredits: itemsToSend,
          selectedRequests: requestsToSend,
          selectedSKDIds: skdItemsIds,
          selectedUNVIBORIds: wiborItemsIds,
          selectedCOMMISSIONIds: commissionItemsIds,
          price: Number((totalBIKPrice.COMPANY + totalBIKPrice.CUSTOMER).toFixed(2)),
          discountPrice: Number((totalBIKDiscountPrice.COMPANY + totalBIKDiscountPrice.CUSTOMER).toFixed(2)),
        });
      }

      if (!isReloadRequired && data.status === 'USER_DATA_ENTERED' && !areDetailsMissing) {
        navigate(`/process-bik/${summaryData.id}/summary`);
      } else {
        navigate(`/process-bik/${summaryData.id}/add-details`);
      }
    } catch (error) {
      showSnackbar(handleApiError(error), 'error');
    } finally {
      setIsBIKLoading(false);
    }
  }, [isDisabled, summaryData, navigate, getDataForComplateBIKReport, showSnackbar]);

  const selectedServicesCount = Object.values(selectedService).reduce(
    (prev, curr) => (curr ? prev + curr.items.length : prev),
    0
  );

  const calculateSummary = () => {
    let totalBotProfit = 0;
    let totalModelProfit = 0;
    let totalItems = 0;

    Object.values(selectedService).forEach((service) => {
      if (service && service.items.length) {
        service.items.forEach((item) => {
          if (isCommission(item) || isSKD(item) || isVIBOR(item)) {
            totalBotProfit += getServiceProfit(item);
            if (isCommission(item)) {
              totalModelProfit += getServiceProfit(item, true);
            }
            totalItems++;
          }
        });
      }
    });

    return { totalBotProfit, totalModelProfit, totalItems };
  };

  const summary = calculateSummary();

  const activeServiceTypes = Object.entries({ ...selectedService, ...selectedBIKServices })
    .filter(
      ([_, service]) => service && (service.items.length > 0 || ('totalNumber' in service && service.totalNumber > 0))
    )
    .map(([type]) => type);

  return (
    <Box
      sx={{
        ...(isMobile
          ? {
              position: 'relative',
              height: 'auto',
              borderTop: '1px solid #ddd',
            }
          : {
              position: 'sticky',
              height: 'calc(100vh - 64px)',
            }),
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: (theme) => theme.palette.common.background,
        boxShadow: (theme) =>
          theme.palette.mode === 'dark' ? '0 4px 12px rgba(0, 0, 0, 0.5)' : '0 2px 6px rgba(0, 0, 0, 0.1)',
        p: 1.5,
        width: '100%',
      }}
    >
      <Box sx={{ mb: 1 }}>
        <Button
          disabled={isBIKLoading || (!(selectedBIKRowsCount + selectedServicesCount) && !isDisabled)}
          onClick={handleSubmitSelectedRows}
          variant="contained"
          color="success"
          size="large"
          startIcon={<ShoppingCart />}
          fullWidth
          sx={{
            mt: 1,
            borderRadius: 2,
            textTransform: 'none',
            fontWeight: 'bold',
          }}
        >
          {isDisabled ? 'Wróć do podsumowania raportu' : 'Zamów usługi i dokumenty'}
        </Button>

        {isForcedPromo && (
          <Button
            onClick={continueToNextStep}
            variant="contained"
            color="secondary"
            fullWidth
            sx={{
              fontSize: '1em',
              width: '100%',
              ':hover': { transform: 'scale(1.05)' },
            }}
          >
            Przejdź dalej
          </Button>
        )}

        <Paper
          elevation={3}
          sx={{
            mt: 1,
            p: 1.5,
            borderRadius: 2,
            backgroundColor: theme.palette.background.default,
            border: '1px solid',
            borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)',
            boxShadow: 'none',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              mb: 1,
              fontWeight: 700,
              color: 'primary.main',
              textAlign: 'center',
            }}
          >
            Podsumowanie zamówienia
          </Typography>

          <Divider sx={{ mb: 1 }} />

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
            <Typography variant="body1">Liczba wszystkich wybranych pozycji:</Typography>
            <Typography variant="body1" fontWeight={600}>
              {summary.totalItems + selectedBIKRowsCount}
            </Typography>
          </Box>

          <Box sx={{ mb: 0.5 }}>
            <Typography variant="body1" sx={{ mb: 0.5 }}>
              Rodzaje usług:
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: 0.5,
                flexWrap: 'wrap',
              }}
            >
              {activeServiceTypes.length ? (
                activeServiceTypes.map((type) => (
                  <Chip
                    key={type}
                    label={ProductTypeTranslations[type as StructuredType] || type}
                    size="small"
                    color="primary"
                    variant="outlined"
                  />
                ))
              ) : (
                <Chip label="Brak" size="small" color="default" variant="outlined" />
              )}
            </Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
            <Typography variant="body1">Łączny WPS BOT:</Typography>
            <Typography variant="body1" fontWeight={700} color="success.main">
              {summary.totalBotProfit.toFixed(2)} zł
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
            <Typography variant="body1">Łączny WPS MODEL:</Typography>
            <Typography variant="body1" fontWeight={700} color="error.main">
              {summary.totalModelProfit.toFixed(2)} zł
            </Typography>
          </Box>

          {/* Additional Price Summary */}
          <Box sx={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', gap: 1 }}>
            {isDiscounted && (
              <Box sx={{ textAlign: 'center', p: 1 }}>
                <Typography variant="body2" color="text.secondary">
                  Koszt przed adnotacją
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  {totalPrice.toFixed(2)} zł
                </Typography>
              </Box>
            )}

            <Box sx={{ textAlign: 'center', p: 1 }}>
              <Typography variant="body2" color="text.secondary">
                Koszt optymalizacji
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {finalPrice.toFixed(2)} zł
              </Typography>
            </Box>

            <Box
              sx={{
                textAlign: 'center',
                p: 1,
                backgroundColor: (theme) => (theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.05)' : '#f0f7ff'),
                borderRadius: 2,
              }}
            >
              <Typography variant="body2" color="text.secondary">
                Koszt optymalizacji z VAT
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                {(finalPrice * 1.23).toFixed(2)} zł
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Box>

      <ProductDetails rowData={selectedBIKServices} otherServices={selectedService} />
    </Box>
  );
};

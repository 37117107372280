import { Box, Typography, useTheme } from '@mui/material';
import ScoringChart from 'src/components/ScoringChart';
import { BIKReportType } from 'src/types/BIKReport.type';
import { StructuredBIKDetails } from 'src/types/StructuredBIK';

export const InstitutionDetailsCard = ({
  details,
  type,
  isVulnerableDataHidden,
}: {
  details?: StructuredBIKDetails;
  type?: BIKReportType;
  isVulnerableDataHidden?: boolean;
}) => {
  const theme = useTheme();

  if (!details) return null;

  return (
    <Box
      p={2}
      mb={1}
      borderRadius={1}
      sx={{
        width: '100%',
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#f5f5f5',
        color: theme.palette.mode === 'dark' ? theme.palette.text.primary : 'inherit',
        boxShadow:
          theme.palette.mode === 'dark' ? '0px 2px 8px rgba(255, 255, 255, 0.1)' : '0px 2px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
      {type && (
        <Box>
          <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
            {type === 'CUSTOMER' ? 'BIK Prywatny' : 'BIK Firmowy'}
          </Typography>
        </Box>
      )}

      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
            {details.name}
          </Typography>
          {!isVulnerableDataHidden && (
            <Typography variant="body2" color="text.secondary">
              {details.identifier.length === 11 ? 'PESEL' : 'NIP'}: <b>{details.identifier}</b>
            </Typography>
          )}
        </Box>

        {!!details.scoring && (
          <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
            <ScoringChart scoring={details.scoring} height={60} width={60} fontType="small" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

import { Box, useTheme } from '@mui/material';
import React from 'react';

const HowItWorks: React.FC = () => {
  const mode = useTheme().palette.mode;
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        paddingTop: '56.25%', // 16:9 Aspect Ratio
        borderRadius: 4,
        overflow: 'hidden',
        boxShadow:
          mode === 'dark'
            ? '0px 8px 24px rgba(0, 0, 0, 0.5)' // Heavier shadow for dark mode
            : '0px 8px 24px rgba(0, 0, 0, 0.15)', // Lighter shadow for light mode
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background:
            mode === 'dark'
              ? 'linear-gradient(to bottom right, rgba(37, 99, 235, 0.2), rgba(79, 70, 229, 0.2))' // Subtle gradient for dark mode
              : 'linear-gradient(to bottom right, rgba(37, 99, 235, 0.05), rgba(79, 70, 229, 0.05))', // Subtle gradient for light mode
        }}
      />
      <iframe
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          border: 0,
        }}
        src="https://app.storylane.io/demo/rbkqgkuxrigd?embed=inline"
        title="Prezentacja Produktu"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Box>
  );
};

export default HowItWorks;

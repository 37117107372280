import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Button, Container, Drawer, Toolbar, useColorScheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkJWTValidity, LAST_PATH } from '../utils';
import { DarkModeToggle } from './DarkModeToggle';
import { Logo } from './Logo';
import { getToolbarContent, ToolbarContent, ToolbarType } from './ToolbarContent';

export function Header({ type }: { type: ToolbarType }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { mode } = useColorScheme();
  const [open, setOpen] = useState(false);

  const content = useMemo(() => getToolbarContent(), []);

  const toggleDrawer = (newOpen: boolean) => () => setOpen(newOpen);

  const scrollToSection = (sectionId: string) => {
    const sectionElement = document.getElementById(sectionId);

    if (sectionElement) {
      const offset = 128;
      const elementPosition = sectionElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });

      setOpen(false);
    }
  };

  useEffect(() => {
    const lastPath = localStorage.getItem(LAST_PATH);

    if (lastPath && location.pathname !== '/sign-in' && location.pathname !== '/check-default-page') {
      localStorage.removeItem(LAST_PATH);
    }

    checkJWTValidity();

    const authCheckInterval = setInterval(() => {
      checkJWTValidity();
    }, 10 * 60000);

    return () => clearInterval(authCheckInterval);
  }, [location.pathname]);

  if (!content[type].length) return null;

  const renderMenuItems = (items: ToolbarContent[], isMobile?: boolean) =>
    items.map((item, key) =>
      isMobile && item.type === 'BUTTON' ? (
        <Button
          key={key}
          variant="outlined"
          color="secondary"
          sx={{
            mt: 2,
            alignSelf: 'center',
            width: '100%',
          }}
          data-testid={item.dataTestId}
          onMouseDown={(event) => {
            if (event.button === 1 || event.ctrlKey) {
              // Obsługa kliknięcia środkowym przyciskiem myszy lub Ctrl+LPM
              event.preventDefault();
              if (item.url) window.open(item.url, '_blank');
            }
          }}
          onClick={() => {
            if (item.customNavigation) {
              item.customNavigation(navigate);
            } else {
              scrollToSection(item.id);
            }
            setOpen(false); // Close drawer after navigation
          }}
        >
          {item.text}
        </Button>
      ) : (
        <Button
          key={key}
          sx={{
            color: mode === 'dark' ? 'text.primary' : 'text.secondary',
            justifyContent: 'center', // Align buttons to the left
            mb: 1, // Add spacing between buttons
            '&:hover': {
              color: mode === 'dark' ? 'primary.light' : 'primary.main',
            },
          }}
          data-testid={item.dataTestId}
          onMouseDown={(event) => {
            if (event.button === 1 || event.ctrlKey) {
              // Obsługa kliknięcia środkowym przyciskiem myszy lub Ctrl+LPM
              event.preventDefault();
              if (item.url) window.open(item.url, '_blank');
            }
          }}
          onClick={() => {
            if (item.customNavigation) {
              item.customNavigation(navigate);
            } else {
              scrollToSection(item.id);
            }
            setOpen(false); // Close drawer after navigation
          }}
        >
          {item.text}
        </Button>
      )
    );

  return (
    <AppBar
      className="fs-unmask"
      position="fixed"
      sx={{
        bgcolor: mode === 'dark' ? 'rgba(18, 18, 18, 0.9)' : 'rgba(248, 248, 248, 0.9)',
        backdropFilter: 'blur(8px)',
        borderBottom: 1,
        borderColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
      }}
    >
      <Container maxWidth={false} sx={{ maxWidth: 2000 }}>
        <Toolbar
          disableGutters
          sx={{
            justifyContent: { xs: 'space-between', md: 'normal' },
          }}
        >
          {/* Logo */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, cursor: 'pointer' }} onClick={() => navigate('/')}>
            <Logo />
          </Box>

          {/* Desktop Navigation */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 4, mx: 'auto' }}>{renderMenuItems(content[type])}</Box>

          {/* Action Section */}
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
              justifyContent: { xs: 'space-between', md: 'normal' },
            }}
          >
            <DarkModeToggle />
            <Button
              onClick={() => navigate('/process-bik')}
              variant="contained"
              color="primary"
              onMouseDown={(event) => {
                if (event.button === 1 || event.ctrlKey) {
                  // Obsługa kliknięcia środkowym przyciskiem myszy lub Ctrl+LPM
                  event.preventDefault();
                  window.open('/process-bik', '_blank');
                }
              }}
              sx={{
                background:
                  mode === 'dark'
                    ? 'linear-gradient(to right, #60a5fa, #818cf8)'
                    : 'linear-gradient(to right, #2563eb, #4f46e5)',
                color: 'white',
                '&:hover': {
                  background:
                    mode === 'dark'
                      ? 'linear-gradient(to right, #3b82f6, #6366f1)'
                      : 'linear-gradient(to right, #1d4ed8, #4338ca)',
                },
              }}
            >
              Rozpocznij nową Analizę
            </Button>
          </Box>

          {/* Mobile Navigation */}
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <Button
              variant="text"
              color="primary"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ minWidth: '30px', p: '4px' }}
            >
              <MenuIcon />
            </Button>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
              <Box
                sx={{
                  minWidth: '60vw',
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  backgroundColor: mode === 'dark' ? 'background.default' : 'background.paper',
                }}
              >
                {/* Render Menu Items */}
                {renderMenuItems(content[type], true)}
              </Box>
            </Drawer>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

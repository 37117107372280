import CheckIcon from '@mui/icons-material/Check';
import { Box, FormControl, MenuItem, Paper, Select, SelectChangeEvent, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { MultiplierOption } from 'src/types/CreditCalculation';

interface MultiplierSelectorProps {
  multiplier: number;
  multiplierOptions: MultiplierOption[];
  onMultiplierChange: (multiplier: number) => void;
}

const MultiplierContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : '#4285F4',
  color: theme.palette.getContrastText('#4285F4'),
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 300,
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.common.white,
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
}));

const MultiplierSelector: React.FC<MultiplierSelectorProps> = ({
  multiplier,
  multiplierOptions,
  onMultiplierChange,
}) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent<number>) => {
    onMultiplierChange(Number(event.target.value));
  };

  return (
    <MultiplierContainer elevation={0}>
      <Typography variant="h6" component="div">
        Wybierz mnożnik
      </Typography>
      <StyledFormControl>
        <Select
          value={multiplier}
          onChange={handleChange}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          renderValue={(value) => {
            const option = multiplierOptions.find((option) => option.value === value);
            return option ? option.label : '';
          }}
        >
          {multiplierOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Box display="flex" alignItems="center" width="100%" justifyContent="space-between">
                {option.label}
                {option.value === multiplier && <CheckIcon sx={{ color: theme.palette.primary.main }} />}
              </Box>
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>
    </MultiplierContainer>
  );
};

export default MultiplierSelector;

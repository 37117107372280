import { CalendarViewDay } from '@mui/icons-material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EventIcon from '@mui/icons-material/Event';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FunctionComponent, memo, useState } from 'react';
import { CommissionObject } from 'src/types/CommissionResponse';
import { formatDate } from 'src/utils/date';
import { CommissionStatusDetails } from '../CommissionTimeline/types';
import BIKCommissionItemDetails from './BIKCommissionItemDetails';

interface BIKCommissionItemProps {
  commission: CommissionObject;
  refetch: () => void;
  setSearchText?: (text: string) => void;
}

const generateChecksumColor = (input: string) => {
  let hash = 0;
  for (let i = 0; i < input.length; i++) {
    hash = input.charCodeAt(i) + ((hash << 5) - hash);
  }
  const colors = ['error', 'secondary', 'primary', 'warning', 'success', 'info'];
  return colors[Math.abs(hash) % colors.length] as any;
};

const BIKCommissionItem: FunctionComponent<BIKCommissionItemProps> = ({ commission, refetch, setSearchText }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion key={commission.id} expanded={expanded} onChange={handleToggle}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container spacing={2} alignItems="center" style={{ width: '100%' }}>
          <Grid size={{ xs: 12, sm: 6 }} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {commission.creditorName}
            </Typography>
            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 1, fontSize: '0.9rem' }}>
              <PersonIcon fontSize="small" /> {commission.customerName}
            </Typography>
          </Grid>
          <Grid
            size={{ xs: 12, sm: 6 }}
            sx={{
              textAlign: 'right',
              alignItems: 'center',
              ml: 'auto',
            }}
          >
            <Box>
              <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'primary.main', textAlign: 'right' }}>
                Szacowana wartość przedmiotu sporu (WPS): {commission.possibleProfit} PLN
              </Typography>
            </Box>
            {commission.finalProfit && (
              <Box>
                <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'success.main', textAlign: 'right' }}>
                  Przyznana wartość przedmiotu sporu (WPS): {commission.finalProfit} PLN
                </Typography>
              </Box>
            )}
          </Grid>

          <Grid size={{ xs: 12, sm: 6 }} sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <EventIcon fontSize="small" /> {formatDate(commission.startedAt)}
            </Typography>
            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CalendarTodayIcon fontSize="small" /> {formatDate(commission.lastInstallmentDate)}
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }} sx={{ textAlign: 'center', mt: 1 }}>
            <Chip
              label={CommissionStatusDetails[commission.status].translation}
              color={generateChecksumColor(commission.status)}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }} sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CalendarViewDay fontSize="small" /> Data złożenia zamówienia: {formatDate(commission.createdAt, true)}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      {expanded && (
        <AccordionDetails>
          <BIKCommissionItemDetails
            commission={commission}
            refetchCommissions={refetch}
            setSearchText={setSearchText}
          />
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default memo(BIKCommissionItem);

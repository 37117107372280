import { Box, Button, TextField, useMediaQuery, useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { updateBIKDiscount } from 'src/api/bikApi';
import { PromoCodeType } from 'src/types/PromoCode.type';

const PROMO_ERROR_MESSAGE = 'Adnotacja do raportu nie istnieje';

type PromoCodeProps = {
  setSelectedPromoCode?: (promoCode: PromoCodeType) => void;
  isDisabled: boolean;
  reportPromoCode?: string;
  reportId: string;
};

export const PromoCode = ({ reportPromoCode = '', setSelectedPromoCode, isDisabled, reportId }: PromoCodeProps) => {
  const theme = useTheme(); // Access the current theme
  const queryClient = useQueryClient();

  const [promoCodeInfo, setPromoCodeInfo] = useState('');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [promoCode, setPromoCode] = useState(reportPromoCode);
  const [isLoading, setIsLoading] = useState(false);

  const handlePromoCodeChange = (e: any) => {
    setPromoCode(e.target.value);
  };

  const handleApplyPromoCode = async () => {
    try {
      setIsLoading(true);
      await updateBIKDiscount(reportId, promoCode.toUpperCase() || null);
      queryClient.invalidateQueries({
        queryKey: ['bik-report', reportId],
      });
      queryClient.invalidateQueries({
        queryKey: ['structured-bik-report', reportId],
      });

      if (setSelectedPromoCode) {
        setSelectedPromoCode({ code: promoCode.toUpperCase() });
      }

      setPromoCodeInfo(
        promoCode ? `Aktywowano adnotację do raportu ${promoCode.toUpperCase()}` : 'Dezaktywowano adnotację do raportu'
      );
    } catch {
      setPromoCodeInfo(`Podano niepoprawną adnotację do raportu.`);
    } finally {
      setIsLoading(false);
    }
  };

  const isPromoError = promoCodeInfo === PROMO_ERROR_MESSAGE;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        gap: '10px',
        alignItems: isMobile ? 'stretch' : 'center',
        width: isMobile ? '100%' : 'auto',
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#ffffff', // Adaptive background
        borderRadius: '8px',
        p: 2,
        mt: 2,
        justifyContent: 'space-between',
        boxShadow:
          theme.palette.mode === 'dark'
            ? '0px 4px 12px rgba(0, 0, 0, 0.5)' // Shadow for dark mode
            : '0px 2px 8px rgba(0, 0, 0, 0.15)', // Shadow for light mode
      }}
    >
      <TextField
        label="Adnotacja do raportu"
        variant="outlined"
        size="small"
        error={isPromoError}
        disabled={isDisabled}
        helperText={promoCodeInfo || ' '}
        value={promoCode}
        onChange={handlePromoCodeChange}
        fullWidth={isMobile}
        sx={{
          flexDirection: 'row',
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: theme.palette.primary.main, // Border color on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: theme.palette.primary.dark, // Border color on focus
            },
          },
          '& .MuiFormHelperText-root': {
            color: isPromoError ? theme.palette.error.main : theme.palette.text.secondary, // Helper text color
          },
        }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleApplyPromoCode}
        disabled={isLoading || isDisabled}
        fullWidth={isMobile}
        sx={{
          ':hover': {
            backgroundColor: theme.palette.primary.dark, // Hover effect for button
          },
          ':disabled': {
            backgroundColor: theme.palette.grey[700], // Disabled state color for dark mode
            color: theme.palette.text.disabled,
          },
          my: '0.5em',
          fontSize: '1em',
          borderRadius: '8px',
          transition: 'background-color 0.3s ease, transform 0.2s ease', // Smooth transition
          '&:hover:not(:disabled)': {
            transform: 'scale(1.05)', // Slight scaling on hover
          },
        }}
      >
        {isLoading ? 'Ładowanie...' : 'Zatwierdź adnotacje'}
      </Button>
    </Box>
  );
};

import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FunctionComponent, ReactNode } from 'react';

interface BIKGridProps {
  bikCreationTimeComponent: ReactNode;
  bikAgentDataComponent: ReactNode;
  bikTotalComponent?: ReactNode;
  bikTotalCommissionComponent?: ReactNode;
  bikCommentComponent?: ReactNode;
  bikStatusProps?: ReactNode;
  bikSourceComponent?: ReactNode;
  bikAdditionalDataComponent: ReactNode;
}

export const BIKGrid: FunctionComponent<BIKGridProps> = ({
  bikCreationTimeComponent,
  bikAgentDataComponent,
  bikTotalComponent,
  bikTotalCommissionComponent,
  bikCommentComponent,
  bikStatusProps,
  bikSourceComponent,
  bikAdditionalDataComponent,
}) => {
  return (
    <>
      <Grid order={{ xs: 1 }} size={{ xs: 6, md: 2 }}>
        {bikCreationTimeComponent}
      </Grid>

      <Grid order={{ xs: 2, md: 3 }} size={{ xs: 6, md: 2 }}>
        <Box textAlign="right">
          <Grid container spacing={1}>
            <Grid size={{ xs: 12 }}>Wartość usług netto</Grid>
            <Grid size={{ xs: 12 }}>{bikTotalComponent}</Grid>
            {bikTotalCommissionComponent && (
              <>
                {/* <Grid size={{ xs: 12 }}>Wartość prowizji netto</Grid> */}
                {/* <Grid size={{ xs: 12 }}>{bikTotalCommissionComponent}</Grid> */}
              </>
            )}
          </Grid>
        </Box>
      </Grid>

      <Grid order={{ xs: 3, md: 2 }} size={{ xs: 12, md: 8 }}>
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, sm: 4, lg: 4 }}>
            <Grid container spacing={1}>
              <Grid size={{ xs: 12 }}>{bikAgentDataComponent}</Grid>

              <Grid size={{ xs: 12 }}>{bikStatusProps}</Grid>
            </Grid>
          </Grid>

          <Grid size={{ xs: 12, sm: 8, lg: 8 }}>
            <Grid container spacing={1}>
              <Grid size={{ xs: 12, lg: 6 }}>{bikSourceComponent}</Grid>

              <Grid size={{ xs: 12, lg: 6 }}>{bikAdditionalDataComponent}</Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid order={{ xs: 3 }} size={{ xs: 12 }}>
        {bikCommentComponent}
      </Grid>
    </>
  );
};

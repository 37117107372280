import { TextField } from '@mui/material';
import type { TextFieldProps } from '@mui/material/TextField';
import { forwardRef } from 'react';

interface InputProps extends Omit<TextFieldProps, 'variant' | 'error'> {
  error?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(({ error, helperText, ...props }, ref) => {
  return (
    <TextField
      variant="outlined"
      fullWidth
      error={!!error}
      helperText={error || helperText}
      size="medium"
      inputRef={ref}
      {...props}
    />
  );
});

import { useCallback, useEffect, useState } from 'react';
import { BIKReportType } from 'src/types/BIKReport.type';
import { BIKRequest } from 'src/types/CompanyBIKSummary.type';
import { SelectedRow } from 'src/types/SelectedRow';
import { mapBIKRequestToSelectedRow } from 'src/utils/mapBIKContent';

type Props = {
  selectedIds: string[];
  isSelectedAll: boolean;
  type: BIKReportType;
  bikRequests: BIKRequest[];
};

export const useCheckedRequests = ({ bikRequests, selectedIds, isSelectedAll, type }: Props) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [checkedRequests, setCheckedRequests] = useState<SelectedRow[]>([]);
  const [isSelectAllRequestsChecked, setIsSelectAllRequestsChecked] = useState(isSelectedAll);

  const requests = bikRequests;

  useEffect(() => {
    if (!selectedIds.length || isInitialized) {
      return;
    }

    const selectedRequests: SelectedRow[] = [];

    requests.forEach((request) => {
      if (!selectedIds.includes(request.id)) {
        return;
      }

      selectedRequests.push(mapBIKRequestToSelectedRow(request, type));
    });

    setIsInitialized(true);
    setCheckedRequests(selectedRequests);
  }, [requests, selectedIds, type]);

  const handleCheckboxRequestsChange = (bikRequest: BIKRequest) => {
    if (checkedRequests.find((item) => item.id === bikRequest.id)) {
      setCheckedRequests(checkedRequests.filter((i) => i.id !== bikRequest.id));
    } else {
      setCheckedRequests([...checkedRequests, mapBIKRequestToSelectedRow(bikRequest, type)]);
    }

    setIsSelectAllRequestsChecked(false);
  };

  const onChangeAllRequests = useCallback(
    (isSelectedAll?: boolean) => {
      const allSelectableRequests: SelectedRow[] = requests
        .filter((item) => item.enableToRemove)
        .map((item) => mapBIKRequestToSelectedRow(item, type));

      if (isSelectedAll !== undefined) {
        setIsSelectAllRequestsChecked(isSelectedAll);
        setCheckedRequests(isSelectedAll ? allSelectableRequests : []);
        return;
      }

      setIsSelectAllRequestsChecked(!isSelectAllRequestsChecked);
      setCheckedRequests(!isSelectAllRequestsChecked ? allSelectableRequests : []);
    },
    [requests, checkedRequests, isSelectAllRequestsChecked, type]
  );

  return { requests, isSelectAllRequestsChecked, checkedRequests, onChangeAllRequests, handleCheckboxRequestsChange };
};

import InfoIcon from '@mui/icons-material/Info'; // MUI equivalent of Info icon
import { Box, Typography, useTheme } from '@mui/material';

type PaymentInfoProps = {
  type: 'SKD' | 'VIBOR' | 'COMMISSION' | 'BIK';
};

const PaymentTypeTranslation: Record<'SKD' | 'VIBOR' | 'COMMISSION' | 'BIK', string> = {
  SKD: 'Sankcji Kredytu Darmowego',
  VIBOR: 'Odwiborowania',
  BIK: 'Optymalizacji BIK',
  COMMISSION: 'Zwrotu prowizji',
};

export function PaymentInfo({ type }: PaymentInfoProps) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box
      sx={{
        mt: 4,
        p: 2,
        border: 1,
        borderRadius: 2,
        backgroundColor: isDarkMode ? 'success.dark' : 'success.light',
        borderColor: isDarkMode ? 'success.main' : 'success.light',
      }}
    >
      <Box display="flex" alignItems="flex-start" gap={2}>
        <InfoIcon
          sx={{
            fontSize: 20,
            color: 'success.main',
            mt: 0.5,
          }}
        />
        <Typography
          variant="body2"
          sx={{
            color: isDarkMode ? 'success.light' : 'success.dark',
          }}
        >
          Płatność za usługę jest pobierana wyłącznie po skutecznym odzyskaniu środków w ramach procesu{' '}
          {PaymentTypeTranslation[type]}.
        </Typography>
      </Box>
    </Box>
  );
}

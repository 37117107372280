import { ShoppingCart } from '@mui/icons-material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import { Box, Button, CircularProgress, Theme, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { isNotPartner } from 'src/common/roles';
import { useSelectedService } from 'src/context/useSelectedService';
import { StructuredBIK } from 'src/types/StructuredBIK';
import { ReportPartnerServices } from './ReportPartnerServices';
import { useBIKDiscountRefresh } from './useBIKDiscountRefresh';
import { useConfirmationOrder } from './useConfirmationOrder';

export const ReportPartnerSummary = ({
  reportId,
  isClientView,
  bikData,
}: {
  reportId: string;
  bikData: StructuredBIK[];
  isClientView?: boolean;
}) => {
  const { selectedService } = useSelectedService();
  useBIKDiscountRefresh(bikData);

  const isUserNotPartner = isNotPartner();

  const { handleSubmitSelectedRows, isLoading, isEmpty, prices, isBIKDisabled } = useConfirmationOrder({
    reportId,
    selectedService,
  });

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const isDiscounted = prices.totalDiscountPrice !== prices.totalPrice;

  if (isUserNotPartner) {
    return null;
  }

  return (
    <Box
      sx={{
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 200px)',
        overflowY: 'auto',
        borderRadius: 2,
        backgroundColor: (theme) => theme.palette.common.background,
        boxShadow: (theme) =>
          theme.palette.mode === 'dark' ? '0 4px 12px rgba(0, 0, 0, 0.5)' : '0 2px 6px rgba(0, 0, 0, 0.1)',
        p: 3,
        width: '100%',
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: 1 }}>
        <TaskAltIcon color="primary" />
        Wybrane Rozwiązania
      </Typography>

      {!isClientView && !!prices.totalPartnerCommission && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center', // Vertically aligns the icon and text
            gap: 2, // Adds space between the icon and text
            textAlign: 'left',
            p: 2,
            m: 1,
            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? 'rgb(26 28 56 / 52%)' : '#f0f7ff'), // Adjusts background for light/dark mode
            borderRadius: 2,
          }}
        >
          {/* Icon */}
          <WorkOutlineOutlinedIcon
            sx={{
              fontSize: 32,
              color: 'primary.main',
            }}
          />

          {/* Text */}
          {/* <Box>
            <Typography variant="body2" color="text.secondary">
              Łączna prowizja / z VAT
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
              {prices.totalPartnerCommission.toFixed(2)} zł / {(prices.totalPartnerCommission * 1.23).toFixed(2)} zł
            </Typography>
            <Typography sx={{ fontWeight: 'bold', color: 'primary.main' }}>
              <Tooltip title={'Jest to ilość pieniędzy, którą otrzyma partner po wygranym procesie.'} arrow>
                <Box>Estymowana wartość: {prices.totalEstimatedPartnerCommission.toFixed(2)} zł</Box>
              </Tooltip>
            </Typography>
          </Box> */}
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center', // Vertically aligns the icon and text
          gap: 2, // Adds space between the icon and text
          textAlign: 'left',
          p: 2,
          m: 1,
          backgroundColor: (theme) => (theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.05)' : '#6eae3f17'), // Adjusts background for light/dark mode
          borderRadius: 2,
        }}
      >
        {/* Icon */}
        <TrackChangesIcon
          sx={{
            fontSize: 32,
            color: '#53aa53c9',
          }}
        />

        {/* Text */}
        <Box>
          <Typography variant="body2" color="text.secondary">
            {isClientView ? 'Wartość wybranych usług' : 'Łączna wartość umów'} / z VAT
          </Typography>
          {isDiscounted && (
            <Typography sx={{ textDecoration: 'line-through', fontWeight: 'bold', color: 'error.main' }}>
              {prices.totalPrice.toFixed(2)} zł / {(prices.totalPrice * 1.23).toFixed(2)} zł
            </Typography>
          )}
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#53aa53c9' }}>
            {prices.totalDiscountPrice.toFixed(2)} zł / {(prices.totalDiscountPrice * 1.23).toFixed(2)} zł
          </Typography>
          <Typography sx={{ fontWeight: 'bold', color: '#53aa53c9' }}>
            <Tooltip title={'Jest to ilość pieniędzy, która jest będzie do zapłaty po pomyślnym procesie.'} arrow>
              <Box>Estymowana płatność: {prices.totalEstimatedPrice.toFixed(2)} zł</Box>
            </Tooltip>
          </Typography>
        </Box>
      </Box>

      <ReportPartnerServices selectedService={selectedService} />
      <Button
        disabled={isLoading || isEmpty}
        variant="contained"
        onClick={handleSubmitSelectedRows}
        color="success"
        size="large"
        startIcon={<ShoppingCart />}
        fullWidth
        sx={{
          borderRadius: 2,
          textTransform: 'none',
          fontWeight: 'bold',
        }}
      >
        {isLoading ? <CircularProgress /> : isBIKDisabled ? 'Przejdź do podsumowania' : 'Złóż zamówienie'}
      </Button>
    </Box>
  );
};

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse, IconButton, List, ListItem, ListItemText, Typography, useTheme } from '@mui/material';
import { Fragment, useMemo, useState } from 'react';
import { ProductTypeTranslations } from 'src/pages/PartnerReportPreview/translations';
import { SelectedServiceType } from 'src/types/SelectableServiceType';
import { StructuredType } from 'src/types/StructuredBIK';

export const ReportPartnerServices = ({
  selectedService,
}: {
  selectedService: Record<StructuredType, SelectedServiceType | null>;
}) => {
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme(); // Access the current theme

  const toggleExpand = () => {
    setExpanded((prev) => !prev);
  };

  const items = useMemo(
    () =>
      Object.entries(selectedService)
        .filter(([, service]) => !!service && service.price !== 0)
        .map(([type, service]) => ({
          type,
          service: service as SelectedServiceType,
        })),
    [selectedService]
  );

  const isExpandingDisabled = items.length === 0;

  return (
    <Box>
      {/* Header */}
      <Box
        mb={1}
        display="flex"
        alignItems="center"
        sx={{
          cursor: isExpandingDisabled ? 'default' : 'pointer',
        }}
        onClick={() => !isExpandingDisabled && toggleExpand()}
      >
        <IconButton disabled={isExpandingDisabled} size="small">
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>

        <Typography
          variant="subtitle2"
          fontWeight="bold"
          color={isExpandingDisabled ? 'text.disabled' : 'text.primary'}
          sx={{ mr: 1 }}
        >
          Szczegóły wybranych produktów
        </Typography>
      </Box>

      {/* Expandable Content */}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List disablePadding>
          {items.map((item, index) => (
            <Fragment key={index}>
              <ListItem
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f5f5f5', // Dynamic background for light/dark mode
                  borderRadius: 1,
                  py: 1,
                  px: 2,
                  mb: 1,
                  border: `1px solid ${
                    theme.palette.mode === 'dark' ? theme.palette.divider : 'rgba(0, 0, 0, 0.1)' // Border color adapts to mode
                  }`,
                }}
              >
                <ListItemText
                  primary={ProductTypeTranslations[item.service.type]}
                  primaryTypographyProps={{ variant: 'body2', color: 'text.primary' }}
                />
                <Box display="flex" alignItems="center">
                  <Typography variant="body2" sx={{ color: 'primary.main', fontWeight: 'bold', mr: 1 }}>
                    {item.service.discountPrice.toFixed(2)} zł
                  </Typography>
                </Box>
              </ListItem>
            </Fragment>
          ))}
        </List>
      </Collapse>
    </Box>
  );
};

import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, IconButton, Paper } from '@mui/material';
import React, { useState } from 'react';

interface StepImageProps {
  src: string;
  alt: string;
}

const StepImage: React.FC<StepImageProps> = ({ src, alt }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box sx={{ my: 2, display: 'flex', justifyContent: 'center' }}>
        <Paper
          elevation={3}
          sx={{
            maxWidth: '80%',
            overflow: 'hidden',
            p: 1,
            cursor: 'pointer',
            '&:hover': {
              boxShadow: 6,
            },
          }}
          onClick={handleOpen}
        >
          <img
            src={src}
            alt={alt}
            style={{
              maxWidth: '100%',
              height: 'auto',
              display: 'block',
              maxHeight: '300px',
            }}
          />
        </Paper>
      </Box>

      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogContent sx={{ p: 1, position: 'relative' }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              bgcolor: 'rgba(255, 255, 255, 0.7)',
              '&:hover': {
                bgcolor: 'rgba(255, 255, 255, 0.9)',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={src}
            alt={alt}
            style={{
              width: '100%',
              height: 'auto',
              display: 'block',
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default StepImage;

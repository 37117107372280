import { BIKItem as BIKItemProp } from 'src/types/CompanyBIKSummary.type';
import { DebtRecommendation } from 'src/types/DebtRecommendation';

const isItemRecommededToRemove = (item: BIKItemProp): boolean => {
  if (!item.enableToRemove) {
    return false;
  }

  if (item.longestDebt === 0 && item.type === 'NON_BANK') {
    return true;
  }

  if (item.type === 'LEASING' && (item.isOpen || item.longestDebt > 0)) {
    return true;
  }

  if (item.longestDebt > 0 && item.longestDebt <= 60) {
    return true;
  }

  return false;
};

export const getDebtInfo = (item: BIKItemProp): DebtRecommendation => {
  const isRecommendedToRemove = isItemRecommededToRemove(item);

  const colorMap = {
    good: '#79c44b',
    weak: '#fbb14b',
    bad: '#e95c56',
    veryBad: '#808080',
  };

  if (item.longestDebt === undefined || item.longestDebt === null) {
    return {
      color: colorMap.good,
      text: 'Dobra',
      isRecommendedToRemove,
      isInfoIconVisible: false,
    };
  }

  if (item.longestDebt > 0 && item.longestDebt <= 30) {
    return {
      color: colorMap.weak,
      text: 'Słaba',
      isRecommendedToRemove,
      isInfoIconVisible: false,
    };
  }

  if (item.longestDebt > 30 && item.longestDebt <= 60) {
    return {
      color: colorMap.bad,
      text: 'Zła',
      isRecommendedToRemove,
      isInfoIconVisible: false,
    };
  }

  if (item.longestDebt > 60) {
    return {
      color: colorMap.veryBad,
      text: 'Bardzo zła',
      isRecommendedToRemove,
      isInfoIconVisible: item.type !== 'LEASING',
    };
  }

  return {
    color: colorMap.good,
    text: 'Dobra',
    isRecommendedToRemove,
    isInfoIconVisible: false,
  };
};

import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useColorScheme } from '@mui/material';
import { motion } from 'framer-motion';
import { useState } from 'react';

const faqs = [
  {
    question: 'Jak szybko mogę zobaczyć poprawę w mojej zdolności kredytowej?',
    answer:
      'Wyniki są różne, ale wielu użytkowników widzi poprawę w ciągu 30-60 dni po wdrożeniu naszych rekomendacji. Niektóre wpisy mogą zostać usunięte już w ciągu 14 dni.',
  },
  {
    question: 'Co wyróżnia naszą analizę od tradycyjnych sprawdzeń kredytowych?',
    answer:
      'Nasza analiza dostsowuje się do Twoich indywidualnych potrzeb. Wyróżnia ją szybkość – odpowiedzi otrzymasz w 30 sekund, a realizacja usługi jest błyskawiczna. Dzięki temu proces jest nie tylko precyzyjny, ale i wyjątkowo efektywny w porównaniu do tradycyjnych metod.',
  },
  {
    question: 'Czy usuwanie wpisów z raportu BIK jest legalne?',
    answer:
      'Absolutnie! Używamy tylko legalnych metod zgodnych z przepisami prawa, aby pomóc Ci usunąć nieprawidłowe lub nieaktualne wpisy z raportu.',
  },
  {
    question: 'Co jeśli nie będę zadowolony z analizy?',
    answer:
      'Oferujemy 100% gwarancję satysfakcji. Jeśli nie będziesz zadowolony z naszej analizy, zwrócimy pełną kwotę w ciągu 14 dni.',
  },
  {
    question: 'Jak bezpieczne są moje dane osobowe?',
    answer:
      'Używamy szyfrowania i zabezpieczeń klasy bankowej. Twoje dane są szyfrowane zarówno podczas przesyłania, jak i przechowywania, i nigdy nie udostępniamy ich stronom trzecim.',
  },
];

export function FAQ() {
  const [expanded, setExpanded] = useState<number | false>(false);
  const { mode } = useColorScheme();

  const handleChange = (panel: number) => (_: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box sx={{ py: 6 }}>
      <motion.div initial={{ opacity: 0, y: 20 }} whileInView={{ opacity: 1, y: 0 }} viewport={{ once: true }}>
        <Box sx={{ maxWidth: 'lg', mx: 'auto' }}>
          {faqs.map((faq, index) => (
            <Accordion
              key={index}
              expanded={expanded === index}
              onChange={handleChange(index)}
              sx={{
                mb: 1,
                border: 'none',
                borderRadius: '12px !important',
                overflow: 'hidden',
                '&:before': { display: 'none' },
                boxShadow: expanded === index ? 3 : 1,
                bgcolor:
                  expanded === index
                    ? mode === 'dark'
                      ? 'primary.900'
                      : 'white'
                    : mode === 'dark'
                      ? 'background.paper'
                      : 'white',
                transition: 'all 0.3s ease',
                '&:hover': {
                  bgcolor:
                    expanded === index
                      ? mode === 'dark'
                        ? 'primary.900'
                        : 'primary.50'
                      : mode === 'dark'
                        ? 'background.paper'
                        : 'grey.50',
                },
              }}
            >
              <AccordionSummary
                expandIcon={expanded === index ? <RemoveIcon /> : <AddIcon />}
                sx={{
                  '& .MuiAccordionSummary-expandIconWrapper': {
                    color: 'primary.main',
                    transform: expanded === index ? 'rotate(180deg)' : 'none',
                  },
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 600,
                    color: expanded === index ? 'primary.main' : 'text.primary',
                  }}
                >
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography color="text.secondary">{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </motion.div>
    </Box>
  );
}

import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useBIKSummary } from 'src/api/bikApi';
import { BIKSummary } from 'src/types/BIKSummary';
import { RowData } from 'src/types/RowData';

export const getSummaryPreview = (summaryData: BIKSummary | RowData): string[] => {
  const selectedIds: string[] = [];

  if ('CUSTOMER' in summaryData) {
    selectedIds.push(...summaryData.CUSTOMER.items.map((item) => item.id));
    selectedIds.push(...summaryData.COMPANY.items.map((item) => item.id));
    selectedIds.push(...summaryData.CUSTOMER.requests.map((item) => item.id));
    selectedIds.push(...summaryData.COMPANY.requests.map((item) => item.id));
  } else {
    selectedIds.push(...summaryData.items.map((item) => item.id));
    selectedIds.push(...summaryData.requests.map((item) => item.id));
  }

  return selectedIds;
};

export const useSummaryPreview = (
  id: string
): { selectedIds: string[]; summaryRawData?: BIKSummary; isLoading: boolean } => {
  const queryClient = useQueryClient();

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { data: summaryData, isLoading } = useBIKSummary(id);

  useEffect(() => {
    if (!summaryData) {
      return;
    }

    setSelectedIds(getSummaryPreview(summaryData));

    return () => {
      queryClient.removeQueries({
        queryKey: ['bik-summary', id],
      });
    };
  }, [summaryData]);

  return { selectedIds, summaryRawData: summaryData, isLoading };
};

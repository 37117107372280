import { Card, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React from 'react';
import { BIKRequest } from 'src/types/CompanyBIKSummary.type';
import { formatDate } from 'src/utils/date';

interface BIKRequestCardProps {
  request: BIKRequest;
}

export const BIKRequestCard: React.FC<BIKRequestCardProps> = ({ request }) => {
  return (
    <Card style={{ marginTop: 8 }}>
      <Grid container spacing={2} width="100%">
        <Grid size={{ xs: 6, sm: 4 }} flexDirection="column" display="flex" justifyContent="center">
          <Typography variant="body1" display="flex" alignItems="center">
            Kwota zapytania kredytowego
          </Typography>
          <Typography variant="body2">
            <strong>
              {new Intl.NumberFormat('pl-PL', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(request.amount)}{' '}
              {request.currency}
            </strong>
          </Typography>
        </Grid>
        <Grid size={{ xs: 6, sm: 4 }} flexDirection="column" display="flex" justifyContent="center">
          <Typography variant="body1" display="flex" alignItems="center">
            Zapytanie z dnia:
          </Typography>
          <Typography variant="body2">
            <strong>{formatDate(request.date)}</strong>
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

import { Box, Container } from '@mui/material';
import { useState } from 'react';
import { Header } from 'src/components/app-toolbar/Header';
import Footer from 'src/components/Footer';
import CompanyInstructions from 'src/components/instructions/CompanyInstructions';
import HowItWorks from 'src/components/instructions/HowItWorks';
import PersonalInstructions from 'src/components/instructions/PersonalInstructions';
import ProfileToggle from 'src/components/instructions/ProfileToggle';

const Instructions = () => {
  const [profileType, setProfileType] = useState<'personal' | 'company' | 'how-it-works'>('personal');

  return (
    <Box sx={{ minHeight: '100vh', bgcolor: 'background.default' }}>
      <Header type="back" />
      <Box component="main">
        <Container maxWidth="lg" sx={{ mt: 10 }}>
          <ProfileToggle profileType={profileType} setProfileType={setProfileType} />
          {profileType === 'how-it-works' && <HowItWorks />}
          {profileType === 'company' && <CompanyInstructions />}
          {profileType === 'personal' && <PersonalInstructions />}
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default Instructions;

import { Box, Typography, useTheme } from '@mui/material';
import { CompanyDetails, ContactDetails, CustomerDetails } from 'src/types/BIKSummary';
import { Customer } from 'src/types/Customer';
import { PartnerDetails } from 'src/types/PartnerDetails';
interface DetailSectionProps {
  title: string;
  details?: CustomerDetails | CompanyDetails | ContactDetails | Customer | PartnerDetails;
}

const translationMap: Record<string, string> = {
  firstName: 'Imię',
  lastName: 'Nazwisko',
  companyName: 'Nazwa firmy',
  invoiceEmail: 'Email do faktur',
  phone: 'Telefon',
  country: 'Kraj',
  city: 'Miasto',
  postalCode: 'Kod pocztowy',
  fullAddress: 'Adres',
  bankAccountNumber: 'Numer konta',
  bankAccountName: 'Nazwa banku',
  street: 'Ulica',
  buildingNumber: 'Numer budynku',
  nip: 'NIP',
  regon: 'REGON',
  fullName: 'Imię i nazwisko',
  email: 'Email',
  pesel: 'PESEL',
  active: 'Aktywny',
};

const ignoreKeys: (keyof CustomerDetails | keyof CompanyDetails | keyof Customer | keyof PartnerDetails)[] = [
  'id',
  'regulationsConsentVersion',
  'friendlyId',
  'roles',
  'commissions',
  'hasRODOConsentsAccepted',
  'regulationsConsentAcceptedAt',
  'hasMarketingConsentsAccepted',
];

const formatDetailsWithTranslation = (detailsObject: Record<string, any>) => {
  return Object.entries(detailsObject)
    .map(([key, value]) =>
      typeof value !== 'object' &&
      !ignoreKeys.includes(key as keyof CustomerDetails | keyof CompanyDetails | keyof Customer | keyof PartnerDetails)
        ? {
            label: translationMap[key] || key, // Fallback to the key if no translation exists
            value,
          }
        : null
    )
    .filter(Boolean);
};

export function DetailSection({ title, details }: DetailSectionProps) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  if (!details) {
    return null;
  }

  return (
    <Box
      sx={{
        borderColor: isDarkMode ? 'grey.800' : 'grey.100',
      }}
    >
      <Box display="flex" alignItems="center" mb={2}>
        <Box
          sx={{
            width: 8,
            height: 8,
            backgroundColor: 'indigo.500',
            borderRadius: '50%',
            mr: 1,
          }}
        />
        <Typography variant="h6" fontWeight="bold" color="text.primary">
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          p: 2,
          borderRadius: 2,
          backgroundColor: isDarkMode ? 'grey.900' : 'grey.50',
        }}
      >
        {formatDetailsWithTranslation(details).map((detail, index) =>
          detail && detail.label !== 'id' && detail.value ? (
            <Typography
              key={index}
              variant="body2"
              color="text.secondary"
              sx={{ mb: 1, display: 'flex', alignItems: 'baseline' }}
            >
              {detail.label && (
                <Typography component="span" sx={{ color: 'text.disabled', mr: 0.5 }}>
                  {detail.label}:
                </Typography>
              )}
              <Typography component="span" sx={{ color: 'text.primary' }}>
                {detail.value}
              </Typography>
            </Typography>
          ) : null
        )}
      </Box>
    </Box>
  );
}

import { FileCopyOutlined as CopyIcon } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { FunctionComponent, MouseEvent, useCallback } from 'react';
import { useSnackbar } from 'src/common/useSnackbar';
import { Report } from 'src/types/Report';

interface BIKSourceProps {
  bikReport: Report;
}

export const BIKSource: FunctionComponent<BIKSourceProps> = ({ bikReport }) => {
  const { showSnackbar } = useSnackbar();
  const copyToClipboard = useCallback(
    async (event: MouseEvent<HTMLElement>) => {
      if (bikReport.orderId) {
        event.stopPropagation();
        await navigator.clipboard.writeText(bikReport.orderId);
        showSnackbar('Skopiowano numer zamówienia', 'info');
      }
    },
    [bikReport.orderId]
  );

  return (
    <>
      {!!bikReport.orderId && (
        <div className="additional-info vertical-centered">
          <div className="label">Nr zam</div>
          <div className="value">{bikReport.orderId}</div>

          <div className="clipboard-element">
            <Tooltip placement="top" title="Copy to clipboard" arrow>
              <IconButton size="medium" onClick={copyToClipboard}>
                <CopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}

      {!!bikReport.alias && (
        <Box className="additional-info">
          <div className="label">Alias</div>
          <div className="value" data-test-id="bik-alias">
            {bikReport.alias}
          </div>
        </Box>
      )}

      {!!bikReport.customer?.pesel && (
        <div className="additional-info">
          <div className="label">PESEL</div>
          <div className="value" data-test-id="bik-psesl">
            {bikReport.customer?.pesel}
          </div>
        </div>
      )}
    </>
  );
};

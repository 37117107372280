import CalculatorIcon from '@mui/icons-material/Calculate';
import { Alert, Box, CircularProgress, Fade, Modal, Paper, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useCreditBIKCalculations } from 'src/api/bikApi';
import { BIKReportType } from 'src/types/BIKReport.type';
import { MultiplierOption } from 'src/types/CreditCalculation';
import BIKCalculationsTable from './BIKCalculationsTable';
import MultiplierSelector from './MultiplierSelector';
import TotalCapacity from './TotalCapacity';

const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 900,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 0,
  outline: 'none',
};

const HeaderContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : '#4285F4',
  padding: theme.spacing(4),
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  textAlign: 'center',
  color: theme.palette.getContrastText('#4285F4'),
}));

const IconContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : '#4285F4',
  width: 80,
  height: 80,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto',
  marginBottom: theme.spacing(2),
  boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(0, 0, 0, 0.5)' : '0px 4px 10px rgba(0, 0, 0, 0.1)',
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const TabContainer = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
}));

export const multiplierOptions: MultiplierOption[] = [
  { value: 2, label: '2% - Santander, Pekao' },
  { value: 3, label: '3% - BNP Paribas' },
  { value: 5, label: '5% - ING, PKO BP, PEKAO SA' },
];

interface BikReportModalProps {
  id: string;
  open: boolean;
  onClose: () => void;
}

const BIKCalculationsPreviewModal: React.FC<BikReportModalProps> = ({ id, open, onClose }) => {
  const theme = useTheme();
  const [selectedType, setSelectedType] = useState<BIKReportType>('CUSTOMER');
  const [multiplier, setMultiplier] = useState<number>(5);
  const { data: report, isError, isLoading } = useCreditBIKCalculations(id, multiplier, open);

  useEffect(() => {
    if (open && report) {
      if (selectedType === 'CUSTOMER' && !report.customer && report.company) {
        setSelectedType('COMPANY');
      } else if (selectedType === 'COMPANY' && !report.company && report.customer) {
        setSelectedType('CUSTOMER');
      }
    }
  }, [open, report]);

  const handleMultiplierChange = (newMultiplier: number) => {
    setMultiplier(newMultiplier);
  };

  const handleTabChange = (_: React.SyntheticEvent, newValue: BIKReportType) => {
    setSelectedType(newValue);
  };

  const isCompanyDisabled = !report || !report.company;
  const isCustomerDisabled = !report || !report.customer;

  const currentCredits = React.useMemo(() => {
    if (!report) return [];

    if (selectedType === 'COMPANY' && report.company) {
      return report.company.credits;
    } else if (selectedType === 'CUSTOMER' && report.customer) {
      return report.customer.credits;
    }

    return [];
  }, [report, selectedType]);

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="bik-report-modal-title" closeAfterTransition>
      <Fade in={open}>
        <Box sx={modalStyle} height="90vh" overflow="auto">
          <HeaderContainer>
            <IconContainer>
              <CalculatorIcon sx={{ fontSize: 40, color: 'white' }} />
            </IconContainer>
            <Typography variant="h4" component="h2" id="bik-report-modal-title" gutterBottom>
              Rata do zdolności
            </Typography>
            <Typography variant="body1">
              Oblicz ratę do zdolności na podstawie sald zobowiązań kredytowych oraz rat dla limitów w rachunkach i
              kartach kredytowych.
            </Typography>
          </HeaderContainer>

          <ContentContainer>
            {isLoading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="300px">
                <CircularProgress />
              </Box>
            ) : isError ? (
              <Alert severity="error">Błąd podczas pobierania kalkulacji raportu.</Alert>
            ) : report ? (
              <>
                <MultiplierSelector
                  multiplier={multiplier}
                  multiplierOptions={multiplierOptions}
                  onMultiplierChange={handleMultiplierChange}
                />

                <TabContainer elevation={0}>
                  <Tabs
                    value={selectedType}
                    onChange={handleTabChange}
                    aria-label="report type tabs"
                    centered
                    textColor="primary"
                    indicatorColor="primary"
                    sx={{ borderBottom: 1, borderColor: 'divider' }}
                  >
                    <Tab
                      label="Klient"
                      value="CUSTOMER"
                      disabled={isCustomerDisabled}
                      sx={{
                        fontWeight: 'bold',
                        '&.Mui-disabled': { color: theme.palette.text.disabled },
                      }}
                    />
                    <Tab
                      label="Firma"
                      value="COMPANY"
                      disabled={isCompanyDisabled}
                      sx={{
                        fontWeight: 'bold',
                        '&.Mui-disabled': { color: theme.palette.text.disabled },
                      }}
                    />
                  </Tabs>
                </TabContainer>

                {selectedType === 'CUSTOMER' && report.customer ? (
                  <BIKCalculationsTable credits={report.customer.credits} />
                ) : selectedType === 'COMPANY' && report.company ? (
                  <BIKCalculationsTable credits={report.company.credits} />
                ) : (
                  <Alert severity="info">
                    {`Raport dla ${selectedType === 'CUSTOMER' ? 'klienta' : 'firmy'} nie jest dostępny.`}
                  </Alert>
                )}

                <TotalCapacity credits={currentCredits} />
              </>
            ) : (
              <Alert severity="info">Brak danych do wyświetlenia</Alert>
            )}
          </ContentContainer>
        </Box>
      </Fade>
    </Modal>
  );
};

export default BIKCalculationsPreviewModal;

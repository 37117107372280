import { createContext, useContext } from 'react';
import { BIKReportCounterByType, BIKReportType } from 'src/types/BIKReport.type';
import { BIKSummary } from 'src/types/BIKSummary';
import { RowData } from 'src/types/RowData';
import { SelectedServiceType } from 'src/types/SelectableServiceType';
import { SelectedRow } from 'src/types/SelectedRow';
import { StructuredType } from 'src/types/StructuredBIK';

interface SelectedServiceContextType {
  selectedService: Record<StructuredType, SelectedServiceType | null>;
  setSelectedService: (service: SelectedServiceType) => void;
  setGlobalSelectedService: (
    value:
      | Record<StructuredType, SelectedServiceType | null>
      | ((
          prev: Record<StructuredType, SelectedServiceType | null>
        ) => Record<StructuredType, SelectedServiceType | null>)
  ) => void;
  selectedBIKServices: RowData;
  selectedBIKRowsCount: number;
  totalBIKPrice: BIKReportCounterByType;
  totalBIKDiscountPrice: BIKReportCounterByType;
  onSelectedBIKRows: (itemsIndex: SelectedRow[], requestsIndex: SelectedRow[], type: BIKReportType) => void;
  getDataForComplateBIKReport: (summaryData: BIKSummary) => {
    itemsToSend: Omit<SelectedRow, 'name'>[];
    requestsToSend: Omit<SelectedRow, 'name'>[];
    isReloadRequired: boolean;
    areDetailsMissing: boolean;
    skdItemsIds: string[];
    commissionItemsIds: string[];
    wiborItemsIds: string[];
  };
}

// Create the context
export const SelectedServiceContext = createContext<SelectedServiceContextType | undefined>(undefined);

// Hook for using the context
export const useSelectedService = () => {
  const context = useContext(SelectedServiceContext);

  if (!context) {
    throw new Error('useSelectedService must be used within a SelectedServiceProvider');
  }
  return context;
};

import { Box, CircularProgress, Container, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  sendContactRequest,
  useBIKSummary,
  useCommissionsForBIK,
  useStructuredBIKReport,
  useStructuredCommissionReport,
  useStructuredSKDReport,
  useStructuredUnwiborReport,
} from 'src/api/bikApi';
import { handleApiError } from 'src/api/handleApiError';
import {
  isBIKEnabledForLoggedUser,
  isRefundOfCommissionEnabledForLoggedUser,
  isSKDEnabledForLoggedUser,
  isWIBOREnabledForLoggedUser,
} from 'src/common/roles';
import { useSnackbar } from 'src/common/useSnackbar';
import { Header } from 'src/components/app-toolbar/Header';
import CommunicationPreferencesModal from 'src/components/CommunicationPreferencesModal';
import Footer from 'src/components/Footer';
import ScrollToTop from 'src/components/ScrollToTop';
import { SelectedServiceProvider } from 'src/context/SelectedServiceProvider';
import { BIKCard } from 'src/pages/PartnerReportPreview/ExpandableCards/BIKCard';
import { CommissionCard } from 'src/pages/PartnerReportPreview/ExpandableCards/CommissionCard';
import { SKDCard } from 'src/pages/PartnerReportPreview/ExpandableCards/SKDCard';
import { UnviborCard } from 'src/pages/PartnerReportPreview/ExpandableCards/UnviborCard';
import { ProductCard } from 'src/pages/PartnerReportPreview/ProductCard';
import { RecommendedSection } from 'src/pages/PartnerReportPreview/RecommendedSection';
import { ReportPartnerSummary } from 'src/pages/PartnerReportPreview/ReportPartnerSummary';
import { PromoCode } from 'src/pages/report-preview/report-summary/PromoCode';
import { CommunicationPreferences } from 'src/types/CommunicationPreferences.type';
import { isBIKDisabledForEdit } from 'src/utils/bikUtils';

export default function ShowBIKRaport({ isClientView }: { isClientView?: boolean }) {
  const params = useParams();

  const { showSnackbar } = useSnackbar();

  const [failedReportId, setFailedReportId] = useState('');

  const handleClosePreferences = () => {
    setFailedReportId('');
  };

  const handlePreferencesSubmit = async (data: CommunicationPreferences) => {
    try {
      await sendContactRequest(failedReportId, data);
    } catch (error) {
      showSnackbar(handleApiError(error), 'error');
      return false;
    } finally {
      setFailedReportId('');
    }

    showSnackbar('Dziękujemy za zgłoszenie.', 'info');
    return true;
  };

  const { data: bikSummary, isLoading: isBIKSummaryLoading } = useBIKSummary(params?.id ?? 'Wrong_param');
  const { data: commissions, isLoading: isCommissionDetailsLoading } = useCommissionsForBIK(
    params.id || '',
    !!bikSummary?.commission.length,
    'ROLE_PARTNER',
    isClientView
  );

  const {
    data: bikReport,
    isLoading: isBIKLoading,
    failureReason,
  } = useStructuredBIKReport(
    params?.id ?? 'Wrong_param',
    Boolean(isClientView),
    isBIKEnabledForLoggedUser() || Boolean(isClientView)
  );

  const rawBIKReport = bikReport?.rawReport;
  const bikData = bikReport?.structuredReport;

  const { data: sdkData, isLoading: isSDKLoading } = useStructuredSKDReport(
    params?.id ?? 'Wrong_param',
    Boolean(isClientView),
    isSKDEnabledForLoggedUser()
  );

  const { data: unwiborData, isLoading: isUnwiborLoading } = useStructuredUnwiborReport(
    params?.id ?? 'Wrong_param',
    Boolean(isClientView),
    isWIBOREnabledForLoggedUser()
  );

  const { data: commissionData, isLoading: isCommissionLoading } = useStructuredCommissionReport(
    params?.id ?? 'Wrong_param',
    Boolean(isClientView),
    isRefundOfCommissionEnabledForLoggedUser()
  );

  useEffect(() => {
    if (failureReason && (failureReason as any).status === 500) {
      setFailedReportId(params?.id ?? '');
    }
  }, [failureReason, params?.id]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const isLoading =
    isBIKLoading ||
    isSDKLoading ||
    isUnwiborLoading ||
    isBIKSummaryLoading ||
    isCommissionLoading ||
    isCommissionDetailsLoading;

  const isReportDisabled = isBIKDisabledForEdit(rawBIKReport) || !!commissions?.length;

  const generateJsonForTests = () => {
    if (!commissionData) {
      return '';
    }

    const isTestsEnabled = import.meta.env.VITE_ENV === 'DEV' || import.meta.env.VITE_ENV === 'STAGE';

    if (isTestsEnabled && 'allItems' in commissionData) {
      return JSON.stringify(commissionData.allItems);
    }

    return '';
  };

  const commissionTestJSON = useMemo(generateJsonForTests, [commissionData]);

  return (
    <SelectedServiceProvider>
      <ScrollToTop />
      <Header type="back" />
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'center', // Wyrównanie komponentów do początku
          alignItems: 'flex-start',
          gap: isMobile ? 4 : 0, // Brak przerwy między kontenerami w układzie desktopowym
          mt: '64px',
          position: 'relative',
        }}
      >
        {isLoading && <CircularProgress />}
        {!isLoading && (
          <>
            <Container
              id="show-bik-raport"
              sx={{
                pt: 0,
                pb: 0,
                pl: 0,
                pr: 0,
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: isMobile ? '100%' : '70%', // Dopasowanie szerokości
                marginTop: 1,
                marginLeft: 0,
                marginRight: 0,
              }}
              data-testid="bik-report"
            >
              <RecommendedSection />
              {!!sdkData?.services.length && (
                <ProductCard
                  data={sdkData}
                  isDisabled={isReportDisabled}
                  isClientView={isClientView}
                  isCheckboxHidden
                  dataTestId="skd-main"
                  type={sdkData.type}
                  expandCard={
                    <SKDCard
                      isClientView={isClientView}
                      isDisabled={isReportDisabled}
                      previouslySelected={bikSummary?.skd || []}
                      services={sdkData.services}
                    />
                  }
                />
              )}
              {!!unwiborData?.services.length && (
                <ProductCard
                  isCheckboxHidden
                  isClientView={isClientView}
                  isDisabled={isReportDisabled}
                  data={unwiborData}
                  type={unwiborData.type}
                  dataTestId="unwibor-main"
                  expandCard={
                    <UnviborCard
                      isClientView={isClientView}
                      isDisabled={isReportDisabled}
                      previouslySelected={bikSummary?.unwibor || []}
                      services={unwiborData.services}
                    />
                  }
                />
              )}
              {!!commissionData?.services.length && (
                <ProductCard
                  isCheckboxHidden
                  isClientView={isClientView}
                  isDisabled={isReportDisabled}
                  data={commissionData}
                  type={commissionData.type}
                  dataTestId="commission-main"
                  expandCard={
                    <CommissionCard
                      isClientView={isClientView}
                      isDisabled={isReportDisabled}
                      previouslySelected={bikSummary?.commission || []}
                      services={commissionData.services}
                    />
                  }
                />
              )}
              {bikData?.map((data, index) => {
                if (!data.services.length) {
                  return null;
                }
                const items = [
                  ...(bikSummary?.items.filter((item) => item.bikReportType === data.type) ?? []),
                  ...(bikSummary?.requests.filter((request) => request.bikReportType === data.type) ?? []),
                ];

                const preselectedType = items.length ? items[0].packageName || 'CUSTOM' : '';

                return (
                  <ProductCard
                    isCheckboxHidden
                    isClientView={isClientView}
                    isDisabled={isReportDisabled}
                    key={index}
                    data={data}
                    type={data.type}
                    expandCard={
                      <BIKCard
                        bikId={rawBIKReport?.id || ''}
                        isClientView={isClientView}
                        isDisabled={isReportDisabled}
                        preselectedType={preselectedType}
                        services={data.services}
                        type={data.type}
                        previouslySelectedItems={bikSummary?.items || []}
                        previouslySelectedRequests={bikSummary?.requests || []}
                      />
                    }
                  />
                );
              })}
              {!isClientView && (
                <PromoCode
                  isDisabled={isReportDisabled}
                  reportPromoCode={rawBIKReport?.discountCode || ''}
                  reportId={params?.id || ''}
                />
              )}
            </Container>
            <Box
              mt={isMobile ? 0 : 3}
              sx={{
                mr: isMobile ? 'none' : 3,
                maxWidth: isMobile ? 'auto' : '450px',
                position: isMobile ? 'static' : 'sticky', // Sticky for desktop, static for mobile
                width: isMobile ? '100%' : '30%',
                borderRadius: 2,
                backgroundColor: theme.palette.background.paper, // Ensure background blends with the page
              }}
            >
              <ReportPartnerSummary
                isClientView={isClientView}
                reportId={params?.id ?? 'Wrong_param'}
                bikData={bikData || []}
              />
            </Box>
          </>
        )}
      </Box>

      {commissionTestJSON && <Box data-testid="commission-test" data-json={commissionTestJSON} />}

      <Footer />

      <CommunicationPreferencesModal
        isOpen={!!failedReportId}
        onClose={handleClosePreferences}
        onSubmit={handlePreferencesSubmit}
      />
    </SelectedServiceProvider>
  );
}

import { Box } from '@mui/material';
import { PartnerDetails } from 'src/types/PartnerDetails';

interface UserConsentsProps {
  user: PartnerDetails;
}

export const UserConsents = ({ user }: UserConsentsProps) => {
  return (
    <>
      <Box className="additional-info">
        <div className="label">
          Czy zaakceptowano zgody RODO?{' '}
          {user.hasRODOConsentsAccepted && user.hasMarketingConsentsAccepted ? 'Tak' : 'Nie'}
        </div>
      </Box>
    </>
  );
};

import type { ThemeOptions } from '@mui/material/styles';
import { ThemeProvider, createTheme, useColorScheme } from '@mui/material/styles';
import { Fragment, useEffect, useMemo } from 'react';
import { getUserDetailsFromLocalStorage } from 'src/common/roles';
import { dataDisplayCustomizations } from './customizations/dataDisplay';
import { feedbackCustomizations } from './customizations/feedback';
import { inputsCustomizations } from './customizations/inputs';
import { navigationCustomizations } from './customizations/navigation';
import { surfacesCustomizations } from './customizations/surfaces';
import { colorSchemes, shadows, shape, typography } from './themePrimitives';

interface AppThemeProps {
  children: React.ReactNode;
  /**
   * This is for the docs site. You can ignore it or remove it.
   */
  disableCustomTheme?: boolean;
  themeComponents?: ThemeOptions['components'];
}

const ModeChecker: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { mode, setMode } = useColorScheme();

  useEffect(() => {
    if (!mode) {
      return;
    }

    if (mode === 'system') {
      setMode('light');
    }
  }, [mode, setMode]);

  return children;
};

export default function AppTheme({ children, disableCustomTheme, themeComponents }: AppThemeProps) {
  useEffect(() => {
    const { FS } = window as any;
    const userData = getUserDetailsFromLocalStorage();

    if (FS && userData?.id) {
      FS('setIdentity', {
        uid: userData.id,
        properties: {
          email: userData.email,
          displayName: `${userData.firstName} ${userData.lastName}`,
        },
      });
    }
  }, []);

  const theme = useMemo(() => {
    return disableCustomTheme
      ? {}
      : createTheme({
          // For more details about CSS variables configuration, see https://mui.com/material-ui/customization/css-theme-variables/configuration/
          cssVariables: {
            colorSchemeSelector: 'data-mui-color-scheme',
            cssVarPrefix: 'template',
          },
          colorSchemes, // Recently added in v6 for building light & dark mode app, see https://mui.com/material-ui/customization/palette/#color-schemes
          typography,
          shadows,
          shape,
          components: {
            ...inputsCustomizations,
            ...dataDisplayCustomizations,
            ...feedbackCustomizations,
            ...navigationCustomizations,
            ...surfacesCustomizations,
            ...themeComponents,
          },
        });
  }, [disableCustomTheme, themeComponents]);
  if (disableCustomTheme) {
    return <Fragment>{children}</Fragment>;
  }

  return (
    <ThemeProvider theme={theme} disableTransitionOnChange>
      <ModeChecker>{children}</ModeChecker>
    </ThemeProvider>
  );
}

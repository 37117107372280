import { CommissionProductType, CommissionType } from 'src/types/PartnerDetails';

export const CommissionTranslation: Record<CommissionProductType, string> = {
  BIK_CLEANING: 'Optymalizacja BIK',
  SKD: 'SKD',
  REFUND_OF_COMMISSION: 'Zwrot prowizji',
  UNWIBOR: 'Odwiborowanie',
};

export const CommissionTypeTranslation: Record<CommissionType, string> = {
  PERCENTAGE: 'Procentowa',
  CONSTANT_VALUE: 'Stała',
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import { BIKReportType } from 'src/types/BIKReport.type';
import { BIKItem } from 'src/types/CompanyBIKSummary.type';
import { SelectedRow } from 'src/types/SelectedRow';
import { mapBIKItemToSelectedRow } from 'src/utils/mapBIKContent';
import { getDebtInfo } from './utils';

type Props = {
  selectedIds: string[];
  isSelectedAll: boolean;
  type: BIKReportType;
  bikItems: BIKItem[];
};

export const useCheckedItems = ({ selectedIds, bikItems, isSelectedAll, type }: Props) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [checkedItems, setCheckedItems] = useState<SelectedRow[]>([]);
  const [isSelectAllItemsChecked, setIsSelectAllItemsChecked] = useState(isSelectedAll);

  const handleCheckboxItemsChange = (bikItem: BIKItem) => {
    if (checkedItems.find((item) => item.id === bikItem.id)) {
      setCheckedItems(checkedItems.filter((i) => i.id !== bikItem.id));
    } else {
      setCheckedItems([...checkedItems, mapBIKItemToSelectedRow(bikItem, type)]);
    }
    setIsSelectAllItemsChecked(false);
  };

  const openItems: BIKItem[] = useMemo(() => bikItems.filter((item) => item.isOpen), [bikItems]);
  const closedItems: BIKItem[] = useMemo(() => bikItems.filter((item) => !item.isOpen), [bikItems]);

  useEffect(() => {
    if (!selectedIds.length || isInitialized) {
      return;
    }

    const selectedItems: SelectedRow[] = [];

    openItems.forEach((item) => {
      if (!selectedIds.includes(item.id)) {
        return;
      }

      selectedItems.push(mapBIKItemToSelectedRow(item, type));
    });

    closedItems.forEach((item) => {
      if (!selectedIds.includes(item.id)) {
        return;
      }

      selectedItems.push(mapBIKItemToSelectedRow(item, type));
    });

    setIsInitialized(true);
    setCheckedItems(selectedItems);
  }, [openItems, closedItems, selectedIds, type]);

  const onChangeAllItems = useCallback(
    (isSelectedAll?: boolean) => {
      const allSelectableOpenItems: SelectedRow[] = openItems
        .filter((item) =>
          isSelectedAll ? item.enableToRemove && getDebtInfo(item).isRecommendedToRemove : item.enableToRemove
        )
        .map((item) => mapBIKItemToSelectedRow(item, type));

      const allSelectableClosedItems: SelectedRow[] = closedItems
        .filter((item) =>
          isSelectedAll ? item.enableToRemove && getDebtInfo(item).isRecommendedToRemove : item.enableToRemove
        )
        .map((item) => mapBIKItemToSelectedRow(item, type));

      if (isSelectedAll !== undefined) {
        setIsSelectAllItemsChecked(isSelectedAll);
        setCheckedItems(isSelectedAll ? [...allSelectableOpenItems, ...allSelectableClosedItems] : []);
        return;
      }

      setIsSelectAllItemsChecked(!isSelectAllItemsChecked);
      setCheckedItems(!isSelectAllItemsChecked ? [...allSelectableOpenItems, ...allSelectableClosedItems] : []);
    },
    [openItems, closedItems, checkedItems, isSelectAllItemsChecked, type]
  );

  return { openItems, isSelectAllItemsChecked, closedItems, checkedItems, onChangeAllItems, handleCheckboxItemsChange };
};

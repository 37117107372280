import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse, IconButton, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { ImmediatePaymentInfo } from './ImmediatePaymentInfo';
import { OrderSection } from './OrderSection/OrderSection';
import { OrderSectionTypeItem } from './OrderSection/OrderSection.types';
import { OrderSummary } from './OrderSection/OrderSummary';
import { OrderSummaryVariant } from './OrderSummaryVariant.types';
import { PaymentInfo } from './PaymentInfo';

const titleMap: Record<string, string> = {
  BIK: 'Pozycje wybrane do przygotowania dokumentacji do optymalizacji BIK',
  SKD: 'Pozycje wybrane do ubiegania się o zwrot pieniędzy w ramach procesu Sankcji Kredytu Darmowego',
  VIBOR: 'Ubieganie się o zwrot pieniędzy od banku w ramach procesu Odwiborowania',
  COMMISSION: 'Pozycje wybrane do ubiegania się o zwrot pieniędzy w ramach procesu Zwrotu prowizji',
};

interface CollapsibleSectionProps {
  type: 'SKD' | 'VIBOR' | 'BIK' | 'COMMISSION';
  items: OrderSectionTypeItem[];
  totalDiscountPrice?: number;
  orderNumber: string;
  className?: string;
  summaryVariant?: OrderSummaryVariant;
}

function calculateTotals(items: OrderSectionTypeItem[], discountPrice?: number) {
  const netTotal = items.reduce((sum, item) => sum + item.price, 0);
  const discountTotal = discountPrice || items.reduce((sum, item) => sum + item.discountPrice, 0);

  return {
    netTotal,
    discountTotal,
    vatAmount: (discountTotal !== netTotal ? discountTotal : netTotal) * 0.23,
    grossTotal: (discountTotal !== netTotal ? discountTotal : netTotal) * 1.23,
  };
}

export function CollapsibleSection({
  type,
  items,
  totalDiscountPrice,
  orderNumber,
  className = '',
  summaryVariant = 'default',
}: CollapsibleSectionProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const isSanctionSection = titleMap[type].includes('się o zwrot pieniędzy');
  const isBikSection = titleMap[type].includes('optymalizacji BIK');

  const { netTotal, vatAmount, grossTotal, discountTotal } = calculateTotals(items, totalDiscountPrice);

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box className={className}>
      {/* Header */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          cursor: 'pointer',
          mb: 2,
          p: 2,
          borderRadius: 2,
          backgroundColor: isExpanded ? (isDarkMode ? 'grey.800' : 'grey.200') : isDarkMode ? 'grey.900' : 'grey.50',
        }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Box display="flex" alignItems="center">
          <Box
            sx={{
              width: 8,
              height: 8,
              backgroundColor: 'indigo.500',
              borderRadius: '50%',
              mr: 2,
            }}
          />
          <Typography variant="h6" fontWeight="bold" color={isDarkMode ? 'grey.100' : 'text.primary'}>
            {titleMap[type]}
          </Typography>
        </Box>
        <IconButton sx={{ color: isDarkMode ? 'grey.400' : 'text.secondary' }}>
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      <Collapse in={isExpanded}>
        <Box>
          <OrderSection title="" items={items} variant={summaryVariant} />
        </Box>
      </Collapse>
      <Box
        sx={{
          backgroundColor: isDarkMode ? 'grey.900' : 'grey.50',
          p: 2,
          borderRadius: 2,
          mb: 2,
        }}
      >
        <OrderSummary
          orderNumber={orderNumber}
          netTotal={netTotal}
          discountTotal={discountTotal}
          vatAmount={vatAmount}
          grossTotal={grossTotal}
          variant={summaryVariant}
        />
        {isSanctionSection && <PaymentInfo type={type} />}
        {isBikSection && <ImmediatePaymentInfo />}
      </Box>
    </Box>
  );
}

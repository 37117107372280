import { Box, Chip, Stack, useTheme } from '@mui/material';

export const RowDetailsIcon = ({ isExpanded, toggleExpand }: { isExpanded: boolean; toggleExpand: () => void }) => {
  const theme = useTheme(); // Access the current theme

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        cursor: 'pointer',
        height: '100%',
      }}
    >
      <Stack direction="row" spacing={1}>
        <Chip
          size="small"
          data-testid="bik-info-action"
          onClick={toggleExpand}
          label="Szczegóły"
          sx={{
            borderColor: isExpanded ? theme.palette.warning.main : theme.palette.warning.light, // Adaptive border color
            background: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.warning.light, // Adaptive background
            '& .MuiChip-label': {
              color: theme.palette.mode === 'dark' ? theme.palette.warning.contrastText : theme.palette.warning.dark, // Adaptive font color
            },
            '&:hover': {
              borderColor: theme.palette.warning.light,
              background: theme.palette.warning.light, // Hover background
              '& .MuiChip-label': {
                color: theme.palette.warning.contrastText, // Ensure text remains readable on hover
              },
            },
          }}
        />
      </Stack>
    </Box>
  );
};

import { styled } from '@mui/material';
import Grid from '@mui/material/Grid2';

const forbiddenProps = ['$longestDebt', '$isRemovable'];

export const BIKSection = styled(Grid, { shouldForwardProp: (prop) => !forbiddenProps.includes(prop as string) })<{
  $longestDebt?: number;
  $isRemovable: boolean;
}>(({ $isRemovable, theme }) => ({
  borderRadius: '8px',
  border: `2px solid ${theme.palette.mode === 'dark' ? '#444' : '#00000014'}`, // Dynamic border color
  padding: '16px',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#ffffff', // Adaptive background
  color: theme.palette.text.primary, // Text color adapts to theme
  transition: $isRemovable ? 'transform 0.3s ease-in-out' : '',
  cursor: $isRemovable ? 'pointer' : '',
  '&:hover': $isRemovable
    ? {
        transform: 'scale(1.02)',
        boxShadow: theme.palette.mode === 'dark' ? '0 8px 16px rgba(0, 0, 0, 0.8)' : '0 4px 8px rgba(0, 0, 0, 0.1)', // Dynamic hover shadow
      }
    : {},
}));

export const GridTitle = styled(Grid)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '0.9em',
  color: theme.palette.text.secondary, // Adjust text color for dark mode
}));

export const BIKItem = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#ffffffe6', // Adaptive background
  textAlign: 'center',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.mode === 'dark' ? '#555' : '#e8e8e88a'}`, // Dynamic border color
  boxShadow: theme.palette.mode === 'dark' ? '0 8px 16px rgba(0, 0, 0, 0.8)' : '0 4px 8px rgba(0, 0, 0, 0.1)', // Dynamic shadow
  color: theme.palette.text.primary, // Adaptive text color
}));

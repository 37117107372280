import { Box, Container, useTheme } from '@mui/material';
import { ReactNode } from 'react';

interface PageContainerProps {
  children: ReactNode;
}

export function PageContainer({ children }: PageContainerProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        minHeight: '100vh',
        pt: 12,
        pb: { xs: 2, sm: 3 },
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: { xs: 'scroll', sm: 'fixed' },
        color: theme.palette.text.primary, // Adjust text color based on theme
      }}
    >
      <Container>{children}</Container>
    </Box>
  );
}

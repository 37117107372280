import { round } from 'lodash';

export const formatPrice = (value?: string | number ): string =>
  (Math.round(Number(value ?? 0) * 100) / 100).toFixed(2);

const normalizeTaxRate = (taxRate: number): number => (100 + Number(taxRate)) / 100;

export const applyTaxRate = (value: number | string , taxRate: number): number =>
  round(Number(value || 0) * normalizeTaxRate(taxRate), 2);

export const removeTax = (valueWithTax: number | string, taxRate: number): number =>
  round(Number(valueWithTax || 0) / normalizeTaxRate(taxRate), 2);

import { Button as MuiButton } from '@mui/material';
import { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

interface ButtonProps extends Omit<MuiButtonProps, 'variant'> {
  variant?: 'primary' | 'secondary' | 'outline';
  isLoading?: boolean;
}

export const Button = ({
  variant = 'primary',
  isLoading,
  children,
  startIcon,
  ...props
}: ButtonProps) => {
  const getMuiVariant = (): MuiButtonProps['variant'] => {
    switch (variant) {
      case 'primary':
        return 'contained';
      case 'secondary':
        return 'text';
      case 'outline':
        return 'outlined';
      default:
        return 'contained';
    }
  };

  return (
    <MuiButton
      variant={getMuiVariant()}
      disabled={isLoading || props.disabled}
      startIcon={!isLoading && startIcon}
      {...props}
    >
      {isLoading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <CircularProgress size={20} color="inherit" />
          Ładowanie...
        </Box>
      ) : (
        children
      )}
    </MuiButton>
  );
};
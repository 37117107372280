import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import { SKDResponse } from 'src/types/SDKResponse';

interface SKDModalProps {
  open: boolean;
  data: SKDResponse | null;
  onClose: () => void;
}

const SKDModal: React.FC<SKDModalProps> = ({ open, data, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Reusable styles
  const styles = {
    dialog: {
      margin: isMobile ? 0 : undefined,
    },
    dialogTitle: {
      textAlign: 'center',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
    dialogContent: {
      px: isMobile ? 0 : 4,
      py: isMobile ? 0 : 3,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    tableContainer: {
      borderRadius: 2,
      boxShadow: theme.shadows[3],
      flexGrow: 1, // Ensures the table takes available height
      overflowY: 'auto',
    },
    tableHeadCell: {
      fontWeight: 'bold',
      color: theme.palette.common.white,
      backgroundColor: alpha(theme.palette.primary.dark, 0.85),
      position: 'sticky',
      top: 0,
      zIndex: 2,
    },
    tableRowOdd: {
      backgroundColor: alpha(theme.palette.primary.light, 0.1),
    },
    dialogActions: {
      justifyContent: isMobile ? 'center' : 'flex-end',
      px: isMobile ? 0 : 2,
      pb: isMobile ? 1 : 2,
    },
    closeButton: {
      px: isMobile ? 3 : 5,
    },
    emptyStateText: {
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  };

  if (!data) return null;

  // Sort the entries by `qualifyForSKD` in descending order
  const sortedEntries = [...data.entries].sort((a, b) => Number(b.qualifyForSKD) - Number(a.qualifyForSKD));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      sx={styles.dialog}
      PaperProps={{
        sx: {
          height: '100%',
          borderRadius: isMobile ? 0 : undefined, // Remove border radius for mobile
        },
      }}
    >
      <DialogTitle sx={styles.dialogTitle}>
        Szczegóły SKD - {data.name} ({data.pesel})
      </DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        {sortedEntries.length === 0 ? (
          <Typography variant="body1" sx={styles.emptyStateText}>
            Brak danych SKD do wyświetlenia.
          </Typography>
        ) : (
          <TableContainer component={Paper} sx={styles.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={styles.tableHeadCell}>Kwalifikacja SKD</TableCell>
                  <TableCell sx={styles.tableHeadCell}>Możliwy Zysk SKD</TableCell>
                  <TableCell sx={styles.tableHeadCell}>Kwota Początkowa</TableCell>
                  <TableCell sx={styles.tableHeadCell}>Kwota Bieżąca</TableCell>
                  <TableCell sx={styles.tableHeadCell}>Rata</TableCell>
                  <TableCell sx={styles.tableHeadCell}>Rodzaj Wierzyciela</TableCell>
                  <TableCell sx={styles.tableHeadCell}>Opis Typu</TableCell>
                  <TableCell sx={styles.tableHeadCell}>Wierzyciel</TableCell>
                  <TableCell sx={styles.tableHeadCell}>Rozpoczęto</TableCell>
                  <TableCell sx={styles.tableHeadCell}>Data Ostatniej Raty</TableCell>
                  <TableCell sx={styles.tableHeadCell}>Liczba Spłaconych Rat</TableCell>
                  <TableCell sx={styles.tableHeadCell}>Zamknięte</TableCell>
                  <TableCell sx={styles.tableHeadCell}>Dni od Zamknięcia</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedEntries.map((entry, index) => (
                  <TableRow key={index} sx={index % 2 === 1 ? styles.tableRowOdd : undefined}>
                    <TableCell>{entry.qualifyForSKD ? 'Tak' : 'Nie'}</TableCell>
                    <TableCell>{entry.possibleSKDProfit.toLocaleString()} zł</TableCell>
                    <TableCell>{entry.startingAmount.toLocaleString()} zł</TableCell>
                    <TableCell>{entry.currentAmount.toLocaleString()} zł</TableCell>
                    <TableCell>{entry.installment.toLocaleString()} zł</TableCell>
                    <TableCell>{entry.creditorType}</TableCell>
                    <TableCell>{entry.typeDescription}</TableCell>
                    <TableCell>{entry.creditor}</TableCell>
                    <TableCell>{entry.startedAt}</TableCell>
                    <TableCell>{entry.lastInstallmentDate}</TableCell>
                    <TableCell>{entry.numberOfPaidInstallments}</TableCell>
                    <TableCell>{entry.alreadyClosed ? 'Tak' : 'Nie'}</TableCell>
                    <TableCell>{entry.daysFromClosing}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions sx={styles.dialogActions}>
        <Button onClick={onClose} color="primary" variant="contained" sx={styles.closeButton}>
          Zamknij
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SKDModal;

import { Box, Chip, styled } from '@mui/material';
import { FunctionComponent } from 'react';
import { RoleType } from 'src/common/roles';
import { PartnerDetails } from 'src/types/PartnerDetails';

interface UserTypeProps {
  user: PartnerDetails;
}

const StyledChip = styled(Chip)`
  text-transform: uppercase;
  display: inline;
  margin-right: 4px;
`;

const getChipColorByType = (type: RoleType) => {
  switch (type) {
    case 'ROLE_PARTNER':
      return 'secondary';
    case 'ROLE_MANAGER':
      return 'success';
    case 'ROLE_ADMIN':
      return 'info';
    default:
      return;
  }
};

export const UserType: FunctionComponent<UserTypeProps> = ({ user }) => {
  return (
    <div className="user-type">
      <Box display="flex" alignItems="center" justifyContent="center" className="agent-name">
        <Box fontWeight={600}>
          {user.firstName} {user.lastName}
        </Box>
      </Box>
      {user.roles.map((role) => (
        <Box mt={1} key={role} display="flex" justifyContent="center">
          <StyledChip color={getChipColorByType(role)} label={role} size="small" data-testid="user-type" />
        </Box>
      ))}
    </div>
  );
};

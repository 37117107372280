import { Inbox, SearchOff } from '@mui/icons-material';
import { Box, Paper, Typography, useTheme } from '@mui/material';
import React from 'react';

interface NoServicesMessageProps {
  title?: string;
  message?: string;
}

const NoServicesMessage: React.FC<NoServicesMessageProps> = ({
  title = 'Brak usług do wyświetlenia',
  message = 'Nie masz żadnych usług do wyświetlenia w tej chwili dla wgranych raportów.',
}) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';

  return (
    <Paper
      elevation={0}
      sx={{
        padding: 5,
        borderRadius: 4,
        backgroundColor: isDark ? 'rgba(30, 30, 30, 0.7)' : 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(10px)',
        border: `1px solid ${theme.palette.divider}`,
        maxWidth: 500,
        margin: '0 auto',
        textAlign: 'center',
        transition: 'all 0.3s ease',
        '&:hover': {
          boxShadow: isDark ? '0 8px 30px rgba(0, 0, 0, 0.6)' : '0 8px 30px rgba(0, 0, 0, 0.12)',
          transform: 'translateY(-5px)',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mb: 3,
        }}
      >
        <Box
          sx={{
            position: 'relative',
            height: 80,
            width: 80,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Inbox
            sx={{
              fontSize: 70,
              color: theme.palette.primary.main,
              opacity: 0.7,
              position: 'absolute',
            }}
          />
          <SearchOff
            sx={{
              fontSize: 30,
              color: theme.palette.error.main,
              position: 'absolute',
              bottom: 5,
              right: 5,
              backgroundColor: isDark ? theme.palette.background.paper : 'white',
              borderRadius: '50%',
              padding: 0.5,
              boxShadow: isDark ? '0 2px 10px rgba(0,0,0,0.5)' : '0 2px 10px rgba(0,0,0,0.1)',
            }}
          />
        </Box>
      </Box>

      <Typography
        variant="h5"
        component="h2"
        sx={{
          fontWeight: 600,
          mb: 1,
          background: `linear-gradient(120deg, ${theme.palette.primary.main}, ${
            theme.palette.secondary.main || theme.palette.primary.dark
          })`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        {title}
      </Typography>

      <Typography variant="body1" color="text.secondary" sx={{ mt: 1, lineHeight: 1.6 }}>
        {message}
      </Typography>
    </Paper>
  );
};

export default NoServicesMessage;

import { AccountBalanceWalletOutlined, PercentOutlined, ShieldOutlined } from '@mui/icons-material';
import { Box, Card, CardContent, Container, Stack, Typography, useColorScheme } from '@mui/material';
import { motion } from 'framer-motion';
import { FAQ } from './FAQ';

const benefits = [
  {
    icon: PercentOutlined,
    title: 'Niższe Oprocentowanie',
    description: 'Uzyskaj lepsze warunki kredytów i oszczędzaj na odsetkach.',
  },
  {
    icon: AccountBalanceWalletOutlined,
    title: 'Wyższe Limity Kredytowe',
    description: 'Kwalifikuj się na większe kredyty i lepsze produkty finansowe.',
  },
  {
    icon: ShieldOutlined,
    title: 'Wolność Finansowa',
    description: 'Przejmij kontrolę nad swoją przyszłością finansową z czystym raportem kredytowym.',
  },
];

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const item = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 },
};

export function Benefits() {
  const { mode } = useColorScheme();

  return (
    <Box
      sx={{
        py: 10,
        background:
          mode === 'dark'
            ? 'linear-gradient(to bottom right, #1a1a1a, #2d2d2d)'
            : 'linear-gradient(to bottom right, #f8fafc, #ffffff)',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Container maxWidth="lg">
        <motion.div initial={{ opacity: 0, y: 20 }} whileInView={{ opacity: 1, y: 0 }} viewport={{ once: true }}>
          <Stack spacing={2} alignItems="center" mb={8}>
            <Typography
              variant="h2"
              sx={{
                background:
                  mode === 'dark'
                    ? 'linear-gradient(to right, #60a5fa, #818cf8)'
                    : 'linear-gradient(to right, #2563eb, #4f46e5)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Dlaczego Wyczyścić Raport BIK?
            </Typography>
            <Typography variant="h5" color="text.secondary">
              Przekształć swoją przyszłość finansową z lepszą zdolnością kredytową
            </Typography>
          </Stack>
        </motion.div>

        <Box
          component={motion.div}
          variants={container}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true }}
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', md: 'repeat(3, 1fr)' },
            gap: 4,
            mb: 10,
          }}
        >
          {benefits.map((benefit, index) => (
            <Card
              key={index}
              component={motion.div}
              variants={item}
              whileHover={{ scale: 1.05 }}
              sx={{
                position: 'relative',
                overflow: 'hidden',
                transition: 'all 0.3s ease',
                bgcolor: mode === 'dark' ? 'background.paper' : 'white',
                '&:hover': {
                  boxShadow: (theme) => theme.shadows[8],
                  '& .benefit-overlay': {
                    opacity: 0.05,
                  },
                },
              }}
            >
              <Box
                className="benefit-overlay"
                sx={{
                  position: 'absolute',
                  inset: 0,
                  background:
                    mode === 'dark'
                      ? 'linear-gradient(to bottom right, #60a5fa, #818cf8)'
                      : 'linear-gradient(to bottom right, #2563eb, #4f46e5)',
                  opacity: 0,
                  transition: 'opacity 0.3s ease',
                }}
              />
              <CardContent sx={{ position: 'relative', zIndex: 1 }}>
                <benefit.icon
                  sx={{
                    fontSize: 48,
                    color: 'primary.main',
                    mb: 2,
                    transform: 'scale(1)',
                    transition: 'transform 0.3s ease',
                    '.MuiCard-root:hover &': {
                      transform: 'scale(1.1)',
                    },
                  }}
                />
                <Typography variant="h6" gutterBottom>
                  {benefit.title}
                </Typography>
                <Typography color="text.secondary">{benefit.description}</Typography>
              </CardContent>
            </Card>
          ))}
        </Box>

        <motion.div
          id="faq"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
        >
          <Stack id="faq" spacing={2} alignItems="center" mb={6}>
            <Typography
              variant="h2"
              sx={{
                background:
                  mode === 'dark'
                    ? 'linear-gradient(to right, #60a5fa, #818cf8)'
                    : 'linear-gradient(to right, #2563eb, #4f46e5)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Często Zadawane Pytania
            </Typography>
            <Typography variant="h5" color="text.secondary">
              Wszystko, co musisz wiedzieć o poprawie swojej zdolności kredytowej
            </Typography>
          </Stack>
        </motion.div>

        <FAQ />
      </Container>
    </Box>
  );
}

import { ArrowForward, CheckCircle, Info } from '@mui/icons-material';
import { Box, Button, Divider, Modal, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Logo } from '../app-toolbar/Logo';

interface MissingPartnerPermissionsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const MissingPartnerPermissionsModal: React.FC<MissingPartnerPermissionsModalProps> = ({ isOpen, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleNavigateToMainPage = () => {
    navigate('/');
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      aria-labelledby="permission-modal-title"
      aria-describedby="permission-modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: isMobile ? 2 : 4,
      }}
    >
      <Paper
        elevation={8}
        sx={{
          maxWidth: 500,
          width: '100%',
          p: 0,
          borderRadius: 3,
          position: 'relative',
          outline: 'none',
          overflow: 'hidden',
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[10],
        }}
      >
        {/* Nagłówek */}
        <Box
          sx={{
            bgcolor: theme.palette.mode === 'dark' ? '#3e4396' : '#6366f1',
            color: '#ffffff',
            p: isMobile ? 2 : 3,
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Info sx={{ fontSize: isMobile ? 28 : 32 }} />
          <Typography id="permission-modal-title" variant={isMobile ? 'h6' : 'h5'} component="h2" fontWeight="500">
            Brak uprawnień
          </Typography>
        </Box>

        {/* Ikona */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            py: isMobile ? 3 : 4,
            background:
              theme.palette.mode === 'dark'
                ? 'linear-gradient(145deg, rgba(62, 67, 150, 0.1), rgba(99, 102, 241, 0.05))'
                : 'linear-gradient(145deg, rgba(99, 102, 241, 0.05), rgba(224, 231, 255, 0.2))',
          }}
        >
          <Logo />
        </Box>

        <Divider sx={{ opacity: 0.6 }} />

        <Box sx={{ p: isMobile ? 2.5 : 4 }}>
          <Typography
            id="permission-modal-description"
            sx={{
              mt: 1,
              mb: 3,
              fontSize: isMobile ? '0.95rem' : '1rem',
            }}
          >
            Nie posiadasz odpowiednich uprawnień do analizy raportu BIK. Aby uzyskać dostęp, skontaktuj się ze swoim
            menedżerem lub administratorem systemu.
          </Typography>

          <Box
            sx={{
              mb: 3,
              p: isMobile ? 2 : 2.5,
              borderRadius: 2,
              display: 'flex',
              alignItems: 'flex-start',
              gap: 1.5,
              bgcolor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(99, 102, 241, 0.08)',
              border:
                theme.palette.mode === 'dark'
                  ? '1px solid rgba(255, 255, 255, 0.1)'
                  : '1px solid rgba(99, 102, 241, 0.2)',
            }}
          >
            <CheckCircle
              sx={{
                color: theme.palette.mode === 'dark' ? '#a5b4fc' : '#4f46e5',
                mt: 0.5,
                fontSize: isMobile ? '1rem' : '1.25rem',
              }}
            />
            <Typography
              sx={{
                fontStyle: 'italic',
                color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.7)',
                fontSize: isMobile ? '0.85rem' : '0.95rem',
              }}
            >
              Jeśli potrzebujesz pomocy, skontaktuj się z działem wsparcia technicznego.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <Button
              variant="contained"
              onClick={handleNavigateToMainPage}
              endIcon={<ArrowForward />}
              sx={{
                backgroundColor: theme.palette.mode === 'dark' ? '#3e4396' : '#6366f1',
                color: '#ffffff',
                px: isMobile ? 2 : 3,
                py: isMobile ? 1 : 1.2,
                fontWeight: 500,
                fontSize: isMobile ? '0.85rem' : '0.95rem',
                borderRadius: 2,
                textTransform: 'none',
                boxShadow: theme.shadows[3],
                '&:hover': {
                  backgroundColor: theme.palette.mode === 'dark' ? '#4d54c0' : '#4f46e5',
                  boxShadow: theme.shadows[5],
                },
              }}
            >
              Powrót do strony głównej
            </Button>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default MissingPartnerPermissionsModal;

import { Card, styled } from '@mui/material';

const forbiddenProps = ['$glowColor', '$isActive'];

export const StyledCard = styled(Card, { shouldForwardProp: (prop) => !forbiddenProps.includes(prop as string) })<{
  $glowColor: string;
  $isActive: boolean;
}>(({ $glowColor, $isActive, theme }) => ({
  textAlign: 'center',
  padding: '8px !important',
  borderRadius: 10,
  overflow: 'hidden',
  position: 'relative',
  transition: 'transform 0.2s',
  opacity: $isActive ? 1 : 0.5,
  boxShadow: $glowColor ? `0 0 15px ${$glowColor}` : 'none',
  height: '100%',
  cursor: 'pointer',
  transform: 'scale(1)',
  backgroundColor: theme.palette.common.background,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
}));

import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { Report } from 'src/types/Report';
import { formatPrice } from 'src/utils/price';
import { Price } from '../../../Price/Price';

interface BIKAdditionalDataProps {
  bikReport: Report;
}

export const BIKCommissionTotal: FunctionComponent<BIKAdditionalDataProps> = ({ bikReport }) =>
  'commissionValue' in bikReport && typeof bikReport.commissionValue === 'number' ? (
    <Typography fontSize="1.3rem" lineHeight={1}>
      <Price
        color="dodgerblue"
        data-testid="order-item-total-price"
        value={formatPrice(bikReport.commissionValue)}
        currency={'PLN'}
      />
    </Typography>
  ) : 'partnerCommissionValue' in bikReport && typeof bikReport.partnerCommissionValue === 'number' ? (
    <Typography fontSize="1.3rem" lineHeight={1}>
      <Price
        color="dodgerblue"
        data-testid="order-item-total-price"
        value={formatPrice(bikReport.partnerCommissionValue)}
        currency={'PLN'}
      />
    </Typography>
  ) : (
    <Price data-testid="order-item-total-price" color="dodgerblue" value={formatPrice(0)} currency={'PLN'} />
  );

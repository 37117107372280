import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { IconButton } from '@mui/material';
import { getUserDetailsFromLocalStorage } from 'src/common/roles';
import { DarkModeToggle } from '../app-toolbar/DarkModeToggle';
import MenuContent from './MenuContent';

interface SideMenuMobileProps {
  open: boolean | undefined;
  toggleDrawer: (newOpen: boolean) => () => void;
}

export default function SideMenuMobile({ open, toggleDrawer }: SideMenuMobileProps) {
  const userData = getUserDetailsFromLocalStorage();

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      sx={{
        [`& .${drawerClasses.paper}`]: {
          backgroundImage: 'none',
          backgroundColor: 'background.paper',
        },
      }}
    >
      <Stack
        sx={{
          maxWidth: '70dvw',
          height: '100%',
        }}
      >
        <Stack direction="row" sx={{ p: 2, pb: 0, gap: 1 }}>
          <Stack direction="row" sx={{ gap: 1, alignItems: 'center', flexGrow: 1, p: 1 }}>
            <Avatar sizes="small" src="/static/images/avatar/7.jpg" sx={{ width: 24, height: 24 }} />
            <Typography component="p" variant="h6">
              {userData.firstName} {userData.lastName}
            </Typography>
          </Stack>
        </Stack>

        <Divider />
        <Stack sx={{ flexGrow: 1 }}>
          <MenuContent />
          <Divider />
        </Stack>
        <IconButton sx={{ display: 'inline-flex', m: 1 }}>
          <DarkModeToggle />
        </IconButton>
      </Stack>
    </Drawer>
  );
}

import { Box, Paper, Typography } from '@mui/material';
import React from 'react';

interface InstructionStepProps {
  number: number;
  title: string;
  children: React.ReactNode;
}

const InstructionStep: React.FC<InstructionStepProps> = ({ number, title, children }) => {
  return (
    <Paper elevation={2} sx={{ p: 3, mb: 3, borderLeft: '4px solid #2196f3' }}>
      <Box sx={{ display: 'flex', alignItems: 'baseline', mb: 1 }}>
        <Typography variant="h5" component="h2" fontWeight="bold" sx={{ mr: 2 }}>
          {number}.
        </Typography>
        <Typography variant="h5" component="h2">
          {title}
        </Typography>
      </Box>
      <Box sx={{ ml: 4 }}>{children}</Box>
    </Paper>
  );
};

export default InstructionStep;

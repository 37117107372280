import { Box } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from 'src/components/utils';

export const Logout = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    logoutUser();
    queryClient.removeQueries();
    navigate('/sign-in?message=Zostałeś wylogowany.');
  }, [navigate, queryClient]);

  return <Box>Wylogowuję...</Box>;
};

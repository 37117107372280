import { List, SavingsOutlined } from '@mui/icons-material';
import { Box, CardContent, Typography, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { BIKItem, BIKRequest, CompanyBIKSummary } from 'src/types/CompanyBIKSummary.type';
import { StyledCard } from './CustomStyles';
import { renderInfoBox } from './renderInfoBox';

interface ServiceBIKTileProps {
  service: CompanyBIKSummary;
  onClick: () => void;
}

export function ServiceBIKTile({ service, onClick }: ServiceBIKTileProps) {
  const theme = useTheme();

  const getMaxPrice = useCallback(
    (items: (BIKItem | BIKRequest)[]) =>
      items.reduce(
        (acc, item) => {
          if (item.enableToRemove) {
            acc.profit += item.partnerCommission || 0;
            acc.price += item.discountPrice;
            acc.count += 1;
          }
          return acc;
        },
        { price: 0, profit: 0, count: 0 }
      ),
    []
  );

  const calculateMaxValues = () => {
    const maxPriceCompanyItems = getMaxPrice(service.company?.items || []);
    const maxPriceCompanyRequests = getMaxPrice(service.company?.requests || []);
    const maxPriceCustomerItems = getMaxPrice(service.customer?.items || []);
    const maxPriceCustomerRequests = getMaxPrice(service.customer?.requests || []);

    return {
      maxPrice:
        maxPriceCompanyItems.price +
        maxPriceCompanyRequests.price +
        maxPriceCustomerItems.price +
        maxPriceCustomerRequests.price,
      maxCount:
        maxPriceCompanyItems.count +
        maxPriceCompanyRequests.count +
        maxPriceCustomerItems.count +
        maxPriceCustomerRequests.count,
      maxProfit:
        maxPriceCompanyItems.profit +
        maxPriceCompanyRequests.profit +
        maxPriceCustomerItems.profit +
        maxPriceCustomerRequests.profit,
    };
  };

  const { maxPrice, maxCount } = calculateMaxValues();

  return (
    <StyledCard onClick={onClick} $glowColor="" data-testid="service-bik-tile">
      <CardContent sx={{ width: '100%' }}>
        <Typography variant="h5" component="h3" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
          Optymalizacja BIK
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          {/* {!!maxProfit &&
            renderInfoBox(
              <SavingsOutlined sx={{ width: 30, height: 30, color: theme.palette.success.main }} />,
              theme.palette.success.light,
              'Maks. zysk partnera:',
              maxProfit,
              true,
              theme
            )} */}
          {renderInfoBox(
            <SavingsOutlined sx={{ width: 30, height: 30, color: theme.palette.success.main }} />,
            theme.palette.success.light,
            'Maks. cena:',
            maxPrice.toFixed(2),
            true,
            theme
          )}
          {renderInfoBox(
            <List sx={{ width: 28, height: 28, color: theme.palette.primary.main }} />,
            theme.palette.primary.light,
            'Ilość pozycji rekomendowanych do zmiany:',
            maxCount,
            false,
            theme
          )}
        </Box>
      </CardContent>
    </StyledCard>
  );
}

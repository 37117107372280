import { Paper, Theme, alpha, styled } from '@mui/material';

interface BIKHeadingContainerProps {
  isCancelled?: boolean;
  isCompleted?: boolean;
  isAttentionRequired?: boolean;
  isExpandable?: boolean;
  isDraft?: boolean;
}

export const RowContainer = styled('div')({
  width: '100%',
  margin: '1rem 0',

  '.MuiSkeleton-rectangular': {
    borderRadius: '4px',
  },
});

const getHeadingColor = (theme: Theme, headingProps: BIKHeadingContainerProps) => {
  if (headingProps.isCancelled) {
    return theme.palette.error.main;
  }
  if (headingProps.isDraft) {
    return theme.palette.info.main;
  }

  if (headingProps.isCompleted) {
    return theme.palette.success.main;
  }
  
  if (headingProps.isAttentionRequired) {
    return theme.palette.warning.main;
  }

  return theme.palette.text.secondary;
};

export const RowHeadingContainer = styled(Paper, {
  shouldForwardProp: prop =>
    prop !== 'isExpandable' &&
    prop !== 'isCancelled' &&
    prop !== 'isCompleted' &&
    prop !== 'isAttentionRequired' &&
    prop !== 'isDraft',
})<BIKHeadingContainerProps>`
  ${({ theme, ...props }) => `
    padding: 1rem;
    border-left: 6px solid ${getHeadingColor(theme, props)};
    background: ${alpha(getHeadingColor(theme, props), 0.15)};
    border-radius: 6px;
    &:hover {
      background: ${alpha(getHeadingColor(theme, props), 0.2)};
    }

    cursor: ${props.isExpandable ? 'pointer' : 'default'};

    .additional-info {
      font-size: 0.9rem;
      display: flex;
      margin-top: 0.2rem;
      &.vertical-centered {
        align-items: center;
      }
      .label {
        align-content: center;
        color: ${theme.palette.text.secondary};
        text-transform: uppercase;
        padding-right: 1rem;
        font-weight: 300;
      }

      .clipboard-element {
        padding-left: 0.4rem;
        height: 1.15rem;

        .MuiIconButton-root {
          margin: -16px 0;
        }
      }

      .value {
        color: ${theme.palette.text.primary};
      }
    }

    .status {
      color: ${theme.palette.text.primary};
    }

    .phone {
      font-size: 1.2rem;
      color: ${theme.palette.text.primary};
      margin: 0.2rem 0;
    }

    .agent-name {
      color: ${theme.palette.text.secondary};
      font-weight: 200;

      .icon {
        font-size: 1.2rem;
      }
    }

    .composition-duration {
      color: ${theme.palette.text.secondary};
      font-weight: 200;
      font-size: 0.8rem;
    }
  `}
`;

export const ExpandedContainer = styled('div')(({ theme }) => ({
  margin: '1rem 0 4rem 0',

  '.order-details-table': {
    display: 'table',

    '.details-row': {
      display: 'table-row',
      fontSize: '0.9rem',

      '.key': {
        padding: '0.2rem',
        color: theme.palette.text.secondary,
        textTransform: 'uppercase',
      },

      '.key, .value': {
        display: 'table-cell',
      },

      '.space-cell': {
        display: 'table-cell',
        width: '1rem',
      },
    },
  },
}));

export const CreationInProgressLabel = styled('div')(({ theme }) => ({
  fontSize: '0.9rem',
  color: theme.palette.text.primary,
}));

export const SkeletonWrapper = styled('div')(({ theme }) => ({
  background: theme.palette.background.default,
  padding: '1rem',
  cursor: 'pointer',
  margin: '1rem 0',
}));

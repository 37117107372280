import { alpha, Box, Stack } from '@mui/material';
import React from 'react';
import AppNavbar from 'src/components/dashboard-navbar/AppNavbar';
import DashboardHeader from 'src/components/dashboard-navbar/DashboardHeader';
import SideMenu from 'src/components/dashboard-navbar/SideMenu';
import Footer from 'src/components/Footer';
import ScrollToTop from 'src/components/ScrollToTop';

type DashboardContainerProps = {
  title: string;
  children: React.ReactNode;
};

const DashboardContainer: React.FunctionComponent<DashboardContainerProps> = ({ title, children }) => {
  return (
    <>
      <ScrollToTop />

      <Box sx={{ display: 'flex' }}>
        <SideMenu />
        <AppNavbar />
        {/* Main content */}
        <Box
          component="main"
          sx={(theme) => ({
            flexGrow: 1,
            backgroundColor: theme.vars
              ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
              : alpha(theme.palette.background.default, 1),
            overflow: 'auto',
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              mx: 3,
              pb: 10,
              mt: { xs: 8, md: 0 },
            }}
          >
            <DashboardHeader title={title} />
            {children}
          </Stack>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default DashboardContainer;

import BusinessIcon from '@mui/icons-material/Business';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PersonIcon from '@mui/icons-material/Person';
import ShareIcon from '@mui/icons-material/Share';
import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'src/common/useSnackbar';
import { isUserLogged } from 'src/components/utils';
import { useSelectedService } from 'src/context/useSelectedService';
import { useConfirmationOrder } from './useConfirmationOrder';

export function RecommendedSection() {
  const { id } = useParams<{ id: string }>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check for mobile screen

  const [view, setView] = useState(window.location.pathname.includes('client') ? 'client' : 'partner');
  const [modalOpen, setModalOpen] = useState(false);
  const { showSnackbar } = useSnackbar();

  const customerLink = `/process-bik/${id}/client-process`;
  const currentLink = view === 'client' ? `/process-bik/${id}/partner-process` : customerLink;

  const fullUrl = `${window.location.origin}${customerLink}`;

  const { selectedService } = useSelectedService();

  const { handleSubmitSelectedRows, isLoading, isBIKDisabled } = useConfirmationOrder({
    reportId: id || '',
    selectedService,
    customNavigationUrl: currentLink,
  });

  const isLogged = isUserLogged();

  const handleViewChange = (_: React.MouseEvent<HTMLElement>, newView: string | null) => {
    if (!newView) return;

    setView(newView);

    handleSubmitSelectedRows();
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(fullUrl).then(() => {
      showSnackbar('Link skopiowany do schowka!', 'info');
    });
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'} // Stack vertically for mobile
        justifyContent="space-between"
        alignItems={isMobile ? 'flex-start' : 'center'}
        mt={2}
        gap={2}
        sx={{
          backgroundColor: theme.palette.common.background,
          borderRadius: 2,
          padding: 2,
          boxShadow:
            theme.palette.mode === 'dark' ? '0px 4px 12px rgba(255, 255, 255, 0.1)' : '0px 4px 12px rgba(0, 0, 0, 0.1)',
        }}
      >
        {/* Header Section */}
        <Box>
          <Typography variant={isMobile ? 'h6' : 'h5'} fontWeight="bold" sx={{ color: theme.palette.text.primary }}>
            Rekomendowane Produkty {isBIKDisabled ? ' - (RAPORT ZATWIERDZONY)' : ''}
          </Typography>
          <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
            Na podstawie analizy dokumentu BIK
          </Typography>
        </Box>

        {/* Button Section */}
        <Box display="flex" alignItems="center" gap={isMobile ? 0 : 2} width={isMobile ? '100%' : 'auto'}>
          <ToggleButtonGroup
            value={view}
            exclusive
            disabled={isLoading}
            onChange={handleViewChange}
            fullWidth={isMobile} // Make buttons full-width on mobile
            sx={{
              backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#f5f5f5',
              borderRadius: 1,
              overflow: 'hidden',
            }}
          >
            {isLogged && (
              <ToggleButton
                value="partner"
                size="small"
                sx={{
                  textTransform: 'none',
                  color: view === 'partner' ? theme.palette.primary.contrastText : theme.palette.text.secondary,
                  backgroundColor: view === 'partner' ? theme.palette.primary.main : 'transparent',
                  '&:hover': {
                    backgroundColor: view === 'partner' ? theme.palette.primary.dark : theme.palette.action.hover,
                  },
                }}
              >
                <BusinessIcon sx={{ fontSize: 20, mr: 1 }} />
                Widok Sprzedawcy
              </ToggleButton>
            )}
            <ToggleButton
              value="client"
              size="small"
              sx={{
                textTransform: 'none',
                color: view === 'client' ? theme.palette.primary.contrastText : theme.palette.text.secondary,
                backgroundColor: view === 'client' ? theme.palette.primary.main : 'transparent',
                '&:hover': {
                  backgroundColor: view === 'client' ? theme.palette.primary.dark : theme.palette.action.hover,
                },
              }}
            >
              <PersonIcon sx={{ fontSize: 20, mr: 1 }} />
              Widok Klienta
            </ToggleButton>
          </ToggleButtonGroup>
          {isLogged && (
            <IconButton
              onClick={() => setModalOpen(true)}
              sx={{
                color: theme.palette.primary.main,
              }}
            >
              <ShareIcon />
            </IconButton>
          )}
        </Box>
      </Box>

      {/* Share Modal */}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="share-modal-title"
        aria-describedby="share-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '90%' : 'auto', // Adjust width for mobile
            bgcolor: theme.palette.background.paper,
            boxShadow: 24,
            p: 3, // Reduce padding for mobile
            borderRadius: 2,
          }}
        >
          <Grid container spacing={1}>
            <Grid size={{ xs: 12 }}>
              <Typography id="share-modal-title" variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
                Udostępnij widok
              </Typography>
              <Typography id="share-modal-description" variant="body2" sx={{ mb: 2 }}>
                Skopiuj poniższy link, aby udostępnić widok dla klienta:
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, sm: 9 }}>
              <TextField
                fullWidth
                value={fullUrl}
                InputProps={{
                  readOnly: true,
                }}
                sx={{ mb: isMobile ? 1 : 0 }}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 3 }}>
              <Button
                onClick={handleCopyToClipboard}
                variant="contained"
                fullWidth
                startIcon={<ContentCopyIcon />}
                sx={{ height: isMobile ? 40 : 50 }}
              >
                Kopiuj
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

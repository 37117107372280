import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Header } from 'src/components/app-toolbar/Header';
import Footer from 'src/components/Footer';
import ScrollToTop from 'src/components/ScrollToTop';

type Content = {
  title: string;
  content: string;
};

const content: Content[] = [
  {
    title: 'Co to jest BIK i jaki ma wpływ na Państwa firmę?',
    content:
      'Biuro Informacji Kredytowej (BIK) to instytucja, która zbiera dane o historii kredytowej zarówno osób prywatnych, jak i przedsiębiorstw. Rejestruje informacje na temat wszelkich zobowiązań finansowych, takich jak kredyty, pożyczki, czy umowy leasingowe. W przypadku opóźnień w spłatach, takie informacje zostają odnotowane w BIK, co może wpłynąć na obniżenie wiarygodności kredytowej firmy. Obniżona wiarygodność znajduje odzwierciedlenie w punktacji kredytowej, którą sprawdzają banki oraz inne instytucje finansowe przy rozpatrywaniu wniosków o finansowanie.',
  },
  {
    title: 'Jak szybko poprawić historię kredytową i uzyskać lepsze warunki finansowania?',
    content:
      'Tak, jako firma specjalizująca się w poprawie historii kredytowej oraz przywracaniu płynności finansowej, możemy pomóc w naprawie historii kredytowej lub leasingowej Państwa firmy. Dzięki naszym usługom będą Państwo mogli uzyskać lepsze warunki finansowania, a często również dostęp do nowych źródeł finansowania, w tym leasingów. Jesteśmy jedyną firmą technologiczną, która realizuje ten proces w sposób niezwykle szybki i skuteczny, minimalizując czas oczekiwania oraz związane z tym koszty.',
  },
  {
    title: 'Jak nasze usługi mogą poprawić Twoją sytuację finansową i dlaczego warto je poznać?',
    content:
      'Wiele osób i firm nie zdaje sobie sprawy, że można naprawić historię kredytową i podnieść ocenę punktową w BIK. Dla przedsiębiorstw oznacza to często niższe marże, prowizje, oprocentowanie, a czasem nawet przetrwanie dzięki odzyskanej zdolności kredytowej. Nasza firma, dzięki doświadczeniu i skutecznym metodom, oferuje błyskawiczną pomoc w poprawie sytuacji finansowej naszych klientów.',
  },
  {
    title: 'Dlaczego nasze podejście jest szybsze i skuteczniejsze niż korzystanie z kancelarii?',
    content:
      'To prawda, że wiele osób nadal wybiera kancelarie, choć nie jest to konieczne. Proces naprawy historii kredytowej nie wymaga działań prawnych, ale raczej znajomości procedur związanych z zobowiązaniami zapisanymi w BIK. Kancelarie często wydłużają ten proces, działając jako pośrednicy, co nie zwiększa gwarancji sukcesu. Z naszego doświadczenia wynika, że dokumenty składane bezpośrednio przez klienta do instytucji finansowych są przetwarzane szybciej i skuteczniej.',
  },
  {
    title:
      'Jak to działa: Precyzyjna analiza i działania prawne, które poprawiają Twoją ocenę BIK i otwierają nowe możliwości finansowania',
    content:
      'Dzięki dogłębnej znajomości prawa, przepisów, i zasad, jesteśmy w stanie przygotować precyzyjne pisma do odpowiednich instytucji finansowych. Te działania umożliwiają usunięcie lub przeniesienie części danych do celów statystycznych poprzez zmianę sposobu przetwarzania informacji w rejestrze BIK. W efekcie, ocena BIK ulega poprawie, co sprawia, że instytucje finansowe, które wcześniej nie współpracowały z Państwa firmą, będą bardziej skłonne do zaoferowania korzystnych warunków współpracy.',
  },
  {
    title: 'Jak nasza innowacyjna technologia daje Twojej firmie przewagę?',
    content:
      'Jako jedyna firma na polskim rynku zainwestowaliśmy w technologię, która automatycznie analizuje dane i generuje dokumenty zgodne z wymaganiami różnych instytucji finansowych. Naszym celem jest wspieranie rozwoju małych i średnich przedsiębiorstw, zapewniając im płynność finansową. Tradycyjne metody są albo kosztowne, albo czasochłonne, co ogranicza dostępność takich usług. Nasza technologia pozwala na błyskawiczną analizę, strategię i rekomendacje w zaledwie 30 sekund, oferując pełną transparentność, estymacje poprawy oceny BIK, szanse powodzenia oraz czas realizacji. Dzięki temu skracamy czas oczekiwania, obniżamy koszty i czynimy nasze usługi dostępne dla wszystkich firm, które tego potrzebują.',
  },
  {
    title: 'Jak poprawa BIK firmowego i prywatnego zwiększa Twoje szanse na lepsze finansowanie?',
    content:
      'Aby maksymalizować skuteczność w przywracaniu zdolności kredytowej, zarówno firmowej, jak i prywatnej, doświadczenie pokazuje, że banki i instytucje finansowe często analizują oba profile BIK. Dobra historia i ocena w obu rejestrach znacząco zwiększają wiarygodność przedsiębiorcy oraz jego firmy, co prowadzi do lepszych warunków finansowania.',
  },
  {
    title: 'Jak możemy usunąć negatywne wpisy bez pełnej spłaty zobowiązań?',
    content:
      'W przypadku zobowiązań wobec banków, niestety brak regulacji pozwalających na usunięcie aktywnych pozycji, co sprawia, że banki rzadko zgadzają się na ich usunięcie. Jednak z dumą oferujemy możliwość „usunięcia” leasingów, które są w trakcie spłaty. W wielu przypadkach możemy również usunąć całą negatywną historię kredytową lub leasingową. Proces ten wymaga precyzyjnej analizy i zastosowania specjalnych procedur, które uprościliśmy, aby nasi klienci mogli skupić się na rozwoju swojego biznesu.',
  },
  {
    title:
      'Jak lepsza ocena BIK może zaoszczędzić Twojej firmie tysiące złotych na finansowaniu lub w ogóle je umożliwić?',
    content:
      'Tak, poprawa oceny BIK znacząco obniża ryzyko dla instytucji finansowych, co przekłada się na korzystniejsze warunki finansowania. Wielu naszych klientów przeszło od zerowej zdolności kredytowej do 1-2 milionów złotych. Poprzez poprawę oceny BIK, nasi klienci często uzyskiwali niższe oprocentowanie, mniejsze marże i prowizje, co przyniosło oszczędności rzędu 50 000-100 000 zł. Dzięki temu zyskali dostęp do lepszych warunków leasingu i finansowania, a w wielu przypadkach mogli w ogóle uzyskać finansowanie, które wcześniej było niedostępne.',
  },
  {
    title: 'Jak usunięcie już jednego wpisu może odblokować nowe możliwości finansowania dla Twojej firmy?',
    content:
      'Tak, nawet jeden negatywny wpis może znacząco obniżyć ocenę BIK i wpłynąć na możliwości finansowania. Usunięcie wpisu dotyczącego nieregularnych wpłat lub zamkniętych zobowiązań z negatywną historią często otwiera naszym klientom drogę do nowych inwestycji, w tym dodatkowych leasingów, które wspierają rozwój firmy. Lepsza ocena BIK pomaga również zaoszczędzić Twojej firmie tysiące złotych na finansowaniu.',
  },
  {
    title: 'Jak szybko możesz poprawić swoją historię kredytową i uzyskać lepsze finansowanie?',
    content:
      'Czas klienta jest dla nas priorytetem, dlatego nasze procedury umożliwiają samodzielne wysyłanie dokumentów, co znacznie przyspiesza ich przetwarzanie przez instytucje finansowe. Dokumenty wysyłane przez klientów są zwykle procesowane szybciej i z mniejszym ryzykiem odrzucenia. Czas naprawy historii kredytowej zależy od rodzaju zobowiązań: dla kredytów bankowych wynosi od dwóch tygodni do trzech miesięcy, a dla leasingów i zobowiązań w parabankach - od dwóch tygodni do miesiąca.',
  },
  {
    title: 'Dlaczego nasze doświadczenie i technologia gwarantują skuteczną poprawę Twojej oceny BIK?',
    content:
      'Nasza skuteczność w przypadku leasingów wynosi 100%, a dla zobowiązań bankowych również utrzymujemy bardzo wysoki poziom, zależnie od charakteru zobowiązania i banku. Dzięki inwestycji w technologię, możemy szybko analizować wnioski i dostosować procedury do konkretnej sprawy. W ciągu 30 sekund od załadowania raportów BIK otrzymują Państwo transparentne rekomendacje. Nikt nie działa dziś szybciej ani skuteczniej w poprawie oceny kredytowej BIK.',
  },
  {
    title:
      'Czy możecie podać przykład? Jak nasze działania przynoszą realne oszczędności i finansowanie dla Twojej firmy.',
    content:
      'Mamy liczne sukcesy w branżach takich jak budowlana, transportowa, i produkcyjna. Na przykład, niedawno pomogliśmy klientowi początkowo bez zdolności kredytowej uzyskać leasing na nowe pojazdy. Inny klient, dzięki poprawie oceny BIK, uzyskał dzięki naszym staraniom finansowanie na 500 000 zł i zaoszczędził 50 000 zł na opłatach dzięki niższym prowizjom i oprocentowaniu. To tylko kilka przykładów naszej skuteczności. Więcej znajdziecie Państwo w zakładce "historie sukcesu," gdzie prezentujemy raporty przed i po naszych działaniach.',
  },
  {
    title: 'Przejrzyste i uczciwe ceny – ile kosztuje poprawa Twojej oceny BIK?',
    content:
      'Koszt naszych usług jest przekazywany po bezpłatnej analizie raportów BIK, która nie zobowiązuje do współpracy. Nasz cennik jest prosty, bez ukrytych kosztów, i taki sam dla każdego. Stawiamy na szybką pomoc i transparentność, licząc na długoterminową współpracę z każdym klientem.',
  },
];

export default function FAQ() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (_: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <ScrollToTop />
      <Header type="back" />
      <Container
        className="fs-unmask"
        sx={{
          pt: { xs: 4, sm: 12 },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          color="text.primary"
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          Najczęściej zadawane pytania (FAQ)
        </Typography>
        <Box sx={{ width: '100%' }}>
          {content.map((item, key) => (
            <Accordion key={key} expanded={expanded === `panel-${key}`} onChange={handleChange(`panel-${key}`)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${key}d-content`}
                id={`panel${key}d-header`}
              >
                <Typography component="h3" variant="subtitle2">
                  {item.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" gutterBottom sx={{ maxWidth: { sm: '100%', md: '70%' } }}>
                  {item.content}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Container>
      <Footer />
    </>
  );
}

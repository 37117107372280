import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isUserLawOffice, isUserManager, isUserPartner } from 'src/common/roles';
import { LAST_PATH } from 'src/components/utils';

const CheckDefaultPage: React.FC = () => {
  const navigate = useNavigate();

  const isManager = isUserManager();
  const isPartner = isUserPartner();
  const isLawOffice = isUserLawOffice();

  const defaultPage = localStorage.getItem(LAST_PATH);

  useEffect(() => {
    if (defaultPage) {
      localStorage.removeItem(LAST_PATH);
      navigate(defaultPage);
      return;
    }

    if (isManager) {
      navigate('/manager/partner-manage', { replace: true });
      return;
    }

    if (isLawOffice) {
      navigate('/law-office/commissions', { replace: true });
      return;
    }

    if (isPartner) {
      navigate('/partner/dashboard', { replace: true });
      return;
    }
  }, [navigate, defaultPage]);

  return <div>Checking...</div>;
};

export default CheckDefaultPage;

import { Box, Container, Typography, useColorScheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { motion } from 'framer-motion';

export function VideoDemo() {
  const { mode } = useColorScheme();

  return (
    <Box
      sx={{
        py: 10,
        bgcolor: mode === 'dark' ? '#121212' : '#f9f9f9', // Set a clear background color for both modes
      }}
    >
      <Container maxWidth="lg" id="how-it-works">
        {/* Title Section */}
        <motion.div initial={{ opacity: 0, y: 20 }} whileInView={{ opacity: 1, y: 0 }} viewport={{ once: true }}>
          <Box sx={{ textAlign: 'center', mb: 6 }}>
            <Typography
              variant="h2"
              sx={{
                background:
                  mode === 'dark'
                    ? 'linear-gradient(to right, #60a5fa, #818cf8)' // Cool blue for dark mode
                    : 'linear-gradient(to right, #2563eb, #4f46e5)', // Brighter blue for light mode
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Zobacz Jak To Działa
            </Typography>
            <Typography
              variant="h5"
              color={mode === 'dark' ? '#b0bec5' : 'text.secondary'} // Subtle gray in dark mode
            >
              Zobacz nasz serwis w akcji
            </Typography>
          </Box>
        </motion.div>

        {/* Video Section */}
        <motion.div initial={{ opacity: 0, y: 20 }} whileInView={{ opacity: 1, y: 0 }} viewport={{ once: true }}>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              paddingTop: '56.25%', // 16:9 Aspect Ratio
              borderRadius: 4,
              overflow: 'hidden',
              boxShadow:
                mode === 'dark'
                  ? '0px 8px 24px rgba(0, 0, 0, 0.5)' // Heavier shadow for dark mode
                  : '0px 8px 24px rgba(0, 0, 0, 0.15)', // Lighter shadow for light mode
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background:
                  mode === 'dark'
                    ? 'linear-gradient(to bottom right, rgba(37, 99, 235, 0.2), rgba(79, 70, 229, 0.2))' // Subtle gradient for dark mode
                    : 'linear-gradient(to bottom right, rgba(37, 99, 235, 0.05), rgba(79, 70, 229, 0.05))', // Subtle gradient for light mode
              }}
            />
            <iframe
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 0,
              }}
              src="https://app.storylane.io/demo/rbkqgkuxrigd?embed=inline"
              title="Prezentacja Produktu"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Box>
        </motion.div>

        {/* Feature Section */}
        <Grid container spacing={1} sx={{ mt: 6 }}>
          {[
            {
              title: 'Szybka Analiza',
              description: 'Zobacz, jak nasz system analizuje Twój raport BIK w sekundy',
            },
            {
              title: 'Jasne Wyniki',
              description: 'Otrzymaj łatwe do zrozumienia rekomendacje',
            },
            {
              title: 'Bezpieczny Proces',
              description: 'Twoje dane są chronione na każdym etapie',
            },
          ].map((feature, index) => (
            <Grid size={{ xs: 12, md: 4 }} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
              >
                <Box
                  sx={{
                    textAlign: 'center',
                    p: 3,
                    bgcolor: mode === 'dark' ? '#1e1e1e' : '#ffffff', // Card background
                    borderRadius: 4,
                    boxShadow:
                      mode === 'dark'
                        ? '0px 4px 16px rgba(0, 0, 0, 0.5)' // Card shadow for dark mode
                        : '0px 4px 16px rgba(0, 0, 0, 0.1)', // Card shadow for light mode
                  }}
                >
                  <Typography
                    variant="h6"
                    color={mode === 'dark' ? '#ffffff' : 'text.primary'} // White text for dark mode
                    gutterBottom
                  >
                    {feature.title}
                  </Typography>
                  <Typography color={mode === 'dark' ? '#b0bec5' : 'text.secondary'}>{feature.description}</Typography>
                </Box>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Box, Button, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import html2pdf from 'html2pdf.js';
import React, { useMemo } from 'react';
import { SanitazedReportData } from 'src/types/SanitazedReport';
import Footer from '../Footer';
import { BankAssessmentCard } from './BankAssessmentCard';
import { EffectsCard } from './EffectsCard';
import { ReportStatCard } from './ReportStatCard';

const inlineAllFontSizes = (el: HTMLElement, fontSize = '11px') => {
  el.style.fontSize = fontSize;
  Array.from(el.children).forEach((child) => inlineAllFontSizes(child as HTMLElement, fontSize));
};

const SanitazedBIKReportBody: React.FC<SanitazedBIKReportProps> = ({
  reportData,
  detailsContainer,
  fixedSized,
  dataTestId,
}) => {
  const theme = useTheme();

  const calculatedData = useMemo(() => {
    const beforeDebtAmount = reportData.activeCreditsLeftAmountSum;
    const afterDebtAmount = reportData.activeCreditsLeftAmountSanitizedSum;
    const debtReduction = beforeDebtAmount - afterDebtAmount;
    const debtPercentage = Math.round((debtReduction / beforeDebtAmount) * 100);

    const beforeCreditsCount = reportData.activeCreditsCount;
    const afterCreditsCount = reportData.activeCreditsSanitizedCount;
    const creditsReduction = beforeCreditsCount - afterCreditsCount;
    const creditsPercentage = Math.round((creditsReduction / beforeCreditsCount) * 100) || 0;

    const beforeInstallment = reportData.activeCreditsInstallmentSum;
    const afterInstallment = reportData.activeCreditsInstallmentSanitizedSum;
    const installmentReduction = beforeInstallment - afterInstallment;
    const installmentPercentage = Math.round((installmentReduction / beforeInstallment) * 100) || 0;

    const beforeRequests = reportData.creditRequestsCount;
    const afterRequests = reportData.creditRequestsSanitizedCount;
    const requestsReduction = beforeRequests - afterRequests;
    const requestsPercentage = Math.round((requestsReduction / beforeRequests) * 100) || 0;

    // Prosta funkcja do odmiany słów w zależności od liczby
    const pluralize = (count: number, singular: string, pluralFew: string, pluralMany: string) => {
      if (count === 1) return singular;
      if (count % 10 >= 2 && count % 10 <= 4 && !(count % 100 >= 12 && count % 100 <= 14)) {
        return pluralFew;
      }
      return pluralMany;
    };

    const assessments: string[] = [];

    if (debtReduction > 0) {
      assessments.push(
        `Łączne zadłużenie zostało zmniejszone o ${debtReduction.toLocaleString('pl-PL')} zł (${debtPercentage}%)`
      );
    }

    if (creditsReduction > 0) {
      const creditWord = pluralize(
        creditsReduction,
        'aktywne zobowiązanie kredytowe',
        'aktywne zobowiązania kredytowe',
        'aktywnych zobowiązań kredytowych'
      );
      assessments.push(`Usunięto ${creditsReduction} ${creditWord} (${creditsPercentage}%)`);
    }

    if (installmentReduction > 0) {
      assessments.push(
        `Suma miesięcznych rat została zmniejszona o ${installmentReduction.toLocaleString('pl-PL')} zł (${installmentPercentage}%)`
      );
    }

    if (requestsReduction > 0) {
      const requestWord = pluralize(
        requestsReduction,
        'zapytanie kredytowe',
        'zapytania kredytowe',
        'zapytań kredytowych'
      );
      assessments.push(`Z raportu usunięto ${requestsReduction} ${requestWord} (${requestsPercentage}%)`);
    }

    return {
      beforeDebtAmount,
      afterDebtAmount,
      debtReduction,
      debtPercentage,
      beforeCreditsCount,
      afterCreditsCount,
      creditsReduction,
      creditsPercentage,
      beforeInstallment,
      afterInstallment,
      installmentReduction,
      installmentPercentage,
      beforeRequests,
      afterRequests,
      requestsReduction,
      requestsPercentage,
      assessments,
    };
  }, [reportData]);

  const formatCurrency = (value: number) => `${value.toLocaleString('pl-PL')} zł`;

  return (
    <Box
      data-testid={dataTestId}
      sx={{
        fontSize: fixedSized ? '14px' : '14px',
        padding: 3,
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        borderRadius: 2,
      }}
    >
      <Box sx={{ mb: 3 }}>
        <Typography variant="h4" fontWeight="bold">
          Efekty wdrożenia Optymalizacji BIK
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Kluczowe korzyści po usunięciu wybranych pozycji
        </Typography>
      </Box>

      <Grid container spacing={1}>
        <Grid size={{ xs: 12 }}>{detailsContainer}</Grid>

        <Grid size={fixedSized ? { xs: 6 } : { xs: 12, md: 6 }}>
          <ReportStatCard
            title="Aktywne zadłużenie"
            beforeValue={formatCurrency(calculatedData.beforeDebtAmount)}
            afterValue={formatCurrency(calculatedData.afterDebtAmount)}
            percentageChange={calculatedData.debtPercentage}
            decreaseText={`Zmniejszenie o ${formatCurrency(calculatedData.debtReduction)}`}
            icon={<AttachMoneyIcon color="primary" />}
          />
        </Grid>

        <Grid size={fixedSized ? { xs: 6 } : { xs: 12, md: 6 }}>
          <ReportStatCard
            title="Liczba aktywnych kredytów"
            beforeValue={calculatedData.beforeCreditsCount}
            afterValue={calculatedData.afterCreditsCount}
            percentageChange={calculatedData.creditsPercentage}
            decreaseText={`Zmniejszenie o ${calculatedData.creditsReduction} kredyty`}
            icon={<CreditCardIcon color="secondary" />}
          />
        </Grid>

        <Grid size={fixedSized ? { xs: 6 } : { xs: 12, md: 6 }}>
          <ReportStatCard
            title="Miesięczne zobowiązania"
            beforeValue={formatCurrency(calculatedData.beforeInstallment)}
            afterValue={formatCurrency(calculatedData.afterInstallment)}
            percentageChange={calculatedData.installmentPercentage}
            decreaseText={`Zmniejszenie o ${formatCurrency(calculatedData.installmentReduction)} miesięcznie`}
            icon={<ReceiptIcon color="primary" />}
          />
        </Grid>

        <Grid size={fixedSized ? { xs: 6 } : { xs: 12, md: 6 }}>
          <ReportStatCard
            title="Zapytania kredytowe"
            beforeValue={calculatedData.beforeRequests}
            afterValue={calculatedData.afterRequests}
            percentageChange={calculatedData.requestsPercentage}
            decreaseText={`Zmniejszenie o ${calculatedData.requestsReduction} zapytań`}
            icon={<HelpOutlineIcon color="warning" />}
          />
        </Grid>

        {!!calculatedData.assessments.length && (
          <Grid size={fixedSized ? { xs: 6 } : { xs: 12, md: 6 }}>
            <BankAssessmentCard title="Ocena w oczach banków" assessments={calculatedData.assessments} />
          </Grid>
        )}

        {!!calculatedData.assessments.length && (
          <Grid size={fixedSized ? { xs: 6 } : { xs: 12, md: 6 }}>
            <EffectsCard title="Efekt?" />
          </Grid>
        )}

        {fixedSized && (
          <Grid size={{ xs: 12 }}>
            <Typography variant="body2" color="textSecondary">
              Informujemy, że nie ponosimy odpowiedzialności za decyzje banków i instytucji finansujących, które
              opierają swoje analizy oraz możliwości udzielenia finansowania na wewnętrznych modelach scoringowych,
              zmiennych w czasie i zależnych od wielu czynników. Dokładamy wszelkich starań, aby dostarczane przez nas
              informacje były rzetelne i aktualne.
            </Typography>
            <br />
            <Typography variant="body2" color="textSecondary">
              Treść niniejszej wiadomości jest poufna i objęta zakazem ujawniania. Odbiorcą dokumentu może być wyłącznie
              jego adresat. Jeżeli nie jesteś adresatem tej wiadomości ani osobą upoważnioną do jej przekazania,
              informujemy, że jej rozpowszechnianie, kopiowanie lub dalsze udostępnianie jest zabronione i może stanowić
              naruszenie prawa. W przypadku omyłkowego otrzymania wiadomości, prosimy o poinformowanie nadawcy oraz
              usunięcie wiadomości wraz z załącznikami ze swojej skrzynki pocztowej.
            </Typography>
            <br />
            <Typography variant="body2" color="textSecondary">
              Dziękujemy za zrozumienie,
              <br />
              Zespoł BIKMaster.
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

interface SanitazedBIKReportProps {
  reportData: SanitazedReportData;
  reportName?: string;
  fixedSized?: boolean;
  dataTestId?: string;
  detailsContainer: React.ReactNode;
}

export const SanitazedBIKReport: React.FC<SanitazedBIKReportProps> = ({ reportData, reportName, detailsContainer }) => {
  const handleDownloadPDF = () => {
    const element = document.querySelector('[data-testid="sanitazed-bik-report"]');
    if (!element) return;

    const clone = element.cloneNode(true) as HTMLElement;
    inlineAllFontSizes(clone);

    const opt = {
      margin: 0.3,
      filename: `${reportName || 'raport'}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
    };

    html2pdf().set(opt).from(clone).save();
  };

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Box width="100%" textAlign="end">
          <Button variant="contained" color="primary" onClick={handleDownloadPDF}>
            Pobierz Raport w PDF
          </Button>
        </Box>

        <SanitazedBIKReportBody detailsContainer={detailsContainer} reportData={reportData} />

        <Box
          sx={{
            display: 'none',
            height: '1000px',
            width: '1200px',
          }}
        >
          <SanitazedBIKReportBody
            detailsContainer={detailsContainer}
            reportData={reportData}
            fixedSized
            dataTestId="sanitazed-bik-report"
          />
        </Box>
        <Footer />
      </Box>
    </>
  );
};

import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { Report } from 'src/types/Report';
import { formatPrice } from 'src/utils/price';
import { Price } from '../../../Price/Price';

interface BIKAdditionalDataProps {
  bikReport: Report;
}

export const BIKTotal: FunctionComponent<BIKAdditionalDataProps> = ({ bikReport }) =>
  typeof bikReport.pricing?.bikRemovalDiscountPrice === 'number' ? (
    <Typography fontSize="1.3rem" lineHeight={1}>
      {bikReport.pricing.bikRemovalDiscountPrice !== bikReport.pricing.bikRemovalPrice && (
        <Price
          data-testid="bik-without-discount"
          discounted
          value={formatPrice(bikReport.pricing.bikRemovalPrice)}
          currency={'PLN'}
        />
      )}
      <Price
        data-testid="order-item-total-price"
        value={formatPrice(bikReport.pricing.bikRemovalDiscountPrice)}
        currency={'PLN'}
      />
    </Typography>
  ) : (
    <Price data-testid="order-item-total-price" value={formatPrice(0)} currency={'PLN'} />
  );

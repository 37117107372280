import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useMemo, useState } from 'react';

import { Box, styled, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DatePresets } from './DatePresets';

const StyledDatePicker = styled(DatePicker)({
  '.MuiInputBase-root': {
    height: '2.25rem',
  },
});

export const useDateManagement = (setPage?: (page: number) => void, isRevertedDate?: boolean) => {
  const [fromDate, setFromDate] = useState<Dayjs>(dayjs().subtract(14, 'day').startOf('day'));
  const [toDate, setToDate] = useState<Dayjs>(dayjs().endOf('day'));
  const [activePreset, setActivePreset] = useState<string>('Last14Days');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Format dates for API usage
  const formattedFromDate = useMemo(
    () => fromDate.format(isRevertedDate ? 'YYYY-MM-DD' : 'DD-MM-YYYY'),
    [fromDate, isRevertedDate]
  );
  const formattedToDate = useMemo(
    () => toDate.format(isRevertedDate ? 'YYYY-MM-DD' : 'DD-MM-YYYY'),
    [isRevertedDate, toDate]
  );

  const handleFromDateChange = useCallback(
    (newValue: Dayjs | null) => {
      if (!newValue) return;
      if (toDate && newValue.isAfter(toDate)) {
        setErrorMessage('Data początkowa nie może być późniejsza niż data końcowa.');
      } else {
        if (setPage) setPage(0);
        setErrorMessage(null);
        setFromDate(newValue);
      }
      setActivePreset('Custom');
    },
    [toDate]
  );

  const handleToDateChange = useCallback(
    (newValue: Dayjs | null) => {
      if (!newValue) return;
      if (fromDate && newValue.isBefore(fromDate)) {
        setErrorMessage('Data końcowa nie może być wcześniejsza niż data początkowa.');
      } else {
        if (setPage) setPage(0);
        setErrorMessage(null);
        setToDate(newValue);
      }
      setActivePreset('Custom');
    },
    [fromDate]
  );

  const DateFromContainer = useMemo(
    () => <DatePicker sx={{ width: '100%' }} label="Od" value={fromDate} onChange={handleFromDateChange} timezone="" />,
    [fromDate, handleFromDateChange]
  );

  const DateToContainer = useMemo(
    () => <DatePicker sx={{ width: '100%' }} label="Do" value={toDate} onChange={handleToDateChange} />,
    [handleToDateChange, toDate]
  );
  const DatePresetsContainer = useMemo(() => {
    return (
      <DatePresets
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        activePreset={activePreset}
        setActivePreset={setActivePreset}
        setPage={setPage}
      />
    );
  }, [activePreset, fromDate, toDate, setPage]);

  const DateContainer = useMemo(
    () => (
      <Box sx={{ width: '100%' }}>
        <Grid container spacing={2} justifyContent="space-between" alignItems="center">
          <Grid size={{ xs: 6, md: 6, lg: 2 }}>
            <StyledDatePicker
              sx={{ width: '100%' }}
              label="Od"
              value={fromDate}
              onChange={handleFromDateChange}
              format="DD/MM/YYYY"
            />
          </Grid>
          <Grid size={{ xs: 6, md: 6, lg: 2 }}>
            <StyledDatePicker
              sx={{ width: '100%' }}
              label="Do"
              value={toDate}
              onChange={handleToDateChange}
              format="DD/MM/YYYY"
            />
          </Grid>
          <Grid size={{ xs: 12, md: 12, lg: 8 }}>
            <DatePresets
              fromDate={fromDate}
              toDate={toDate}
              setFromDate={setFromDate}
              setToDate={setToDate}
              activePreset={activePreset}
              setActivePreset={setActivePreset}
              setPage={setPage}
            />
          </Grid>
        </Grid>
        {errorMessage && (
          <Typography color="error" textAlign="center" mt={2}>
            {errorMessage}
          </Typography>
        )}
      </Box>
    ),
    [activePreset, errorMessage, fromDate, handleFromDateChange, handleToDateChange, toDate, setPage]
  );

  return {
    formattedFromDate,
    formattedToDate,
    errorMessage,
    DateContainer,
    DateFromContainer,
    DateToContainer,
    DatePresetsContainer,
  };
};

import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, styled, TextField } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ReportStatus, ReportStatusDetails } from 'src/common/reports';
import { CommissionProductType, CommissionProductTypeDetails } from 'src/types/PartnerDetails';

const StyledTextField = styled(TextField)({
  '.MuiInputBase-root': {
    height: '2.25rem',
  },
});

export const useReportSearch = (setPage: (page: number) => void) => {
  const [filterText, setFilterText] = useState('');
  const [searchText, setSearchText] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<ReportStatus | ''>('');
  const [selectedBIKService, setSelectedBIKService] = useState<CommissionProductType | ''>('');
  const selectedStatusText = selectedStatus ? ReportStatusDetails[selectedStatus]?.translation : '';

  useEffect(() => {
    if (filterText.length === 1 || filterText.length === 2 || filterText.length === 3) {
      return;
    }

    const searchTimeout = setTimeout(() => {
      setPage(0);
      setSearchText(filterText);
    }, 500);

    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
    };
  }, [filterText, setPage]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  const handleStatusChange = useCallback(
    (event: SelectChangeEvent<ReportStatus>) => {
      setFilterText('');
      setPage(0);
      setSelectedStatus(event.target.value as ReportStatus);
    },
    [setPage]
  );

  const handleBIKServiceChange = useCallback(
    (event: SelectChangeEvent<CommissionProductType>) => {
      setFilterText('');
      setPage(0);
      setSelectedBIKService(event.target.value as CommissionProductType);
    },
    [setPage]
  );

  const SearchField = useMemo(
    () => (
      <StyledTextField
        label="(min. 4 znaki) Wpisz tekst, aby przefiltrować raporty po nazwie, numerze NIP/PESEL lub numerze zamówienia"
        variant="outlined"
        fullWidth
        slotProps={{
          inputLabel: { style: { top: -8 } },
          input: { style: { minWidth: 'auto' } },
          formHelperText: { style: { margin: 0, height: 0 } },
        }}
        value={filterText}
        onChange={handleFilterChange}
      />
    ),
    [filterText]
  );

  const StatusSelect = useMemo(
    () => (
      <FormControl variant="outlined" fullWidth>
        <InputLabel sx={{ top: -6 }}>Status</InputLabel>
        <Select value={selectedStatus} onChange={handleStatusChange} label="Status" size="small">
          {Object.entries({
            ...{ EMPTY: { key: '', translation: 'Brak' } },
            ...ReportStatusDetails,
          }).map(([status, { translation }]) => (
            <MenuItem key={status} value={status === 'EMPTY' ? '' : (status as ReportStatus)}>
              {translation}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ),
    [selectedStatus]
  );

  const ServiceSelect = useMemo(
    () => (
      <FormControl variant="outlined" fullWidth>
        <InputLabel sx={{ top: -6 }}>Dostępne usługi</InputLabel>
        <Select value={selectedBIKService} onChange={handleBIKServiceChange} label="Dostępne usługi" size="small">
          {Object.entries({
            ...{ EMPTY: { key: '', translation: 'Brak' } },
            ...CommissionProductTypeDetails,
          }).map(([status, { translation }]) => (
            <MenuItem key={status} value={status === 'EMPTY' ? '' : (status as CommissionProductType)}>
              {translation}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ),
    [selectedBIKService]
  );

  return {
    searchText,
    selectedStatus,
    selectedStatusText,
    SearchField,
    StatusSelect,
    ServiceSelect,
    selectedBIKService,
  };
};

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Card, CardContent, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';

interface BankAssessmentCardProps {
  title: string;
  assessments: string[];
}

export const BankAssessmentCard: React.FC<BankAssessmentCardProps> = ({ title, assessments }) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';

  const StyledCard = styled(Card)(() => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 16,
    boxShadow: isDark ? '0px 2px 8px rgba(0, 0, 0, 0.4)' : '0px 2px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.palette.background.paper,
  }));

  const AssessmentItem = styled(Box)(() => ({
    backgroundColor: isDark
      ? 'rgba(76, 175, 80, 0.2)' // slightly stronger for dark mode
      : 'rgba(76, 175, 80, 0.1)',
    borderRadius: 8,
    padding: 16,
    marginTop: 8,
  }));

  return (
    <StyledCard>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6" sx={{ fontSize: 16, fontWeight: 600 }}>
            {title}
          </Typography>
          <CheckCircleOutlineIcon color="success" />
        </Box>

        {assessments.map((assessment, index) => (
          <AssessmentItem key={index}>
            <Typography variant="body2">{assessment}</Typography>
          </AssessmentItem>
        ))}
      </CardContent>
    </StyledCard>
  );
};

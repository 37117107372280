import { CurrencyExchange, Inventory2Outlined, PriceCheck } from '@mui/icons-material';
import { Box, CardContent, Chip, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Report } from 'src/types/Report';
import { ReportCalculation } from 'src/types/ReportCalculations';
import { StyledCard } from './CustomStyles';

interface BIKServicesSummaryProps {
  bikReport: Report;
}

const getKeyTranslation = (key: string) => {
  switch (key) {
    case 'skd':
      return 'SKD';
    case 'commission':
      return 'Zwrot prowizji';
    case 'unwibor':
      return 'Odwiborowanie';
    default:
      return key;
  }
};

const getColorByProfit = (profit: number) => {
  let glowColor = '';
  if (profit > 10000)
    glowColor = '#FFD700'; // Gold
  else if (profit > 5000)
    glowColor = '#8A2BE2'; // Violet
  else if (profit > 2500)
    glowColor = '#1E90FF'; // Blue
  else if (profit > 1000) glowColor = '#32CD32'; // Green

  return glowColor;
};

export const BIKServicesSummary = ({ bikReport }: BIKServicesSummaryProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!bikReport.calculations) return null;

  const services = Object.entries(bikReport.calculations).reduce(
    (acc, [key, value]) => {
      if (value?.availableItemsCount) {
        acc.push({
          type: key,
          isActive: true,
          ...value,
        });
      }
      return acc;
    },
    [] as (ReportCalculation & { type: string; isActive: boolean })[]
  );

  if (!services.length) return null;

  return (
    <Grid container spacing={2}>
      {services.map((service) => (
        <Grid size={{ xs: 6, md: 4 }} key={service.type}>
          <StyledCard $glowColor={getColorByProfit(service.maxObjectValue)} $isActive={service.isActive}>
            <CardContent
              sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Stack spacing={1.5} sx={{ width: '100%' }}>
                {/* Header with service type and count */}
                <Stack direction="row" justifyContent="space-around" alignItems="center">
                  <Typography
                    variant="h6"
                    component="h3"
                    sx={{
                      fontWeight: 'bold',
                      fontSize: { xs: '1.1rem', sm: '1.1rem' },
                      lineHeight: 1.2,
                    }}
                  >
                    {getKeyTranslation(service.type)}
                  </Typography>
                  <Chip
                    icon={<Inventory2Outlined fontSize="small" />}
                    label={`${service.availableItemsCount} szt.`}
                    size="small"
                    color="primary"
                    variant="outlined"
                    sx={{ fontWeight: 'medium' }}
                  />
                </Stack>

                {/* Service details */}
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                  sx={{ mt: 1 }}
                  divider={<Box sx={{ width: '1px', bgcolor: 'divider' }} />}
                >
                  {/* Max unit value */}
                  <Stack direction="row" alignItems="center" spacing={1} sx={{ flex: 1 }}>
                    <PriceCheck color="action" fontSize="small" />
                    <Box>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        display="block"
                        fontSize={isMobile ? '0.65rem' : '0.75rem'}
                      >
                        Max. jedn. WPS BOT
                      </Typography>
                      <Typography variant="body2" fontWeight="medium" fontSize={isMobile ? '0.85rem' : '0.875rem'}>
                        {service.maxObjectValue.toFixed(2)} zł
                      </Typography>
                    </Box>
                  </Stack>

                  {/* Total value */}
                  <Stack direction="row" alignItems="center" spacing={1} sx={{ flex: 1 }}>
                    <CurrencyExchange color="primary" fontSize="small" />
                    <Box>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        display="block"
                        fontSize={isMobile ? '0.65rem' : '0.75rem'}
                      >
                        Suma WPS BOT
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        color="primary"
                        fontSize={isMobile ? '0.85rem' : '0.875rem'}
                      >
                        {service.totalValue.toFixed(2)} zł
                      </Typography>
                    </Box>
                  </Stack>
                </Stack>
                {!!service?.availableItemsV2Count && (
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    sx={{ mt: 1 }}
                    divider={<Box sx={{ width: '1px', bgcolor: 'divider' }} />}
                  >
                    {/* Max unit value */}
                    <Stack direction="row" alignItems="center" spacing={1} sx={{ flex: 1 }}>
                      <PriceCheck color="action" fontSize="small" />
                      <Box>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          display="block"
                          fontSize={isMobile ? '0.65rem' : '0.75rem'}
                        >
                          Max. jedn. WPS MODEL
                        </Typography>
                        <Typography variant="body2" fontWeight="medium" fontSize={isMobile ? '0.85rem' : '0.875rem'}>
                          {(service?.maxObjectV2Value || 0).toFixed(2)} zł
                        </Typography>
                      </Box>
                    </Stack>

                    {/* Total value */}
                    <Stack direction="row" alignItems="center" spacing={1} sx={{ flex: 1 }}>
                      <CurrencyExchange color="primary" fontSize="small" />
                      <Box>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          display="block"
                          fontSize={isMobile ? '0.65rem' : '0.75rem'}
                        >
                          Suma WPS MODEL
                        </Typography>
                        <Typography
                          variant="body2"
                          fontWeight="bold"
                          color="primary"
                          fontSize={isMobile ? '0.85rem' : '0.875rem'}
                        >
                          {(service?.totalV2Value || 0).toFixed(2)} zł
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </CardContent>
          </StyledCard>
        </Grid>
      ))}
    </Grid>
  );
};

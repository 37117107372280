import { Check as CheckIcon } from '@mui/icons-material';
import { Box } from '@mui/material';

interface StepIconProps {
  active?: boolean;
  completed: boolean;
  icon: number;
}

export function StepIcon({ active, completed, icon }: StepIconProps) {
  return (
    <Box
      sx={{
        width: { xs: 32, sm: 40 },
        height: { xs: 32, sm: 40 },
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 0.3s ease-in-out',
        background: completed
          ? 'linear-gradient(135deg, #10b981 0%, #059669 100%)'
          : active
            ? 'linear-gradient(135deg, #3b82f6 0%, #2563eb 100%)'
            : 'rgba(229, 231, 235, 0.5)',
        boxShadow:
          active || completed ? '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)' : 'none',
        color: active || completed ? 'white' : 'rgba(107, 114, 128, 0.8)',
        fontWeight: 600,
        fontSize: { xs: '0.875rem', sm: '1rem' },
      }}
    >
      {completed ? <CheckIcon fontSize="small" /> : icon}
    </Box>
  );
}

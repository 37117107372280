import { Paper, useTheme } from '@mui/material';
import { useState } from 'react';
import ScrollToTop from 'src/components/ScrollToTop';
import { Header } from 'src/components/app-toolbar/Header';
import { PageContainer } from 'src/components/layout/PageContainer';
import { ContractPreview } from 'src/components/onboarding/ContractPreview';
import { OTPVerification } from 'src/components/onboarding/OTPVerification';
import { OnboardingSummary } from 'src/components/onboarding/OnboardingSummary';
import { PersonalInfoForm } from 'src/components/onboarding/PersonalInfoForm';
import { StepperHeader } from 'src/components/onboarding/StepperHeader';
import { Footer } from 'src/pages/LandingPage/Footer';
import { PersonalInfo } from 'src/types/onboarding';

type Step = 'personal-info' | 'phone-verification' | 'email-verification' | 'preview' | 'summary';

const STEPS = ['Dane osobowe', 'Weryfikacja', 'Podgląd', 'Zakończenie'];
const STEP_ORDER: Step[] = ['personal-info', 'phone-verification', 'email-verification', 'preview', 'summary'];

function getStepperIndex(currentStep: Step): number {
  if (currentStep === 'personal-info') return 0;
  if (currentStep === 'phone-verification' || currentStep === 'email-verification') return 1;
  if (currentStep === 'preview') return 2;
  if (currentStep === 'summary') return 3;
  return 0;
}

export default function PartnerOnboarding() {
  const theme = useTheme();

  const [currentStep, setCurrentStep] = useState<Step>('personal-info');
  const [personalInfo, setPersonalInfo] = useState<PersonalInfo | null>(null);
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleBack = () => {
    const currentIndex = STEP_ORDER.indexOf(currentStep);
    if (currentIndex > 0) {
      setCurrentStep(STEP_ORDER[currentIndex - 1]);
    }
  };

  const handlePersonalInfoSubmit = async (data: PersonalInfo) => {
    setIsLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setPersonalInfo(data);
    setCurrentStep(isVerified ? 'preview' : 'phone-verification');
    setIsLoading(false);
  };

  const handlePhoneVerification = async (code: string) => {
    console.log('Phone verification code:', code);
    setIsLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setCurrentStep('email-verification');
    setIsLoading(false);
  };

  const handleEmailVerification = async (code: string) => {
    console.log('Email verification code:', code);

    setIsLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setIsVerified(true);
    setCurrentStep('preview');
    setIsLoading(false);
  };

  const handleContractConfirm = async () => {
    setIsLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setCurrentStep('summary');
    setIsLoading(false);
  };

  const handleResendOTP = async () => {
    setIsLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setIsLoading(false);
  };

  const activeStep = getStepperIndex(currentStep);

  return (
    <>
      <ScrollToTop />
      <Header type="back" />
      <PageContainer>
        <StepperHeader activeStep={activeStep} steps={STEPS} />
        <Paper
          elevation={0}
          sx={{
            p: { xs: 3, sm: 5 },
            backdropFilter: 'blur(20px)',
            backgroundColor: theme.palette.mode === 'dark' ? 'rgb(28 35 47 / 90%)' : 'rgba(255, 255, 255, 0.9)',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 2,
            boxShadow:
              theme.palette.mode === 'dark' ? '0 4px 6px -1px rgba(0, 0, 0, 0.5)' : '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
          }}
        >
          {currentStep === 'personal-info' && (
            <PersonalInfoForm initialData={personalInfo} onSubmit={handlePersonalInfoSubmit} isLoading={isLoading} />
          )}

          {currentStep === 'phone-verification' && personalInfo && (
            <OTPVerification
              type="phone"
              contact={personalInfo.phone}
              onVerify={handlePhoneVerification}
              onResend={handleResendOTP}
              onBack={handleBack}
              isLoading={isLoading}
            />
          )}

          {currentStep === 'email-verification' && personalInfo && (
            <OTPVerification
              type="email"
              contact={personalInfo.email}
              onVerify={handleEmailVerification}
              onResend={handleResendOTP}
              onBack={handleBack}
              isLoading={isLoading}
            />
          )}

          {currentStep === 'preview' && personalInfo && (
            <ContractPreview
              data={personalInfo}
              onConfirm={handleContractConfirm}
              onEdit={() => setCurrentStep('personal-info')}
              onBack={handleBack}
              isLoading={isLoading}
            />
          )}

          {currentStep === 'summary' && personalInfo && <OnboardingSummary data={personalInfo} />}
        </Paper>
      </PageContainer>
      <Footer />
    </>
  );
}

import { CircularProgress } from '@mui/material';
import { FunctionComponent, memo } from 'react';
import { CommissionStatus, TimelineCommissionStatus } from 'src/types/TimelineStatus';
import { CommissionTimeline } from '../CommissionTimeline/CommissionTimeline';

interface BIKCommissionItemStatusesProps {
  data: TimelineCommissionStatus[];
  isLoading: boolean;
  commissionStatus: CommissionStatus;
}

const BIKCommissionItemStatuses: FunctionComponent<BIKCommissionItemStatusesProps> = ({
  data,
  isLoading,
  commissionStatus,
}) => {
  if (isLoading) {
    return <CircularProgress />;
  }

  if (!data) {
    return null;
  }

  return <CommissionTimeline commissionStatus={commissionStatus} timeline={data} />;
};

export default memo(BIKCommissionItemStatuses);

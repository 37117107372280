// AliasEditor.tsx
import { CircularProgress, Select, SelectChangeEvent } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface SelectEditProps {
  defaultValue: string;
  reportId: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  children: React.ReactNode;
  onSelectChange: (id: string, value: string) => void;
}

let editTimeout: NodeJS.Timeout;

const SelectEdit: React.FC<SelectEditProps> = ({
  defaultValue,
  children,
  isLoading,
  reportId,
  isDisabled,
  onSelectChange,
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (e: SelectChangeEvent<HTMLInputElement>) => {
    const updatedValue = e.target.value as string;

    if (editTimeout) {
      clearTimeout(editTimeout);
    }

    editTimeout = setTimeout(() => {
      onSelectChange(reportId, updatedValue);
    }, 500);
  };

  useEffect(() => {
    setValue(defaultValue); // Sync with parent changes
  }, [defaultValue]);

  if (isLoading) {
    return <CircularProgress size={20} />;
  }

  return (
    <Select value={value as any} onChange={handleChange} size="small" disabled={isDisabled}>
      {children}
    </Select>
  );
};

export default SelectEdit;

import { Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as d3 from 'd3';
import { useEffect, useRef } from 'react';

const getScoreColor = (score: number, isDarkMode: boolean) => {
  const colors = isDarkMode
    ? {
        veryGood: '#00963d',
        good: '#75b728',
        moderate: '#ffcc02',
        lowModerate: '#f49004',
        low: '#e4300b',
        inactive: 'rgba(255, 255, 255, 0.2)', // Transparent inactive segments for dark mode
        text: '#fff',
        secondaryText: '#bbb',
      }
    : {
        veryGood: '#00963d',
        good: '#75b728',
        moderate: '#ffcc02',
        lowModerate: '#f49004',
        low: '#e4300b',
        inactive: 'rgba(0, 0, 0, 0.1)', // Transparent inactive segments for light mode
        text: '#000',
        secondaryText: '#666',
      };

  if (score >= 80)
    return { fill: colors.veryGood, text: colors.text, inactive: colors.inactive, secondaryText: colors.secondaryText };
  if (score >= 74)
    return { fill: colors.good, text: colors.text, inactive: colors.inactive, secondaryText: colors.secondaryText };
  if (score >= 69)
    return { fill: colors.moderate, text: colors.text, inactive: colors.inactive, secondaryText: colors.secondaryText };
  if (score >= 59)
    return {
      fill: colors.lowModerate,
      text: colors.text,
      inactive: colors.inactive,
      secondaryText: colors.secondaryText,
    };
  return { fill: colors.low, text: colors.text, inactive: colors.inactive, secondaryText: colors.secondaryText };
};

const getScoreDescription = (score: number) => {
  if (score >= 80) return 'Bardzo dobra ocena punktowa - największa szansa na korzystny kredyt.';
  if (score >= 74) return 'Dobra ocena punktowa - wysokie szanse na kredyt na dobrych warunkach.';
  if (score >= 69) return 'Dobra ocena punktowa - niewielkie opóźnienia mogą wpłynąć na warunki kredytu.';
  if (score >= 59) return 'Umiarkowana ocena punktowa - kredyt możliwy, ale na mniej korzystnych warunkach.';
  return 'Niska ocena punktowa - szanse na kredyt są bardzo małe, bank może odrzucić wniosek.';
};

const ScoringChart = ({
  scoring,
  width,
  height,
  fontType,
}: {
  scoring: number;
  width: number;
  height: number;
  fontType?: 'small';
}) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const chartRef = useRef<SVGSVGElement | null>(null);

  const scoreDescription = getScoreDescription(scoring);

  const {
    fill: scoreColor,
    text: textColor,
    secondaryText: secondaryTextColor,
    inactive: inactiveColor,
  } = getScoreColor(scoring, isDarkMode);

  useEffect(() => {
    if (!chartRef.current || scoring === undefined) return;

    const radius = Math.min(width, height) / 2;
    const segmentCount = 36;
    const activeSegments = Math.round((scoring / 100) * segmentCount);
    const segmentGap = 1;

    // Select and clear previous content
    const svg = d3.select(chartRef.current);
    svg.selectAll('*').remove();

    svg.attr('width', width).attr('height', height).style('background', 'transparent');

    const g = svg.append('g').attr('transform', `translate(${width / 2},${height / 2})`);

    const arc = d3
      .arc()
      .innerRadius(radius * 0.8)
      .outerRadius(radius)
      .padAngle(segmentGap * (Math.PI / 180));

    const pie = d3
      .pie<number>()
      .value(() => 1)
      .sort(null);

    const data = Array(segmentCount).fill(1);

    g.selectAll('path')
      .data(pie(data))
      .enter()
      .append('path')
      .attr('d', arc as any)
      .attr('fill', (_, i) => (i < activeSegments ? scoreColor : inactiveColor))
      .attr('stroke', 'rgba(255, 255, 255, 0.01)')
      .attr('stroke-width', 2);

    g.append('text')
      .attr('text-anchor', 'middle')
      .attr('dy', '-.3em')
      .attr('font-size', fontType === 'small' ? '0.9em' : '1.5em')
      .attr('font-weight', 'bold')
      .attr('fill', textColor)
      .text(scoring);

    g.append('text')
      .attr('text-anchor', 'middle')
      .attr('dy', '1.5em')
      .attr('font-size', fontType === 'small' ? '0.7em' : '0.9em')
      .attr('fill', secondaryTextColor)
      .text('/ 100');
  }, [scoring, width, height, scoreColor, textColor, secondaryTextColor]);

  return (
    <Tooltip title={scoreDescription}>
      <svg ref={chartRef}></svg>
    </Tooltip>
  );
};

export default ScoringChart;

import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { createPartnerForManager, updateUserCommission } from 'src/api/bikApi';
import { handleApiError } from 'src/api/handleApiError';
import { getUserCommission, ROLES, RoleType } from 'src/common/roles';
import { PartnerDetails } from 'src/types/PartnerDetails';
import { formatEmail } from 'src/utils/string';

interface RegistrationData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  roles: RoleType[];
}

interface RegistrationFormProps {
  onSuccess: () => void;
  users: PartnerDetails[];
}

export const PartnerRegistrationForm = ({ onSuccess, users }: RegistrationFormProps) => {
  const [formData, setFormData] = useState<RegistrationData>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    roles: [ROLES.ROLE_PARTNER],
  });
  const [newEmail, setNewEmail] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState<string>('');
  const [formErrors, setFormErrors] = useState<Partial<RegistrationData>>({});
  const userCommissions = getUserCommission();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setFormErrors((prev) => ({ ...prev, [name]: '' }));
  };

  useEffect(() => {
    const user = users.find((u) => u.email === newEmail);
    const newCommission = [...userCommissions].map((c) => ({
      ...c,
      value: 0,
    }));

    if (newCommission.length !== 0 && user && user.commissions.length === 0) {
      try {
        updateUserCommission(user.id, newCommission).then(() => {
          onSuccess();
        });
      } catch {
        /* noop */
      }
    }
  }, [users, newEmail]);

  const validateInputs = (): boolean => {
    const errors: Partial<Partial<RegistrationData>> = {};
    let isValid = true;

    if (!formData.firstName) {
      errors.firstName = 'Proszę podać imię.';
      isValid = false;
    }
    if (!formData.lastName) {
      errors.lastName = 'Proszę podać nazwisko.';
      isValid = false;
    }
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Proszę podać prawidłowy adres email.';
      isValid = false;
    }
    if (!formData.phone || !/^\+?\d{9,15}$/.test(formData.phone)) {
      errors.phone = 'Proszę podać prawidłowy numer telefonu (9-15 cyfr).';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = async () => {
    if (!validateInputs()) return;
    setIsLoading(true);

    try {
      const response = await createPartnerForManager({ ...formData, email: formatEmail(formData.email) });
      if (response && response.status === 204) {
        setInfo(
          'Pomyślnie stworzono nowego partnera! Email do utworzenia hasła został wysłany na podany adres mailowy.'
        );

        if (userCommissions.find((c) => c.productType === 'BIK_CLEANING')) {
          setNewEmail(formatEmail(formData.email));
        }

        onSuccess();
      } else {
        setInfo('Błąd podczas tworzenia nowego partnera.');
      }
    } catch (error: any) {
      if (error?.responseBody.includes('Email already exist')) {
        setInfo('Partner o podanym adresie email już istnieje.');
        return;
      }
      setInfo(handleApiError(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <TextField
        label="Imię"
        name="firstName"
        variant="outlined"
        fullWidth
        margin="normal"
        value={formData.firstName}
        onChange={handleChange}
        error={!!formErrors.firstName}
        helperText={formErrors.firstName}
      />
      <TextField
        label="Nazwisko"
        name="lastName"
        variant="outlined"
        fullWidth
        margin="normal"
        value={formData.lastName}
        onChange={handleChange}
        error={!!formErrors.lastName}
        helperText={formErrors.lastName}
      />
      <TextField
        label="Email"
        name="email"
        type="email"
        variant="outlined"
        fullWidth
        margin="normal"
        value={formData.email}
        onChange={handleChange}
        error={!!formErrors.email}
        helperText={formErrors.email}
      />
      <TextField
        label="Telefon"
        name="phone"
        variant="outlined"
        fullWidth
        margin="normal"
        value={formData.phone}
        onChange={handleChange}
        error={!!formErrors.phone}
        helperText={formErrors.phone}
      />
      {info && (
        <Typography
          component="p"
          variant="h6"
          color={info.includes('Pomyślnie') ? 'green' : 'red'}
          align="center"
          sx={{ my: 2 }}
        >
          {info}
        </Typography>
      )}
      <Button onClick={handleSubmit} fullWidth variant="contained" color="primary" disabled={isLoading} sx={{ mt: 2 }}>
        {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Zatwierdź dodanie'}
      </Button>
    </Box>
  );
};

import { RoleType } from "src/common/roles";

export type CommissionProductType = 'BIK_CLEANING' | 'SKD' | 'UNWIBOR' | 'REFUND_OF_COMMISSION';

export const CommissionProductTypeDetails: Record<CommissionProductType, { translation: string }> = {
  BIK_CLEANING: { translation: 'Optymalizacja BIK' },
  SKD: { translation: 'Sankcja Kredytu Darmowego' },
  UNWIBOR: { translation: 'Odwiborowanie' },
  REFUND_OF_COMMISSION: { translation: 'Zwrot prowizji' },
};
export type CommissionType = 'PERCENTAGE' | 'CONSTANT_VALUE';

export type Commission = {
    productType: CommissionProductType;
    type: CommissionType;
    value: number;
    isPublic: boolean;
}

export type PartnerDetails = {
  id: string,
  active?: boolean,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  hasMarketingConsentsAccepted: boolean,
  hasRODOConsentsAccepted: boolean,
  regulationsConsentVersion: string,
  regulationsConsentAcceptedAt: string,
  friendlyId: string,
  roles: RoleType[],
  commissions: Commission[]
}
import { Box, useMediaQuery, useTheme } from '@mui/material';
import Container from '@mui/material/Container';
import { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  sendContactRequest,
  useBIKReports,
  useCommissionsForBIK,
  useStructuredCommissionReport,
  useStructuredSKDReport,
  useStructuredUnwiborReport,
} from 'src/api/bikApi';
import {
  isBIKEnabledForLoggedUser,
  isNotPartner,
  isRefundOfCommissionEnabledForLoggedUser,
  isSKDEnabledForLoggedUser,
  isWIBOREnabledForLoggedUser,
} from 'src/common/roles';
import { Header } from 'src/components/app-toolbar/Header';
import { useSummaryPreview } from 'src/components/bik-report/useSummaryPreview';
import CommunicationPreferencesModal from 'src/components/CommunicationPreferencesModal';
import Footer from 'src/components/Footer';
import NoServicesMessage from 'src/components/NoServicesFound';
import ScrollToTop from 'src/components/ScrollToTop';
import { SelectedServiceProvider } from 'src/context/SelectedServiceProvider';
import { CommissionCard } from 'src/pages/PartnerReportPreview/ExpandableCards/CommissionCard';
import { SKDCard } from 'src/pages/PartnerReportPreview/ExpandableCards/SKDCard';
import { UnviborCard } from 'src/pages/PartnerReportPreview/ExpandableCards/UnviborCard';
import ProductCard from 'src/pages/PartnerReportPreview/ProductCard';
import { ReportSummary } from 'src/pages/report-preview/report-summary/ReportSummary';
import { CommunicationPreferences } from 'src/types/CommunicationPreferences.type';
import { PromoCodeType } from 'src/types/PromoCode.type';
import { StructuredBIKDetails } from 'src/types/StructuredBIK';
import { isBIKDisabledForEdit } from 'src/utils/bikUtils';
import BIKNotFound from '../commonSites/BIKNotFound';
import LoadingSite from '../commonSites/LoadingSite';
import BIKReportPreview from './BIKReportPreview';
import ServicesDashboard from './HubServices/ServicesDashboard';

type AvailableViews = 'HUB' | 'BIK' | 'SKD' | 'VIBOR' | 'COMMISSION';

function isTypeOfAvailableViews(keyInput: string): keyInput is AvailableViews {
  return ['HUB', 'BIK', 'SKD', 'VIBOR', 'COMMISSION'].includes(keyInput);
}

export default function HubServiceView() {
  const [searchParams] = useSearchParams();
  const [isViewInitialized, setIsViewInitialized] = useState(false);
  const defaultTab = searchParams.get('defaultTab');
  const params = useParams();
  const theme = useTheme();
  const [viewType, setViewType] = useState<AvailableViews>('HUB');

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { data, isLoading, isError, error } = useBIKReports(
    params?.id ?? 'Wrong_param',
    true,
    isBIKEnabledForLoggedUser()
  );
  const { selectedIds, summaryRawData, isLoading: isSummaryLoading } = useSummaryPreview(params?.id ?? '');

  const [failedReportId, setFailedReportId] = useState(params?.id);
  const { data: commissions } = useCommissionsForBIK(
    params.id || '',
    !!summaryRawData?.commission.length,
    'ROLE_PARTNER'
  );

  const [selectedPromoCode, setSelectedPromoCode] = useState<PromoCodeType>({ code: data?.discountCode || '' });

  const { data: skdData, isLoading: isSDKLoading } = useStructuredSKDReport(
    params?.id ?? 'Wrong_param',
    true,
    isSKDEnabledForLoggedUser()
  );

  const { data: unwiborData, isLoading: isUnwiborLoading } = useStructuredUnwiborReport(
    params?.id ?? 'Wrong_param',
    true,
    isWIBOREnabledForLoggedUser()
  );

  const { data: commissionData, isLoading: isCommissionLoading } = useStructuredCommissionReport(
    params?.id ?? 'Wrong_param',
    true,
    isRefundOfCommissionEnabledForLoggedUser()
  );

  useEffect(() => {
    if (data?.discountCode && data?.discountCode !== selectedPromoCode.code) {
      setSelectedPromoCode({ code: data.discountCode });
    }
  }, [data?.discountCode]);

  const customerBIKDetails: undefined | StructuredBIKDetails = useMemo(() => {
    if (!data?.customer?.pesel || !data?.customer?.name) {
      return undefined;
    }

    return {
      name: data.customer.name,
      identifier: data.customer.pesel,
      genaratedAt: data.customer.generatedAt,
      scoring: data.customer.scoring || 0,
    };
  }, [data?.customer]);

  const companyBIKDetails: undefined | StructuredBIKDetails = useMemo(() => {
    if (!data?.company?.nip || !data?.company?.name) {
      return undefined;
    }

    return {
      name: data.company.name,
      identifier: data.company.nip,
      genaratedAt: data.company.generatedAt,
      scoring: 0,
    };
  }, [data?.company]);

  const handleClosePreferences = () => {
    setFailedReportId('');
  };

  const handlePreferencesSubmit = async (data: CommunicationPreferences) => {
    if (!failedReportId) {
      setFailedReportId('');
      return false;
    }

    try {
      await sendContactRequest(failedReportId, data);
    } finally {
      setFailedReportId('');
    }

    return true;
  };

  const generateJsonForTests = () => {
    if (!commissionData) {
      return '';
    }

    const isTestsEnabled = import.meta.env.VITE_ENV === 'DEV' || import.meta.env.VITE_ENV === 'STAGE';

    if (isTestsEnabled && 'allItems' in commissionData) {
      return JSON.stringify(commissionData.allItems);
    }

    return '';
  };

  const commissionTestJSON = useMemo(generateJsonForTests, [commissionData]);

  const isAnyReportAvailable = useMemo(() => {
    return Boolean(
      (data?.customer && data?.customer?.pesel) ||
        (data?.company && data?.company?.nip) ||
        skdData?.services.length ||
        unwiborData?.services.length ||
        commissionData?.services.length
    );
  }, [data, skdData, unwiborData, commissionData]);

  const isViewLoading = isLoading || isSummaryLoading || isSDKLoading || isUnwiborLoading || isCommissionLoading;

  useEffect(() => {
    if (isViewInitialized) {
      return;
    }

    if (defaultTab && isTypeOfAvailableViews(defaultTab) && !isViewLoading) {
      setViewType(defaultTab as AvailableViews);
      setIsViewInitialized(true);
    }
  }, [defaultTab, isViewLoading, isViewInitialized]);

  // Early return for loading or data not found
  if (isLoading || isSummaryLoading || isSDKLoading || isUnwiborLoading || isCommissionLoading) return <LoadingSite />;
  if (!summaryRawData?.id)
    return (
      <>
        <BIKNotFound reportData={data} />
        {(error as any)?.status !== 400 && (
          <CommunicationPreferencesModal
            isOpen={!!failedReportId && isError}
            onClose={handleClosePreferences}
            onSubmit={handlePreferencesSubmit}
          />
        )}
      </>
    );

  const isReportDisabled = isBIKDisabledForEdit(data) || !!commissions?.length;
  const isUserNotPartner = isNotPartner();

  const isForcedPromo = Boolean(
    !summaryRawData?.discountCode &&
      summaryRawData?.discountPrice &&
      summaryRawData.price &&
      summaryRawData.discountPrice !== summaryRawData.price
  );

  return (
    <SelectedServiceProvider>
      <ScrollToTop />
      <Header type="back" />
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          gap: 4,
          mt: '64px',
          position: 'relative',
        }}
      >
        <Container
          id="show-bik-raport"
          sx={{
            pt: 1,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            width: isMobile ? '100%' : '70%',
          }}
          data-testid="hub-view"
        >
          <ServicesDashboard
            bikCustomerDetails={customerBIKDetails}
            bikCompanyDetails={companyBIKDetails}
            selectedService={viewType}
            setSelectedService={setViewType}
            bikData={data}
            skdData={skdData}
            viborData={unwiborData}
            commissionData={commissionData}
          />

          {!isAnyReportAvailable && <NoServicesMessage />}

          {data && (viewType === 'BIK' || viewType === 'HUB') && (
            <BIKReportPreview
              isHidden={viewType !== 'BIK'}
              data={data}
              previouslySelectedIds={selectedIds}
              setSelectedPromoCode={setSelectedPromoCode}
            />
          )}
          {(viewType === 'SKD' || viewType === 'HUB') && !!skdData?.services.length && (
            <ProductCard
              bikDetails={customerBIKDetails}
              isHidden={viewType !== 'SKD'}
              data={skdData}
              isDisabled={isReportDisabled}
              isCheckboxHidden
              isExpanded
              dataTestId="skd-main"
              type={skdData.type}
              expandCard={
                <SKDCard
                  isDisabled={isReportDisabled}
                  previouslySelected={summaryRawData?.skd || []}
                  services={skdData.services}
                />
              }
            />
          )}
          {(viewType === 'VIBOR' || viewType === 'HUB') && !!unwiborData?.services.length && (
            <ProductCard
              bikDetails={customerBIKDetails}
              isHidden={viewType !== 'VIBOR'}
              isCheckboxHidden
              isExpanded
              isDisabled={isReportDisabled}
              data={unwiborData}
              type={unwiborData.type}
              dataTestId="unwibor-main"
              expandCard={
                <UnviborCard
                  isDisabled={isReportDisabled}
                  previouslySelected={summaryRawData?.unwibor || []}
                  services={unwiborData.services}
                />
              }
            />
          )}
          {(viewType === 'COMMISSION' || viewType === 'HUB') && !!commissionData?.services.length && (
            <ProductCard
              bikDetails={customerBIKDetails}
              isHidden={viewType !== 'COMMISSION'}
              isCheckboxHidden
              isExpanded
              isDisabled={isReportDisabled}
              data={commissionData}
              type={commissionData.type}
              dataTestId="commission-main"
              expandCard={
                <CommissionCard
                  isDisabled={isReportDisabled}
                  previouslySelected={summaryRawData?.commission || []}
                  services={commissionData.services}
                />
              }
            />
          )}
        </Container>
        {!isUserNotPartner && (
          <Box
            sx={{
              position: isMobile ? 'static' : 'sticky',
              top: 64,
              width: isMobile ? '100%' : '30%',
              borderRadius: 2,
              boxShadow: theme.shadows[2],
            }}
          >
            <ReportSummary data={data || summaryRawData} isForcedPromo={isForcedPromo} isDisabled={isReportDisabled} />
          </Box>
        )}

        {commissionTestJSON && <Box data-testid="commission-test" data-json={commissionTestJSON} />}
      </Box>
      <Footer />
    </SelectedServiceProvider>
  );
}

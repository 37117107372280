import { Box, useTheme } from '@mui/material';
import { useMemo, useState } from 'react';
import BIKReportContent from 'src/components/bik-report/BIKReportContent';
import { getSummaryPreview } from 'src/components/bik-report/useSummaryPreview';
import BIKCalculationsPreviewModal from 'src/components/BIKCalculationsPreview/BIKCalculationsPreviewModal';
import { ShowBIKReportButton } from 'src/components/SanitazedBIKPreview/SanitazedBIKButton';
import { useSelectedService } from 'src/context/useSelectedService';
import { PromoCode } from 'src/pages/report-preview/report-summary/PromoCode';
import { SummaryTopTitle } from 'src/pages/report-preview/report-summary/SummaryTopTitle';
import { BIKReportType } from 'src/types/BIKReport.type';
import { BIKCompany, BIKPrivate, CompanyBIKSummary } from 'src/types/CompanyBIKSummary.type';
import { PromoCodeType } from 'src/types/PromoCode.type';
import { isBIKDisabledForEdit } from 'src/utils/bikUtils';

export default function BIKReportPreview({
  setSelectedPromoCode,
  data,
  isHidden,
  previouslySelectedIds,
}: {
  setSelectedPromoCode: (code: PromoCodeType) => void;
  isHidden?: boolean;
  data: CompanyBIKSummary;
  previouslySelectedIds: string[];
}) {
  const [isCalculationOpen, setIsCalculationOpen] = useState(false);
  const theme = useTheme();
  const { selectedBIKServices, selectedBIKRowsCount } = useSelectedService();
  const [isSelectedAll, setIsSelectedAll] = useState<boolean | undefined>(undefined);

  const handleOnCloseCalculation = () => {
    setIsCalculationOpen(false);
  };

  const handleShowCalculation = () => {
    setIsCalculationOpen(true);
  };

  // Helper component to render BIK report content based on type
  const useRenderBIKReportContent = (
    dataItem: BIKCompany | BIKPrivate | undefined,
    type: BIKReportType,
    isDisabled: boolean
  ) =>
    useMemo(() => {
      return dataItem ? (
        <BIKReportContent
          bikId={data.id}
          isDisabled={isDisabled}
          selectedIds={selectedBIKRowsCount ? getSummaryPreview(selectedBIKServices) : previouslySelectedIds}
          data={dataItem}
          type={type}
          isSelectedAll={isSelectedAll}
        />
      ) : null;
    }, [data, dataItem, type, isSelectedAll, isDisabled, selectedBIKServices, previouslySelectedIds]);

  const isReportDisabled = isBIKDisabledForEdit(data);

  const { company, customer } = data;

  return (
    <Box
      sx={{
        display: isHidden ? 'none' : 'block',
        background: theme.palette.common.background,
        p: 2,
        borderRadius: 2,
        boxShadow: 3,
      }}
      data-testid="bik-report"
    >
      <ShowBIKReportButton reportId={data.id} onShowCalculations={handleShowCalculation} />
      {company && (
        <SummaryTopTitle
          type={'COMPANY'}
          generatedAt={company.generatedAt}
          isDisabled={isReportDisabled}
          isSelectedAll={Boolean(isSelectedAll)}
          setIsSelectedAll={setIsSelectedAll}
          showSelectAll
        />
      )}
      {useRenderBIKReportContent(company, 'COMPANY', isReportDisabled)}
      {customer && (
        <SummaryTopTitle
          isDisabled={isReportDisabled}
          showSelectAll={!company}
          generatedAt={customer.generatedAt}
          scoring={customer.scoring}
          type={'CUSTOMER'}
          isSelectedAll={Boolean(isSelectedAll)}
          setIsSelectedAll={setIsSelectedAll}
        />
      )}
      {useRenderBIKReportContent(customer, 'CUSTOMER', isReportDisabled)}
      <PromoCode
        isDisabled={isReportDisabled}
        setSelectedPromoCode={setSelectedPromoCode}
        reportPromoCode={data.discountCode || ''}
        reportId={data.id}
      />

      <BIKCalculationsPreviewModal id={data.id} onClose={handleOnCloseCalculation} open={isCalculationOpen} />
    </Box>
  );
}

import { Box } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { useSelectedService } from 'src/context/useSelectedService';
import { isBIKItem } from 'src/guards/isBIKItem';
import { isBIKRequest } from 'src/guards/isBIKRequest';
import { BIKSummaryItem, BIKSummaryRequest } from 'src/types/BIKSummary';
import { BIKItem, BIKRequest } from 'src/types/CompanyBIKSummary.type';
import { StructuredType } from 'src/types/StructuredBIK';
import BIKPosition from './BIKPosition';

type BIKPositionsProps = {
  items: (BIKItem | BIKRequest)[];
  savedItems: BIKSummaryItem[];
  savedRequests: BIKSummaryRequest[];
  isDisabled: boolean;
  bikId: string;
  isSelectable: boolean;
  isClientView?: boolean;
  isGlobalCheckboxSelected: boolean;
  type: StructuredType;
  clearGlobalCheckbox: () => void;
};

const BIKPositions = ({
  items,
  bikId,
  isSelectable,
  isDisabled,
  isClientView,
  savedItems,
  savedRequests,
  type,
  clearGlobalCheckbox,
  isGlobalCheckboxSelected,
}: BIKPositionsProps) => {
  const { setSelectedService } = useSelectedService();

  const [selectedItems, setSelectedItems] = useState<(BIKItem | BIKRequest)[]>([]);

  const uniqueHeaders = new Set<string>();

  const handleCheckboxChange = (item: BIKItem | BIKRequest, isChecked: boolean) => {
    clearGlobalCheckbox();
    setSelectedItems((prev) => {
      const actualItems = isChecked ? [...prev, item] : prev.filter((selected) => selected.id !== item.id);

      if (setSelectedService) {
        setSelectedService({
          type,
          installment: actualItems.reduce((sum, item) => sum + (isBIKItem(item) ? item.installment : 0), 0),
          left: actualItems.reduce((sum, item) => sum + (isBIKItem(item) ? item.left : 0), 0),
          partnerCommission: actualItems.reduce((sum, item) => sum + (item?.partnerCommission || 0), 0),
          discountPartnerCommission: actualItems.reduce((sum, item) => sum + (item?.partnerCommission || 0), 0),
          discountPrice: actualItems.reduce((sum, item) => sum + item.discountPrice, 0),
          price: actualItems.reduce((sum, item) => sum + item.price, 0),
          items: actualItems,
          subType: 'CUSTOM',
        });
      }

      return actualItems;
    });
  };

  useEffect(() => {
    if (!isGlobalCheckboxSelected || !isSelectable) {
      return;
    }

    setSelectedItems([]);
  }, [isGlobalCheckboxSelected]);

  useEffect(() => {
    if (!isSelectable) {
      return;
    }

    if (!savedItems.length && !savedRequests.length) {
      return;
    }

    let selectedItems: BIKItem[] = [];

    if (savedItems.length) {
      selectedItems = items.filter((item) => {
        const typedItem = isBIKItem(item) ? item : undefined;

        if (!typedItem) {
          return false;
        }

        const isFound = Boolean(
          savedItems.find(
            (savedItem) => savedItem.id === typedItem.id && savedItem.bikReportType === typedItem.bikReportType
          )
        );

        return isFound;
      }) as BIKItem[];
    }

    let selectedRequests: BIKRequest[] = [];

    if (savedRequests.length) {
      selectedRequests = items.filter((item) => {
        const typedRequest = isBIKRequest(item) ? item : undefined;

        if (!typedRequest) {
          return false;
        }

        const isFound = Boolean(
          savedRequests.find(
            (savedRequest) =>
              savedRequest.id === typedRequest.id && savedRequest.bikReportType === typedRequest.bikReportType
          )
        );

        return isFound;
      }) as BIKRequest[];
    }

    const actualItems = [...selectedItems, ...selectedRequests];
    if (setSelectedService) {
      setSelectedService({
        type,
        installment: actualItems.reduce((sum, item) => sum + (isBIKItem(item) ? item.installment : 0), 0),
        left: actualItems.reduce((sum, item) => sum + (isBIKItem(item) ? item.left : 0), 0),
        partnerCommission: actualItems.reduce((sum, item) => sum + (item?.partnerCommission || 0), 0),
        discountPartnerCommission: actualItems.reduce((sum, item) => sum + (item?.partnerCommission || 0), 0),
        discountPrice: actualItems.reduce((sum, item) => sum + item.discountPrice, 0),
        price: actualItems.reduce((sum, item) => sum + item.price, 0),
        items: actualItems,
        subType: 'CUSTOM',
      });
    }
    setSelectedItems(actualItems);
  }, [isSelectable, savedItems, savedRequests, items]);

  useEffect(() => {
    if (!selectedItems.length) {
      return;
    }

    const actualItems = items.filter((item) => {
      const isFound = selectedItems.find(
        (selectedItem) => item.id === selectedItem.id && item.creditorId === selectedItem.creditorId
      );

      return Boolean(isFound);
    });

    setSelectedItems(actualItems);
  }, [items]);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {items.map((item, index) => {
        const type = isBIKItem(item) ? (item.isOpen ? 'ADD' : 'REMOVE') : 'REQUEST';
        const isFirstOccurrence = !uniqueHeaders.has(type);

        if (isFirstOccurrence) {
          uniqueHeaders.add(type);
        }

        return (
          <BIKPosition
            bikId={bikId}
            isDisabled={isDisabled}
            isClientView={isClientView}
            item={item}
            key={index}
            isSelectable={isSelectable}
            isFirstOccurrence={isFirstOccurrence}
            onCheckboxChange={handleCheckboxChange}
            isChecked={selectedItems.includes(item)}
          />
        );
      })}
    </Box>
  );
};

export default memo(BIKPositions);

import { LineChart as MUILineChart } from '@mui/x-charts/LineChart';
import React, { useMemo } from 'react';

type LineChartData = {
  title: string;
  x: number;
  y: number;
}[];

type HeatmapData = {
  x: string;
  y: string;
  isStarted: boolean;
  value: number;
}[];

interface LineChartProps {
  data: HeatmapData;
  height?: number;
}

const LineChart: React.FC<LineChartProps> = ({ data }) => {
  const convertHeatmapDataToLineChartData = (data: HeatmapData): LineChartData => {
    return data
      .filter((data) => data.isStarted)
      .map((d, index) => ({
        title: `${d.x}-${d.y}`,
        x: index,
        y: d.value,
      }));
  };

  const lineChartData = useMemo(() => convertHeatmapDataToLineChartData(data), [data]);
  const xValues = lineChartData.map((d) => d.x);
  const yValues = lineChartData.map((d) => d.y);

  return (
    <MUILineChart
      dataset={lineChartData}
      xAxis={[
        {
          data: xValues,
          valueFormatter: (value) => {
            return lineChartData[value]?.title || '';
          },
        },
      ]}
      series={[
        {
          data: yValues,
          valueFormatter: (value) => (value == null ? '?' : `${value.toString()} dni opóźnienia`),
          area: true,
        },
      ]}
      height={300}
    />
  );
};

export default LineChart;

import {
  format as dateFnsFormat,
} from 'date-fns';

const MINUTE_IN_SECONDS = 60;
const HOUR_IN_SECONDS = 60 * MINUTE_IN_SECONDS;

export interface Duration {
  hours: number;
  minutes: number;
  seconds: number;
  [key: string]: number;
}


export const secondsToDuration = (secs: number | null | undefined): Duration => {
  if (!secs) {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  const hours = Math.floor(secs / HOUR_IN_SECONDS);
  const rest = secs % HOUR_IN_SECONDS;
  const minutes = Math.floor(rest / MINUTE_IN_SECONDS);
  const seconds = rest % MINUTE_IN_SECONDS;

  return {
    hours,
    minutes,
    seconds,
  };
};

export const formatDuration = (duration: Partial<Duration>, { short = false } = {}): string => {
  let result = '';
  const durationPropertiesOrder = ['hours', 'minutes', 'seconds'];

  for (const propertyName of durationPropertiesOrder) {
    if (duration[propertyName] || result.length || propertyName === 'seconds') {
      const longName = duration[propertyName] === 1 ? propertyName.slice(0, -1) : propertyName;
      const name = short ? propertyName.charAt(0) : ` ${longName}`;

      result += `${duration[propertyName] || 0}${name} `;
    }
  }

  return result.trim();
};

export const format = (date: Date, format: string): string =>
  dateFnsFormat(date, format);

export function convertDate(dateString: string) {
  const [day, month, year] = dateString.split('.');

  // Create a new date string in yyyy-MM-DD format
  return `${year}-${month}-${day}`;
}
export const formatDate = (dateString: string, isExtended?: boolean) => {
  const date = new Date(dateString);
  return isExtended
    ? date.toLocaleString('pl-PL') // Includes date and time
    : date.toLocaleDateString('pl-PL'); // Only date
};

import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FunctionComponent, ReactNode } from 'react';

interface RowGridProps {
  creationTimeComponent: ReactNode;
  dataComponent?: ReactNode;
  totalComponent?: ReactNode;
  commentComponent?: ReactNode;
  statusProps?: ReactNode;
  sourceComponent?: ReactNode;
  totalInfoComponent?: ReactNode;
  additionalDataComponent?: ReactNode;
}

export const RowGrid: FunctionComponent<RowGridProps> = ({
  creationTimeComponent,
  dataComponent,
  totalComponent,
  commentComponent,
  statusProps,
  totalInfoComponent,
  sourceComponent,
  additionalDataComponent,
}) => {
  return (
    <>
      <Grid order={{ xs: 1 }} size={{ xs: 6, md: 2 }}>
        {creationTimeComponent}
      </Grid>

      <Grid order={{ xs: 2, md: 3 }} size={{ xs: 6, md: 2 }}>
        <Box textAlign="right">
          <Grid container spacing={1}>
            {totalInfoComponent && <Grid size={{ xs: 12 }}>{totalInfoComponent}</Grid>}
            <Grid size={{ xs: 12 }}>{totalComponent}</Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid order={{ xs: 3, md: 2 }} size={{ xs: 12, md: 8 }}>
        <Grid container spacing={1}>
          {(dataComponent || statusProps) && (
            <Grid size={{ xs: 12, sm: 4, lg: 4 }}>
              <Grid container spacing={1}>
                <Grid size={{ xs: 12 }}>{dataComponent}</Grid>

                <Grid size={{ xs: 12 }}>{statusProps}</Grid>
              </Grid>
            </Grid>
          )}

          {(sourceComponent || additionalDataComponent) && (
            <Grid size={{ xs: 12, sm: 8, lg: 8 }}>
              <Grid container spacing={1}>
                <Grid size={{ xs: 12, lg: additionalDataComponent ? 6 : 12 }}>{sourceComponent}</Grid>

                <Grid size={{ xs: 12, lg: sourceComponent ? 6 : 12 }}>{additionalDataComponent}</Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      {commentComponent && (
        <Grid order={{ xs: 3 }} size={{ xs: 12 }}>
          {commentComponent}
        </Grid>
      )}
    </>
  );
};

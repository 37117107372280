import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';

interface ProfileToggleProps {
  profileType: 'personal' | 'company' | 'how-it-works';
  setProfileType: (type: 'personal' | 'company' | 'how-it-works') => void;
}

const ProfileToggle: React.FC<ProfileToggleProps> = ({ profileType, setProfileType }) => {
  const handleChange = (
    _: React.MouseEvent<HTMLElement>,
    newProfileType: 'personal' | 'company' | 'how-it-works' | null
  ) => {
    if (newProfileType !== null) {
      setProfileType(newProfileType);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 3 }}>
      <ToggleButtonGroup value={profileType} exclusive onChange={handleChange} aria-label="profile type">
        <ToggleButton value="personal" aria-label="personal profile">
          <PersonIcon sx={{ mr: 1 }} />
          BIK dla osoby prywatnej
        </ToggleButton>
        <ToggleButton value="company" aria-label="company profile">
          <BusinessIcon sx={{ mr: 1 }} />
          BIK dla firmy
        </ToggleButton>
        {/* <ToggleButton value="how-it-works" aria-label="how it works">
          <SlideshowIcon sx={{ mr: 1 }} />
          Jak działa BIK?
        </ToggleButton> */}
      </ToggleButtonGroup>
    </Box>
  );
};

export default ProfileToggle;

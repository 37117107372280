import { Cancel, CheckCircle, SecurityRounded } from '@mui/icons-material';
import { Box, Paper, Tab, Tabs, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { acceptUserConsentWithVersion, usePartnerDetails } from 'src/api/bikApi';
import { CURRENT_CONSENT_VERSION } from 'src/common/currentConsentVersion';
import DashboardContainer from 'src/common/DashboardContainer';
import { useSnackbar } from 'src/common/useSnackbar';
import { MainConsent } from 'src/components/MainConsent/MainConsent';
import { ConsentForm } from 'src/pages/consents/ConsentsForm';
import LoadingSite from '../commonSites/LoadingSite';

const ConsentsPage = () => {
  const { data, isLoading } = usePartnerDetails();
  const { showSnackbar } = useSnackbar();
  const { mutate: acceptRegulationsConsent, isPending } = useMutation({
    mutationFn: acceptUserConsentWithVersion,
    onSuccess: () => {
      showSnackbar('Regulamin został pomyślnie zaakceptowany.', 'success');

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
  });
  const [tabIndex, setTabIndex] = useState(0);

  const onAcceptRegulationsConsent = () => {
    acceptRegulationsConsent(CURRENT_CONSENT_VERSION);
  };

  const handleTabChange = (_: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex);
  };

  if (isLoading) {
    return <LoadingSite />;
  }

  const isConsentChanged =
    typeof data?.regulationsConsentVersion === 'string' && data.regulationsConsentVersion !== CURRENT_CONSENT_VERSION;

  return (
    <DashboardContainer title="Zgody użytkownika">
      <Tabs value={tabIndex} onChange={handleTabChange} variant="fullWidth" sx={{ mb: 4 }}>
        <Tab
          label={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              Regulamin korzystania z usług BIK Master
              {data?.regulationsConsentVersion === CURRENT_CONSENT_VERSION ? (
                <CheckCircle color="success" />
              ) : (
                <Cancel color="error" />
              )}
            </Box>
          }
        />
        {/* <Tab
          label={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              Zarządzanie Zgodami RODO
              {data?.hasMarketingConsentsAccepted && data?.hasRODOConsentsAccepted ? (
                <CheckCircle color="success" />
              ) : (
                <Cancel color="error" />
              )}
            </Box>
          }
        /> */}
      </Tabs>

      {tabIndex === 0 && (
        <MainConsent
          hasRegulationsAccepted={data?.regulationsConsentVersion === CURRENT_CONSENT_VERSION}
          timestamp={data?.regulationsConsentAcceptedAt}
          isConsentChanged={isConsentChanged}
          isLoading={isPending}
          onAccept={onAcceptRegulationsConsent}
        />
      )}

      {tabIndex === 1 && (
        <>
          <Box sx={{ py: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 4 }}>
              <SecurityRounded sx={{ fontSize: 32, color: 'primary.main' }} />
              <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold' }}>
                Zarządzanie Zgodami RODO
              </Typography>
            </Box>

            <Paper elevation={3} sx={{ p: 3, mb: 4, borderLeft: 4, borderColor: 'primary.main' }}>
              <Typography variant="body1" color="text.secondary">
                Akceptując zgody w tym panelu, zgody te zostaną automatycznie zaakceptowane podczas wgrywania raportu
                BIK.
              </Typography>
            </Paper>

            {data && <ConsentForm partnerDetails={data} />}
          </Box>

          <Box component="footer" sx={{ mt: 4, pb: 4 }}>
            <Typography variant="body2" color="text.secondary" align="center" sx={{ fontStyle: 'italic' }}>
              Uwaga: Te zgody zostaną automatycznie zastosowane podczas wgrywania raportu BIK.
            </Typography>
          </Box>
        </>
      )}
    </DashboardContainer>
  );
};

export default ConsentsPage;

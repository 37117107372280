import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { get, intersection, pick } from 'lodash';
import { FunctionComponent, useCallback, useEffect, useRef } from 'react';
import { ReportStatus, ReportTimeline } from 'src/common/reports';
import { TimelineStep } from '../TimelineStep/TimelineStep';
import { TimelineStepsContainer, TimelineStepsContainerInner } from './styles';

const nonTerminalSteps = [
  'reportCreatedAt',
  'documentsUploadedAt',
  'itemsSelectedForRemovalAt',
  'userDataEnteredAt',
  'summaryInformationSentAt',
  'paymentCompletedAt',
  'documentsToBeSentAt',
  'documentsSentAt',
] as const;

const terminalSteps = ['documentsUploadFailedAt', 'documentsUploadedParsingFailedAt', 'paymentFailedAt'] as const;

const steps = [...nonTerminalSteps, ...terminalSteps] as const;

const stepNames: ReportTimeline = {
  documentsSentAt: 'documentsSentAt',
  documentsToBeSentAt: 'documentsToBeSentAt',
  documentsUploadedParsingFailedAt: 'documentsUploadedParsingFailedAt',
  paymentFailedAt: 'paymentFailedAt',
  paymentCompletedAt: 'paymentCompletedAt',
  paymentPendingAt: 'paymentPendingAt',
  paymentProcessInitiatedAt: 'paymentProcessInitiatedAt',
  userDataEnteredAt: 'userDataEnteredAt',
  itemsSelectedForRemovalAt: 'itemsSelectedForRemovalAt',
  documentsUploadFailedAt: 'documentsUploadFailedAt',
  summaryInformationSentAt: 'summaryInformationSentAt',
  documentsUploadedAt: 'documentsUploadedAt',
  reportCreatedAt: 'reportCreatedAt',
};

export interface TimelineStepsProps {
  timeline: ReportTimeline;
  bikStatus: ReportStatus;
  width: number;
}

const ensureAllSteps = (timeline: ReportTimeline) => {
  // Extract existing steps from the timeline
  const timelineSteps = pick(timeline, nonTerminalSteps);

  // Add missing steps with undefined
  nonTerminalSteps.forEach((step) => {
    if (!(step in timelineSteps)) {
      timelineSteps[step] = undefined;
    }
  });

  return timelineSteps;
};

export const TimelineSteps: FunctionComponent<TimelineStepsProps> = ({ timeline, bikStatus, width }) => {
  let timelineSteps = ensureAllSteps(timeline);
  const hasTerminalStep = timeline.documentsUploadFailedAt || timeline.paymentFailedAt;

  const ref = useRef<HTMLDivElement | null>(null);

  if (hasTerminalStep) {
    for (const stepName of [...nonTerminalSteps].reverse()) {
      if (timelineSteps[stepName]) {
        break;
      }

      delete timelineSteps[stepName];
    }

    for (const stepName of terminalSteps) {
      if (timeline[stepName]) {
        timelineSteps = {
          ...timelineSteps,
          [stepName]: timeline[stepName],
        };
      }
    }
  }

  const stepsMapped = intersection(steps, Object.keys(timelineSteps)).map((stepName) => {
    const stepEnum = stepName as keyof typeof timelineSteps;
    return {
      stepName: stepNames[stepEnum]!,
      key: stepName,
      time: timeline[stepEnum] ? timelineSteps[stepEnum] : undefined,
    };
  });

  const timelineItemMinWidth = 180;
  const minTimelineWidth = timelineItemMinWidth * stepsMapped.length;

  const scrollTimeline = useCallback((left: number) => {
    if (ref.current) {
      ref.current.scroll({
        top: 0,
        left,
        behavior: 'smooth',
      });
    }
  }, []);

  const onTimelineBackward = useCallback(() => {
    if (ref.current) {
      scrollTimeline(ref.current.scrollLeft - ref.current.scrollWidth / stepsMapped.length);
    }
  }, [scrollTimeline, stepsMapped.length]);

  const onTimelineForward = useCallback(() => {
    if (ref.current) {
      scrollTimeline(ref.current.scrollLeft + ref.current.scrollWidth / stepsMapped.length);
    }
  }, [scrollTimeline, stepsMapped.length]);

  useEffect(() => {
    const initialIndex = stepsMapped.findIndex((el) => el.stepName === bikStatus.toLowerCase());
    if (ref.current) {
      scrollTimeline(initialIndex * timelineItemMinWidth);
    }
  }, [bikStatus, stepsMapped, scrollTimeline]);

  return (
    <Box display="flex" data-testid="timeline">
      {width < minTimelineWidth && (
        <Box alignSelf="center" mr={1} mt={-3.2}>
          <IconButton size="medium" onClick={onTimelineBackward} data-testid="timeline-navigation-backward">
            <ArrowBack />
          </IconButton>
        </Box>
      )}

      <Box flex="100%" minWidth="0">
        <TimelineStepsContainer ref={ref}>
          <TimelineStepsContainerInner minTimelineWidth={minTimelineWidth}>
            {stepsMapped.map((step, index) => {
              return (
                <TimelineStep
                  stepsCount={stepsMapped.length}
                  stepName={step.stepName}
                  stepTime={step.time ?? undefined}
                  previousStepTime={get(stepsMapped, `${index - 1}.time`)}
                  key={step.stepName}
                />
              );
            })}
          </TimelineStepsContainerInner>
        </TimelineStepsContainer>
      </Box>

      {width < minTimelineWidth && (
        <Box alignSelf="center" ml={1} mt={-3.2}>
          <IconButton size="medium" onClick={onTimelineForward} data-testid="timeline-navigation-forward">
            <ArrowForward />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

import {
  ErrorOutline as AlertIcon,
  KeyboardArrowDown as ArrowDownIcon,
  CheckCircleOutline as CheckIcon,
  DescriptionOutlined as DocumentIcon,
  VisibilityOutlined as EyeIcon,
} from '@mui/icons-material';
import {
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Paper,
  Step,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import React, { useState } from 'react';
import { useSnackbar } from 'src/common/useSnackbar';
import { formatDate } from 'src/utils/date';
import MainConsentText from '../MainConsentText';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

export const MainConsent = ({
  hasRegulationsAccepted,
  isLoading,
  timestamp,
  onAccept,
  isConsentChanged,
}: {
  hasRegulationsAccepted: boolean;
  isLoading?: boolean;
  timestamp?: string;
  onAccept: () => void;
  isConsentChanged?: boolean;
}) => {
  const { showSnackbar } = useSnackbar();
  const [isChecked, setIsChecked] = useState(hasRegulationsAccepted);
  const [showError, setShowError] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const element = e.currentTarget;
    const isAtBottom = Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) < 50;
    if (isAtBottom) {
      setHasScrolled(true);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleAccept = () => {
    if (hasRegulationsAccepted) {
      showSnackbar('Regulamin został już zaakceptowany.', 'info');
      return;
    }

    scrollToTop();
    onAccept();
  };

  const steps = ['Przeczytaj regulamin', 'Zapoznaj się z treścią', 'Potwierdź akceptację'];

  let statusMessage = '';
  let statusSeverity: 'success' | 'warning' | 'info' = 'info';

  if (hasRegulationsAccepted && timestamp) {
    statusMessage = `Regulamin został zaakceptowany dnia ${formatDate(timestamp, true)}`;
    statusSeverity = 'success';
  } else if (hasRegulationsAccepted) {
    statusMessage = 'Regulamin został zaakceptowany.';
    statusSeverity = 'success';
  } else if (isConsentChanged) {
    statusMessage = 'Regulamin został zaktualizowany. Proszę zapoznać się z nim ponownie i zaakceptować.';
    statusSeverity = 'warning';
  } else {
    statusMessage =
      'Prosimy o dokładne zapoznanie się z treścią regulaminu. Po przeczytaniu należy zaznaczyć pole akceptacji i kliknąć przycisk "Akceptuję regulamin".';
    statusSeverity = 'info';
  }

  const activeStep = hasRegulationsAccepted ? 3 : hasScrolled ? (isChecked ? 2 : 1) : 0;

  return (
    <Box sx={{ minHeight: '100vh', bgcolor: 'grey.50', py: 4 }}>
      <Container maxWidth="lg">
        {/* Progress Steps */}
        <Box sx={{ mb: 4 }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} completed={index < activeStep}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        {/* Header */}
        <StyledPaper elevation={1}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
            <DocumentIcon sx={{ fontSize: 40, color: 'primary.main' }} />
            <Typography variant="h4" component="h1">
              Regulamin korzystania z usług BIK Master
            </Typography>
          </Box>

          {statusMessage && (
            <Alert
              severity={statusSeverity}
              icon={<EyeIcon />}
              sx={{
                mb: 3,
                mt: 1,
                '& .MuiAlert-message': {
                  width: '100%',
                },
              }}
            >
              <AlertTitle>Ważne</AlertTitle>

              {statusMessage}
            </Alert>
          )}
        </StyledPaper>

        <StyledPaper elevation={1}>
          {/* Scroll indicator */}
          {!hasScrolled && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                mb: 2,
                color: 'primary.main',
                animation: 'bounce 1s infinite',
                '@keyframes bounce': {
                  '0%, 100%': {
                    transform: 'translateY(0)',
                  },
                  '50%': {
                    transform: 'translateY(-10px)',
                  },
                },
              }}
            >
              <ArrowDownIcon />
              <Typography>Przewiń, aby przeczytać całość</Typography>
            </Box>
          )}

          {/* Regulations content */}
          <MainConsentText handleScroll={handleScroll} />

          {/* Instructions */}
          <Alert severity="success" sx={{ mb: 3 }}>
            <AlertTitle>Co należy zrobić?</AlertTitle>
            <Box component="ol" sx={{ mt: 1, pl: 2 }}>
              <Typography component="li">Przeczytaj dokładnie regulamin powyżej</Typography>
              <Typography component="li">Zaznacz pole potwierdzenia poniżej</Typography>
              <Typography component="li">Kliknij niebieski przycisk "Akceptuję regulamin"</Typography>
            </Box>
          </Alert>

          {/* Checkbox */}
          <Paper
            elevation={0}
            sx={{
              p: 3,
              mb: 3,
              border: 2,
              borderColor: isChecked ? 'success.main' : showError ? 'error.main' : 'primary.light',
              bgcolor: isChecked ? 'success.lighter' : showError ? 'error.lighter' : 'primary.lighter',
              borderRadius: 2,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={(e) => {
                    if (hasRegulationsAccepted) {
                      showSnackbar('Regulamin został już zaakceptowany.', 'info');
                      return;
                    }
                    setIsChecked(e.target.checked);
                    setShowError(false);
                  }}
                  color="primary"
                />
              }
              label={
                <Box>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                    Potwierdzam zapoznanie się z regulaminem
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Zaznaczając to pole, potwierdzam że przeczytałem(-am) i akceptuję warunki regulaminu korzystania z
                    usług BIK Master.
                  </Typography>
                </Box>
              }
            />
          </Paper>

          {/* Error message */}
          {showError && (
            <Alert severity="error" icon={<AlertIcon />} sx={{ mb: 3 }}>
              Aby kontynuować, musisz potwierdzić zapoznanie się z regulaminem zaznaczając pole powyżej.
            </Alert>
          )}

          {/* Accept button */}
          <Button
            variant="outlined"
            size="large"
            fullWidth
            onClick={handleAccept}
            disabled={!isChecked || isLoading || hasRegulationsAccepted}
            startIcon={<CheckIcon />}
            sx={{
              py: 2,
              fontSize: '1.1rem',
              transition: 'transform 0.2s',
              '&:not(:disabled):hover': {
                transform: 'scale(1.02)',
              },
            }}
          >
            Akceptuję regulamin
          </Button>
        </StyledPaper>
      </Container>
    </Box>
  );
};

import { ReactNode } from 'react';

import { RoleType } from 'src/common/roles';
import { PartnerDetails } from 'src/types/PartnerDetails';
import { Report } from 'src/types/Report';
import { useBIKRowLayout } from './useBIKRowLayout';
import { useUserRowLayout } from './useUserRowLayout';

interface RowElementData {
  rowLayout: ReactNode;
  rowDetails?: ReactNode;
  rowActions?: ReactNode;
  isCancelled?: boolean;
  isCompleted?: boolean;
  isDraft?: boolean;
  isAttentionRequired?: boolean;
  isLoading?: boolean;
  isRowExpanded: boolean;
  loadingText: string;
  toggleExpanded: () => void;
}

type useRowTypeProps = {
  data: Report | PartnerDetails;
  type: RoleType;
  options?: {
    refetch?: () => void;
    users?: PartnerDetails[];
  };
};

export const useRowType = ({ data, type, options }: useRowTypeProps): RowElementData => {
  const userRowLayout = useUserRowLayout({ user: 'roles' in data ? data : undefined, options });
  const bikRowLayout = useBIKRowLayout('reportType' in data ? data : undefined, type, options?.refetch);

  if (userRowLayout) {
    return userRowLayout;
  }

  if (bikRowLayout) {
    return bikRowLayout;
  }

  return {
    rowLayout: null,
    isRowExpanded: false,
    loadingText: 'Wczytywanie danych...',
    toggleExpanded: () => {},
  };
};

import { useTheme } from '@mui/material/styles';
import * as d3 from 'd3';
import React, { useEffect, useRef, useState } from 'react';

type HeatmapData = {
  x: string;
  y: string;
  isStarted: boolean;
  value: number;
}[];

interface HeatmapProps {
  data: HeatmapData;
  width?: number;
  height?: number;
  selectedYear?: string;
}

const Heatmap: React.FC<HeatmapProps> = ({ data, width = 700, height = 500, selectedYear }) => {
  const theme = useTheme();
  const svgRef = useRef<SVGSVGElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState(width);

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  useEffect(() => {
    if (!svgRef.current || data.length === 0) return;

    const margin = { top: 50, right: 100, bottom: 50, left: 100 };
    const svgWidth = containerWidth - margin.left - margin.right;
    const svgHeight = height - margin.top - margin.bottom;

    const filteredData = selectedYear ? data.filter((d) => d.x === selectedYear) : data;

    const xLabels = [...new Set(filteredData.map((d) => d.x))];
    const yLabels = [...new Set(filteredData.map((d) => d.y))];

    const xScale = d3.scaleBand().domain(xLabels).range([0, svgWidth]).padding(0.05);
    const yScale = d3.scaleBand().domain(yLabels).range([0, svgHeight]).padding(0.05);

    const isDarkMode = theme.palette.mode === 'dark';

    const colorScale = (value: number) => {
      if (value === -1) {
        return '#fff0';
      }

      if (isDarkMode) {
        if (value === 0) return '#222222'; // Dark Grey
        if (value >= 1 && value <= 2) return '#993333'; // Darker Light Red
        if (value >= 3 && value <= 9) return '#cc4444'; // Darker More Red
        if (value >= 10 && value <= 29) return '#aa0000'; // Darker Even Redder
        return '#660000'; // Darkest Red
      } else {
        if (value === 0) return '#ffffff'; // White
        if (value >= 1 && value <= 2) return '#ffcccc'; // Light Red
        if (value >= 3 && value <= 9) return '#ff6666'; // More Red
        if (value >= 10 && value <= 29) return '#cc0000'; // Even Redder
        return '#800000'; // The Reddest
      }
    };

    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

    const g = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`);

    // Draw heatmap cells
    g.selectAll()
      .data(filteredData)
      .enter()
      .append('rect')
      .attr('x', (d) => xScale(d.x)!)
      .attr('y', (d) => yScale(d.y)!)
      .attr('width', xScale.bandwidth())
      .attr('height', yScale.bandwidth())
      .style('fill', (d) => colorScale(d.value))
      .style('stroke', isDarkMode ? '#444' : '#fff');

    // Add labels
    g.selectAll()
      .data(filteredData)
      .enter()
      .append('text')
      .attr('x', (d) => xScale(d.x)! + xScale.bandwidth() / 2)
      .attr('y', (d) => yScale(d.y)! + yScale.bandwidth() / 2)
      .attr('text-anchor', 'middle')
      .attr('dy', '0.35em')
      .style('fill', 'white')
      .style('font-size', '13px')
      .style('font-weight', '800')
      .text((d) => (d.value === 0 || d.value === -1 ? '' : d.value));

    // X-axis
    g.append('g')
      .call(d3.axisTop(xScale))
      .selectAll('text')
      .attr('transform', 'rotate(45)')
      .style('text-anchor', 'end')
      .style('fill', isDarkMode ? '#ddd' : '#000');

    // Y-axis
    g.append('g')
      .call(d3.axisLeft(yScale))
      .selectAll('text')
      .style('fill', isDarkMode ? '#ddd' : '#000');
  }, [data, containerWidth, height, selectedYear, theme.palette.mode]);

  return (
    <div ref={containerRef}>
      <svg ref={svgRef} width={containerWidth} height={height} />
      <div
        ref={tooltipRef}
        style={{
          position: 'absolute',
          backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#fff',
          color: theme.palette.mode === 'dark' ? '#fff' : '#000',
          padding: '5px',
          borderRadius: '5px',
          boxShadow: '0px 0px 5px rgba(0,0,0,0.3)',
          opacity: 0,
          pointerEvents: 'none',
        }}
      />
    </div>
  );
};

export default Heatmap;

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Box, Card, CardContent, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';

interface ReportStatCardProps {
  title: string;
  beforeValue: number | string;
  afterValue: number | string;
  percentageChange: number;
  decreaseText: string;
  icon: React.ReactNode;
}

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 16,
  boxShadow: theme.palette.mode === 'dark' ? '0px 2px 8px rgba(0, 0, 0, 0.5)' : '0px 2px 8px rgba(0, 0, 0, 0.1)',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.background.default,
  overflow: 'visible',
}));

const PercentageBadge = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.error.dark : '#FFE8E8',
  borderRadius: 16,
  padding: '2px 8px',
  display: 'inline-flex',
  alignItems: 'center',
  gap: 4,
}));

export const ReportStatCard: React.FC<ReportStatCardProps> = ({
  title,
  beforeValue,
  afterValue,
  percentageChange,
  decreaseText,
  icon,
}) => {
  const theme = useTheme();

  return (
    <StyledCard>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <Typography variant="h6" sx={{ fontSize: 16, fontWeight: 600 }}>
            {title}
          </Typography>
          {icon}
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
          <Box>
            <Typography variant="body2" color="text.secondary">
              Przed
            </Typography>
            <Typography variant="h5" fontWeight="bold">
              {beforeValue}
            </Typography>
          </Box>

          <PercentageBadge>
            <ArrowDownwardIcon sx={{ color: theme.palette.error.main }} fontSize="small" />
            <Typography variant="body2" color="error.main">
              {percentageChange}%
            </Typography>
          </PercentageBadge>

          <Box textAlign="right">
            <Typography variant="body2" color="text.secondary">
              Po
            </Typography>
            <Typography variant="h5" fontWeight="bold" color="success.main">
              {afterValue}
            </Typography>
          </Box>
        </Box>

        <Typography variant="body2" mt={2} textAlign="end">
          {decreaseText}
        </Typography>
      </CardContent>
    </StyledCard>
  );
};

import { useCallback, useMemo, useState } from 'react';
import { useBIKStatuses } from 'src/api/bikApi';
import { RoleType } from 'src/common/roles';
import BIKDetails from 'src/components/BIKDetails/BIKDetails';
import { Report } from 'src/types/Report';
import { BIKDetailsActions } from '../BIKLayout/BIKDetailsActions';
import { BIKLayout } from '../BIKLayout/BIKLayout';

export const useBIKRowLayout = (bikReport: Report | undefined, type: RoleType, refetch?: () => void) => {
  const [isRowExpanded, setIsRowExpanded] = useState(false);

  const {
    data: bikStatuses,
    isLoading: isLoading,
    refetch: refetchStatuses,
  } = useBIKStatuses(bikReport?.id || '', isRowExpanded);

  const toggleExpanded = useCallback(() => {
    setIsRowExpanded(!isRowExpanded);
  }, [isRowExpanded]);

  const isCancelled =
    bikReport?.status === 'DOCUMENTS_UPLOADED_PARSING_FAILED' || bikReport?.status === 'DOCUMENTS_UPLOAD_FAILED';
  const isAttentionRequired = bikReport?.status === 'SUMMARY_INFORMATION_SENT';
  const isDraft = bikReport?.status === 'REPORT_CREATED';
  const isCompleted = bikReport?.status === 'DOCUMENTS_SENT';

  const refetchData = useCallback(() => {
    refetchStatuses();
    if (refetch) {
      refetch();
    }
  }, [refetch, refetchStatuses]);

  const BIKRowLayout = useMemo(() => bikReport && <BIKLayout bikReport={bikReport} />, [bikReport]);
  const BIKRowDetails = useMemo(
    () =>
      bikStatuses &&
      bikReport && <BIKDetails type={type} bikReport={bikReport} bikStatuses={bikStatuses} refetchData={refetchData} />,
    [bikReport, bikStatuses, refetchData, type]
  );
  const BIKActionButtons = useMemo(
    () => bikReport && <BIKDetailsActions type={type} bikReport={bikReport} refetchData={refetchData} />,
    [bikReport, refetchData, type]
  );

  if (!bikReport) {
    return null;
  }

  const loadingText = 'Wczytywanie analizy raportu BIK...';

  return {
    rowLayout: BIKRowLayout,
    rowDetails: BIKRowDetails,
    rowActions: BIKActionButtons,
    isCancelled,
    isCompleted,
    isDraft,
    isAttentionRequired,
    isLoading,
    isRowExpanded,
    loadingText,
    toggleExpanded,
  };
};

import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelectedService } from 'src/context/useSelectedService';
import { BIKSummaryItem, BIKSummaryRequest } from 'src/types/BIKSummary';
import { BIKItem, BIKRequest } from 'src/types/CompanyBIKSummary.type';
import { StructuredService, StructuredType } from 'src/types/StructuredBIK';
import ProductCard from '../ProductCard';
import { TranslateByServiceType } from '../translations';
import BIKPositions from './BIKPositions';

export const BIKCard = ({
  services,
  bikId,
  type,
  isDisabled,
  isClientView,
  preselectedType,
  previouslySelectedItems,
  previouslySelectedRequests,
}: {
  bikId: string;
  services: StructuredService[];
  isDisabled: boolean;
  isClientView?: boolean;
  preselectedType?: string;
  type: StructuredType;
  previouslySelectedItems: BIKSummaryItem[];
  previouslySelectedRequests: BIKSummaryRequest[];
}) => {
  const { setSelectedService } = useSelectedService();

  const [selectedCheckboxId, setSelectedCheckboxId] = useState<string>('');

  const clearGlobalCheckbox = () => {
    setSelectedCheckboxId('');
  };

  useEffect(() => {
    if (!preselectedType || preselectedType === 'CUSTOM') {
      return;
    }

    const service = services.find((service) => service.type === preselectedType);

    if (!service) {
      return;
    }

    const items = service.items as (BIKItem | BIKRequest)[];

    if (setSelectedService) {
      setSelectedService({
        left: 0,
        installment: 0,
        type,
        discountPartnerCommission: service.maximumPartnerDiscountedCommission || 0,
        partnerCommission: service.maximumPartnerCommission,
        discountPrice: service.maximumDiscountPrice || 0,
        price: service.maximumPrice,
        items: items,
        subType: preselectedType,
      });
    }
    setSelectedCheckboxId(preselectedType);
  }, [preselectedType, services]);

  return (
    <Box>
      {services.map((service, index) => {
        const checkboxId = service.type;
        const isSelected = preselectedType === checkboxId;

        return (
          <Box key={index} mt={2}>
            <ProductCard
              isDisabled={isDisabled}
              data={service}
              type={type}
              isClientView={isClientView}
              title={TranslateByServiceType[service.type]}
              isCheckboxHidden={service.type === 'CUSTOM'}
              customCheckbox={{
                selectedCheckboxId,
                setSelectedCheckboxId,
                checkboxId,
              }}
              expandCard={
                <BIKPositions
                  bikId={bikId}
                  isDisabled={isDisabled}
                  isClientView={isClientView}
                  items={service.items as (BIKItem | BIKRequest)[]}
                  savedItems={isSelected ? previouslySelectedItems : []}
                  savedRequests={isSelected ? previouslySelectedRequests : []}
                  type={type}
                  isSelectable={service.type === 'CUSTOM'}
                  isGlobalCheckboxSelected={!!selectedCheckboxId}
                  clearGlobalCheckbox={clearGlobalCheckbox}
                />
              }
            />
          </Box>
        );
      })}
    </Box>
  );
};

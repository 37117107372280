import { StructuredType } from 'src/types/StructuredBIK';

export const ProductTypeTranslations: Record<StructuredType, string> = {
  SKD: 'Możliwa Sankcja Kredytu Darmowego',
  VIBOR: 'Możliwe odwioborowanie',
  COMPANY: 'Raport firmowy - Optymalizacja BIK ',
  CUSTOMER: 'Raport prywatny - Optymalizacja BIK',
  COMMISSION: 'Możliwe prowizje do odzyskania',
};

export const TranslateByServiceType: Record<string, string> = {
  BANK: 'Pakiet optymalizacji bankowej',
  NON_BANK: 'Pakiet optymalizacji pozabankowej',
  LEASING: 'Pakiet optymalizacji leasingowej',
  FACTORING: 'Pakiet optymalizacji faktoringowej',
  REQUEST: 'Pakiet optymalizacji zapytań do BIK',
  CUSTOM: 'Personalizowany wybór usług',
  COMMISSION: 'COMMISSION',
  SKD: 'SKD',
  VIBOR: 'VIBOR',
};

import { Box, Pagination, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useState } from 'react';
import { useCommissionsList } from 'src/api/bikApi';
import DashboardContainer from 'src/common/DashboardContainer';
import { RoleType } from 'src/common/roles';
import BIKCommissionItem from 'src/components/BIKDetails/BIKCommissionItem';
import { useCommissionSearch } from 'src/pages/searches/useCommissionSearch';
import { UserDetails } from 'src/pages/user-details/UserDetails';

const ROWS_PER_PAGE = 10;

type CommissionDashboardProps = {
  type: RoleType;
};

const CommissionDashboard: React.FC<CommissionDashboardProps> = ({ type }) => {
  const [page, setPage] = useState(0);

  const { searchText, setSearchText, SearchField, StatusSelect, selectedStatus } = useCommissionSearch(setPage);
  const { data, isLoading, isError, refetch } = useCommissionsList(
    page + 1,
    ROWS_PER_PAGE,
    `${selectedStatus},${searchText}`,
    type
  );

  const reports = data?.content || [];
  const totalReports = data?.totalElements || 0;

  return (
    <DashboardContainer title="Moje zlecenia">
      <UserDetails type={type} />
      <Grid container spacing={2} sx={{ width: '100%' }}>
        <Grid size={{ xs: 12, sm: 8 }}>{SearchField}</Grid>
        <Grid size={{ xs: 12, sm: 4 }}>{StatusSelect}</Grid>
      </Grid>

      <Box sx={{ m: 2, display: 'flex', justifyContent: 'center' }}>
        <Pagination
          count={Math.ceil(totalReports / ROWS_PER_PAGE)}
          page={page + 1} // Display 1-based index for pagination
          onChange={(_, value) => setPage(value - 1)}
          color="primary"
        />
      </Box>
      {isLoading ? (
        <Typography>Wczytywanie...</Typography>
      ) : isError ? (
        <Typography color="error">Error fetching reports.</Typography>
      ) : (
        <Box width="100%">
          {reports.length === 0 && <Typography>Brak raportów dla podanych parametrów</Typography>}
          {reports.map((commissionItem) => (
            <BIKCommissionItem
              key={commissionItem.id}
              commission={commissionItem}
              refetch={refetch}
              setSearchText={setSearchText}
            />
          ))}
        </Box>
      )}
      <Box sx={{ m: 2, display: 'flex', justifyContent: 'center' }}>
        <Pagination
          count={Math.ceil(totalReports / ROWS_PER_PAGE)}
          page={page + 1} // Display 1-based index for pagination
          onChange={(_, value) => setPage(value - 1)}
          color="primary"
        />
      </Box>
    </DashboardContainer>
  );
};

export default CommissionDashboard;

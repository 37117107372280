export type ReportStatus =
  | 'REPORT_CREATED'
  | 'DOCUMENTS_UPLOADED'
  | 'DOCUMENTS_UPLOAD_FAILED'
  | 'DOCUMENTS_UPLOADED_PARSING_FAILED'
  | 'ITEMS_SELECTED_FOR_REMOVAL'
  | 'USER_DATA_ENTERED'
  | 'PAYMENT_PROCESS_INITIATED'
  | 'PAYMENT_PENDING'
  | 'PAYMENT_COMPLETED'
  | 'PAYMENT_FAILED'
  | 'SUMMARY_INFORMATION_SENT'
  | 'DOCUMENTS_TO_BE_SENT'
  | 'DOCUMENTS_SENT';

export const ReportStatusDetails: Record<ReportStatus, { key: keyof ReportTimeline; translation: string }> = {
  REPORT_CREATED: { key: 'reportCreatedAt', translation: 'Raport utworzony' },
  DOCUMENTS_UPLOADED: { key: 'documentsUploadedAt', translation: 'BIK raport dodany' },
  DOCUMENTS_UPLOAD_FAILED: { key: 'documentsUploadFailedAt', translation: 'Niepowodzenie przesyłania dokumentów' },
  DOCUMENTS_UPLOADED_PARSING_FAILED: {
    key: 'documentsUploadedParsingFailedAt',
    translation: 'Błąd parsowania przesłanych dokumentów',
  },
  ITEMS_SELECTED_FOR_REMOVAL: { key: 'itemsSelectedForRemovalAt', translation: 'Elementy wybrane do usunięcia' },
  USER_DATA_ENTERED: { key: 'userDataEnteredAt', translation: 'Dane użytkownika wprowadzone' },
  PAYMENT_PROCESS_INITIATED: { key: 'paymentProcessInitiatedAt', translation: 'Proces płatności zainicjowany' },
  PAYMENT_PENDING: { key: 'paymentPendingAt', translation: 'Płatność oczekująca' },
  PAYMENT_COMPLETED: { key: 'paymentCompletedAt', translation: 'Płatność zakończona' },
  PAYMENT_FAILED: { key: 'paymentFailedAt', translation: 'Płatność nieudana' },
  SUMMARY_INFORMATION_SENT: { key: 'summaryInformationSentAt', translation: 'Podsumowanie wysłane' },
  DOCUMENTS_TO_BE_SENT: { key: 'documentsToBeSentAt', translation: 'Oczekuje na wysłanie dokumentów' },
  DOCUMENTS_SENT: { key: 'documentsSentAt', translation: 'Dokumenty wysłane' },
};

export interface ReportTimeline {
  reportCreatedAt?: string;
  documentsUploadedAt?: string;
  documentsUploadFailedAt?: string;
  documentsUploadedParsingFailedAt?: string;
  itemsSelectedForRemovalAt?: string;
  userDataEnteredAt?: string;
  paymentProcessInitiatedAt?: string;
  paymentPendingAt?: string;
  paymentCompletedAt?: string;
  paymentFailedAt?: string;
  summaryInformationSentAt?: string;
  documentsToBeSentAt?: string;
  documentsSentAt?: string;
}

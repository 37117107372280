import { ReactNode, useCallback, useMemo, useState } from 'react';
import { BIKReportCounterByType, BIKReportType } from 'src/types/BIKReport.type';
import { BIKSummary } from 'src/types/BIKSummary';
import { CommissionEntry } from 'src/types/CommissionResponse';
import { RowData } from 'src/types/RowData';
import { SKDEntry } from 'src/types/SDKResponse';
import { SelectedServiceType } from 'src/types/SelectableServiceType';
import { SelectedRow } from 'src/types/SelectedRow';
import { StructuredType } from 'src/types/StructuredBIK';
import { UnwiborEntry } from 'src/types/UnwiborReponse';
import { SelectedServiceContext } from './useSelectedService';

// Provider component
export const SelectedServiceProvider = ({ children }: { children: ReactNode }) => {
  const [globalSelectedService, setGlobalSelectedService] = useState<
    Record<StructuredType, SelectedServiceType | null>
  >({
    COMPANY: null,
    CUSTOMER: null,
    SKD: null,
    VIBOR: null,
    COMMISSION: null,
  });
  const [selectedBIKServices, setSelectedBIKServices] = useState<RowData>({
    COMPANY: { items: [], requests: [], totalNumber: 0 },
    CUSTOMER: { items: [], requests: [], totalNumber: 0 },
  });
  const [totalBIKPrice, setTotalBIKPrice] = useState<BIKReportCounterByType>({ COMPANY: 0, CUSTOMER: 0 });
  const [totalBIKDiscountPrice, setTotalBIKDiscountPrice] = useState<BIKReportCounterByType>({
    COMPANY: 0,
    CUSTOMER: 0,
  });

  const selectedBIKRowsCount = useMemo(
    () => selectedBIKServices.COMPANY.totalNumber + selectedBIKServices.CUSTOMER.totalNumber,
    [selectedBIKServices]
  );

  const onSelectedBIKRows = useCallback(
    (itemsIndex: SelectedRow[], requestsIndex: SelectedRow[], type: BIKReportType) => {
      const totalPriceForType =
        itemsIndex.reduce((prev, curr) => prev + curr.price, 0) +
        requestsIndex.reduce((prev, curr) => prev + curr.price, 0);
      const totalDicountPriceForType =
        itemsIndex.reduce((prev, curr) => prev + curr.discountPrice, 0) +
        requestsIndex.reduce((prev, curr) => prev + curr.discountPrice, 0);

      setTotalBIKPrice((prevPrice) => ({
        ...prevPrice,
        [type]: totalPriceForType,
      }));
      setTotalBIKDiscountPrice((prevPrice) => ({
        ...prevPrice,
        [type]: totalDicountPriceForType,
      }));

      setSelectedBIKServices((prevRows) => ({
        ...prevRows,
        [type]: {
          items: itemsIndex,
          requests: requestsIndex,
          totalNumber: itemsIndex.length + requestsIndex.length,
        },
      }));
    },
    []
  );

  const setSelectedService = (service: SelectedServiceType) => {
    setGlobalSelectedService((prev) => {
      return {
        ...prev,
        [service.type]: service,
      };
    });
  };

  const getDataForComplateBIKReport = useCallback(
    (summaryData: BIKSummary) => {
      const items = [...selectedBIKServices.COMPANY.items, ...selectedBIKServices.CUSTOMER.items];
      const requests = [...selectedBIKServices.COMPANY.requests, ...selectedBIKServices.CUSTOMER.requests];

      const itemsToSend: Omit<SelectedRow, 'name'>[] = items.map((item) => ({
        id: item.id,
        creditorId: item.creditorId,
        bikReportType: item.bikReportType,
        longestDebt: item.longestDebt,
        longestDebtAt: item.longestDebtAt,
        type: item.type,
        price: item.price,
        discountPrice: item.discountPrice,
        signedOn: item.signedOn,
        finishedAt: item?.finishedAt,
        financingOption: item.financingOption,
      }));

      const requestsToSend: Omit<SelectedRow, 'name'>[] = requests.map((request) => ({
        id: request.id,
        creditorId: request.creditorId,
        bikReportType: request.bikReportType,
        price: request.price,
        discountPrice: request.discountPrice,
        signedOn: request.signedOn,
        financingOption: request.financingOption,
      }));

      const skdItemsIds = globalSelectedService['SKD']
        ? (globalSelectedService['SKD'].items as SKDEntry[]).map((item) => item.id)
        : [];
      const wiborItemsIds = globalSelectedService['VIBOR']
        ? (globalSelectedService['VIBOR'].items as UnwiborEntry[]).map((item) => item.id)
        : [];
      const commissionItemsIds = globalSelectedService['COMMISSION']
        ? (globalSelectedService['COMMISSION'].items as CommissionEntry[]).map((item) => item.id)
        : [];

      const isSKDReloadNotRequired =
        skdItemsIds.length === summaryData?.skd?.length &&
        skdItemsIds.every((id) => summaryData?.skd?.find((item) => item.id === id));
      const isWIBORReloadNotRequired =
        wiborItemsIds.length === summaryData?.unwibor?.length &&
        wiborItemsIds.every((id) => summaryData?.unwibor?.find((item) => item.id === id));
      const isCOMMISIONReloadNotRequired =
        commissionItemsIds.length === summaryData?.commission?.length &&
        commissionItemsIds.every((id) => summaryData?.commission?.find((item) => item.id === id));
      const isBIKItemsReloadNotRequired =
        items.length === summaryData?.items?.length &&
        items.every((item) => summaryData?.items?.find((summaryItem) => summaryItem.id === item.id));
      const isBIKRequestsReloadNotRequired =
        requests.length === summaryData?.requests?.length &&
        requests.every((request) => summaryData?.requests?.find((summaryRequest) => summaryRequest.id === request.id));

      const isReloadRequired =
        !isSKDReloadNotRequired ||
        !isWIBORReloadNotRequired ||
        !isCOMMISIONReloadNotRequired ||
        !isBIKItemsReloadNotRequired ||
        !isBIKRequestsReloadNotRequired;

      const isBankAccountNumberMissing = !!commissionItemsIds.length && !summaryData?.customer?.bankAccountNumber;
      const isCompanyDataMissing = !!selectedBIKServices.COMPANY.totalNumber && !summaryData.company?.companyName;
      const isCustomerDataMissing =
        (!!selectedBIKServices.CUSTOMER.totalNumber || !!skdItemsIds.length || !!wiborItemsIds.length) &&
        !summaryData.customer?.fullName;

      const areDetailsMissing = isBankAccountNumberMissing || isCompanyDataMissing || isCustomerDataMissing;
      return {
        itemsToSend,
        requestsToSend,
        isReloadRequired,
        areDetailsMissing,
        skdItemsIds,
        wiborItemsIds,
        commissionItemsIds,
      };
    },
    [selectedBIKServices, globalSelectedService]
  );

  return (
    <SelectedServiceContext.Provider
      value={{
        selectedBIKServices,
        onSelectedBIKRows,
        selectedBIKRowsCount,
        totalBIKPrice,
        totalBIKDiscountPrice,
        selectedService: globalSelectedService,
        getDataForComplateBIKReport,
        setSelectedService,
        setGlobalSelectedService,
      }}
    >
      {children}
    </SelectedServiceContext.Provider>
  );
};

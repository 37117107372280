import { Alert, Box, Button, Snackbar } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { complateBIKReport, useBIKSummary } from 'src/api/bikApi';
import { useSnackbar } from 'src/common/useSnackbar';
import { useSelectedService } from 'src/context/useSelectedService';
interface ShowBIKReportButtonProps {
  reportId: string;
  onShowCalculations: () => void;
  label?: string;
}

export const ShowBIKReportButton: React.FC<ShowBIKReportButtonProps> = ({
  reportId,
  onShowCalculations,
  label = 'Wygeneruj raport poprawy sytuacji kredytowej',
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { data: summaryData } = useBIKSummary(reportId);

  const [showErrorToast, setShowErrorToast] = useState(false);

  const { getDataForComplateBIKReport, totalBIKPrice, totalBIKDiscountPrice } = useSelectedService();

  const handleShowReport = useCallback(async () => {
    if (!summaryData) {
      showSnackbar('Nie udało się pobrać danych raportu. Spróbuj ponownie później.', 'error');
      return;
    }

    setIsLoading(true);

    const { itemsToSend, requestsToSend, isReloadRequired, skdItemsIds, wiborItemsIds, commissionItemsIds } =
      getDataForComplateBIKReport(summaryData);

    try {
      if (isReloadRequired) {
        await complateBIKReport(reportId, {
          selectedCredits: itemsToSend,
          selectedRequests: requestsToSend,
          selectedSKDIds: skdItemsIds,
          selectedUNVIBORIds: wiborItemsIds,
          selectedCOMMISSIONIds: commissionItemsIds,
          price: Number((totalBIKPrice.COMPANY + totalBIKPrice.CUSTOMER).toFixed(2)),
          discountPrice: Number((totalBIKDiscountPrice.COMPANY + totalBIKDiscountPrice.CUSTOMER).toFixed(2)),
        });
      }

      navigate(`/process-bik/${reportId}/sanitazed-summary`);
    } catch (error) {
      showSnackbar('Nie udało się pobrać danych raportu. Spróbuj ponownie później.', 'error');
    } finally {
      setIsLoading(false);
    }
  }, [summaryData, navigate, getDataForComplateBIKReport]);

  const handleCloseToast = () => {
    setShowErrorToast(false);
  };

  const isInstallmentDisabled = import.meta.env.VITE_ENV === 'PROD';

  return (
    <>
      <Box mb={2} display="flex" justifyContent="end" alignItems="center" width="100%" gap={2}>
        <Button size="small" variant="outlined" onClick={handleShowReport} disabled={isLoading} color="primary">
          {isLoading ? 'Ładowanie...' : label}
        </Button>
        {!isInstallmentDisabled && (
          <Button size="small" variant="outlined" onClick={onShowCalculations} color="primary">
            Pokaż kalkulator - Raty do zdolności
          </Button>
        )}
      </Box>

      <Snackbar
        open={showErrorToast}
        autoHideDuration={6000}
        onClose={handleCloseToast}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseToast} severity="error">
          Nie udało się pobrać danych raportu. Spróbuj ponownie później.
        </Alert>
      </Snackbar>
    </>
  );
};

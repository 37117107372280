import { Box, LinearProgress, styled } from '@mui/material';

const StyledProgress = styled(LinearProgress)(() => ({
  height: 2,
  backgroundColor: 'rgba(229, 231, 235, 0.5)',
  '.MuiLinearProgress-bar': {
    background: 'linear-gradient(135deg, #3b82f6 0%, #2563eb 100%)',
  },
}));

interface StepperProgressProps {
  activeStep: number;
  totalSteps: number;
}

export function StepperProgress({ activeStep, totalSteps }: StepperProgressProps) {
  const progress = (activeStep / (totalSteps - 1)) * 100;

  return (
    <Box sx={{ width: '100%', mt: { xs: 2, sm: 3 } }}>
      <StyledProgress variant="determinate" value={progress} />
    </Box>
  );
}

import { FunctionComponent } from 'react';
import ObserveSize from 'react-observe-size';
import { ReportStatus, ReportStatusDetails, ReportTimeline } from 'src/common/reports';
import { TimelineStatus } from 'src/types/TimelineStatus';
import { TimelineSteps } from './TimelineSteps/TimelineSteps';

interface TimelieProps {
  timeline: TimelineStatus[];
  bikStatus: ReportStatus;
}

const convertTimelineToReportTimeline = (timeline: TimelineStatus[]): ReportTimeline => {
  const reportTimeline: ReportTimeline = {};

  timeline.forEach(({ createdAt, status }) => {
    reportTimeline[ReportStatusDetails[status].key] = createdAt;
  });

  return reportTimeline;
};

export const Timeline: FunctionComponent<TimelieProps> = ({ timeline, bikStatus }) => {
  const mappedTimeline = convertTimelineToReportTimeline(timeline);
  return (
    <ObserveSize>
      {({ width }) => <TimelineSteps timeline={mappedTimeline} bikStatus={bikStatus} width={width} />}
    </ObserveSize>
  );
};

import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface TextEditProps {
  field: string;
  placeholder: string;
  disabled?: boolean;
  reportId: string;
  onTextChange: (id: string, value: string) => void;
}

let editTimeout: NodeJS.Timeout;

const TextEdit: React.FC<TextEditProps> = ({ field, reportId, placeholder, disabled, onTextChange }) => {
  const [value, setValue] = useState(field);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedValue = e.target.value;
    setValue(updatedValue);

    if (editTimeout) {
      clearTimeout(editTimeout);
    }

    editTimeout = setTimeout(() => {
      onTextChange(reportId, updatedValue);
    }, 2000);
  };

  useEffect(() => {
    setValue(field); // Sync with parent changes
  }, [field]);

  return (
    <TextField
      disabled={disabled}
      sx={{ minWidth: 100 }}
      placeholder={placeholder}
      variant="outlined"
      fullWidth
      value={value}
      onChange={handleChange}
    />
  );
};

export default TextEdit;

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import HomeIcon from '@mui/icons-material/Home';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Box, Card, CardContent, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';

const EffectItem = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  marginBottom: 16,
}));

interface EffectsCardProps {
  title: string;
}

export const EffectsCard: React.FC<EffectsCardProps> = ({ title }) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 4,
        backgroundColor: isDark ? theme.palette.primary.dark : theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        boxShadow: isDark ? '0px 2px 8px rgba(0,0,0,0.4)' : '0px 2px 8px rgba(0,0,0,0.1)',
      }}
    >
      <CardContent>
        <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
          {title}
        </Typography>

        <EffectItem>
          <TrendingUpIcon />
          <Typography variant="body1">Wyższa zdolność kredytowa</Typography>
        </EffectItem>

        <EffectItem>
          <HomeIcon />
          <Typography variant="body1">Lepsze warunki kredytu hipotecznego</Typography>
        </EffectItem>

        <EffectItem>
          <AccountBalanceIcon />
          <Typography variant="body1">Możliwość uzyskania kredytu na lepszych zasadach</Typography>
        </EffectItem>

        <EffectItem>
          <CreditCardIcon />
          <Typography variant="body1">Niższe oprocentowanie i lepsze oferty finansowe</Typography>
        </EffectItem>
      </CardContent>
    </Card>
  );
};

import { DarkMode, LightMode, SecurityRounded } from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';
import React from 'react';

export const ThemeIcon: React.FC<{ isDark: boolean } & SvgIconProps> = ({ isDark, ...props }) =>
  isDark ? <LightMode {...props} /> : <DarkMode {...props} />;

export const SecurityIcon: React.FC<SvgIconProps & { active?: boolean }> = ({ active, ...props }) => (
  <SecurityRounded
    {...props}
    sx={{
      color: active ? '#4CAF50' : '#757575',
      ...props.sx,
    }}
  />
);

import { InfoOutlined } from '@mui/icons-material';
import { Box, Checkbox, FormControlLabel, Paper, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SecurityIcon } from './icons';

interface ConsentCardProps {
  title: string;
  content: string;
  contentTwo: string;
  contentThree?: string;
  checked: boolean;
  forcedChecked?: boolean;
  onChange: (checked: boolean) => void;
  provider: string;
}

export const ConsentCard: React.FC<ConsentCardProps> = ({
  title,
  content,
  contentThree,
  contentTwo,
  checked,
  forcedChecked,
  onChange,
  provider,
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    if (forcedChecked) {
      onChange(true);
    }

    setIsChecked(forcedChecked || checked);
  }, [forcedChecked, checked]);

  return (
    <Paper
      elevation={3}
      sx={{
        p: 3,
        mb: 3,
        transition: 'transform 0.2s, box-shadow 0.2s',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: 6,
        },
        border: (theme) => (isChecked ? `2px solid ${theme.palette.primary.main}` : '2px solid transparent'),
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <SecurityIcon active={isChecked} sx={{ mr: 1 }} />
        <Typography variant="h6" component="h2" sx={{ flex: 1 }}>
          {title}
        </Typography>
        <Tooltip title="Ta zgoda jest wymagana do przetwarzania danych">
          <InfoOutlined sx={{ color: 'text.secondary', cursor: 'help' }} />
        </Tooltip>
      </Box>

      <Typography variant="body2" color="text.secondary" sx={{ mb: 2, whiteSpace: 'pre-line' }}>
        {content}
        <br />
        {contentTwo}
        {contentThree && (
          <>
            <br />
            {contentThree}
          </>
        )}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: 2,
          pt: 2,
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Typography variant="caption" color="text.secondary">
          Dostawca: {provider}
        </Typography>
        <FormControlLabel
          control={<Checkbox checked={isChecked} onChange={(e) => onChange(e.target.checked)} color="primary" />}
          label={
            <Typography variant="body2" color="primary">
              Zgadzam się na te warunki
            </Typography>
          }
        />
      </Box>
    </Paper>
  );
};

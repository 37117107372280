import { QueryClient } from '@tanstack/react-query';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { isUserLogged, logoutUser } from '../utils';

export type ToolbarContent = {
  id: string;
  text: string;
  url?: string;
  dataTestId?: string;
  type?: 'BUTTON';
  customNavigation?: (navigate: ReturnType<typeof useNavigate>) => void;
};
export type ToolbarType = 'main' | 'back';

// const getReportUrl = (pathname: string) => {
//   const isLogged = isUserLogged();
//   const newUrl = isLogged ? 'partner-process' : 'client-process';

//   const params = pathname.split('/');
//   const isProcess = params[params.length - 1] === 'process';
//   params[params.length - 1] = isProcess ? newUrl : 'process';
//   return params.join('/');
// };

const queryClient = new QueryClient();

export const getToolbarContent = (): Record<ToolbarType, ToolbarContent[]> => {
  const backNavigation = [
    { id: 'back', url: '/', text: 'Powrót do strony głównej', customNavigation: () => (window.location.href = '/') },
    // location.pathname.endsWith('process') && {
    //   id: 'back',
    //   text: 'Zmień typ widoku',
    //   dataTestId: 'change-view',
    //   url: getReportUrl(location.pathname),
    //   customNavigation: () => (window.location.href = getReportUrl(location.pathname)),
    // },
    isUserLogged() && {
      id: 'partner-program',
      text: 'Master Panel',
      url: '/check-default-page',
      customNavigation: (navigate: NavigateFunction) => navigate('/check-default-page'),
    },
    isUserLogged() && {
      id: 'logout',
      text: 'Wyloguj',
      url: '/logout',
      customNavigation: (navigate: NavigateFunction) => {
        logoutUser();
        queryClient.removeQueries();

        navigate('/sign-in?message=Zostałeś pomyślnie wylogowany.');
      },
    },
  ].filter(Boolean) as ToolbarContent[];

  const mainNavigation: ToolbarContent[] = [
    { id: 'how-it-works', text: 'Jak to działa?' },
    { id: 'benefits', text: 'Korzyści' },
    { id: 'security', text: 'Bezpieczeństwo' },
    { id: 'faq', text: 'FAQ' },
    {
      id: 'partner-program',
      url: '/check-default-page',
      text: 'Master Panel',
      type: 'BUTTON',
      customNavigation: (navigate: NavigateFunction) => navigate('/check-default-page'),
    },
  ].filter(Boolean) as ToolbarContent[];

  return {
    main: mainNavigation,
    back: backNavigation,
  };
};

import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import { Alert, Box, Button, CircularProgress, DialogContent, DialogTitle, Tab, Tabs, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBIKReports } from 'src/api/bikApi';
import { isBIKEnabledForLoggedUser } from 'src/common/roles';
import { InstitutionDetailsCard } from 'src/pages/PartnerReportPreview/InstitutionDetailsCard';
import { BIKReportType } from 'src/types/BIKReport.type';
import { SanitazedReport } from 'src/types/SanitazedReport';
import { StructuredBIKDetails } from 'src/types/StructuredBIK';
import { SanitazedBIKReport } from './SanitazedBIKReport';

interface SanitazedBIKReportModalProps {
  reportId: string;
  reportData?: SanitazedReport;
  loading: boolean;
  error: string | null;
}

export const SanitazedBIKReportModal: React.FC<SanitazedBIKReportModalProps> = ({
  reportId,
  reportData,
  loading,
  error,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState<BIKReportType>('COMPANY');
  const { data, isLoading } = useBIKReports(reportId, true, isBIKEnabledForLoggedUser());

  useEffect(() => {
    if (reportData) {
      if (reportData.company) {
        setActiveTab('COMPANY');
      } else if (reportData.customer) {
        setActiveTab('CUSTOMER');
      }
    }
  }, [reportData]);

  const handleOnClose = () => {
    navigate(`/process-bik/${reportId}/process?defaultTab=BIK`);
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: BIKReportType) => {
    setActiveTab(newValue);
  };

  const isCompanyAvailable = Boolean(reportData?.company);
  const isCustomerAvailable = Boolean(reportData?.customer);

  const customerBIKDetails: undefined | StructuredBIKDetails = useMemo(() => {
    if (!data?.customer?.pesel || !data?.customer?.name) {
      return undefined;
    }

    return {
      name: data.customer.name,
      identifier: data.customer.pesel,
      genaratedAt: data.customer.generatedAt,
      scoring: data.customer.scoring || 0,
    };
  }, [data?.customer]);

  const companyBIKDetails: undefined | StructuredBIKDetails = useMemo(() => {
    if (!data?.company?.nip || !data?.company?.name) {
      return undefined;
    }

    return {
      name: data.company.name,
      identifier: data.company.nip,
      genaratedAt: data.company.generatedAt,
      scoring: 0,
    };
  }, [data?.company]);

  return (
    <Box>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          pb: 1,
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Button onClick={handleOnClose} variant="outlined">
          Powrót do raportu Optymalizacji BIK
        </Button>
      </DialogTitle>

      {loading || isLoading ? (
        <DialogContent>
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
            <CircularProgress />
          </Box>
        </DialogContent>
      ) : error ? (
        <DialogContent>
          <Alert severity="error" sx={{ my: 2 }}>
            {error}
          </Alert>
        </DialogContent>
      ) : reportData ? (
        <>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{
              px: 2,
              backgroundColor: theme.palette.background.default,
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Tab
              value={'COMPANY'}
              label="Firma"
              icon={<BusinessIcon />}
              iconPosition="start"
              disabled={!isCompanyAvailable}
            />
            <Tab
              value={'CUSTOMER'}
              label="Klient indywidualny"
              icon={<PersonIcon />}
              iconPosition="start"
              disabled={!isCustomerAvailable}
            />
          </Tabs>

          <DialogContent>
            {!isCompanyAvailable && !isCustomerAvailable ? (
              <Alert severity="info">Brak dostępnych danych dla tego raportu.</Alert>
            ) : (
              <>
                {activeTab === 'COMPANY' && isCompanyAvailable && (
                  <SanitazedBIKReport
                    reportData={reportData.company!}
                    reportName={`Optymalizacja_BIK_firma_${data?.company?.name}`}
                    detailsContainer={
                      <InstitutionDetailsCard details={companyBIKDetails} type="COMPANY" isVulnerableDataHidden />
                    }
                  />
                )}

                {activeTab === 'CUSTOMER' && isCustomerAvailable && (
                  <SanitazedBIKReport
                    reportData={reportData.customer!}
                    reportName={`Optymalizacja_BIK_klient_${data?.customer?.name}`}
                    detailsContainer={
                      <InstitutionDetailsCard details={customerBIKDetails} type="CUSTOMER" isVulnerableDataHidden />
                    }
                  />
                )}
              </>
            )}
          </DialogContent>
        </>
      ) : (
        <DialogContent>
          <Alert severity="info">Nie znaleziono danych. Spróbuj ponownie później.</Alert>
        </DialogContent>
      )}
    </Box>
  );
};

import { StepLabel, StepLabelProps, styled } from '@mui/material';
import { StepIcon } from './StepIcon';

const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  '.MuiStepLabel-label': {
    marginTop: theme.spacing(1),
    fontSize: '0.875rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
    '&.Mui-active': {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    '&.Mui-completed': {
      color: theme.palette.success.main,
      fontWeight: 500,
    },
  },
}));

interface CustomStepLabelProps extends Omit<StepLabelProps, 'StepIconComponent'> {
  icon: number;
}

export function CustomStepLabel({ icon, ...props }: CustomStepLabelProps) {
  return (
    <StyledStepLabel
      StepIconComponent={(iconProps) => (
        <StepIcon
          {...iconProps}
          completed={iconProps.completed ?? false} // Default to false if undefined
          active={iconProps.active ?? false} // Default to false if undefined
          icon={icon}
        />
      )}
      {...props}
    />
  );
}

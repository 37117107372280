import {
  BarChart as BarChartIcon,
  CheckCircle as CheckCircleIcon,
  Article as FileTextIcon,
  School as GraduationCapIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import { Box, Paper, Typography, useTheme } from '@mui/material';
import { PersonalInfo } from 'src/types/onboarding';

interface OnboardingSummaryProps {
  data: PersonalInfo;
}

interface StepProps {
  icon: React.ElementType;
  title: string;
  description: string;
}

function Step({ icon: Icon, title, description }: StepProps) {
  const theme = useTheme();

  return (
    <Box sx={{ textAlign: 'center', width: '100%' }}>
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 600,
          mb: 2,
          color: theme.palette.text.primary,
          width: '100%', // Full width
        }}
      >
        {title}
      </Typography>
      <Box sx={{ display: 'flex', gap: 3, alignItems: 'flex-start' }}>
        <Icon
          sx={{
            color: theme.palette.primary.main,
            fontSize: 24,
            marginTop: '8px',
            flexShrink: 0,
          }}
        />
        <div>
          <Typography color="text.secondary">{description}</Typography>
        </div>
      </Box>
    </Box>
  );
}

export function OnboardingSummary({ data }: OnboardingSummaryProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        textAlign: 'center',
        color: theme.palette.text.primary,
      }}
    >
      <CheckCircleIcon
        sx={{
          fontSize: 80,
          color: theme.palette.success.main,
          mb: 2,
        }}
      />

      <Typography variant="h4" sx={{ fontWeight: 700, mb: 1 }}>
        Witaj na pokładzie, {data.firstName}!
      </Typography>
      <Typography color="text.secondary" sx={{ mb: 6 }}>
        Twój wniosek został pomyślnie złożony.
      </Typography>

      <Paper
        sx={{
          p: 4,
          bgcolor: theme.palette.mode === 'light' ? 'grey.50' : 'grey.900', // Adjust background for dark mode
          color: theme.palette.text.primary,
          borderRadius: 2,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: 600,
            mb: 4,
            color: theme.palette.text.primary,
          }}
        >
          Następne kroki:
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Step
            icon={FileTextIcon}
            title="Podpisz umowę"
            description="Otrzymasz umowę od Autenti na email. Prosimy o jej przejrzenie i podpisanie."
          />
          <Step
            icon={SettingsIcon}
            title="Skonfiguruj portal partnera"
            description="Równocześnie otrzymasz od nas email z instrukcjami dotyczącymi konfiguracji danych dostępowych do portalu partnera."
          />
          <Step
            icon={GraduationCapIcon}
            title="Ukończ program wdrożeniowy"
            description="Zaloguj się do portalu i ukończ program wdrożeniowy online, aby zapoznać się z systemem."
          />
          <Step
            icon={BarChartIcon}
            title="Rozpocznij analizę raportów"
            description="Rozpocznij analizę raportów BIK swoich klientów, aby tworzyć wartość zarówno dla nich, jak i dla swojego biznesu."
          />
        </Box>
      </Paper>
    </Box>
  );
}

import { CloudUpload, Description, Memory, TrendingUp } from '@mui/icons-material';
import { Box, Card, CardContent, Container, Stack, Typography, useColorScheme } from '@mui/material';
import { motion } from 'framer-motion';

const steps = [
  {
    icon: CloudUpload,
    title: 'Prześlij Dokumenty',
    description: 'Bezpiecznie udostępnij swój raport BIK przez nasz szyfrowany system.',
  },
  {
    icon: Memory,
    title: 'Analiza Raportu',
    description: 'Otrzymaj natychmiastowe wskazówki i rekomendacje w ciągu 30 sekund.',
  },
  {
    icon: Description,
    title: 'Otrzymaj Dokumenty',
    description: 'Uzyskaj spersonalizowane dokumenty do czyszczenia raportu BIK.',
  },
  {
    icon: TrendingUp,
    title: 'Popraw Swoją Ocenę',
    description: 'Ciesz się lepszymi opcjami kredytowymi i większą elastycznością finansową.',
  },
];

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const item = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 },
};

export function Process() {
  const { mode } = useColorScheme();

  return (
    <Box
      sx={{
        py: 10,
        bgcolor: mode === 'dark' ? '#121212' : '#f9f9f9', // Background for light and dark mode
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Container maxWidth="lg">
        {/* Header */}
        <motion.div initial={{ opacity: 0, y: 20 }} whileInView={{ opacity: 1, y: 0 }} viewport={{ once: true }}>
          <Stack spacing={2} alignItems="center" mb={8}>
            <Typography
              variant="h2"
              sx={{
                background:
                  mode === 'dark'
                    ? 'linear-gradient(to right, #60a5fa, #818cf8)' // Soft gradient for dark mode
                    : 'linear-gradient(to right, #2563eb, #4f46e5)', // Vibrant gradient for light mode
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                textAlign: 'center',
              }}
            >
              Jak To Działa
            </Typography>
            <Typography
              variant="h5"
              color={mode === 'dark' ? '#b0bec5' : 'text.secondary'} // Subtle gray for dark mode
              textAlign="center"
            >
              Cztery proste kroki do wolności finansowej
            </Typography>
          </Stack>
        </motion.div>

        {/* Steps */}
        <Box
          component={motion.div}
          variants={container}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true }}
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: '1fr',
              sm: 'repeat(2, 1fr)',
              lg: 'repeat(4, 1fr)',
            },
            gap: 4,
          }}
        >
          {steps.map((step, index) => (
            <Card
              key={index}
              component={motion.div}
              variants={item}
              whileHover={{ scale: 1.05 }}
              sx={{
                position: 'relative',
                overflow: 'hidden',
                transition: 'all 0.3s ease',
                bgcolor: mode === 'dark' ? '#1e1e1e' : '#ffffff', // Card background for both modes
                border: `1px solid ${mode === 'dark' ? '#2c2c2c' : '#e0e3ea'}`, // Subtle border
                borderRadius: 2,
                boxShadow:
                  mode === 'dark'
                    ? '0px 4px 16px rgba(0, 0, 0, 0.5)' // Darker shadow for dark mode
                    : '0px 4px 16px rgba(0, 0, 0, 0.1)', // Lighter shadow for light mode
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: 0,
                  left: '50%',
                  transform: 'translateX(-50%)', // Center the bar horizontally
                  width: '100%', // Bar width as a percentage of the card
                  height: 4, // Bar height
                  background: mode === 'dark' ? '#60a5fa' : '#2563eb', // Solid color for the bar
                  borderRadius: 1, // Rounded corners
                },
                '&:hover': {
                  boxShadow:
                    mode === 'dark'
                      ? '0px 8px 24px rgba(0, 0, 0, 0.8)' // Glow-like shadow on hover for dark mode
                      : '0px 8px 24px rgba(0, 0, 0, 0.2)', // Slightly stronger shadow for light mode
                  '& .step-overlay': {
                    opacity: 0.1,
                  },
                  '& .step-icon': {
                    transform: 'scale(1.1)',
                  },
                },
              }}
            >
              <CardContent sx={{ textAlign: 'center', position: 'relative', zIndex: 1 }}>
                {/* Overlay - Centered Behind Icon */}
                <Box
                  className="step-overlay"
                  sx={{
                    position: 'absolute',
                    top: '16%',
                    left: '50%',
                    width: 60,
                    height: 60,
                    borderRadius: '50%',
                    bgcolor: mode === 'dark' ? '#3949ab' : '#bbdefb', // Accent background
                    opacity: 0.1,
                    transform: 'translate(-50%, -50%)', // Center the overlay
                    transition: 'opacity 0.3s ease',
                    zIndex: 0, // Ensure it is behind the icon
                  }}
                />
                {/* Icon */}
                <step.icon
                  className="step-icon"
                  sx={{
                    fontSize: 48,
                    color: mode === 'dark' ? '#90caf9' : 'primary.main', // Icon color based on mode
                    mb: 2,
                    transition: 'transform 0.3s ease',
                    position: 'relative', // Keep icon on top of the overlay
                    zIndex: 1,
                  }}
                />
                {/* Title */}
                <Typography
                  variant="h6"
                  color={mode === 'dark' ? '#ffffff' : 'text.primary'} // White text for dark mode
                  gutterBottom
                >
                  {step.title}
                </Typography>
                {/* Description */}
                <Typography color={mode === 'dark' ? '#b0bec5' : 'text.secondary'}>{step.description}</Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Container>
    </Box>
  );
}

import { useParams } from 'react-router-dom';
import { useSanitazedBIKSummary } from 'src/api/bikApi';
import { SanitazedBIKReportModal } from 'src/components/SanitazedBIKPreview/SanitazedBIKReportModal';

export const SanitazedBIKPreview = () => {
  const { id } = useParams();
  const { data, isLoading, isError } = useSanitazedBIKSummary(id || '');

  return (
    <>
      <SanitazedBIKReportModal
        reportId={id || ''}
        reportData={data}
        loading={isLoading}
        error={isError ? 'Nie udało się pobrać danych raportu. Spróbuj ponownie później.' : null}
      />
    </>
  );
};

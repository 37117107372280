import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createBIKPayment,
  createCommissionsServices,
  sendRegulationsConsentToCustomer,
  useCommissionsForBIK,
} from 'src/api/bikApi';
import { RoleType } from 'src/common/roles';
import { useSnackbar } from 'src/common/useSnackbar';
import { BIKSummary, BIKSummaryItem, BIKSummaryRequest, REGULATIONS_CONSENT_STATUS_LABELS } from 'src/types/BIKSummary';
import {
  convertCommissionToOrderSectionTypeItems,
  convertSKDToOrderSectionTypeItems,
  convertUnwiborToOrderSectionTypeItems,
} from 'src/utils/bikMapper';
import { CollapsibleSection } from './CollapsibleSection';
import { DetailSection } from './DetailsSection';
import { OrderSectionTypeItem } from './OrderSection/OrderSection.types';
import { SummaryHeader } from './SummaryHeader';

const transformBIKItemsToTableData = (
  items: BIKSummaryItem[],
  requests: BIKSummaryRequest[]
): OrderSectionTypeItem[] => {
  const transformedItems = items.map((item) => ({
    id: item.id,
    type: item.financingOption,
    institution: item.subject,
    reportType: item.bikReportType,
    price: item.price,
    discountPrice: item.discountPrice || 0,
  }));

  const transformedRequests = requests.map((request) => ({
    id: request.id,
    type: request.commitment,
    institution: request.companyName,
    reportType: request.bikReportType,
    price: request.price,
    discountPrice: request.discountPrice || 0,
  }));

  return [...transformedItems, ...transformedRequests];
};

const SummaryDetails = ({
  data,
  type,
  isPreview = false,
  refetchData,
}: {
  data?: BIKSummary;
  refetchData?: () => void;
  type?: RoleType;
  isPreview?: boolean;
}) => {
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [isSendingRegulationsConsent, setIsSendingRegulationsConsent] = useState(false);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const skdData = convertSKDToOrderSectionTypeItems(data?.skd || []);
  const unwiborData = convertUnwiborToOrderSectionTypeItems(data?.unwibor || []);
  const commissionData = convertCommissionToOrderSectionTypeItems(data?.commission || []);
  const { data: commissions } = useCommissionsForBIK(
    id || data?.id || '',
    !!data?.commission.length && type !== 'ROLE_LAW_OFFICE',
    type || 'ROLE_PARTNER'
  );

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const { showSnackbar } = useSnackbar();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onSendRegulationsConsentToCustomer = async () => {
    if (!data?.id) {
      showSnackbar('Brak podanego poprawnego identyfikatora ID.', 'error');
      return;
    }

    setIsSendingRegulationsConsent(true);

    try {
      await sendRegulationsConsentToCustomer(data.id);
      showSnackbar('Wiadomość email z linkiem do regulaminu została wysłana do klienta.', 'success');
      if (refetchData) {
        refetchData();
      }
    } catch {
      showSnackbar('Błąd podczas próby wysłania wiadomości email z linkiem do regulaminu do klienta.', 'error');
    } finally {
      setIsSendingRegulationsConsent(false);
    }
  };

  const bikItems = useMemo(() => (data ? transformBIKItemsToTableData(data.items, data.requests) : []), [data]);

  if (
    !data?.items.length &&
    !data?.requests.length &&
    !data?.skd.length &&
    !data?.unwibor.length &&
    !data?.commission?.length
  ) {
    return <Box>Brak wybranych danych do podsumowania</Box>;
  }

  const { orderId } = data;

  const handlePayment = async () => {
    if (!id) {
      showSnackbar('Brak podanego poprawnego identyfikatora ID.', 'error');
      return;
    }
    const allCommissionsIds = data.commission.map((item) => item.id);
    const oldCommissionsIds = (commissions || []).map((item) => item.bikReportCommissionEntryId);

    const newIds = allCommissionsIds.filter((id) => !oldCommissionsIds.includes(id));

    const isCommissionService = newIds.length > 0;
    const isBIKService = data.items.length > 0 || data.requests.length > 0;

    setIsPaymentLoading(true);
    try {
      if (isCommissionService) {
        await createCommissionsServices(id, newIds);
      }

      if (isBIKService) {
        await createBIKPayment(id);
      }

      navigate(`/process-bik/${id}/done`);
    } catch {
      showSnackbar('Błąd podczas próby tworzenia płatności. Skontaktuj się z administaratorem.');
    }

    setIsPaymentLoading(false);
  };

  const isDetailsEditable =
    data.status === 'USER_DATA_ENTERED' ||
    data.status === 'SUMMARY_INFORMATION_SENT' ||
    data.status === 'PAYMENT_PROCESS_INITIATED';

  const isEnabledOnStageOrDev = import.meta.env.VITE_ENV === 'DEV' || import.meta.env.VITE_ENV === 'STAGE';

  const Content = (
    <Box
      sx={{
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="lg">
        <Paper
          elevation={3}
          sx={{
            borderRadius: 4,
            overflow: 'hidden',
            backgroundColor: isDarkMode ? 'grey.900' : 'white',
            boxShadow: isDarkMode ? 2 : 3,
          }}
        >
          <SummaryHeader />

          <Grid container spacing={2} sx={{ padding: 3 }}>
            {isDetailsEditable && (
              <Grid size={{ xs: 12 }}>
                <Button
                  fullWidth={isMobile}
                  variant="outlined"
                  onClick={() => navigate(`/process-bik/${id || data.id}/add-details`)}
                >
                  Edytuj dane kontaktowe
                </Button>
              </Grid>
            )}
            <Grid size={{ xs: 12 }}>
              <DetailSection title="Dane do obsługi zamówienia" details={data.contact} />
            </Grid>
            <Grid size={{ xs: 12, md: data.customer ? 6 : 12 }}>
              <DetailSection title="Dane firmowe" details={data.company} />
            </Grid>

            <Grid size={{ xs: 12, md: data.company ? 6 : 12 }}>
              <DetailSection title="Dane prywatne" details={data.customer} />
            </Grid>
          </Grid>

          <Box sx={{ p: 3 }}>
            {!!bikItems.length && (
              <CollapsibleSection
                type="BIK"
                items={bikItems}
                orderNumber={orderId || 'Brak'}
                totalDiscountPrice={data.discountPrice}
              />
            )}

            {!!skdData.length && (
              <Box
                sx={{
                  pt: 4,
                  borderTop: 1,
                  borderColor: isDarkMode ? 'grey.700' : 'grey.200',
                }}
              >
                <CollapsibleSection
                  type="SKD"
                  items={skdData}
                  orderNumber={orderId || 'Brak'}
                  summaryVariant="estimated"
                />
              </Box>
            )}

            {!!unwiborData.length && (
              <Box
                sx={{
                  pt: 4,
                  borderTop: 1,
                  borderColor: isDarkMode ? 'grey.700' : 'grey.200',
                }}
              >
                <CollapsibleSection
                  type="VIBOR"
                  items={unwiborData}
                  orderNumber={orderId || 'Brak'}
                  summaryVariant="estimated"
                />
              </Box>
            )}

            {!!commissionData?.length && (
              <Box
                sx={{
                  pt: 4,
                  borderTop: 1,
                  borderColor: isDarkMode ? 'grey.700' : 'grey.200',
                }}
              >
                <CollapsibleSection
                  type="COMMISSION"
                  items={commissionData}
                  orderNumber={orderId || 'Brak'}
                  summaryVariant="estimated"
                />
              </Box>
            )}

            <Box
              sx={{
                textAlign: 'center',
                pt: 4,
                borderTop: 1,
                borderColor: isDarkMode ? 'grey.700' : 'grey.200',
              }}
            >
              <Typography variant="body1">
                Aby zatwierdzić zamówienie, regulamin usługi BIKMaster musi zostać zaakceptowany przez klienta.
              </Typography>

              <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
                <Typography variant="body1" fontWeight="bold" mr={1}>
                  Aktualny status akceptacji regulaminu:
                </Typography>
                <Chip
                  label={REGULATIONS_CONSENT_STATUS_LABELS[data.regulationsConsentStatus]}
                  color={data.regulationsConsentStatus === 'ACCEPTED' ? 'success' : 'warning'}
                  sx={{ fontWeight: 'bold' }}
                />
              </Box>

              {data.regulationsConsentStatus !== 'ACCEPTED' && refetchData && (
                <Box mt={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onSendRegulationsConsentToCustomer}
                    disabled={isSendingRegulationsConsent}
                    sx={{
                      width: '100%',
                      maxWidth: 300,
                      fontWeight: 'bold',
                    }}
                  >
                    {isSendingRegulationsConsent ? (
                      <CircularProgress size={24} />
                    ) : data.regulationsConsentStatus === 'NOT_GENERATED' ? (
                      'Wyślij wiadomość email do klienta'
                    ) : (
                      'Wyślij ponownie email'
                    )}
                  </Button>
                </Box>
              )}
            </Box>

            {!isPreview && (
              <Box sx={{ pt: 4, justifySelf: 'end' }}>
                <Button
                  variant="outlined"
                  disabled={
                    isPaymentLoading || (data.regulationsConsentStatus !== 'ACCEPTED' && !isEnabledOnStageOrDev)
                  }
                  color="primary"
                  size="large"
                  sx={{ marginTop: 2, width: isMobile ? '100%' : 'auto', minWidth: 205 }}
                  onClick={handlePayment}
                >
                  {isPaymentLoading ? <CircularProgress size={24} /> : 'Zatwierdź zamówienie'}
                </Button>
              </Box>
            )}
          </Box>
        </Paper>
      </Container>
    </Box>
  );

  return (
    <Box>
      {isPreview ? (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Pokaż podsumowanie zamówienia</Typography>
          </AccordionSummary>
          <AccordionDetails>{Content}</AccordionDetails>
        </Accordion>
      ) : (
        Content
      )}
    </Box>
  );
};

export default SummaryDetails;

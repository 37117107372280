import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { Box } from '@mui/material';
import { Report } from 'src/types/Report';

interface BIKAgentDataProps {
  bikReport: Report;
}

export const BIKAgentData = ({ bikReport }: BIKAgentDataProps) => {
  const isPartner = 'partner' in bikReport;

  return isPartner && bikReport.partner ? (
    <Box display="flex" alignItems="center" className="agent-name">
      <SupportAgentIcon sx={{ mr: 1 }} />
      <div>
        {bikReport.partner?.firstName || ''} {bikReport.partner?.lastName || ''}
      </div>
    </Box>
  ) : null;
};

import { Check } from '@mui/icons-material';
import { Box, Fade, LinearProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ProcessingIcon } from './ProcessingIcon';

const steps = [
  { threshold: 0, message: 'Inicjalizacja analizy...' },
  { threshold: 25, message: 'Analiza historii kredytowej...' },
  { threshold: 50, message: 'Obliczanie scoringu...' },
  { threshold: 75, message: 'Generowanie rekomendacji...' },
  { threshold: 95, message: 'Finalizacja raportu...' },
];

export const BIKUploadLoader = ({ progress }: { progress: number }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);

  useEffect(() => {
    // Find current active step
    const newStep = steps.findIndex((step, index) => {
      const nextThreshold = steps[index + 1]?.threshold ?? 101; // Changed to 101 to ensure 100 includes last step
      return progress >= step.threshold && progress < nextThreshold;
    });

    if (newStep !== -1) {
      setCurrentStep(newStep);
      // Complete all steps that have threshold less than or equal to current progress
      const stepsToComplete = steps.map((_, index) => index).filter((index) => steps[index].threshold <= progress);

      setCompletedSteps((prev) => Array.from(new Set([...prev, ...stepsToComplete])));
    }
  }, [progress, steps]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        maxWidth: '400px',
        width: '100%',
        p: 3,
      }}
    >
      <ProcessingIcon size={96} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 0.5,
          width: '100%',
        }}
      >
        <Typography
          color="primary"
          sx={{
            fontWeight: 500,
            animation: 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
            '@keyframes pulse': {
              '0%, 100%': {
                opacity: 1,
              },
              '50%': {
                opacity: 0.5,
              },
            },
          }}
        >
          Specjalistyczna Analiza Dokumentu
        </Typography>
        <Typography
          color="text.secondary"
          variant="body2"
          sx={{
            animation: 'pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite',
            mb: 2,
          }}
        >
          Trwa budowanie raportu BIK Master...
        </Typography>

        <Box sx={{ width: '100%', mb: 2 }}>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              height: 8,
              borderRadius: 4,
              backgroundColor: 'rgba(144, 202, 249, 0.2)',
              '& .MuiLinearProgress-bar': {
                borderRadius: 4,
                backgroundImage: 'linear-gradient(90deg, #2196f3, #1976d2)',
              },
            }}
          />
        </Box>

        <Box sx={{ width: '100%' }}>
          {steps.map((step, index) => (
            <Fade
              key={step.message}
              in={index <= currentStep || completedSteps.includes(index)}
              timeout={{ enter: 800, exit: 400 }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  mb: 1,
                  opacity: index === currentStep ? 1 : 0.6,
                }}
              >
                <Box
                  sx={{
                    width: 20,
                    height: 20,
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: completedSteps.includes(index) ? 'success.main' : 'primary.main',
                    color: 'white',
                    transition: 'all 0.3s ease',
                  }}
                >
                  {completedSteps.includes(index) ? <Check /> : <Typography variant="caption">{index + 1}</Typography>}
                </Box>
                <Typography
                  variant="body2"
                  color={index === currentStep ? 'text.primary' : 'text.secondary'}
                  sx={{ transition: 'all 0.3s ease' }}
                >
                  {step.message}
                </Typography>
              </Box>
            </Fade>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

import { Box, Divider, Typography, useTheme } from '@mui/material';
import { OrderSummaryVariant } from '../OrderSummaryVariant.types';

interface OrderSummaryProps {
  orderNumber: string;
  netTotal: number;
  discountTotal: number;
  vatAmount: number;
  grossTotal: number;
  variant?: OrderSummaryVariant;
}

export function OrderSummary({
  orderNumber,
  netTotal,
  discountTotal,
  vatAmount,
  grossTotal,
  variant = 'default',
}: OrderSummaryProps) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const isEstimated = variant === 'estimated';

  const netLabel = 'Suma cen';
  const grossLabel = isEstimated ? 'Orientacyjna cena końcowa z VAT (23%):' : 'Cena końcowa z VAT (23%):';

  const hasDiscount = discountTotal && discountTotal !== netTotal;

  return (
    <Box>
      <Typography variant="body2" fontWeight="medium" sx={{ color: 'indigo.600' }}>
        Zamówienie nr {orderNumber}
      </Typography>
      {!isEstimated && (
        <Box
          sx={{
            p: 3,
            borderRadius: 2,
            backgroundColor: isDarkMode ? 'grey.900' : 'grey.50',
          }}
        >
          <>
            <Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
              <Typography variant="body2" fontWeight="medium" color="text.secondary">
                {netLabel}:
              </Typography>
              <Box>
                {hasDiscount && (
                  <Typography
                    variant="body2"
                    fontWeight="medium"
                    color="text.secondary"
                    sx={{ textDecoration: 'line-through', mr: 1 }}
                  >
                    {netTotal.toFixed(2)} zł
                  </Typography>
                )}
                <Typography variant="body2" fontWeight="medium" color="text.primary" component="span">
                  {discountTotal.toFixed(2)} zł
                </Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
              <Typography variant="body2" fontWeight="medium" color="text.secondary">
                VAT (23%):
              </Typography>
              <Typography variant="body2" fontWeight="medium" color="text.primary">
                {vatAmount.toFixed(2)} zł
              </Typography>
            </Box>
            <Divider sx={{ borderColor: isDarkMode ? 'grey.800' : 'grey.300', my: 2 }} />
            <Box display="flex" justifyContent="space-between" sx={{ pt: 2 }}>
              <Typography variant="body1" fontWeight="bold" sx={{ color: 'indigo.600' }}>
                {grossLabel}
              </Typography>
              <Typography variant="body1" fontWeight="bold" sx={{ color: 'indigo.600' }}>
                {(isEstimated ? discountTotal : grossTotal).toFixed(2)} zł
              </Typography>
            </Box>
          </>
        </Box>
      )}
    </Box>
  );
}

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'; // Optional error icon
import { Box, Button, Container, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/'); // Navigate to the home page
  };

  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          bgcolor: 'background.paper',
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        {/* Optional icon or image */}
        <ErrorOutlineIcon sx={{ fontSize: '5em', color: 'error.main', mb: 2 }} />

        <Typography variant="h1" component="h1" gutterBottom sx={{ fontSize: '3em', fontWeight: 'bold' }}>
          404
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom sx={{ mb: 2 }}>
          Ups! Strona, której szukasz, nie istnieje.
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph sx={{ maxWidth: 600, mb: 3 }}>
          Wygląda na to, że dotarłeś do strony, która nie istnieje. Możliwe, że wpisałeś błędny adres lub strona została
          przeniesiona. Jeśli uważasz, że to błąd, prosimy o kontakt.
        </Typography>

        {/* Go back home button */}
        <Button variant="contained" color="primary" onClick={handleGoHome} sx={{ mt: 3, px: 4, py: 1.5 }}>
          Wróć na stronę główną
        </Button>
      </Box>
    </Container>
  );
};

export default NotFoundPage;

import { CommissionStatus } from 'src/types/TimelineStatus';

export const CommissionStatusDetails: Record<
  CommissionStatus,
  { key: keyof CommissionReportTimeline; translation: string }
> = {
  INITIATED: { key: 'initiatedAt', translation: 'Inicjalizacja' },
  DOCUMENTS_SEND: { key: 'documentsSendAt', translation: 'Wysłano do klienta' },
  PROCESSING: { key: 'processingAt', translation: 'Przetwarzanie' },
  PROCESSING_FAIL: { key: 'processingFailedAt', translation: 'Wniosek odrzucony' },
  PROCESSING_SUCCESSFUL: { key: 'processingSuccessfullAt', translation: 'Wniosek zaakceptowany' },
  COMPLETE: { key: 'completedAt', translation: 'Zakończono' },
};

export interface CommissionReportTimeline {
  completedAt?: string;
  documentsSendAt?: string;
  initiatedAt?: string;
  processingAt?: string;
  processingFailedAt?: string;
  processingSuccessfullAt?: string;
}

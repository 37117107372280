import ArrowForward from '@mui/icons-material/ArrowForward';
import {
  Box,
  StepConnector as MuiStepConnector,
  Step,
  Stepper,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CustomStepLabel } from './Stepper/CustomStepLabel';
import { NextStepIndicator } from './Stepper/NextStepIndicator';
import { StepperProgress } from './Stepper/StepperProgress';

const CustomConnector = styled(MuiStepConnector)(({ theme }) => ({
  '& .MuiStepConnector-line': {
    height: 2,
    border: 0,
    backgroundColor: theme.palette.mode === 'light' ? 'rgba(229, 231, 235, 0.5)' : 'rgba(144, 164, 174, 0.5)', // Adjusted for dark mode
    borderRadius: 1,
  },
  '&.Mui-active': {
    '& .MuiStepConnector-line': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  '&.Mui-completed': {
    '& .MuiStepConnector-line': {
      backgroundColor: theme.palette.success.main,
    },
  },
}));

const ConnectorArrow = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 24,
  height: 24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.background.paper : theme.palette.background.paper,
  borderRadius: '50%',
  boxShadow: theme.palette.mode === 'light' ? '0 2px 4px rgba(0, 0, 0, 0.05)' : '0 2px 4px rgba(255, 255, 255, 0.1)', // Adjusted for dark mode
  color: theme.palette.primary.main,
  zIndex: 1,
}));

interface StepperHeaderProps {
  activeStep: number;
  steps: string[];
}

export function StepperHeader({ activeStep, steps }: StepperHeaderProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const nextStep = activeStep < steps.length - 1 ? steps[activeStep + 1] : undefined;

  return (
    <Box sx={{ mb: { xs: 4, sm: 6 } }}>
      <Typography
        variant="h4"
        component="h1"
        align="center"
        sx={{
          mb: { xs: 4, sm: 5 },
          fontSize: { xs: '1.75rem', sm: '2.25rem' },
          fontWeight: 800,
          background:
            theme.palette.mode === 'light'
              ? 'linear-gradient(135deg, #1e40af 0%, #3b82f6 100%)'
              : 'linear-gradient(135deg, #90caf9 0%, #42a5f5 100%)',
          backgroundClip: 'text',
          color: 'transparent',
          px: { xs: 2, sm: 0 },
          textShadow:
            theme.palette.mode === 'light' ? '0 2px 4px rgba(0, 0, 0, 0.05)' : '0 2px 4px rgba(255, 255, 255, 0.1)', // Adjusted for dark mode
        }}
      >
        Dołącz do nas jako Partner
      </Typography>

      <Box
        sx={{
          px: { xs: 1, sm: 4 },
          py: { xs: 2, sm: 3 },
          background: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.8)' : 'rgb(28 35 47 / 90%)', // Adjusted for dark mode
          borderRadius: { xs: '16px', sm: '24px' },
        }}
      >
        <Stepper
          activeStep={activeStep}
          alternativeLabel={!isMobile}
          orientation={isMobile ? 'vertical' : 'horizontal'}
          connector={
            !isMobile ? (
              <Box sx={{ position: 'relative' }}>
                <CustomConnector />
                <ConnectorArrow>
                  <ArrowForward fontSize="small" />
                </ConnectorArrow>
              </Box>
            ) : undefined
          }
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <CustomStepLabel icon={index + 1}>{label}</CustomStepLabel>
            </Step>
          ))}
        </Stepper>

        {!isMobile && (
          <>
            <StepperProgress activeStep={activeStep} totalSteps={steps.length} />
            <NextStepIndicator nextStep={nextStep} />
          </>
        )}
      </Box>
    </Box>
  );
}

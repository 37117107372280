import { BIKReportType } from 'src/types/BIKReport.type';
import { BIKItem, BIKRequest } from 'src/types/CompanyBIKSummary.type';
import { SelectedRow } from 'src/types/SelectedRow';

export const mapBIKItemToSelectedRow = (BIKItem: BIKItem, type: BIKReportType): SelectedRow => {
  return {
    id: BIKItem.id,
    price: BIKItem.price,
    discountPrice: BIKItem.discountPrice,
    bikReportType: type,
    type: BIKItem.isOpen ? 'OPEN' : 'CLOSE',
    longestDebt: BIKItem.longestDebt,
    finishedAt: BIKItem?.finishedAt,
    longestDebtAt: BIKItem.longestDebtAt,
    creditorId: BIKItem.creditorId,
    signedOn: BIKItem.signedOn,
    financingOption: BIKItem.typeDescription,
    name: BIKItem.subject,
  };
};

export const mapBIKRequestToSelectedRow = (BIKRequest: BIKRequest, type: BIKReportType): SelectedRow => {
  return {
    id: BIKRequest.id,
    price: BIKRequest.price,
    discountPrice: BIKRequest.discountPrice,
    bikReportType: type,
    creditorId: BIKRequest.creditorId,
    signedOn: BIKRequest.date,
    financingOption: BIKRequest.commitment,
    name: BIKRequest.companyName,
  };
};

import { Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { formatDate } from 'src/utils/date';

function calculateTimeSince(dateString: string): { months: number; days: number } {
  const generatedDate = new Date(dateString);
  const today = new Date();

  const timeDiff = today.getTime() - generatedDate.getTime();
  const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // Konwersja milisekund na dni
  const months = Math.floor(days / 30); // Przybliżone miesiące

  return { months, days };
}

export const ReportTopDateComponent = ({ generatedAt }: { generatedAt: string }) => {
  const { months, days } = calculateTimeSince(generatedAt);
  const theme = useTheme();

  // Definiowanie kolorów dla różnych trybów
  const lightModeColors = {
    yellowBg: '#FFF3CD', // Łagodny żółty
    redBg: '#F8D7DA', // Łagodny czerwony
    textColor: '#856404', // Kolor tekstu dla żółtego
    textRedColor: '#721C24', // Kolor tekstu dla czerwonego
  };

  const darkModeColors = {
    yellowBg: '#5A4825', // Ciemny żółty dla dark mode
    redBg: '#4E2A2A', // Ciemny czerwony dla dark mode
    textColor: '#FFD700', // Złoty dla lepszej widoczności
    textRedColor: '#FF7F7F', // Łagodna czerwień
  };

  const isDarkMode = theme.palette.mode === 'dark';

  // Ustal kolor tła i wiadomość na podstawie liczby dni
  let backgroundColor = isDarkMode ? '#0e0e0e' : '#f8f8f8';
  let textColor = theme.palette.text.primary;
  let warningMessage = '';

  if (days > 180) {
    backgroundColor = isDarkMode ? darkModeColors.redBg : lightModeColors.redBg;
    textColor = isDarkMode ? darkModeColors.textRedColor : lightModeColors.textRedColor;
    warningMessage =
      'Raport wygenerowany został ponad 180 dni temu. Upewnij się, że wykorzystujesz do analizy najbardziej aktualny raport klienta.';
  } else if (days > 90) {
    backgroundColor = isDarkMode ? darkModeColors.yellowBg : lightModeColors.yellowBg;
    textColor = isDarkMode ? darkModeColors.textColor : lightModeColors.textColor;
    warningMessage = 'Raport wygenerowany został ponad 90 dni temu. Niektóre dane mogą nie być aktualne.';
  }

  // Formatowanie wyświetlania czasu
  const timeDisplay = days > 31 ? `${months} miesięcy temu (${days} dni)` : `${days} dni temu`;

  return (
    <Grid
      size={{ xs: 12 }}
      style={{
        backgroundColor,
        padding: '12px',
        textAlign: 'center',
        borderRadius: '8px',
        transition: 'background-color 0.3s ease',
      }}
    >
      <Typography variant="body1" style={{ color: theme.palette.text.primary }}>
        Raport wygenerowany dnia <strong>{formatDate(generatedAt)}</strong>, czyli <strong>{timeDisplay}</strong>.
      </Typography>

      {warningMessage && (
        <Typography
          variant="body2"
          style={{
            marginTop: '8px',
            fontWeight: 'bold',
            color: textColor,
          }}
        >
          {warningMessage}
        </Typography>
      )}
    </Grid>
  );
};

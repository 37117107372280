import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import {
  Box,
  Card,
  CardContent,
  Collapse,
  Divider,
  IconButton,
  SvgIcon,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { memo, useState } from 'react';
import { isCommission } from 'src/guards/isCommission';
import { CommissionEntry } from 'src/types/CommissionResponse';
import { StructuredBIK, StructuredBIKDetails, StructuredService, StructuredType } from 'src/types/StructuredBIK';
import { ReportTopDateComponent } from '../report-preview/report-summary/ReportTopDateComponent';
import { InstitutionDetailsCard } from './InstitutionDetailsCard';
import { ProductCheckbox } from './ProductCheckbox';
import { ProductTypeTranslations } from './translations';

type PartnerCommissionCardProps = {
  isClientView?: boolean;
  isCommission?: boolean;
  showMinMax: boolean;
  minPrice: number;
  maxPrice: number;
  minProfit?: number;
  maxProfit?: number;
  minDiscountPrice?: number;
  maxDiscountPrice?: number;
  testMinimumProfit?: number;
  testMaximumProfit?: number;
  commission?: CommissionEntry;
};

const CustomIcon = (props: any) => (
  <SvgIcon {...props}>
    <text
      x="50%"
      y="50%"
      textAnchor="middle"
      dominantBaseline="central"
      fontSize="13"
      fontFamily="Arial, Helvetica, sans-serif"
    >
      {props.text ?? 'PLN'}
    </text>
  </SvgIcon>
);

const CustomIconWithBackground = (props: any) => {
  return (
    <Box
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        minWidth: 30,
        width: 30,
        height: 30,
        backgroundColor: '#e0f7fa', // Light teal background
        borderRadius: '50%',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
      }}
    >
      <CustomIcon fontSize="small" {...props} /> {/* Adjust fontSize and color */}
    </Box>
  );
};

export const PartnerCommissionCard = ({
  showMinMax,
  minPrice,
  maxPrice,
  minDiscountPrice,
  maxDiscountPrice,
}: PartnerCommissionCardProps) => {
  if (!minPrice && !maxPrice) {
    return null;
  }

  const isDiscountApplied = maxDiscountPrice && (maxDiscountPrice !== maxPrice || minDiscountPrice !== minPrice);

  return (
    <Grid size={{ xs: 12, md: 6 }}>
      <Box display="flex" alignItems="center">
        <CustomIconWithBackground fontSize="small" color="primary" />
        <Box ml={1}>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="body2" color="text.secondary">
              {showMinMax ? 'Zakres prowizji partnera' : 'Prowizja partnera'}
            </Typography>
          </Box>
          {isDiscountApplied ? (
            <>
              <Typography variant="h6" color="error.main" sx={{ textDecoration: 'line-through' }}>
                {showMinMax ? `${minPrice.toFixed(2)} - ${maxPrice.toFixed(2)}` : `${maxPrice.toFixed(2)}`} zł
              </Typography>
              <Typography variant="h6" color="primary">
                {showMinMax
                  ? `${(minDiscountPrice ?? 0).toFixed(2)} - ${maxDiscountPrice.toFixed(2)}`
                  : `${maxDiscountPrice.toFixed(2)}`}{' '}
                zł
              </Typography>
            </>
          ) : (
            <Typography variant="h6" color="primary">
              {showMinMax ? `${minPrice.toFixed(2)} - ${maxPrice.toFixed(2)}` : `${maxPrice.toFixed(2)}`} zł
            </Typography>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

type ClientInvestmentsCardProps = {
  isEstimatedCost: boolean;
  showMinMax: boolean;
  minLeft: number;
  maxLeft: number;
  minInstallment: number;
  maxInstallment: number;
};

const ClientInvestmentsCard = ({
  isEstimatedCost,
  showMinMax,
  minLeft,
  maxLeft,
  minInstallment,
  maxInstallment,
}: ClientInvestmentsCardProps) => {
  let leftText = showMinMax ? 'Zakres redukcji aktywnych zobowiązań' : 'Redukcja aktywnych zobowiązań';
  const installmentText = showMinMax ? 'Zakres redukcji miesięcznych zobowiązań' : 'Redukcja miesięcznych zobowiązań';

  if (isEstimatedCost) {
    leftText = showMinMax ? 'Zakres szacowanej wartości dla klienta' : 'Szacowana wartość dla klienta';
  }

  return (
    <Grid size={{ xs: 12, md: 6 }}>
      <Box display="flex" alignItems="center" mb={1}>
        <TrendingUpIcon color="primary" />
        <Box ml={1}>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="body2" color="text.secondary">
              {leftText}
            </Typography>
          </Box>
          <Typography variant="h6" color="primary">
            {showMinMax ? `${minLeft.toFixed(2)} - ${maxLeft.toFixed(2)}` : `${maxLeft.toFixed(2)}`} zł
          </Typography>
        </Box>
      </Box>
      {Boolean(maxInstallment) && (
        <Box display="flex" alignItems="center">
          <QueryStatsIcon color="secondary" />
          <Box ml={1}>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="body2" color="text.secondary">
                {installmentText}
              </Typography>
            </Box>
            <Typography variant="h6" color="secondary">
              {showMinMax
                ? `${minInstallment.toFixed(2)} - ${maxInstallment.toFixed(2)}`
                : `${maxInstallment.toFixed(2)}`}{' '}
              zł
            </Typography>
          </Box>
        </Box>
      )}
    </Grid>
  );
};

const ReportValueCard = ({
  isClientView,
  isCommission,
  showMinMax,
  minPrice,
  maxPrice,
  minProfit,
  maxProfit,
  minDiscountPrice,
  maxDiscountPrice,
  testMinimumProfit,
  testMaximumProfit,
  commission,
}: PartnerCommissionCardProps) => {
  const isDiscountApplied = maxDiscountPrice && (maxDiscountPrice !== maxPrice || minDiscountPrice !== minPrice);

  const hideText = true;
  let text = showMinMax ? 'Zakres wartości zamówienia' : 'Wartość zamówienia';
  const profitText =
    minProfit !== maxProfit
      ? 'Maksymalna szacowana wartość przedmiotu sporu (WPS BOT)'
      : 'Szacowana wartość przedmiotu sporu (WPS BOT)';

  const testProfitText =
    testMinimumProfit !== testMaximumProfit
      ? 'Maksymalna szacowana wartość przedmiotu sporu (WPS MODEL)'
      : 'Szacowana wartość przedmiotu sporu (WPS MODEL)';

  if (isClientView) {
    text = showMinMax ? 'Zakres wartości usług' : 'Wartość usług';
  }

  return (
    <Grid size={{ xs: 12 }}>
      {!hideText && (
        <Box display="flex" alignItems="center">
          <CustomIconWithBackground fontSize="small" color="success" />
          <Box ml={1}>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="body2" color="text.secondary">
                {text}
              </Typography>
            </Box>
            {isDiscountApplied ? (
              <>
                <Typography variant="h6" color="error.main" sx={{ textDecoration: 'line-through' }}>
                  {showMinMax ? `${minPrice.toFixed(2)} - ${maxPrice.toFixed(2)}` : `${maxPrice.toFixed(2)}`} zł
                </Typography>
                <Typography variant="h6" color="success.main">
                  {showMinMax
                    ? `${(minDiscountPrice || 0).toFixed(2)} - ${maxDiscountPrice.toFixed(2)}`
                    : `${maxDiscountPrice.toFixed(2)}`}{' '}
                  zł
                </Typography>
              </>
            ) : (
              <Typography variant="h6" color="success.main">
                {showMinMax ? `${minPrice.toFixed(2)} - ${maxPrice.toFixed(2)}` : `${maxPrice.toFixed(2)}`} zł
              </Typography>
            )}
          </Box>
        </Box>
      )}
      {!!maxProfit && !isClientView && (
        <Box display="flex" alignItems="center">
          <CustomIconWithBackground fontSize="small" color="secondary" />
          <Box ml={1}>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="body2" color="text.secondary">
                {profitText}
              </Typography>
            </Box>

            <Typography variant="h6" color="secondary">
              {showMinMax
                ? `${(minProfit || 0).toFixed(2)} - ${(maxProfit || 0).toFixed(2)}`
                : `${(maxProfit || 0).toFixed(2)}`}{' '}
              zł
            </Typography>
          </Box>
        </Box>
      )}
      {isCommission && (
        <Box display="flex" alignItems="center">
          <CustomIconWithBackground fontSize="small" color="error" />
          <Box ml={1}>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="body2" color="text.secondary">
                {testProfitText}
              </Typography>
            </Box>

            <Typography variant="h6" color="error">
              {showMinMax
                ? `${(testMinimumProfit || 0).toFixed(2)} - ${(testMaximumProfit || 0).toFixed(2)}`
                : `${(testMaximumProfit || 0).toFixed(2)}`}{' '}
              zł {commission && `(WPS Factor ${(commission.wpsFactor * 100).toFixed(2)}%)`}
              {commission && (
                <Tooltip
                  title={
                    <Box component="ul" sx={{ m: 0 }}>
                      <Box component="li">
                        Okres kredytu zaokrąglony do pełnych lat:{' '}
                        {(commission?.wpsEstimates.creditLengthRoundedToFullYear || 0) / 12}
                      </Box>
                      <Box component="li">
                        Okres kredytu: {commission?.wpsEstimates.creditLength.toFixed(3)} miesięcy
                      </Box>
                      <Box component="li">
                        Oprocentowanie: {((commission?.wpsEstimates.interestRate || 0) * 100).toFixed(2)}%
                      </Box>
                      <Box component="li">
                        Rata kapitałowa: {commission?.wpsEstimates.capitalInstallment.toFixed(2)} zł
                      </Box>
                    </Box>
                  }
                >
                  <InfoIcon fontSize="small" sx={{ ml: 1, cursor: 'pointer' }} />
                </Tooltip>
              )}
            </Typography>
          </Box>
        </Box>
      )}
    </Grid>
  );
};

export const ProductCard = ({
  data,
  bikDetails,
  title,
  type,
  isDisabled,
  isExpanded,
  isHidden,
  isCheckboxHidden,
  dataTestId,
  isClientView,
  customCheckbox,
  customMultiCheckbox,
  expandCard,
}: {
  bikDetails?: StructuredBIKDetails;
  data: StructuredBIK | StructuredService;
  title?: string;
  isDisabled: boolean;
  isExpanded?: boolean;
  isHidden?: boolean;
  isCheckboxHidden?: boolean;
  isClientView?: boolean;
  type: StructuredType;
  dataTestId?: string;
  customMultiCheckbox?: {
    selectedCheckboxIds: string[];
    setSelectedCheckboxIds: (id: string) => void;
    checkboxId: string;
  };
  customCheckbox?: { selectedCheckboxId: string; setSelectedCheckboxId: (id: string) => void; checkboxId: string };
  expandCard?: React.ReactNode;
}) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(!!isExpanded);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const isEstimatedCost = !!data.maximumLeft && !data.maximumInstallment;

  const generateJsonForTests = () => {
    const isTestsEnabled = import.meta.env.VITE_ENV === 'DEV' || import.meta.env.VITE_ENV === 'STAGE';

    if (isTestsEnabled && 'allItems' in data) {
      return JSON.stringify(data.allItems);
    }

    return '';
  };

  if (isHidden) {
    return <Box sx={{ display: 'none' }}>{expandCard}</Box>;
  }

  const isProductChecked = Boolean(
    customCheckbox
      ? customCheckbox.checkboxId === customCheckbox.selectedCheckboxId
      : customMultiCheckbox
        ? customMultiCheckbox.selectedCheckboxIds.includes(customMultiCheckbox.checkboxId)
        : undefined
  );

  return (
    <Card
      variant="outlined"
      sx={{
        p: 2,
        border: '2px solid',
        boxShadow: '0px 0px 7px -2px rgba(148, 152, 156, 0.4)',
        borderColor: isProductChecked ? theme.palette.success.main : theme.palette.divider, // Dynamic border color
      }}
      data-testid={dataTestId}
      data-json={generateJsonForTests()}
    >
      <CardContent>
        <Grid container alignItems="flex-start" justifyContent="space-between" sx={{ mb: 1 }}>
          <Grid size={{ xs: 8 }}>
            <Typography variant="h6" fontWeight="bold" sx={{ wordBreak: 'break-word' }}>
              {title ?? `${ProductTypeTranslations[type]}`}
            </Typography>
          </Grid>
          <Grid>
            <Box display="flex" alignItems="center">
              {expandCard && (
                <IconButton onClick={handleExpandClick} size="small">
                  {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              )}
              <ProductCheckbox
                data={data}
                type={type}
                isProductChecked={isProductChecked}
                isDisabled={isDisabled}
                isCheckboxHidden={isCheckboxHidden}
                customCheckbox={customCheckbox}
                customMultiCheckbox={customMultiCheckbox}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {'details' in data && !!data.details?.genaratedAt && (
            <ReportTopDateComponent generatedAt={data.details.genaratedAt} />
          )}
          {('details' in data || bikDetails) && (
            <InstitutionDetailsCard details={'details' in data ? data.details : bikDetails} />
          )}
          {/* {!isClientView && (
            <PartnerCommissionCard
              maxPrice={data.maximumPartnerCommission}
              minPrice={data.minimumPartnerCommission}
              maxDiscountPrice={data.maximumPartnerDiscountedCommission}
              minDiscountPrice={data.minimumPartnerDiscountedCommission}
              showMinMax={'showMinMax' in data ? data.showMinMax : false}
            />
          )} */}

          <ReportValueCard
            isClientView={isClientView}
            maxPrice={data.maximumPrice}
            minPrice={data.minimumPrice}
            minProfit={data.minimumProfit}
            maxProfit={data.maximumProfit}
            testMinimumProfit={data.testMinimumProfit}
            testMaximumProfit={data.testMaximumProfit}
            maxDiscountPrice={data.maximumDiscountPrice}
            minDiscountPrice={data.minimumDiscountPrice}
            showMinMax={false}
            isCommission={data.type === 'COMMISSION'}
            commission={
              'items' in data && data.type === 'COMMISSION' && isCommission(data.items[0]) ? data.items[0] : undefined
            }
          />
          {Boolean(isClientView && (data.maximumLeft || data.maximumProfit)) && (
            <ClientInvestmentsCard
              isEstimatedCost={isEstimatedCost}
              showMinMax={data.showMinMaxCustomer}
              maxInstallment={data.maximumInstallment}
              minInstallment={data.minimumInstallment}
              maxLeft={data.maximumLeft || data.maximumProfit}
              minLeft={data.minimumLeft || data.minimumProfit}
            />
          )}
        </Grid>

        <Collapse in={expanded} timeout="auto">
          <Divider sx={{ mt: 2, mb: 1 }} />
          {expandCard}
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default memo(ProductCard);

import CircleIcon from '@mui/icons-material/Circle';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Collapse, FormControlLabel, IconButton, Switch, Tooltip, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useEffect, useState } from 'react';
import { BIKItem } from 'src/types/CompanyBIKSummary.type';
import ProDetailsToggle from '../ProView/ProDetailsToogle';
import { BIKItemCard } from './BIKItemCard';
import { RowDetailsIcon } from './RowDetailsIcon';
import { BIKSection } from './styles';
import { getDebtInfo } from './utils';

interface BIKItemContentProps {
  item: BIKItem;
  bikId: string;
  isChecked: boolean;
  isDisabled: boolean;
  dataTestId: string;
  onCheckboxChange: (bikItem: BIKItem) => void;
}

const SmallInfoIcon = (isVisible: boolean) => {
  const theme = useTheme(); // Access the theme for dynamic color changes

  return (
    <>
      {isVisible && (
        <Tooltip
          title={
            <>
              <strong>UWAGA</strong> - Ze względu na znaczenie opóźnień, ta pozycja jest bardzo trudna do optymalizacji,
              co sprawia, że <strong>sukces nie jest gwarantowany.</strong> Jednak do tej pory{' '}
              <strong>osiągaliśmy 80% skuteczność w jej eliminacji.</strong>
            </>
          }
        >
          <IconButton sx={{ padding: 0, marginLeft: 0.5 }}>
            <InfoIcon sx={{ fontSize: 'small', color: theme.palette.text.secondary }} />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export const BIKItemContent: React.FC<BIKItemContentProps> = ({
  item,
  bikId,
  dataTestId,
  isChecked,
  isDisabled,
  onCheckboxChange,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const theme = useTheme(); // Access the theme for dark mode support

  const alignCenter = { alignContent: 'center', textAlign: 'center' };
  const isRemovable = item.enableToRemove;
  const debtInfo = getDebtInfo(item);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    const parsedText = `${item.typeDescription}${item.subject}`;
    const FS = (window as any).FS as any;

    if (parsedText.length > 150 && FS) {
      FS('trackEvent', {
        name: 'Check long parsed text',
        properties: {
          text: parsedText,
          type: 'creditItem',
          index: item.id,
        },
      });
    }
  }, [item]);

  return (
    <BIKSection
      className="fs-unmask"
      container
      size={{ xs: 12 }}
      key={item.id}
      spacing={0}
      sx={{
        borderColor: isChecked ? theme.palette.success.main : theme.palette.divider, // Dynamic border color
        p: '0.8em',
        position: 'relative',
        backgroundColor: theme.palette.background.default, // Dynamic background for dark mode
        color: theme.palette.text.primary, // Text color adapts to theme
      }}
      $isRemovable={!isExpanded && isRemovable && !isDisabled}
      data-testid={dataTestId}
      onClick={(ev) => {
        const target = ev.target as HTMLElement;
        const element = target.parentElement;

        if (element?.dataset?.testid === 'bik-info-action' || target?.dataset?.testid === 'position-pro-view') {
          return;
        }

        if (isExpanded) {
          return;
        }

        return !isDisabled && isRemovable && onCheckboxChange(item);
      }}
    >
      <Grid size={{ xs: 3, md: 6 }}>
        <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
          {item.subject}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {item.typeDescription}
        </Typography>
      </Grid>

      <Grid size={{ xs: 1 }} sx={alignCenter}>
        <CircleIcon htmlColor={debtInfo.color} />
      </Grid>
      <Grid size={{ xs: 1 }} sx={alignCenter}>
        {debtInfo.text}
      </Grid>
      <Grid size={{ xs: 3, md: 2 }} sx={alignCenter}>
        {isRemovable && debtInfo.isRecommendedToRemove ? 'Tak' : 'Nie'}
      </Grid>
      {isRemovable && (
        <>
          <Grid size={{ xs: 2, md: 1 }} sx={alignCenter}>
            <FormControlLabel
              control={
                <Switch
                  disabled={!isRemovable || isDisabled}
                  checked={isChecked}
                  onChange={() => onCheckboxChange(item)}
                  color="success"
                  size="medium"
                  inputProps={{ 'aria-label': `checkbox for request ${item.id}` }}
                />
              }
              label=""
            />
          </Grid>
          <Grid size={{ xs: 2, md: 1 }} sx={alignCenter}>
            {item.discountPrice && item.discountPrice !== item.price ? (
              <div>
                <Typography variant="body2" sx={{ textDecoration: 'line-through', color: theme.palette.text.disabled }}>
                  {item.price} zł
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold', color: theme.palette.error.main }}>
                  {item.discountPrice} zł {SmallInfoIcon(debtInfo.isInfoIconVisible)}
                </Typography>
              </div>
            ) : (
              <Typography variant="body1">
                {item.price} zł {SmallInfoIcon(debtInfo.isInfoIconVisible)}
              </Typography>
            )}
          </Grid>
        </>
      )}
      <Grid size={{ xs: 12 }}>{RowDetailsIcon({ isExpanded, toggleExpand })}</Grid>
      <Grid size={{ xs: 12 }}>
        <Collapse in={isExpanded}>
          <Box
            sx={{
              marginTop: 1,
              paddingTop: 1,
              borderTop: `2px solid ${theme.palette.divider}`,
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
              Szczegółowe informacje:
            </Typography>

            <Typography variant="body2" color="text.primary">
              <strong>
                {item.consentText || 'Brak informacji o aktualnej zgodzie na przetwarzanie informacji o zobowiązaniu.'}
              </strong>
            </Typography>
          </Box>

          <BIKItemCard item={item} />

          <ProDetailsToggle
            isExpanded={isExpanded}
            bikId={bikId}
            positionId={item.id}
            startedAt={item.signedOn}
            finishedAt={item.finishedAt}
          />
        </Collapse>
      </Grid>
    </BIKSection>
  );
};

import { useEffect, useState } from 'react';
import { BIKSummary } from 'src/types/BIKSummary';
import { AddDetailsSchema } from './form.zod';

export type AutocompleteDataProps = { nip: string; pesel: string; fullName: string };

type Props = {
  summaryData?: BIKSummary;
  setData: ({ nip, pesel, fullName }: AutocompleteDataProps) => void;
  setCompleteData: (details: AddDetailsSchema) => void;
};

export const useAutocomplateData = ({ summaryData, setData, setCompleteData }: Props) => {
  const [message, setMessage] = useState('');

  const clearMessage = () => {
    setMessage('');
  };

  useEffect(() => {
    if (!summaryData) {
      return;
    }

    if (summaryData?.contact) {
      setCompleteData({
        companyData: summaryData.company!,
        privateData: summaryData.customer,
        supportData: summaryData.contact,
        mainData: {
          email: summaryData.company?.invoiceEmail || summaryData.customer?.email || '',
          phone: summaryData.company?.phone || summaryData.customer?.phone || '',
        },
        marketingConsents: {
          emailConsent: true,
          phoneConsent: true,
        },
      });
      return;
    }

    let isAvailableToSend = false;

    const autocompleteData: AutocompleteDataProps = {
      fullName: '',
      nip: '',
      pesel: '',
    };

    if (summaryData?.company?.nip && summaryData.company.nip.length === 10) {
      isAvailableToSend = true;
      autocompleteData.nip = summaryData.company.nip;
    }

    if (summaryData?.customer?.pesel && summaryData.customer.pesel.length === 11) {
      isAvailableToSend = true;
      autocompleteData.pesel = summaryData.customer.pesel;
    }

    if (summaryData?.customer?.fullName) {
      isAvailableToSend = true;
      autocompleteData.fullName = summaryData?.customer?.fullName;
    }

    if (isAvailableToSend) {
      setData(autocompleteData);
      setMessage('Uzupełniliśmy niektóre dane na podstawie wgranego raportu — prosimy o sprawdzenie ich poprawności.');

      const timeout = setTimeout(() => {
        setMessage('');
      }, 5000);

      return () => {
        if (timeout) clearTimeout(timeout);
      };
    }
  }, [summaryData]);

  return { autocompleteMessage: message, clearMessage };
};

import { CompanyBIKSummary } from 'src/types/CompanyBIKSummary.type';

export const mockedBIKResponse: CompanyBIKSummary = {
  id: 'mockedBIKID',
  status: 'DOCUMENTS_UPLOADED',
  discountCode: null,
  orderId: 'ZAM00000000',
  customer: {
    generatedAt: '2024-09-26T10:54:00',
    name: 'BIK - prywatny - finansowania  pozabankowe i zapytania kredytowe  (proponowana nazwa',
    pesel: '00000000000',
    items: [
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: '08008c1b-561e-4b78-bb56-bd024dcd78f2',
        type: 'NON_BANK',
        bikReportType: 'CUSTOMER',
        typeDescription: 'Pożyczka',
        subject: 'TUSUMI SP. Z O.O.',
        signedOn: '2024-09-23',
        finishedAt: null,
        unWiborValue: 3000,
        isOpen: true,
        status: 'GREEN',
        debt: 0,
        longestDebt: 0,
        longestDebtAt: '2024-09-24',
        enableToRemove: false,
        price: 299,
        discountPrice: 299,
      },
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: '7c97dda9-faa4-4429-913b-c35b9b519ccc',
        type: 'NON_BANK',
        bikReportType: 'CUSTOMER',
        typeDescription: 'Pożyczka',
        subject: 'CAPITAL SERVICE S.A.',
        signedOn: '2024-09-21',
        finishedAt: null,
        isOpen: true,
        status: 'GREEN',
        debt: 0,
        longestDebt: 0,
        longestDebtAt: '2024-10-06',
        enableToRemove: false,
        price: 299,
        discountPrice: 299,
      },
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: '006deb9d-db57-465c-a161-e8c3afe8ee4e',
        type: 'BANK',
        bikReportType: 'CUSTOMER',
        typeDescription: 'Kredyt gotówkowy, pożyczka bankowa',
        subject: 'PKO BP 6 O.ŁÓDZ',
        signedOn: '2022-03-15',
        finishedAt: null,
        unWiborValue: 3500,
        isOpen: true,
        status: 'YELLOW',
        debt: 0,
        longestDebt: 10,
        longestDebtAt: '2022-08-22',
        enableToRemove: false,
        price: 299,
        discountPrice: 299,
      },
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: '006deb9d-db57-465c-a161-e8c3afe8ee4e',
        type: 'BANK',
        bikReportType: 'CUSTOMER',
        typeDescription: 'Kredyt gotówkowy, pożyczka bankowa',
        subject: 'PKO BP 6 O.ŁÓDZ',
        signedOn: '2022-01-05',
        unWiborValue: 1500,
        finishedAt: null,
        isOpen: true,
        status: 'YELLOW',
        debt: 0,
        longestDebt: 4,
        longestDebtAt: '2023-01-09',
        enableToRemove: false,
        price: 299,
        discountPrice: 299,
      },
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: '006deb9d-db57-465c-a161-e8c3afe8ee4e',
        type: 'BANK',
        bikReportType: 'CUSTOMER',
        typeDescription: 'Kredyt gotówkowy, pożyczka bankowa',
        subject: 'PKO BP 6 O.ŁÓDZ',
        signedOn: '2021-12-03',
        finishedAt: null,
        unWiborValue: 2806,
        isOpen: true,
        status: 'YELLOW',
        debt: 539,
        longestDebt: 5,
        longestDebtAt: '2022-12-08',
        enableToRemove: false,
        price: 299,
        discountPrice: 299,
      },
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: '006deb9d-db57-465c-a161-e8c3afe8ee4e',
        type: 'BANK',
        bikReportType: 'CUSTOMER',
        typeDescription: 'Kredyt gotówkowy, pożyczka bankowa',
        subject: 'PKO BP 6 O.ŁÓDZ',
        signedOn: '2021-09-01',
        finishedAt: null,
        isOpen: true,
        status: 'YELLOW',
        debt: 0,
        longestDebt: 10,
        longestDebtAt: '2022-08-22',
        enableToRemove: false,
        price: 299,
        discountPrice: 299,
      },
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: '9724d4ff-fe65-4533-a0a0-a214674671ca',
        type: 'BANK',
        bikReportType: 'CUSTOMER',
        typeDescription: 'Kredyt na zakup towarów i usług',
        subject: 'IKANO BANK O.POLSKA',
        signedOn: '2021-10-01',
        finishedAt: '2021-12-13',
        isOpen: false,
        status: 'YELLOW',
        debt: 0,
        longestDebt: 1,
        longestDebtAt: '2021-12-06',
        enableToRemove: true,
        price: 199,
        discountPrice: 199,
      },
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: '9724d4ff-fe65-4533-a0a0-a214674671ca',
        type: 'BANK',
        bikReportType: 'CUSTOMER',
        typeDescription: 'Kredyt na zakup towarów i usług',
        subject: 'IKANO BANK O.POLSKA',
        signedOn: '2020-07-20',
        finishedAt: '2021-09-09',
        isOpen: false,
        status: 'BLACK',
        debt: 0,
        longestDebt: 63,
        longestDebtAt: '2021-01-07',
        enableToRemove: true,
        price: 199,
        discountPrice: 199,
      },
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: 'f4e96e84-04e5-465d-8ea6-07341937f2ba',
        type: 'NON_BANK',
        bikReportType: 'CUSTOMER',
        typeDescription: 'Pożyczka',
        subject: 'PROVIDENT POLSKA',
        signedOn: '2022-09-17',
        finishedAt: '2024-02-13',
        isOpen: false,
        status: 'RED',
        debt: 0,
        longestDebt: 51,
        longestDebtAt: '2023-05-31',
        enableToRemove: true,
        price: 199,
        discountPrice: 199,
      },
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: '0027945d-4b07-4393-bb3c-b7096c5a0a77',
        type: 'NON_BANK',
        bikReportType: 'CUSTOMER',
        typeDescription: 'Pożyczka',
        subject: 'WONGA.PL',
        signedOn: '2021-03-16',
        finishedAt: '2021-08-18',
        isOpen: false,
        status: 'GREEN',
        debt: 0,
        longestDebt: 0,
        longestDebtAt: '2021-08-21',
        enableToRemove: true,
        price: 199,
        discountPrice: 199,
      },
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: '006deb9d-db57-465c-a161-e8c3afe8ee4e',
        type: 'BANK',
        bikReportType: 'CUSTOMER',
        typeDescription: 'Kredyt gotówkowy, pożyczka bankowa',
        subject: 'PKO BP 6 O.ŁÓDZ',
        signedOn: '2023-01-25',
        finishedAt: '2024-02-21',
        isOpen: false,
        status: 'YELLOW',
        debt: 0,
        longestDebt: 4,
        longestDebtAt: '2023-05-22',
        enableToRemove: true,
        price: 199,
        discountPrice: 199,
      },
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: '8500c876-09f3-4028-a06f-3e4744e97219',
        type: 'BANK',
        bikReportType: 'CUSTOMER',
        typeDescription: 'Kredyt gotówkowy, pożyczka bankowa',
        subject: 'MBANK WYDZIAŁ BANKOWOŚCI ELEKTRONICZNEJ',
        signedOn: '2014-08-13',
        finishedAt: '2021-08-16',
        isOpen: false,
        status: 'RED',
        debt: 0,
        longestDebt: 50,
        longestDebtAt: '2018-04-06',
        enableToRemove: true,
        price: 199,
        discountPrice: 199,
      },
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: '8500c876-09f3-4028-a06f-3e4744e97219',
        type: 'BANK',
        bikReportType: 'CUSTOMER',
        typeDescription: 'Karta kredytowa',
        subject: 'MBANK WYDZIAŁ BANKOWOŚCI ELEKTRONICZNEJ',
        signedOn: '2013-11-07',
        finishedAt: '2024-07-11',
        isOpen: false,
        status: 'BLACK',
        debt: 0,
        longestDebt: 87,
        longestDebtAt: '2018-05-03',
        enableToRemove: true,
        price: 199,
        discountPrice: 199,
      },
    ],
    requests: [
      {
        id: 'test',
        date: '2024-09-26T10:54:00',
        companyName: 'SMARTNEY GRUPA ONEY S.A.',
        creditorId: 'ec38949e-232c-4725-b482-6b8b4e3d4aba',
        commitment: 'Kredyt na zakup towarów i usług',
        amount: 80000,
        skdValue: 5300,
        unWiborValue: 7200,
        currency: 'PLN',
        enableToRemove: true,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2024-09-23T12:36:00',
        companyName: 'TIMBEX SP. Z O.O.',
        creditorId: 'c8f27ab4-306f-402d-8f66-3dc0b7b245ec',
        commitment: 'Kredyt na zakup towarów i usług',
        amount: 4000,
        unWiborValue: 320,
        currency: 'PLN',
        enableToRemove: true,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2024-09-21T11:58:00',
        companyName: 'WONGA.PL',
        creditorId: '0027945d-4b07-4393-bb3c-b7096c5a0a77',
        commitment: 'Kredyt na zakup towarów i usług',
        amount: 1000,
        skdValue: 200,
        unWiborValue: 80,
        currency: 'PLN',
        enableToRemove: true,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2024-09-21T11:32:00',
        companyName: 'DUCATOS SP Z.O.O.',
        creditorId: 'ef7d66b5-b419-4f98-98dd-2ded5a060f17',
        commitment: 'Kredyt gotówkowy / pożyczka gotówkowa',
        amount: 5000,
        currency: 'PLN',
        enableToRemove: true,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2024-09-21T10:16:00',
        companyName: 'CAPITAL SERVICE S.A.',
        creditorId: '7c97dda9-faa4-4429-913b-c35b9b519ccc',
        commitment: 'Kredyt na zakup towarów i usług',
        amount: 3500,
        currency: 'PLN',
        enableToRemove: true,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2024-09-13T09:11:00',
        companyName: 'SANTANDER CONSUMER BANK',
        creditorId: '2a06c638-8761-4329-b34a-e279cdf7b54c',
        commitment: 'Kredyt gotówkowy / pożyczka gotówkowa',
        amount: 5000,
        unWiborValue: 410,
        currency: 'PLN',
        enableToRemove: false,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2024-09-12T13:26:00',
        companyName: 'SMARTNEY GRUPA ONEY S.A.',
        creditorId: 'ec38949e-232c-4725-b482-6b8b4e3d4aba',
        commitment: 'Kredyt na zakup towarów i usług',
        amount: 150000,
        skdValue: 17300,
        unWiborValue: 12303,
        currency: 'PLN',
        enableToRemove: true,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2024-08-31T11:43:00',
        companyName: 'VELOBANK',
        creditorId: '739e880c-68e2-426b-92d8-d366c98bcb68',
        commitment: 'Kredyt gotówkowy / pożyczka gotówkowa',
        amount: 80000,
        skdValue: 8500,
        currency: 'PLN',
        enableToRemove: false,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2024-08-30T17:32:00',
        companyName: 'PROVIDENT POLSKA',
        creditorId: 'f4e96e84-04e5-465d-8ea6-07341937f2ba',
        commitment: 'Kredyt gotówkowy / pożyczka gotówkowa',
        amount: 30000,
        currency: 'PLN',
        enableToRemove: true,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2024-08-29T15:52:00',
        companyName: 'SANTANDER CONSUMER BANK',
        creditorId: '2a06c638-8761-4329-b34a-e279cdf7b54c',
        commitment: 'Kredyt gotówkowy / pożyczka gotówkowa',
        amount: 50000,
        unWiborValue: 4111,
        currency: 'PLN',
        enableToRemove: false,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2024-08-27T00:46:00',
        companyName: 'BANK HANDLOWY CENTRALA PION BANKOWOŚCI DETALICZNEJ',
        creditorId: '276582fe-a451-4a06-aa8d-a1c67147a42d',
        commitment: 'Kredyt gotówkowy / pożyczka gotówkowa',
        amount: 50000,
        skdValue: 430,
        currency: 'PLN',
        enableToRemove: false,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2024-08-22T22:00:00',
        companyName: 'REVOLUT BANK UAB',
        creditorId: 'e89125ba-2418-4ae9-b84c-b878a52d5158',
        commitment: 'Kredyt gotówkowy / pożyczka gotówkowa',
        amount: 150000,
        unWiborValue: 14200,
        currency: 'PLN',
        enableToRemove: false,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2024-08-21T08:26:00',
        companyName: 'CAPITAL SERVICE S.A.',
        creditorId: '7c97dda9-faa4-4429-913b-c35b9b519ccc',
        commitment: 'Kredyt na zakup towarów i usług',
        amount: 10000,
        currency: 'PLN',
        enableToRemove: true,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2024-08-21T08:04:00',
        companyName: 'EASY PAYMENT SERVICES OOD',
        creditorId: 'b2c3016a-d90d-467c-a695-0c363c5b98fa',
        commitment: 'Karta kredytowa',
        amount: 4000,
        skdValue: 200,
        currency: 'PLN',
        enableToRemove: false,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2024-08-21T07:58:00',
        companyName: 'AASA POLSKA',
        creditorId: '598892b9-de0a-4e3d-a1dc-e959bd6bc319',
        commitment: 'Kredyt na zakup towarów i usług',
        amount: 25000,
        currency: 'PLN',
        enableToRemove: true,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2024-08-21T07:57:00',
        companyName: 'WONGA.PL',
        creditorId: '0027945d-4b07-4393-bb3c-b7096c5a0a77',
        commitment: 'Kredyt na zakup towarów i usług',
        amount: 15000,
        currency: 'PLN',
        enableToRemove: true,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2024-08-21T07:57:00',
        companyName: 'PAYTREE SA',
        creditorId: '3c1a48bb-a33a-4755-a931-746aae70f867',
        commitment: 'Kredyt gotówkowy / pożyczka gotówkowa',
        amount: 20000,
        unWiborValue: 1550,
        skdValue: 2200,
        currency: 'PLN',
        enableToRemove: true,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2024-08-21T07:57:00',
        companyName: 'PAYTREE SA',
        creditorId: '3c1a48bb-a33a-4755-a931-746aae70f867',
        commitment: 'Kredyt gotówkowy / pożyczka gotówkowa',
        amount: 20000,
        skdValue: 2400,
        currency: 'PLN',
        enableToRemove: true,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2024-08-21T07:57:00',
        companyName: 'SMARTNEY GRUPA ONEY S.A.',
        creditorId: 'ec38949e-232c-4725-b482-6b8b4e3d4aba',
        commitment: 'Kredyt na zakup towarów i usług',
        amount: 150000,
        skdValue: 17300,
        currency: 'PLN',
        enableToRemove: true,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2024-08-21T07:57:00',
        companyName: 'AIQLABS SP. Z O.O.',
        creditorId: '57e545f7-1597-4a3d-aeb8-660654daa84f',
        commitment: 'Kredyt gotówkowy / pożyczka gotówkowa',
        amount: 30000,
        currency: 'PLN',
        enableToRemove: false,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2024-08-19T15:36:00',
        companyName: 'ALIOR BANK',
        creditorId: '7ab6fb1e-c977-4e78-a5d0-aa19fdf49d80',
        commitment: 'Kredyt gotówkowy / pożyczka gotówkowa',
        amount: 1000,
        currency: 'PLN',
        enableToRemove: false,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2024-06-21T10:22:00',
        companyName: 'ALLEGRO PAY SP. Z O.O.',
        creditorId: 'fb3ad795-4e79-4be2-8f75-3ceed88413ef',
        commitment: 'Kredyt na zakup towarów i usług',
        amount: 300,
        unWiborValue: 22,
        skdValue: 30,
        currency: 'PLN',
        enableToRemove: true,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2024-06-21T10:22:00',
        companyName: 'ALLEGRO PAY SP. Z O.O.',
        creditorId: 'fb3ad795-4e79-4be2-8f75-3ceed88413ef',
        commitment: 'Kredyt na zakup towarów i usług',
        amount: 300,
        skdValue: 86,
        currency: 'PLN',
        enableToRemove: true,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2024-02-25T09:35:00',
        companyName: 'ALIOR BANK',
        creditorId: '7ab6fb1e-c977-4e78-a5d0-aa19fdf49d80',
        commitment: 'Kredyt gotówkowy / pożyczka gotówkowa',
        amount: 1000,
        currency: 'PLN',
        enableToRemove: false,
        price: 99,
        discountPrice: 99,
      },
    ],
  },
  company: {
    generatedAt: '2024-09-26T10:54:00',
    name: 'BIK - master - leasingi #1',
    nip: '0000000000',
    items: [
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: 'b6c48dc5-496d-49f2-82b5-a4412c7ea162',
        type: 'BANK',
        bikReportType: 'COMPANY',
        typeDescription: 'Kredyt hipoteczny lub pożyczka hipoteczna',
        subject: 'BS W PONIATOWEJ',
        signedOn: '2016-03-31',
        finishedAt: null,
        unWiborValue: 55000,
        isOpen: true,
        status: 'YELLOW',
        debt: 0,
        longestDebt: 7,
        longestDebtAt: '2022-12-07',
        enableToRemove: false,
        price: 299,
        discountPrice: 299,
      },
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: 'b6c48dc5-496d-49f2-82b5-a4412c7ea162',
        type: 'BANK',
        bikReportType: 'COMPANY',
        typeDescription: 'Kredyt w rachunku bieżącym lub limit debetowy',
        subject: 'BS W PONIATOWEJ',
        signedOn: '2022-11-24',
        finishedAt: null,
        isOpen: true,
        status: 'GREEN',
        debt: 0,
        longestDebt: 0,
        longestDebtAt: '2023-07-05',
        enableToRemove: false,
        price: 299,
        discountPrice: 299,
      },
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: '9602df33-6940-4897-9dfc-ab7f88ffbe16',
        type: 'LEASING',
        bikReportType: 'COMPANY',
        typeDescription: 'Leasing',
        subject: 'VB Leasing S.A.',
        signedOn: '2019-04-18',
        finishedAt: null,
        unWiborValue: 21200,
        isOpen: true,
        status: 'RED',
        debt: 0,
        longestDebt: 51,
        longestDebtAt: '2022-03-07',
        enableToRemove: true,
        price: 299,
        discountPrice: 299,
      },
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: 'faad8304-b0c3-47a7-8eea-3401ed9a851c',
        type: 'LEASING',
        bikReportType: 'COMPANY',
        typeDescription: 'Leasing',
        subject: 'BNP PARIBAS LEASING SERVICES SP. Z O.O.',
        signedOn: '2020-11-19',
        finishedAt: null,
        isOpen: true,
        status: 'RED',
        debt: 0,
        longestDebt: 49,
        longestDebtAt: '2022-11-30',
        enableToRemove: true,
        price: 299,
        discountPrice: 299,
      },
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: '9d32cb61-9327-4295-9245-11414ad64efe',
        type: 'LEASING',
        bikReportType: 'COMPANY',
        typeDescription: 'Leasing',
        subject: 'BNP Paribas Lease Group Sp. z o.o.',
        signedOn: '2021-03-22',
        finishedAt: null,
        isOpen: true,
        status: 'RED',
        debt: 17877,
        longestDebt: 49,
        longestDebtAt: '2022-11-30',
        enableToRemove: true,
        price: 299,
        discountPrice: 299,
      },
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: '109f9e84-342a-4735-a353-bf7f720e4e96',
        type: 'LEASING',
        bikReportType: 'COMPANY',
        typeDescription: 'Leasing',
        subject: 'MERCEDES-BENZ LEASING POLSKA SP Z O.O.',
        signedOn: '2023-04-06',
        finishedAt: null,
        unWiborValue: 12400,
        isOpen: true,
        status: 'YELLOW',
        debt: 0,
        longestDebt: 18,
        longestDebtAt: '2023-07-03',
        enableToRemove: true,
        price: 299,
        discountPrice: 299,
      },
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: '76c66b8d-ef89-40a7-ae28-e31f7271c9b3',
        type: 'LEASING',
        bikReportType: 'COMPANY',
        typeDescription: 'Leasing',
        subject: 'Alior Leasing Sp. Z o.o.',
        signedOn: '2018-11-23',
        finishedAt: null,
        isOpen: true,
        status: 'RED',
        debt: 8732,
        longestDebt: 36,
        longestDebtAt: '2023-04-30',
        enableToRemove: true,
        price: 299,
        discountPrice: 299,
      },
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: '9602df33-6940-4897-9dfc-ab7f88ffbe16',
        type: 'LEASING',
        bikReportType: 'COMPANY',
        typeDescription: 'Leasing',
        subject: 'VB Leasing S.A.',
        signedOn: '2018-08-29',
        finishedAt: null,
        unWiborValue: 8700,
        isOpen: true,
        status: 'RED',
        debt: 0,
        longestDebt: 44,
        longestDebtAt: '2019-03-06',
        enableToRemove: true,
        price: 299,
        discountPrice: 299,
      },
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: '9602df33-6940-4897-9dfc-ab7f88ffbe16',
        type: 'LEASING',
        bikReportType: 'COMPANY',
        typeDescription: 'Leasing',
        subject: 'VB Leasing S.A.',
        signedOn: '2019-07-11',
        finishedAt: null,
        unWiborValue: 2222,
        isOpen: true,
        status: 'RED',
        debt: 0,
        longestDebt: 38,
        longestDebtAt: '2022-06-07',
        enableToRemove: true,
        price: 299,
        discountPrice: 299,
      },
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: 'b6c48dc5-496d-49f2-82b5-a4412c7ea162',
        type: 'BANK',
        bikReportType: 'COMPANY',
        typeDescription: 'Kredyt inwestycyjny',
        subject: 'BS W PONIATOWEJ',
        signedOn: '2019-03-04',
        finishedAt: '2022-06-13',
        isOpen: false,
        status: 'GREEN',
        debt: 0,
        longestDebt: 0,
        longestDebtAt: '2022-06-13',
        enableToRemove: false,
        price: 199,
        discountPrice: 199,
      },
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: 'b6c48dc5-496d-49f2-82b5-a4412c7ea162',
        type: 'BANK',
        bikReportType: 'COMPANY',
        typeDescription: 'Kredyt inwestycyjny',
        subject: 'BS W PONIATOWEJ',
        signedOn: '2018-10-23',
        finishedAt: '2022-06-13',
        isOpen: false,
        status: 'GREEN',
        debt: 0,
        longestDebt: 0,
        longestDebtAt: '2022-06-13',
        enableToRemove: false,
        price: 199,
        discountPrice: 199,
      },
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: 'b6c48dc5-496d-49f2-82b5-a4412c7ea162',
        type: 'BANK',
        bikReportType: 'COMPANY',
        typeDescription: 'Kredyt w rachunku bieżącym lub limit debetowy',
        subject: 'BS W PONIATOWEJ',
        signedOn: '2021-05-12',
        finishedAt: '2022-11-23',
        isOpen: false,
        status: 'GREEN',
        debt: 0,
        longestDebt: 0,
        longestDebtAt: '2022-11-23',
        enableToRemove: false,
        price: 199,
        discountPrice: 199,
      },
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: 'b6c48dc5-496d-49f2-82b5-a4412c7ea162',
        type: 'BANK',
        bikReportType: 'COMPANY',
        typeDescription: 'Kredyt w rachunku bieżącym lub limit debetowy',
        subject: 'BS W PONIATOWEJ',
        signedOn: '2019-03-04',
        finishedAt: '2019-04-30',
        isOpen: false,
        status: 'GREEN',
        debt: 0,
        longestDebt: 0,
        longestDebtAt: '2019-04-30',
        enableToRemove: false,
        price: 199,
        discountPrice: 199,
      },
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: '9602df33-6940-4897-9dfc-ab7f88ffbe16',
        type: 'LEASING',
        bikReportType: 'COMPANY',
        typeDescription: 'Leasing',
        subject: 'VB Leasing S.A.',
        signedOn: '2016-11-10',
        finishedAt: '2020-10-21',
        isOpen: false,
        status: 'RED',
        debt: 0,
        longestDebt: 40,
        longestDebtAt: '2017-08-02',
        enableToRemove: true,
        price: 199,
        discountPrice: 199,
      },
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: '76c66b8d-ef89-40a7-ae28-e31f7271c9b3',
        type: 'LEASING',
        bikReportType: 'COMPANY',
        typeDescription: 'Leasing',
        subject: 'Alior Leasing Sp. Z o.o.',
        signedOn: '2018-05-16',
        finishedAt: '2023-04-11',
        isOpen: false,
        status: 'YELLOW',
        debt: 0,
        longestDebt: 15,
        longestDebtAt: '2022-09-30',
        enableToRemove: true,
        price: 199,
        discountPrice: 199,
      },
      {
        startDebt: 0,
        id: 'test',
        consentText: '',
        installment: 0,
        left: 0,
        creditorId: '9602df33-6940-4897-9dfc-ab7f88ffbe16',
        type: 'LEASING',
        bikReportType: 'COMPANY',
        typeDescription: 'Leasing',
        subject: 'VB Leasing S.A.',
        signedOn: '2020-01-28',
        finishedAt: '2023-02-02',
        isOpen: false,
        status: 'RED',
        debt: 0,
        longestDebt: 49,
        longestDebtAt: '2021-08-03',
        enableToRemove: true,
        price: 199,
        discountPrice: 199,
      },
    ],
    requests: [
      {
        id: 'test',
        date: '2023-07-04T12:17:00',
        companyName: 'BS W PONIATOWEJ',
        creditorId: 'b6c48dc5-496d-49f2-82b5-a4412c7ea162',
        commitment: 'Gwarancja bankowa',
        amount: 392985,
        skdValue: 28000,
        currency: 'PLN',
        enableToRemove: false,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2023-05-30T07:47:00',
        companyName: 'BS W PONIATOWEJ',
        creditorId: 'b6c48dc5-496d-49f2-82b5-a4412c7ea162',
        commitment: 'Kredyt płatniczy w rachunku bieżącym',
        amount: 3300000,
        skdValue: 24320,
        unWiborValue: 32000,
        currency: 'PLN',
        enableToRemove: false,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2023-04-13T08:00:00',
        companyName: 'BNP Paribas Lease Group Sp. z o.o.',
        creditorId: '9d32cb61-9327-4295-9245-11414ad64efe',
        commitment: 'Leasing',
        amount: 280000,
        currency: 'PLN',
        enableToRemove: true,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2023-03-29T08:27:00',
        companyName: 'MERCEDES-BENZ LEASING POLSKA SP Z O.O.',
        creditorId: '109f9e84-342a-4735-a353-bf7f720e4e96',
        commitment: 'Leasing',
        amount: 697236,
        skdValue: 50000,
        currency: 'PLN',
        enableToRemove: true,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2023-03-28T11:28:00',
        companyName: 'Vehis Fin1 Sp. Z o.o.',
        creditorId: 'be892b2a-9f88-4ae9-aee9-4b73628980f2',
        commitment: 'Leasing',
        amount: 488066,
        skdValue: 35090,
        currency: 'PLN',
        enableToRemove: true,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2022-11-08T07:55:00',
        companyName: 'BS W PONIATOWEJ',
        creditorId: 'b6c48dc5-496d-49f2-82b5-a4412c7ea162',
        commitment: 'Kredyt w rachunku bieżącym lub limit debetowy',
        amount: 2300000,
        unWiborValue: 21400,
        currency: 'PLN',
        enableToRemove: false,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2022-10-27T10:01:00',
        companyName: 'BS W BIAŁEJ PODL.',
        creditorId: '793def1a-0413-473e-a742-bddeec2a1c4d',
        commitment: 'Kredyt obrotowy',
        amount: 4000000,
        skdValue: 230652,
        unWiborValue: 350000,
        currency: 'PLN',
        enableToRemove: false,
        price: 99,
        discountPrice: 99,
      },
      {
        id: 'test',
        date: '2022-09-29T10:57:00',
        companyName: 'BS W PONIATOWEJ',
        creditorId: 'b6c48dc5-496d-49f2-82b5-a4412c7ea162',
        commitment: 'Gwarancja bankowa',
        amount: 375600,
        unWiborValue: 32000,
        currency: 'PLN',
        enableToRemove: false,
        price: 99,
        discountPrice: 99,
      },
    ],
  },
};

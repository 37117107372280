import { FunctionComponent, memo } from 'react';
import { RoleType } from 'src/common/roles';
import { Loader } from 'src/components/Loader/Loader';
import { PartnerDetails } from 'src/types/PartnerDetails';
import { Report } from 'src/types/Report';
import { useRowType } from './RowTypes/useRowType';
import { ExpandedContainer, RowContainer, RowHeadingContainer } from './styles';

interface RowElementProps {
  data: Report | PartnerDetails;
  type: RoleType;
  allUsers?: PartnerDetails[];
  refetch: () => void;
}

const RowElement: FunctionComponent<RowElementProps> = ({ data, type, allUsers, refetch }) => {
  const {
    rowLayout,
    rowDetails,
    rowActions,
    isCancelled,
    isCompleted,
    isDraft,
    isAttentionRequired,
    isLoading,
    isRowExpanded,
    loadingText,
    toggleExpanded,
  } = useRowType({
    data,
    type,
    options: { refetch, users: allUsers || [] },
  });

  return (
    <RowContainer data-testid={`row-element`}>
      <RowHeadingContainer
        isCancelled={isCancelled}
        isAttentionRequired={isAttentionRequired}
        isCompleted={isCompleted}
        isExpandable
        isDraft={isDraft}
        onClick={toggleExpanded}
        variant="elevation"
      >
        {rowLayout}
      </RowHeadingContainer>

      {isRowExpanded && (
        <ExpandedContainer data-testid="row-details-section">
          {rowActions && rowActions}
          <Loader
            fullWidth
            isLoading={Boolean(isLoading)}
            size={40}
            my={10}
            circularProgressProps={{
              thickness: 5,
            }}
            label={loadingText}
          />
          {(isLoading === false || isLoading === undefined) && <>{rowDetails}</>}
        </ExpandedContainer>
      )}
    </RowContainer>
  );
};

export default memo(RowElement);

import { Paper } from '@mui/material';
import { CSSObject, styled } from '@mui/material/styles';

interface DarkPanelTruncatedTextProps {
  lines?: number;
}
export const DarkPanelLabel = styled('div')(({ theme }) => ({
  textTransform: 'uppercase',
  fontSize: '0.8rem',
  marginBottom: '0.2rem',
  opacity: theme.palette.mode === 'dark' ? 0.6 : 0.8,
}));

export const LargeDarkPanelLabel = styled(DarkPanelLabel)(({ theme }) => ({
  fontSize: '0.9rem',
  marginBottom: 0,
  opacity: theme.palette.mode === 'dark' ? 0.6 : 0.8,
}));

export const DarkPanelValue = styled('div')(({ theme }) => ({
  fontSize: '1.2rem',
  fontFamily: "'Roboto Mono', monospace",
  color: theme.palette.text.primary,
}));


export const DarkPanelTruncatedValue = styled('div', {
  shouldForwardProp: (prop) => prop !== 'lines',
})<DarkPanelTruncatedTextProps>(({ lines }) => ({
  overflow: 'hidden',
  ...(lines && lines > 1
    ? {
        display: '-webkit-box',
        WebkitLineClamp: lines,
        WebkitBoxOrient: 'vertical',
      }
    : {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        minWidth: 0,
      }),
}));

const annotationStyles = (theme: any): CSSObject => ({
  fontStyle: 'italic',
  fontSize: '0.8rem',
  color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.6)',
  marginBottom: '0.2rem',
});


export const DarkPanelAnnotation = styled('div')(({ theme }) => annotationStyles(theme));


const darkPanelTruncatedTextStyles = (lines?: number): CSSObject => {
  if (!lines || lines === 1) {
    return {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      minWidth: 0,
    };
  }
  return {
    display: '-webkit-box',
    WebkitLineClamp: lines,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  };
};

export const DarkPanelTruncatedAnnotation = styled('div', {
  shouldForwardProp: (prop) => prop !== 'lines',
})<DarkPanelTruncatedTextProps>(({ theme, lines }) => ({
  ...annotationStyles(theme),
  ...darkPanelTruncatedTextStyles(lines),
}));

export const DarkPanelNote = styled('div')(({ theme }) => ({
  padding: '1rem 0.6rem',
  background: theme.palette.mode === 'dark' ? 'rgba(48, 65, 80, 0.2)' : 'rgba(240, 240, 240, 0.8)',
  boxSizing: 'border-box',
  width: '100%',
  border: theme.palette.mode === 'dark'
    ? '1px solid rgba(255, 255, 255, 0.05)'
    : '1px solid rgba(0, 0, 0, 0.1)',
}));

export const DarkPanelNoteValue = styled(DarkPanelNote)(({ theme }) => ({
  fontSize: '0.9rem',
  textTransform: 'none',
  fontFamily: 'inherit',
  lineHeight: '1.2rem',
  color: theme.palette.text.primary,
}));

export const DarkPanelNoteContent = styled(DarkPanelTruncatedValue, {
  shouldForwardProp: (prop) => prop !== 'lines',
})<DarkPanelTruncatedTextProps>(({ theme, lines = 2 }) => ({
  ...annotationStyles(theme),
  ...darkPanelTruncatedTextStyles(lines),
}));

export const DarkPanel = styled(Paper)(({ theme }) => ({
  padding: '1rem',
  height: '100%',
  backgroundColor: theme.palette.mode === 'dark'
    ? theme.palette.background.paper
    : theme.palette.background.default,
}));

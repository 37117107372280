import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Header } from 'src/components/app-toolbar/Header';
import Content from 'src/pages/auth-pages/Content';
import SignInCard from 'src/pages/auth-pages/SignInCard';

const MemoizedContent = React.memo(Content);
const MemoizedSignInCard = React.memo(SignInCard);

export default function AuthPage() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const location = useLocation();
  const theme = useTheme();

  const mainStackStyles = [
    {
      marginTop: { xs: '90px', md: 0 },
      justifyContent: 'space-between',
      height: { xs: 'auto', md: '100%' },
    },
    {
      backgroundImage:
        theme.palette.mode === 'dark'
          ? 'radial-gradient(ellipse at 70% 51%, hsl(210, 40%, 15%), hsl(210, 40%, 10%))'
          : 'radial-gradient(ellipse at 70% 51%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
      backgroundSize: 'cover',
    },
  ];

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const logoutMessage = queryParams.get('message');

    if (logoutMessage) {
      setMessage(logoutMessage);
      setOpen(true);

      const timeout = setTimeout(() => {
        setOpen(false);
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, [location]);

  return (
    <>
      <Header type="back" />
      <Stack direction="column" component="main" sx={mainStackStyles}>
        <Stack
          direction={{ xs: 'column-reverse', md: 'row' }}
          sx={{
            justifyContent: 'center',
            gap: { xs: 6, sm: 12 },
            p: 2,
            m: 'auto',
          }}
        >
          <MemoizedContent />
          <MemoizedSignInCard />
        </Stack>
      </Stack>

      {/* Snackbar for displaying the logout message */}
      <Snackbar open={open} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert
          onClose={() => setOpen(false)}
          severity="info"
          variant="filled"
          sx={{
            width: '100%',
            bgcolor: theme.palette.mode === 'dark' ? 'primary.dark' : 'primary.main', // Adjust Snackbar background
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}

import { DialogContentText, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { memo, useEffect } from 'react';
import { useSelectedService } from 'src/context/useSelectedService';
import { BIKReportType } from 'src/types/BIKReport.type';
import { BIKCompany, BIKPrivate } from 'src/types/CompanyBIKSummary.type';
import { BIKItemContent } from './BIKItemContent';
import { BIKRequestContent } from './BIKRequestContent';
import { BIKItem, GridTitle } from './styles';
import { useCheckedItems } from './useCheckedItems';
import { useCheckedRequests } from './useCheckedRequests';

function BIKReportContent({
  data,
  isDisabled,
  selectedIds,
  type,
  bikId,
  isSelectedAll,
}: {
  data: BIKCompany | BIKPrivate;
  selectedIds: string[];
  type: BIKReportType;
  isSelectedAll?: boolean;
  bikId: string;
  isDisabled: boolean;
}) {
  const { onSelectedBIKRows } = useSelectedService();

  const { requests, checkedRequests, isSelectAllRequestsChecked, handleCheckboxRequestsChange, onChangeAllRequests } =
    useCheckedRequests({
      selectedIds,
      bikRequests: data.requests,
      isSelectedAll: Boolean(isSelectedAll),
      type,
    });
  const { checkedItems, isSelectAllItemsChecked, openItems, closedItems, handleCheckboxItemsChange, onChangeAllItems } =
    useCheckedItems({
      selectedIds,
      bikItems: data.items,
      isSelectedAll: Boolean(isSelectedAll),
      type,
    });

  useEffect(() => {
    if (isSelectedAll === undefined) {
      return;
    }

    if (type === 'COMPANY') {
      onChangeAllItems(isSelectedAll);
      onChangeAllRequests(isSelectedAll);
    } else {
      setTimeout(() => {
        onChangeAllItems(isSelectedAll);
        onChangeAllRequests(isSelectedAll);
      }, 100);
    }
  }, [isSelectedAll, type]);

  useEffect(() => {
    if (!checkedItems.length && !checkedRequests.length) {
      return;
    }

    const items = [...openItems, ...closedItems];

    checkedItems.forEach((checkedItem) => {
      const index = items.findIndex((item) => item.id === checkedItem.id);

      if (index !== -1) {
        checkedItem.discountPrice = items[index].discountPrice;
      }
    });
    checkedRequests.forEach((checkedItem) => {
      const index = requests.findIndex((request) => request.id === checkedItem.id);

      if (index !== -1) {
        checkedItem.discountPrice = requests[index].discountPrice;
      }
    });
    onSelectedBIKRows(checkedItems, checkedRequests, type);
  }, [data, onSelectedBIKRows]);

  useEffect(() => {
    onSelectedBIKRows(checkedItems, checkedRequests, type);
  }, [checkedRequests, onSelectedBIKRows, checkedItems, isSelectedAll]);

  return (
    <DialogContentText component="div">
      <Grid container spacing={1.5}>
        {/* Informacje ogólne */}
        <Grid size={{ xs: 6 }}>
          <BIKItem>
            <Typography variant="body1" data-testid={`${type.toLocaleLowerCase()}-name`}>
              <b>{type === 'COMPANY' ? 'Nazwa firmy' : 'Imię i nazwisko'}:</b> {data.name}
            </Typography>
          </BIKItem>
        </Grid>

        <Grid size={{ xs: 6 }}>
          {'nip' in data && (
            <BIKItem>
              <Typography variant="body1" data-testid={`${type.toLocaleLowerCase()}-nip`}>
                <b>NIP:</b> {data.nip}
              </Typography>
            </BIKItem>
          )}
          {'pesel' in data && (
            <BIKItem>
              <Typography variant="body1" data-testid={`${type.toLocaleLowerCase()}-pesel`}>
                <b>PESEL:</b> {data.pesel || 'brak danych'}
              </Typography>
            </BIKItem>
          )}
        </Grid>

        {!!data?.items?.length && (
          <>
            <Grid size={{ xs: 12 }}>
              <BIKItem>
                <Typography variant="h5">Zobowiązania kredytowe</Typography>
              </BIKItem>
            </Grid>

            <Grid size={{ xs: 12 }}>
              <BIKItem>
                <Typography variant="body1">
                  Ilość możliwa do usunięcia:{' '}
                  <span data-testid={`${type.toLocaleLowerCase()}-credit-requests`}>
                    {data.items.filter((item) => item?.enableToRemove).length}
                  </span>
                  {!isDisabled && !!data.items.filter((item) => item?.enableToRemove).length && (
                    <a
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => onChangeAllItems()}
                    >
                      <b>{isSelectAllItemsChecked ? ' - Odznacz wszystkie' : ' - Zaznacz wszystkie'}</b>
                    </a>
                  )}
                </Typography>
              </BIKItem>
            </Grid>

            {!!openItems.length && (
              <>
                <Grid size={{ xs: 12 }}>
                  <Typography variant="h6">Zobowiązania kredytowe w trakcie spłaty:</Typography>
                </Grid>
                <>
                  <GridTitle size={{ xs: 3, md: 6 }}>Typ</GridTitle>
                  <GridTitle
                    size={{ xs: 2, md: 2 }}
                    sx={{
                      alignContent: 'center',
                      textAlign: 'center',
                    }}
                  >
                    Historia spłacania
                  </GridTitle>
                  <GridTitle
                    size={{ xs: 3, md: 2 }}
                    sx={{
                      alignContent: 'center',
                      textAlign: 'center',
                    }}
                  >
                    Rekomendowane do usunięcia
                  </GridTitle>
                  <GridTitle size={{ xs: 2, md: 1 }}>Usuwam</GridTitle>
                  <GridTitle size={{ xs: 2, md: 1 }}>Cena netto</GridTitle>
                </>

                {openItems.map((item) => (
                  <BIKItemContent
                    item={item}
                    bikId={bikId}
                    isDisabled={isDisabled}
                    dataTestId={`${type.toLocaleLowerCase()}-credit-in-progress`}
                    key={item.id}
                    isChecked={!!checkedItems.find((checkedItem) => checkedItem.id === item.id)}
                    onCheckboxChange={handleCheckboxItemsChange}
                  />
                ))}
              </>
            )}

            {!!closedItems.length && (
              <>
                <Grid size={{ xs: 12 }}>
                  <Typography variant="h6">
                    Zobowiązania kredytowe - zamknięte (w ciągu ostatnich 60 miesięcy):
                  </Typography>
                </Grid>
                <>
                  <GridTitle size={{ xs: 3, md: 6 }}>Typ</GridTitle>
                  <GridTitle
                    size={{ xs: 2, md: 2 }}
                    sx={{
                      alignContent: 'center',
                      textAlign: 'center',
                    }}
                  >
                    Historia spłacania
                  </GridTitle>
                  <GridTitle
                    size={{ xs: 3, md: 2 }}
                    sx={{
                      alignContent: 'center',
                      textAlign: 'center',
                    }}
                  >
                    Rekomendowane do usunięcia
                  </GridTitle>
                  <GridTitle size={{ xs: 2, md: 1 }}>Usuwam</GridTitle>
                  <GridTitle size={{ xs: 2, md: 1 }}>Cena netto</GridTitle>
                </>
                {closedItems.map((item) => (
                  <BIKItemContent
                    item={item}
                    bikId={bikId}
                    isDisabled={isDisabled}
                    dataTestId={`${type.toLocaleLowerCase()}-credit-closed`}
                    key={item.id}
                    isChecked={!!checkedItems.find((checkedItem) => checkedItem.id === item.id)}
                    onCheckboxChange={handleCheckboxItemsChange}
                  />
                ))}
              </>
            )}
          </>
        )}

        {/* Sekcja zapytań do banków */}
        {!!requests.length && (
          <>
            <Grid size={{ xs: 12 }} className="fs-unmask">
              <BIKItem>
                <Typography variant="h5">Zapytania kredytowe do BIK:</Typography>
              </BIKItem>
            </Grid>

            <Grid size={{ xs: 12 }} className="fs-unmask">
              <BIKItem>
                <Typography variant="body1">
                  Ilość możliwa do usunięcia:
                  <span data-testid={`${type.toLocaleLowerCase()}-bik-requests`}>
                    {requests.filter((request) => request?.enableToRemove).length}
                  </span>
                  {!isDisabled && !!requests.filter((request) => request?.enableToRemove).length && (
                    <a
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => onChangeAllRequests()}
                    >
                      <b>{isSelectAllRequestsChecked ? ' - Odznacz wszystkie' : ' - Zaznacz wszystkie'}</b>
                    </a>
                  )}
                </Typography>
              </BIKItem>
            </Grid>
            <>
              <GridTitle size={{ xs: 5, md: 7 }}>Typ</GridTitle>
              <GridTitle size={{ xs: 3, md: 3 }}>Rekomendowane do usunięcia</GridTitle>
              <GridTitle size={{ xs: 2, md: 1 }}>Usuwam</GridTitle>
              <GridTitle size={{ xs: 2, md: 1 }}>Cena netto</GridTitle>
            </>
            {requests.map((request) => (
              <BIKRequestContent
                dataTestId={`${type.toLocaleLowerCase()}-bik-request`}
                request={request}
                isDisabled={isDisabled}
                key={request.id}
                isChecked={!!checkedRequests.find((item) => item.id === request.id)}
                onCheckboxChange={handleCheckboxRequestsChange}
              />
            ))}
          </>
        )}
      </Grid>
    </DialogContentText>
  );
}

export default memo(BIKReportContent);

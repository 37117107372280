import { Box, CircularProgress, CssBaseline } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import '@fontsource/inter';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { PrivateRoute } from './components/PrivateRoute.tsx';
import './index.css';
import AddDetails from './sites/BIKProcessSites/AddDetails.tsx';
import ProcessBIK from './sites/BIKProcessSites/ProcessBIK.tsx';
import ShowBIKRaport from './sites/BIKProcessSites/ShowBIKRaport.tsx';
import Summary from './sites/BIKProcessSites/Summary.tsx';
import ThankYou from './sites/BIKProcessSites/ThankYou.tsx';
import CheckDefaultPage from './sites/commonSites/CheckDefaultPage.tsx';
import NotFoundPage from './sites/commonSites/NotFoundPage.tsx';
import ManagerPartnersList from './sites/ManagerSites/ManagerPartnersList.tsx';
import ManagerReportsList from './sites/ManagerSites/ManagerReportsList.tsx';
import ManagerStatistics from './sites/ManagerSites/ManagerStatistics.tsx';
import PartnerDashboard from './sites/PartnerSites/PartnerDashboard.tsx';
import PartnerStatistics from './sites/PartnerSites/PartnerStatistics.tsx';
import AuthPage from './sites/PublicSites/AuthPage.tsx';
import FAQ from './sites/PublicSites/FAQ.tsx';
import LandingPage from './sites/PublicSites/LandingPage.tsx';
import ResetPasswordPage from './sites/PublicSites/ResetPasswordPage.tsx';

import * as Sentry from '@sentry/react';
import 'dayjs/locale/pl';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { SnackbarProvider } from './common/SnackbarProvider.tsx';
import DemoProcess from './sites/BIKProcessSites/DemoProcess.tsx';
import HubServiceView from './sites/BIKProcessSites/HubServiceView.tsx';
import { SanitazedBIKPreview } from './sites/BIKProcessSites/SanitazedBIKPreview.tsx';
import CommissionDashboard from './sites/CommissionSites/CommissionDashboard.tsx';
import Instructions from './sites/PublicSites/Instructions.tsx';
import { Logout } from './sites/PublicSites/Logout.tsx';
import PartnerOnboarding from './sites/PublicSites/PartnerOnboarding.tsx';
import UserConsentAcceptationPage from './sites/PublicSites/UserConsentAcceptationPage.tsx';
import ConsentsPage from './sites/UserSites/ConsentsPage.tsx';
import AppTheme from './theme/AppTheme.tsx';

// Extend Day.js with the necessary plugins
dayjs.extend(isoWeek);
dayjs.extend(localizedFormat);
dayjs.locale('pl');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 10_000,
    },
  },
});

const currentEnv = import.meta.env.VITE_ENV; // DEV | STAGE | PROD

// Create a configuration object
const sentryConfig = {
  dsn: 'https://9744e7e38c9623dbc5aed40ebea4369c@o4509017351454720.ingest.de.sentry.io/4509017353551952',
  environment: currentEnv,
};

// Initialize Sentry with the configuration
Sentry.init(sentryConfig);

const router = createBrowserRouter([
  {
    path: '/',
    Component: LandingPage,
  },
  {
    path: '/faq',
    Component: FAQ,
  },
  {
    path: '/onboarding',
    Component: PartnerOnboarding,
  },
  {
    path: '/instruction',
    Component: Instructions,
  },
  {
    path: '/instrukcje',
    Component: Instructions,
  },
  {
    path: '/process-bik',
    element: (
      <PrivateRoute role="ROLE_PARTNER">
        <ProcessBIK />
      </PrivateRoute>
    ),
  },
  {
    path: '/process-bik/:id/partner-process',
    element: (
      <PrivateRoute>
        <ShowBIKRaport />
      </PrivateRoute>
    ),
  },
  {
    path: '/process-bik/:id/client-process',
    element: <ShowBIKRaport isClientView />,
  },
  {
    path: '/process-bik/:id/process',
    element: (
      <PrivateRoute>
        <HubServiceView />
      </PrivateRoute>
    ),
  },
  {
    path: '/process-bik/:id/sanitazed-summary',
    element: (
      <PrivateRoute>
        <SanitazedBIKPreview />
      </PrivateRoute>
    ),
  },
  {
    path: '/process-bik/:id/add-details',
    Component: AddDetails,
  },
  {
    path: '/process-bik/:id/done',
    Component: ThankYou,
  },
  {
    path: '/process-bik/:id/summary',
    Component: Summary,
  },
  {
    path: '/sign-in',
    Component: AuthPage,
  },
  {
    path: '/logout',
    Component: Logout,
  },
  {
    path: '/set-new-password',
    Component: ResetPasswordPage,
  },
  {
    path: '/demo-process',
    Component: DemoProcess,
  },
  {
    path: '/consent-acceptation',
    Component: UserConsentAcceptationPage,
  },
  {
    path: '/partner',
    children: [
      {
        path: '/partner/dashboard',
        element: (
          <PrivateRoute role="ROLE_PARTNER">
            <PartnerDashboard />
          </PrivateRoute>
        ),
      },
      {
        path: '/partner/statistics',
        element: (
          <PrivateRoute role="ROLE_PARTNER">
            <PartnerStatistics />
          </PrivateRoute>
        ),
      },
      {
        path: '/partner/commissions',
        element: (
          <PrivateRoute role="ROLE_PARTNER">
            <CommissionDashboard type="ROLE_PARTNER" />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: '/manager',
    children: [
      {
        path: '/manager/partner-manage',
        element: (
          <PrivateRoute role="ROLE_MANAGER">
            <ManagerPartnersList />
          </PrivateRoute>
        ),
      },
      {
        path: '/manager/reports',
        element: (
          <PrivateRoute role="ROLE_MANAGER">
            <ManagerReportsList />
          </PrivateRoute>
        ),
      },
      {
        path: '/manager/commissions',
        element: (
          <PrivateRoute role="ROLE_MANAGER">
            <CommissionDashboard type="ROLE_MANAGER" />
          </PrivateRoute>
        ),
      },
      {
        path: '/manager/statistics',
        element: (
          <PrivateRoute role="ROLE_MANAGER">
            <ManagerStatistics />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: '/law-office',
    children: [
      {
        path: '/law-office/commissions',
        element: (
          <PrivateRoute role="ROLE_LAW_OFFICE">
            <CommissionDashboard type="ROLE_LAW_OFFICE" />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: '/user',
    children: [
      {
        path: '/user/consents',
        element: (
          <PrivateRoute>
            <ConsentsPage />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: '/check-default-page',
    element: (
      <PrivateRoute>
        <CheckDefaultPage />
      </PrivateRoute>
    ),
  },
  {
    path: '*',
    Component: NotFoundPage,
  },
]);

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Suspense
            fallback={
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  textAlign: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            }
          >
            <AppTheme>
              <CssBaseline enableColorScheme />
              <RouterProvider router={router} />
            </AppTheme>
          </Suspense>
        </LocalizationProvider>
      </SnackbarProvider>
    </QueryClientProvider>
  </StrictMode>
);

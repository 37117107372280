// file: AssignCommission.tsx

import { Box, Button, Checkbox, InputLabel, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useState } from 'react';
import { updateUserCommission } from 'src/api/bikApi';
import { getUserDetailsFromLocalStorage } from 'src/common/roles';
import { useSnackbar } from 'src/common/useSnackbar';
import { Commission, CommissionProductType, PartnerDetails } from 'src/types/PartnerDetails';
import { CommissionTranslation, CommissionTypeTranslation } from '../translations';

interface AssignCommissionProps {
  user: PartnerDetails;
  refetch?: () => void;
}

const AssignCommission: React.FC<AssignCommissionProps> = ({ user, refetch }) => {
  const [commissions, setCommissions] = useState<Commission[]>(user?.commissions || []);
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar } = useSnackbar();

  const userDetails = getUserDetailsFromLocalStorage();

  const handleCommissionChange = (productType: CommissionProductType, field: keyof Commission, value: any) => {
    setCommissions((prevCommissions) => {
      const commissionIndex = prevCommissions.findIndex((c) => c.productType === productType);

      if (commissionIndex === -1 && field === 'isPublic') {
        return [...prevCommissions, { productType, type: 'PERCENTAGE', value: 0, isPublic: value }];
      }

      return prevCommissions.map((commission, index) =>
        index === commissionIndex ? { ...commission, [field]: value } : commission
      );
    });
  };

  const handleUpdateCommissions = async () => {
    setIsLoading(true);
    try {
      await updateUserCommission(user.id, commissions);
      showSnackbar(`Pomyślnie zaktualizowano prowizję dla użytkownika ${user.firstName} ${user.lastName}`, 'success');
      if (refetch) refetch();
    } catch {
      showSnackbar('Błąd podczas aktualizowania prowizji', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ p: 3, borderRadius: 2 }}>
      <Typography variant="h6" mb={2}>
        Edycja usług użytkownika
      </Typography>

      {(userDetails?.commissions || []).map((item) => {
        const productType = item.productType;

        const commission = commissions.find((c) => c.productType === productType) || {
          productType,
          type: item.type,
          value: item.value,
          isPublic: true,
        };

        const isChecked = commissions.some((c) => c.productType === productType);
        // const isPercentage = commission.type === 'PERCENTAGE';

        return (
          <Grid
            container
            key={productType}
            alignItems="center"
            spacing={2}
            sx={{
              mb: 1,
              p: 2,
              borderRadius: 2,
              backgroundColor: isChecked ? 'background.paper' : 'action.disabledBackground',
              opacity: isChecked ? 1 : 0.6,
              transition: 'background-color 0.3s, opacity 0.3s',
            }}
          >
            {/* Checkbox */}
            <Grid size={{ xs: 12, sm: 1 }} sx={{ textAlign: 'center' }}>
              <Checkbox
                checked={isChecked}
                onChange={(e) => {
                  if (e.target.checked) {
                    setCommissions((prev) => [...prev, { productType, type: item.type, value: 0, isPublic: true }]);
                  } else {
                    setCommissions((prev) => prev.filter((c) => c.productType !== productType));
                  }
                }}
              />
            </Grid>

            {/* Commission name */}
            <Grid size={{ xs: 12, sm: 3 }}>
              <Typography>{CommissionTranslation[productType]}</Typography>
            </Grid>

            {/* Type select */}
            <Grid size={{ xs: 12, sm: 3 }}>
              <InputLabel>Typ</InputLabel>
              <Typography> {CommissionTypeTranslation[item.type]}</Typography>
            </Grid>

            {/* Value input */}
            {/* <Grid size={{ xs: 12, sm: 3 }}>
              <TextField
                size="small"
                type="number"
                label="Wartość"
                value={commission.value}
                disabled={!isChecked}
                fullWidth
                inputProps={{
                  min: 0,
                  max: item.value,
                }}
                onChange={(e) => {
                  const value = parseFloat(e.target.value) ?? item.value;
                  handleCommissionChange(productType, 'value', isPercentage ? Math.min(value, item.value) : value);

                  if (value > item.value) {
                    showSnackbar(
                      'Wartość prowizji partnera nie może przekraczać wartości prowizji menedżera.',
                      'error'
                    );
                  }
                }}
              />
            </Grid> */}

            {/* IsPublic checkbox */}
            <Grid size={{ xs: 12, sm: 2 }} sx={{ textAlign: 'center' }}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Checkbox
                  checked={commission.isPublic}
                  disabled={!isChecked}
                  onChange={(e) => handleCommissionChange(productType, 'isPublic', e.target.checked)}
                />
                <Typography>Publiczna</Typography>
              </Box>
            </Grid>
          </Grid>
        );
      })}

      <Box textAlign="end">
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpdateCommissions}
          disabled={isLoading}
          sx={{ mt: 2 }}
        >
          Zapisz zmiany
        </Button>
      </Box>
    </Box>
  );
};

export default AssignCommission;

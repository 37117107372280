import { Box } from '@mui/material';
import { Button } from '../ui/Button';

interface StepNavigationProps {
  onBack?: () => void;
  showBack?: boolean;
  children: React.ReactNode;
}

export function StepNavigation({ onBack, showBack = true, children }: StepNavigationProps) {
  return (
    <Box
      sx={{
        mt: 2,
        display: 'flex',
        gap: 2,
        flexDirection: { xs: 'column', sm: 'row' },
        '& > *': {
          flex: 1,
        },
      }}
    >
      {showBack && (
        <Button type="button" variant="outline" onClick={onBack} fullWidth>
          Wstecz
        </Button>
      )}
      {children}
    </Box>
  );
}

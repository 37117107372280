import { ProViewDelay } from 'src/types/ProView';

interface Result {
  isStarted: boolean;
  x: string; // Year
  y: string; // Month (Polish abbreviation)
  value: number;
}

export function calculateDelays(
  delays: ProViewDelay[],
  startedAt: string,
  isOverrided: boolean,
  finishedAt?: string | null
): Result[] {
  const monthNames: string[] = [
    'Styczeń',
    'Luty',
    'Marzec',
    'Kwiecień',
    'Maj',
    'Czerwiec',
    'Lipiec',
    'Sierpień',
    'Wrzesień',
    'Październik',
    'Listopad',
    'Grudzień',
  ];

  const originalStart: Date = new Date(startedAt);
  const startDate: Date = new Date(startedAt);
  startDate.setMonth(0, 1); // Set to January of the start year
  const endDate: Date = finishedAt ? new Date(finishedAt) : new Date();
  endDate.setMonth(endDate.getMonth() + 1);

  const result: Result[] = [];
  const currentDate: Date = new Date(startDate);

  // Create an empty dataset
  let isGlobalStarted = false;

  while (currentDate <= endDate) {
    const originalStartYear: string = originalStart.getFullYear().toString();
    const originalStartMonth: string = originalStart.getMonth().toString();
    const currentYear: string = currentDate.getFullYear().toString();
    const currentMonth: string = currentDate.getMonth().toString();

    const isStarted = originalStartYear === currentYear && originalStartMonth === currentMonth;

    if (isStarted) isGlobalStarted = true;

    result.push({
      isStarted: isGlobalStarted,
      x: currentDate.getFullYear().toString(),
      y: monthNames[currentDate.getMonth()],
      value: isGlobalStarted ? 0 : -1,
    });
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  // Populate delay values
  delays.forEach(({ dateAt, numberOfDays }: ProViewDelay) => {
    const delayDate: Date = new Date(dateAt);
    const year: string = delayDate.getFullYear().toString();
    const month: string = monthNames[delayDate.getMonth()];

    const entry: Result | undefined = result.find((item) => item.x === year && item.y === month);
    if (entry) {
      entry.value = isOverrided
        ? numberOfDays > entry.value
          ? numberOfDays
          : entry.value
        : entry.value + numberOfDays;
    }
  });

  return result;
}

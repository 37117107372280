import { styled, Theme, useTheme } from '@mui/material/styles';
import { forwardRef, ReactNode, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ControllerRenderProps } from 'react-hook-form';

const getColor = (
  acceptedFiles: File[],
  isDragAccept: boolean,
  isDragReject: boolean,
  isFocused: boolean,
  theme: Theme,
  color?: 'primary' | 'success'
) => {
  const isDark = theme.palette.mode === 'dark';

  if (acceptedFiles?.length) {
    if (color === 'primary') {
      return isDark ? theme.palette.success.main : theme.palette.primary.main;
    }

    if (color === 'success') {
      return isDark ? theme.palette.primary.main : theme.palette.success.main;
    }

    return isDark ? theme.palette.primary.main : theme.palette.success.main;
  }

  if (isDragAccept) {
    return theme.palette.success.main;
  }
  if (isDragReject) {
    return theme.palette.error.main;
  }
  if (isFocused) {
    if (color === 'primary') {
      return isDark ? theme.palette.success.main : theme.palette.primary.main;
    }

    if (color === 'success') {
      return isDark ? theme.palette.primary.main : theme.palette.success.main;
    }

    return isDark ? theme.palette.info.main : theme.palette.success.main;
  }
  return theme.palette.info.mainChannel;
};

const getBackgroundColor = (theme: Theme, color?: 'primary' | 'success') => {
  const isDark = theme.palette.mode === 'dark';

  if (color === 'primary') {
    return isDark ? theme.palette.success.light : theme.palette.primary.light;
  }

  if (color === 'success') {
    return isDark ? theme.palette.primary.light : theme.palette.success.light;
  }

  return theme.palette.background.default;
};

const forbiddenProps = ['$acceptedFiles', '$isDragAccept', '$isDragReject', '$isFocused', '$color'];

export const Container = styled('div', { shouldForwardProp: (prop) => !forbiddenProps.includes(prop as string) })<{
  $acceptedFiles: File[];
  $isDragAccept: boolean;
  $isDragReject: boolean;
  $isFocused: boolean;
  $color?: 'primary' | 'success';
}>(({ $acceptedFiles, $isDragAccept, $isDragReject, $isFocused, $color, theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  borderWidth: 2,
  borderRadius: 2,
  borderColor: getColor($acceptedFiles, $isDragAccept, $isDragReject, $isFocused, theme, $color),
  color: getColor($acceptedFiles, $isDragAccept, $isDragReject, $isFocused, theme, $color),
  borderStyle: 'dashed',
  backgroundColor: getBackgroundColor(theme, $color),
  outline: 'none',
  transition: 'border 0.24s ease-in-out, background-color 0.24s ease-in-out',
}));

const maxFileSize = 5242880;

type PdfDragNDropProps = ControllerRenderProps & {
  dataTestId: string; // Adding `dataTestId` as required prop
  color?: 'primary' | 'success';
  text?: ReactNode;
  textSuccess?: ReactNode;
};
export const PdfDragNDrop = forwardRef<HTMLInputElement, Omit<PdfDragNDropProps, 'ref'>>(
  ({ dataTestId, color = 'success', text, textSuccess, ...field }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const theme = useTheme(); // Access the theme

    const { acceptedFiles, getInputProps, getRootProps, isFocused, isDragAccept, isDragReject } = useDropzone({
      maxFiles: 1,
      multiple: false,
      maxSize: maxFileSize,
      accept: {
        'application/pdf': ['.pdf'],
      },
      onDrop: (files, fileRejections) => {
        setErrorMessage('');

        if (files.length) {
          field.onChange(files[0]);
        }

        if (fileRejections.length) {
          fileRejections.forEach((file) => {
            file.errors.forEach((err) => {
              if (err.code === 'file-too-large') {
                setErrorMessage(
                  `Błąd: rozmiar pliku jest za duży. (Plik jest większy niż ${maxFileSize / 1000000} Mb)`
                );
              }

              if (err.code === 'file-invalid-type') {
                setErrorMessage(`Błąd: nieobsługiwany rozmiar pliku. Dodaj plik *.pdf`);
              }

              if (err.code === 'too-many-files') {
                setErrorMessage(`Błąd: możesz wrzucić tylko jeden plik naraz.`);
              }
            });
          });
        }
      },
    });

    const acceptedFileItems = acceptedFiles.map((file: any) => `${file.path} - ${file.size} bytes`);

    return (
      <section className="container">
        <Container
          {...getRootProps()}
          $color={color}
          $acceptedFiles={acceptedFiles as File[]}
          $isFocused={isFocused}
          $isDragAccept={isDragAccept}
          $isDragReject={isDragReject}
        >
          <input data-testid={dataTestId} {...getInputProps()} />
          {acceptedFileItems.length ? (
            <>
              <p>
                {textSuccess || 'Plik został pomyślnie dodany!'} - {acceptedFileItems[0]}
              </p>
              <em>(Limit 1 plik)</em>
            </>
          ) : (
            <>
              <p>{text || 'Klknij i wybierz BIK lub przenieś plik widoczne pole'}</p>
              <em>(Limit 1 plik)</em>
            </>
          )}
        </Container>
        <aside style={{ color: theme.palette.error.main }}>{errorMessage && <h4>{errorMessage}</h4>}</aside>
      </section>
    );
  }
);

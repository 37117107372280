import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { getUserDetails } from 'src/api/bikApi';
import { CURRENT_CONSENT_VERSION } from 'src/common/currentConsentVersion';
import { ROLES, RoleType, USER_DETAILS } from 'src/common/roles';
import LoadingSite from 'src/sites/commonSites/LoadingSite';
import { PartnerDetails } from 'src/types/PartnerDetails';
import ConsentModal from './Modals/ConsentModal';
import MissingPartnerPermissionsModal from './Modals/MissingPartnerPermissionsModal';
import { isTokenValid, logoutUser, TOKEN_KEY } from './utils';

export const PrivateRoute = ({ children, role }: { children: React.ReactNode; role?: RoleType }) => {
  const [isConsentAccepted, setIsConsentAccepted] = useState(true);
  const [isMissingPartnerPermissions, setIsMissingPartnerPermissions] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem(TOKEN_KEY);

  useEffect(() => {
    let isMounted = true;

    getUserDetails()
      .then((user: PartnerDetails) => {
        if (!isMounted) return;

        localStorage.setItem(USER_DETAILS, JSON.stringify(user || []));

        if (role && !user?.roles.includes(role)) {
          navigate('/check-default-page');
          return;
        }

        if (
          user?.roles &&
          !user?.roles.includes(ROLES.ROLE_MANAGER) &&
          !user?.roles.includes(ROLES.ROLE_PARTNER) &&
          !user?.roles.includes(ROLES.ROLE_LAW_OFFICE)
        ) {
          logoutUser();
          navigate('/sign-in?message=Brak uprawnień do korzystania z Master Panelu.');
        }

        if (
          location.pathname !== '/user/consents' &&
          user &&
          'regulationsConsentVersion' in user &&
          user.regulationsConsentVersion !== CURRENT_CONSENT_VERSION
        ) {
          setIsConsentAccepted(false);
          return;
        }

        if (user.commissions.length === 0 && location.pathname === '/process-bik') {
          setIsMissingPartnerPermissions(true);
          return;
        }
      })
      .catch(() => {
        if (!isMounted) return;
        logoutUser();
        navigate('/sign-in?message=Błąd podczas sprawdzania sesji.');
      })
      .finally(() => {
        if (!isMounted) return;
        setIsLoading(false);
      });

    return () => {
      isMounted = false;
    };
  }, [navigate, role, location.pathname]);

  if (!token) {
    logoutUser(location.pathname);
    return <Navigate to="/sign-in" replace />;
  }

  if (!isTokenValid(token)) {
    return <Navigate to="/sign-in?message=Zostałeś wylogowany, ponieważ upłynął czas twojej sesji." replace />;
  }

  if (isLoading) {
    return <LoadingSite />;
  }

  return (
    <>
      {children}
      {!isConsentAccepted && <ConsentModal isOpen onClose={() => setIsConsentAccepted(true)} />}
      {isMissingPartnerPermissions && (
        <MissingPartnerPermissionsModal isOpen onClose={() => setIsMissingPartnerPermissions(false)} />
      )}
    </>
  );
};

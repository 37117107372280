import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, styled, TextField } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { CommissionStatusDetails } from 'src/components/CommissionTimeline/types';
import { CommissionStatus } from 'src/types/TimelineStatus';

const StyledTextField = styled(TextField)({
  '.MuiInputBase-root': {
    height: '2.25rem',
  },
});

export const useCommissionSearch = (setPage: (page: number) => void) => {
  const [filterText, setFilterText] = useState('');
  const [searchText, setSearchText] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<CommissionStatus | ''>('');
  const selectedStatusText = selectedStatus ? CommissionStatusDetails[selectedStatus]?.translation : '';

  useEffect(() => {
    if (filterText.length === 1 || filterText.length === 2 || filterText.length === 3) {
      return;
    }

    const searchTimeout = setTimeout(() => {
      setPage(0);
      setSearchText(filterText);
    }, 500);

    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
    };
  }, [filterText, setPage]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  // ✅ Corrected to use SelectChangeEvent<CommissionStatus>
  const handleStatusChange = (event: SelectChangeEvent<CommissionStatus>) => {
    setFilterText('');
    setSelectedStatus(event.target.value as CommissionStatus);
  };

  const SearchField = useMemo(
    () => (
      <StyledTextField
        disabled={!!selectedStatus}
        label={`${selectedStatus ? 'Usuń status, aby odblokować globalny filtr' : '(min. 4 znaki) Wpisz tekst, aby przefiltrować prowizje po nazwie, kliencie'}`}
        variant="outlined"
        fullWidth
        slotProps={{
          inputLabel: { style: { top: -8 } },
          input: { style: { minWidth: 'auto' } },
          formHelperText: { style: { margin: 0, height: 0 } },
        }}
        value={filterText}
        onChange={handleFilterChange}
      />
    ),
    [filterText, selectedStatus]
  );

  const StatusSelect = useMemo(
    () => (
      <FormControl variant="outlined" fullWidth>
        <InputLabel sx={{ top: -6 }}>Status</InputLabel>
        <Select value={selectedStatus} onChange={handleStatusChange} label="Status" size="small">
          {Object.entries({
            ...{ EMPTY: { key: '', translation: 'Brak' } },
            ...CommissionStatusDetails,
          }).map(([status, { translation }]) => (
            <MenuItem key={status} value={status === 'EMPTY' ? '' : (status as CommissionStatus)}>
              {translation}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ),
    [selectedStatus]
  );

  return {
    searchText,
    selectedStatus,
    setSearchText: setFilterText,
    selectedStatusText,
    SearchField,
    StatusSelect,
  };
};

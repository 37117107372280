import ShareIcon from '@mui/icons-material/Share';
import { Box, Button, Tooltip } from '@mui/material';
import { FunctionComponent, MouseEvent, useCallback } from 'react';
import { DateTime } from 'src/common/DateTime/DateTime';
import { useSnackbar } from 'src/common/useSnackbar';
import { Report } from 'src/types/Report';
import { StyledLink } from './BIKCreationTime.styles';

interface BIKCreationTimeProps {
  bikReport: Report;
}

const API_LINK: string = import.meta.env.VITE_API_URL;

export const BIKCreationTime: FunctionComponent<BIKCreationTimeProps> = ({ bikReport }) => {
  const { showSnackbar } = useSnackbar();
  const processLink = `${API_LINK.replace('api.', '')}/process-bik/${bikReport.id}/process`;

  const copyToClipboard = useCallback(
    async (event: MouseEvent<HTMLElement>) => {
      if (bikReport.orderId) {
        event.stopPropagation();
        await navigator.clipboard.writeText(processLink);
        showSnackbar('Skopiowano link raportu do schowka.', 'info');
      }
    },
    [bikReport.orderId, processLink, showSnackbar]
  );

  return (
    <div className="bik-date">
      <Box className="time" mb={1} data-testid="bik-creation-time">
        <DateTime date={new Date(bikReport.createdAt)} formatString="HH:mm" />
      </Box>
      <div className="date" data-testid="bik-creation-date">
        <DateTime date={bikReport.createdAt} formatString="dd-MM-y" />
      </div>

      <Box className="additional-info" display="flex" alignItems="center" data-testid="process-bik-info">
        <Box mt={0.5} display="flex" alignItems="center" className="value">
          <Box mr={1} display="flex" alignItems="center" onClick={copyToClipboard}>
            <Tooltip
              title={
                <>
                  Link do podglądu BIK:{' '}
                  <StyledLink href={processLink} target="_blank">
                    {processLink}
                  </StyledLink>
                </>
              }
              arrow
            >
              <ShareIcon fontSize="small" htmlColor={'primary'} />
            </Tooltip>
          </Box>
          <Button href={processLink} variant="contained" size="small">
            Podgląd BIK
          </Button>
        </Box>
      </Box>
    </div>
  );
};

import { CheckCircle, Send as SendIcon } from '@mui/icons-material';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useState } from 'react';
import { userAcceptMarketingConsents, userAcceptRodoConsents } from 'src/api/bikApi';
import { useSnackbar } from 'src/common/useSnackbar';
import { PartnerDetails } from 'src/types/PartnerDetails';
import { ConsentCard } from './ConsentCard';

export const ConsentForm: React.FC<{ partnerDetails: PartnerDetails }> = ({ partnerDetails }) => {
  const { showSnackbar } = useSnackbar();

  const [consent1, setConsent1] = useState(false);
  const [consent2, setConsent2] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleSubmit = async () => {
    if (!partnerDetails) {
      showSnackbar('Błąd podczas pobierania danych użytkownika.');
      return;
    }

    try {
      await userAcceptMarketingConsents(partnerDetails.id);
      await userAcceptRodoConsents(partnerDetails.id);

      setDialogOpen(true);
    } catch {
      showSnackbar('Wystąpił błąd podczas akceptacji zgód marketingowych.');
    }
  };

  const handleConfirm = () => {
    setDialogOpen(false);
  };

  const isConsentUploaded = partnerDetails?.hasMarketingConsentsAccepted && partnerDetails.hasRODOConsentsAccepted;
  const allConsentsAccepted = consent1 && consent2;

  return (
    <Box component="form" sx={{ mt: 3 }}>
      <ConsentCard
        title="Zgoda na przetwarzanie danych osobowych [BIK Firmowy]"
        content="Działając w imieniu i na rzecz mojego Klienta oświadczam, że posiadam zgodę Klienta na przetwarzanie jego danych osobowych przez BIKMASTER.PL, NIP: 7712703730 w celu dokonania analizy historii kredytowej oraz przedstawienia sugestii dotyczących możliwych działań mających na celu poprawę oceny BIK i zdolności kredytowej."
        contentTwo="Dane osobowe mojego Klienta przekazuję w jego imieniu, dobrowolnie i oświadczam, że są one zgodne z prawdą."
        checked={consent1}
        forcedChecked={partnerDetails?.hasMarketingConsentsAccepted}
        onChange={setConsent1}
        provider="BIKMaster.pl"
      />

      <ConsentCard
        title="Zgoda na przetwarzanie danych osobowych [BIK Prywatny]"
        content="Działając w imieniu i na rzecz mojego Klienta oświadczam, że posiadam zgodę Klienta na przetwarzanie jego danych osobowych przez BIKMASTER.PL, NIP: 7712703730 w celu dokonania analizy historii kredytowej oraz przedstawienia sugestii dotyczących możliwych działań mających na celu poprawę oceny BIK i zdolności kredytowej."
        contentTwo="Dane osobowe mojego Klienta przekazuję w jego imieniu, dobrowolnie i oświadczam, że są one zgodne z prawdą."
        checked={consent2}
        forcedChecked={partnerDetails?.hasRODOConsentsAccepted}
        onChange={setConsent2}
        provider="BIKMaster.pl"
      />

      {!allConsentsAccepted && (
        <Alert severity="info" sx={{ mb: 3 }}>
          Proszę zaakceptować obie zgody, aby kontynuować
        </Alert>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          disabled={!allConsentsAccepted || isConsentUploaded}
          onClick={handleSubmit}
          startIcon={<SendIcon />}
          sx={{
            minWidth: 200,
            py: 1.5,
            px: 4,
            borderRadius: 2,
            textTransform: 'none',
            fontSize: '1.1rem',
          }}
        >
          {isConsentUploaded ? 'Zgody przesłane' : 'Prześlij Zgody'}
        </Button>
      </Box>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <CheckCircle sx={{ color: '#4CAF50' }} />
          Zgody Zatwierdzone
        </DialogTitle>
        <DialogContent>
          <Typography>
            Twoje zgody marketingowe zostały pomyślnie zapisane. Zgody te zostaną automatycznie zastosowane podczas
            wgrywania raportu BIK.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm} color="primary" variant="outlined" autoFocus>
            Gotowe
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

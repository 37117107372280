import { SvgIconComponent } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

interface SectionHeaderProps {
  icon: SvgIconComponent; // Updated to use Material-UI's SvgIconComponent
  title: string;
}

export function SectionHeader({ icon: Icon, title }: SectionHeaderProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        mb: { xs: 2, sm: 3 },
        gap: { xs: 1, sm: 1.5 },
        pb: { xs: 1.5, sm: 2 },
        borderBottom: '1px solid',
        borderColor: 'rgba(229, 231, 235, 0.5)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: { xs: 32, sm: 40 },
          height: { xs: 32, sm: 40 },
          borderRadius: { xs: '10px', sm: '12px' },
          background: 'linear-gradient(135deg, #3b82f6 0%, #2563eb 100%)',
          boxShadow: '0 4px 6px -1px rgba(59, 130, 246, 0.2)',
        }}
      >
        <Icon sx={{ fontSize: 20, color: 'white' }} />
      </Box>
      <Typography
        variant="h6"
        sx={{
          fontSize: { xs: '1.125rem', sm: '1.25rem' },
          fontWeight: 600,
          background: 'linear-gradient(135deg, #1e40af 0%, #3b82f6 100%)',
          backgroundClip: 'text',
          color: 'transparent',
        }}
      >
        {title}
      </Typography>
    </Box>
  );
}

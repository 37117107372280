import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SecurityIcon from '@mui/icons-material/Security';
import { Box, Collapse, FormControlLabel, IconButton, Switch, Typography, useTheme } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { PdfDragNDrop } from 'src/components/PdfDragNDrop';
import { BIKUploadValidationSchema } from 'src/types/FormField.type';
export default function CompanyPart({
  type,
  isConsentAccepted,
  setValue,
  control,
}: {
  type: 'OWNER' | 'SUPPORT';
  isConsentAccepted?: boolean;
  setValue: UseFormSetValue<BIKUploadValidationSchema>;
  control: Control<BIKUploadValidationSchema>;
}) {
  const [accepted, setAccepted] = useState(false);
  const theme = useTheme(); // Access the current theme
  const [showConsent, setShowConsent] = useState(false);

  const isDark = theme.palette.mode === 'dark';

  const acceptedText =
    accepted && isConsentAccepted && type === 'SUPPORT' ? 'Zaakceptowano w ustawieniach globalnych' : 'Zaakceptowano';

  const handleAcceptClick = (event: ChangeEvent<HTMLInputElement>) => {
    setAccepted(event.target.checked);
  };

  useEffect(() => {
    if (!isConsentAccepted || type === 'OWNER') {
      return;
    }

    setValue('attachments.companyAgreementOne', true);
    setAccepted(true);
  }, [isConsentAccepted, type, setValue]);

  useEffect(() => {
    if (type === 'SUPPORT') {
      return;
    }

    setValue('attachments.companyAgreementOne', false);
    setAccepted(false);
  }, [type]);

  return (
    <Box mt={2}>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ color: theme.palette.text.primary }} // Adjust text color for dark mode
      >
        1. Dodaj Raport BIK Twoja Firma
      </Typography>

      <Controller
        name="attachments.companyPdf"
        control={control}
        render={({ field }) => (
          <PdfDragNDrop
            {...field}
            dataTestId="company-pdf-input"
            color="primary"
            text={
              <>
                Kliknij i wybierz <strong>Raport BIK Twoja Firma</strong> lub przenieś plik w widoczne pole
              </>
            }
            textSuccess={
              <>
                Pomyślnie dodano <strong>Raport BIK Twoja Firma</strong>
              </>
            }
          />
        )}
      />

      <Box
        sx={{
          border: accepted
            ? isDark
              ? `2px solid ${theme.palette.success.main}` // Slight transparency for accepted state
              : `2px solid ${theme.palette.info.dark}`
            : `2px solid ${theme.palette.background.paper}`, // Use theme background
          color: theme.palette.text.primary, // Adjust text color
          padding: '8px',
          borderRadius: '8px',
          boxShadow: theme.shadows[2],
          marginTop: '20px',
          marginBottom: '20px',
          transition: 'background-color 0.3s ease',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton>
            <SecurityIcon color={accepted ? (isDark ? 'success' : 'info') : 'inherit'} />
          </IconButton>
          <Typography variant="h6">
            Zgoda na przetwarzanie danych osobowych {accepted ? ` (${acceptedText})` : ''}
          </Typography>

          {type === 'SUPPORT' && isConsentAccepted && (
            <IconButton onClick={() => setShowConsent((prev) => !prev)}>
              {showConsent ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          )}
        </Box>

        <Collapse in={type === 'OWNER' || showConsent || !isConsentAccepted} timeout="auto">
          <Typography variant="body1" gutterBottom textAlign={'justify'}>
            {type === 'OWNER'
              ? `Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z Rozporządzeniem Parlamentu Europejskiego i
              Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. (ogólne rozporządzenie o ochronie danych) oraz ustawą z dnia
              10 maja 2018 r. o ochronie danych osobowych (Dz. U. z 2018r., poz. 1000 z późn. zm.) przez BIKMaster.pl w
              celu analizy historii kredytowej oraz przedstawienia sugestii dotyczących możliwych działań mających na
              celu poprawę oceny BIK i zdolności kredytowej.`
              : `Działając w imieniu i na rzecz mojego Klienta oświadczam, że posiadam zgodę Klienta na przetwarzanie jego danych osobowych przez BIKMASTER.PL, NIP: 7712703730 w celu dokonania analizy historii kredytowej oraz przedstawienia sugestii dotyczących możliwych działań mających na celu poprawę oceny BIK i zdolności kredytowej. Dane osobowe mojego Klienta przekazuję w jego imieniu, dobrowolnie i oświadczam, że są one zgodne z prawdą.`}
          </Typography>
          {type === 'OWNER' && (
            <Typography variant="body1" gutterBottom>
              'Podaję dane osobowe dobrowolnie i oświadczam, że są one zgodne z prawdą.'
            </Typography>
          )}
          {type === 'OWNER' && (
            <Typography variant="body1" gutterBottom>
              Zapoznałem/łam się z treścią klauzuli informacyjnej, w tym z informacją dotyczącą celu oraz metod
              przetwarzania danych osobowych, a także z prawem dostępu do swoich danych oraz ich poprawiania.
            </Typography>
          )}
        </Collapse>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '10px',
        }}
      >
        <Controller
          name="attachments.companyAgreementOne"
          defaultValue={false}
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Switch
                  {...field}
                  data-testid="company-agreement"
                  checked={field.value}
                  onChange={(event) => {
                    field.onChange(event);
                    handleAcceptClick(event);
                  }}
                  color={'primary'} // Adjust color for dark mode
                />
              }
              label={
                <Typography
                  style={{
                    marginLeft: 4,
                    color: theme.palette.text.primary, // Adjust text color
                  }}
                >
                  {accepted ? 'Zgoda wyrażona' : 'Wyrażam zgodę'}
                </Typography>
              }
            />
          )}
        />
      </Box>
    </Box>
  );
}

import { zodResolver } from '@hookform/resolvers/zod';
import {
  Business as BuildingIcon,
  Email as MailIcon,
  LocationOn as MapPinIcon,
  Person as UserIcon,
} from '@mui/icons-material';
import { Box, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useForm } from 'react-hook-form';
import { PersonalInfo, personalInfoSchema } from 'src/types/onboarding';
import { Button } from '../ui/Button';
import { Input } from '../ui/Input';
import { SectionHeader } from '../ui/SectionHeader';

interface PersonalInfoFormProps {
  onSubmit: (data: PersonalInfo) => void;
  initialData?: PersonalInfo | null;
  isLoading?: boolean;
}

export function PersonalInfoForm({ onSubmit, initialData, isLoading }: PersonalInfoFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PersonalInfo>({
    defaultValues: initialData || {},
    resolver: zodResolver(personalInfoSchema),
  });

  const theme = useTheme();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ mb: 4 }}>
        <SectionHeader icon={UserIcon} title="Dane osobowe" />
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Input label="Imię" {...register('firstName')} error={errors.firstName?.message} />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Input label="Nazwisko" {...register('lastName')} error={errors.lastName?.message} />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mb: 4 }}>
        <SectionHeader icon={MailIcon} title="Dane kontaktowe" />
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Input label="Email" type="email" {...register('email')} error={errors.email?.message} />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Input label="Telefon" type="tel" {...register('phone')} error={errors.phone?.message} />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mb: 4 }}>
        <SectionHeader icon={BuildingIcon} title="Dane firmowe" />
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Input label="Nazwa firmy" {...register('company')} error={errors.company?.message} />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Input label="NIP" {...register('nip')} placeholder="10 cyfr" error={errors.nip?.message} />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mb: 4 }}>
        <SectionHeader icon={MapPinIcon} title="Dane adresowe" />
        <Grid container spacing={1}>
          <Grid size={{ xs: 12 }}>
            <Input label="Ulica" {...register('street')} error={errors.street?.message} />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Input label="Miasto" {...register('city')} error={errors.city?.message} />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Input label="Kod pocztowy" {...register('postalCode')} error={errors.postalCode?.message} />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Input label="Kraj" {...register('country')} error={errors.country?.message} />
          </Grid>
        </Grid>
      </Box>

      <Button
        type="submit"
        isLoading={isLoading}
        fullWidth
        size="large"
        sx={{
          background:
            theme.palette.mode === 'dark'
              ? 'linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%)'
              : 'linear-gradient(135deg, #3b82f6 0%, #2563eb 100%)',
          '&:disabled': {
            color: theme.palette.background.default,
          },
          '&:hover': {
            background: theme.palette.mode === 'dark' ? 'grey' : 'linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%)',
          },
        }}
      >
        Kontynuuj
      </Button>
    </form>
  );
}

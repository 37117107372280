import { KeyOutlined, LockOutlined, ShieldOutlined } from '@mui/icons-material';
import { Box, Container, Paper, Typography, useColorScheme } from '@mui/material';
import Grid from '@mui/material/Grid2';

export function Security() {
  const { mode } = useColorScheme();

  return (
    <Box
      id="security"
      sx={{
        py: 10,
        bgcolor: mode === 'dark' ? 'primary.900' : 'primary.main',
        color: 'primary.contrastText',
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography variant="h2" gutterBottom>
            Twoje Dane są z Nami Bezpieczne
          </Typography>
          <Typography variant="h5" sx={{ color: mode === 'dark' ? 'primary.200' : 'primary.light' }}>
            Bezpieczeństwo klasy bankowej dla ochrony Twoich informacji
          </Typography>
        </Box>

        <Grid container spacing={1}>
          <Grid size={{ xs: 12, md: 4 }}>
            <Paper
              elevation={0}
              sx={{
                textAlign: 'center',
                p: 4,
                bgcolor: 'transparent',
                color: 'inherit',
              }}
            >
              <LockOutlined sx={{ fontSize: 48, mb: 2, color: mode === 'dark' ? 'primary.200' : 'primary.light' }} />
              <Typography variant="h6" gutterBottom>
                Szyfrowane Przechowywanie
              </Typography>
              <Typography sx={{ color: mode === 'dark' ? 'primary.200' : 'primary.light' }}>
                Twoje dane są szyfrowane podczas przechowywania i przesyłania
              </Typography>
            </Paper>
          </Grid>

          <Grid size={{ xs: 12, md: 4 }}>
            <Paper
              elevation={0}
              sx={{
                textAlign: 'center',
                p: 4,
                bgcolor: 'transparent',
                color: 'inherit',
              }}
            >
              <ShieldOutlined sx={{ fontSize: 48, mb: 2, color: mode === 'dark' ? 'primary.200' : 'primary.light' }} />
              <Typography variant="h6" gutterBottom>
                Bezpieczne Przetwarzanie
              </Typography>
              <Typography sx={{ color: mode === 'dark' ? 'primary.200' : 'primary.light' }}>
                Zaawansowane środki bezpieczeństwa chronią Twoje informacje
              </Typography>
            </Paper>
          </Grid>

          <Grid size={{ xs: 12, md: 4 }}>
            <Paper
              elevation={0}
              sx={{
                textAlign: 'center',
                p: 4,
                bgcolor: 'transparent',
                color: 'inherit',
              }}
            >
              <KeyOutlined sx={{ fontSize: 48, mb: 2, color: mode === 'dark' ? 'primary.200' : 'primary.light' }} />
              <Typography variant="h6" gutterBottom>
                Prywatność Przede Wszystkim
              </Typography>
              <Typography sx={{ color: mode === 'dark' ? 'primary.200' : 'primary.light' }}>
                Twoje dane nigdy nie są udostępniane stronom trzecim
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

import { Box, Paper, Typography, styled } from '@mui/material';
import React from 'react';
import { CreditCalculation } from 'src/types/CreditCalculation';

interface TotalCapacityProps {
  credits: CreditCalculation[];
}

const CapacityContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.main,
  color: theme.palette.getContrastText(theme.palette.primary.main),
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const formatCurrency = (amount: number): string => {
  return new Intl.NumberFormat('pl-PL', {
    style: 'currency',
    currency: 'PLN',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
};

const TotalCapacity: React.FC<TotalCapacityProps> = ({ credits }) => {
  const totalInstallment = React.useMemo(() => {
    return credits.reduce((sum, credit) => sum + credit.installment, 0);
  }, [credits]);

  return (
    <CapacityContainer elevation={0}>
      <Box>
        <Typography variant="h5" component="div" gutterBottom>
          Rata do Zdolności
        </Typography>
        <Typography variant="body2">Całkowita rata zdolności kredytowej</Typography>
      </Box>
      <Typography variant="h4" component="div">
        {formatCurrency(totalInstallment)}
      </Typography>
    </CapacityContainer>
  );
};

export default TotalCapacity;

import { Box, Theme, Typography, alpha } from '@mui/material';

export const renderInfoBox = (
  icon: React.ReactNode,
  bgColor: string,
  label: string,
  value: string | number,
  showCurrency: boolean,
  theme: Theme,
  textColor?: string
) => (
  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Typography color="text.secondary" sx={{ fontSize: '0.9rem' }}>
        {label}
      </Typography>
      <Typography
        color={textColor || theme.palette.text.primary}
        sx={{
          display: 'flex',
          fontWeight: 'bold',
          fontSize: '1.2rem',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            bgcolor: alpha(bgColor, 0.3),
            borderRadius: '50%',
            display: 'inline-flex',
            mr: 1,
            width: 40,
            height: 40,
            alignItems: 'center', // Centers vertically
            justifyContent: 'center', // Centers horizontally
          }}
        >
          {icon}
        </Box>
        {value.toLocaleString()} {showCurrency ? 'zł' : ''}
      </Typography>
    </Box>
  </Box>
);

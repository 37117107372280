import DateRangeIcon from '@mui/icons-material/DateRange';
import TodayIcon from '@mui/icons-material/Today';
import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';

import 'dayjs/locale/pl';

interface DatePresetsProps {
  fromDate: Dayjs;
  toDate: Dayjs;
  setFromDate: (date: Dayjs) => void;
  setToDate: (date: Dayjs) => void;
  activePreset: string;
  setActivePreset: (preset: string) => void;
  setPage?: (page: number) => void;
}

export const DatePresets: React.FC<DatePresetsProps> = ({
  setFromDate,
  setToDate,
  activePreset,
  setActivePreset,
  setPage,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const applyPreset = (preset: string) => {
    setActivePreset(preset);
    if (setPage) setPage(0);
    switch (preset) {
      case 'Today':
        setFromDate(dayjs().startOf('day'));
        setToDate(dayjs().endOf('day'));
        break;
      case 'ThisWeek':
        setFromDate(dayjs().startOf('isoWeek'));
        setToDate(dayjs().endOf('isoWeek'));
        break;
      case 'ThisMonth':
        setFromDate(dayjs().startOf('month'));
        setToDate(dayjs().endOf('month'));
        break;
      case 'LastMonth':
        setFromDate(dayjs().subtract(1, 'month').startOf('month'));
        setToDate(dayjs().subtract(1, 'month').endOf('month'));
        break;
      case 'Last14Days':
        setFromDate(dayjs().subtract(14, 'day').startOf('day'));
        setToDate(dayjs().endOf('day'));
        break;
      default:
        break;
    }
  };

  return (
    <Box sx={{ display: isMobile ? 'grid' : 'flex', gap: 1 }}>
      <Button
        size="small"
        sx={{ fontSize: '0.9em' }}
        variant={activePreset === 'Today' ? 'contained' : 'outlined'}
        startIcon={<TodayIcon />}
        onClick={() => applyPreset('Today')}
      >
        Dzisiaj
      </Button>
      <Button
        sx={{ fontSize: '0.9em' }}
        size="small"
        variant={activePreset === 'ThisWeek' ? 'contained' : 'outlined'}
        startIcon={<DateRangeIcon />}
        onClick={() => applyPreset('ThisWeek')}
      >
        Ten Tydzień
      </Button>
      <Button
        sx={{ fontSize: '0.9em' }}
        size="small"
        variant={activePreset === 'ThisMonth' ? 'contained' : 'outlined'}
        startIcon={<DateRangeIcon />}
        onClick={() => applyPreset('ThisMonth')}
      >
        Ten Miesiąc
      </Button>
      <Button
        sx={{ fontSize: '0.9em' }}
        size="small"
        variant={activePreset === 'LastMonth' ? 'contained' : 'outlined'}
        startIcon={<DateRangeIcon />}
        onClick={() => applyPreset('LastMonth')}
      >
        Poprzedni Miesiąc
      </Button>
      <Button
        sx={{ fontSize: '0.9em' }}
        size="small"
        variant={activePreset === 'Last14Days' ? 'contained' : 'outlined'}
        startIcon={<DateRangeIcon />}
        onClick={() => applyPreset('Last14Days')}
      >
        Ostatnie 14 Dni
      </Button>
    </Box>
  );
};

import { Button, Stack } from '@mui/material';
import { useState } from 'react';
import { getSKDData } from 'src/api/bikApi';
import { RoleType } from 'src/common/roles';
import { useSnackbar } from 'src/common/useSnackbar';
import SKDModal from 'src/components/skd-modal/SKDModal';
import { Report } from 'src/types/Report';
import { SKDResponse } from 'src/types/SDKResponse';

export interface BIKDetailsActionsProps {
  bikReport: Report;
  type: RoleType;
  refetchData: () => void;
}

export const BIKDetailsActions = ({ bikReport }: BIKDetailsActionsProps) => {
  const [skdModalData, setSkdModalData] = useState<SKDResponse | null>(null);

  const { showSnackbar } = useSnackbar();

  const handleShowSKDModal = async (id: string) => {
    try {
      const skdData: SKDResponse = await getSKDData(id);
      setSkdModalData(skdData);
    } catch {
      showSnackbar('Błąd podczas pobierania wyników SKD.', 'error');
    }
  };

  const handleCloseSKDModal = () => {
    setSkdModalData(null);
  };

  const isSKDDisabled = bikReport.reportType === 'COMPANY';
  return (
    <Stack spacing={1} direction="row" my={2} justifyContent="flex-end">
      <Button
        disabled={isSKDDisabled}
        variant="outlined"
        size="small"
        onClick={() => handleShowSKDModal(bikReport.id)}
        style={{ textTransform: 'none', minWidth: 120, padding: '8px' }}
      >
        Wyświetl SKD
      </Button>

      <SKDModal open={Boolean(skdModalData)} data={skdModalData} onClose={handleCloseSKDModal} />
    </Stack>
  );
};

import { jwtDecode } from 'jwt-decode';
import { refreshToken } from 'src/api/bikApi';
import { USER_DETAILS } from 'src/common/roles';

interface JwtPayload {
  exp: number;
}

export const TOKEN_KEY = 'authToken';
export const LAST_PATH = 'lastVisitedPath';

export const isUserLogged = (): boolean => {
  const token = localStorage.getItem(TOKEN_KEY);

  return isTokenValid(token);
};

export const logoutUser = (lastVisitedPath?: string): void => {
  if (lastVisitedPath) {
    localStorage.setItem(LAST_PATH, lastVisitedPath);
  } else {
    localStorage.removeItem(LAST_PATH);
  }

  localStorage.removeItem(USER_DETAILS);
  localStorage.removeItem(TOKEN_KEY);
};

export const isTokenValid = (token: string | null): boolean => {
  if (!token) return false;

  try {
    const decodedToken = jwtDecode<JwtPayload>(token);
    const currentTime = Date.now() / 1000;

    return decodedToken.exp > currentTime;
  } catch {
    return false;
  }
};

export const checkJWTValidity = async (): Promise<void> => {
  const token = localStorage.getItem(TOKEN_KEY);

  if (!token) return;

  try {
    const decodedToken = jwtDecode<JwtPayload>(token);
    const currentTime = Date.now() / 1000;
    const timeUntilExpiration = decodedToken.exp - currentTime;

    if (timeUntilExpiration <= 36000) {
      const response = await refreshToken();

      if (response?.token) {
        localStorage.setItem(TOKEN_KEY, response.token);
      }
    }
  } catch {
    logoutUser();
  }
};

import { Box, Typography } from '@mui/material';
import { BarChart, BarChartProps } from '@mui/x-charts';
import React, { useMemo } from 'react';
import { ReportStatusDetails } from 'src/common/reports'; // Adjust path as needed
import { BIKStatisticsRecord } from 'src/types/BIKStatistics';

interface ChartsProps {
  data: BIKStatisticsRecord[];
}

export const Charts: React.FC<ChartsProps> = ({ data }) => {
  const seriesData = useMemo(
    () =>
      data.map((report) => ({
        id: ReportStatusDetails[report.status].translation,
        label: `${ReportStatusDetails[report.status].translation}: ${report.money} PLN`,
        value: report.discountMoney,
        valueCommission: report.partnerCommissionValue,
      })),
    [data]
  );

  const horizontalBarChartConfig: BarChartProps = useMemo(
    () => ({
      series: [
        {
          data: seriesData.map((item) => item.value), // Extract numeric values for the bars
          label: 'Całkowita kwota transakcji',
        },
        {
          data: seriesData.map((item) => item.valueCommission), // Extract numeric values for the bars
          label: 'Całkowita kwota prowizji',
        },
      ],
      yAxis: [
        {
          data: seriesData.map((item) => item.id), // Use labels as y-axis categories
          scaleType: 'band', // Categorical data for y-axis
        },
      ],
      height: 400, // Adjust height to fit labels
      layout: 'horizontal', // Horizontal layout for bar chart
      margin: { left: 250 }, // Add margin to the left for labels
    }),
    [seriesData]
  );

  const horizontalCountBarChartConfig: BarChartProps = useMemo(
    () => ({
      series: [
        {
          data: data.map((item) => item.count), // Extract numeric values for the bars
        },
      ],
      yAxis: [
        {
          data: data.map((report) => ReportStatusDetails[report.status].translation), // Use translated labels for x-axis
          scaleType: 'band', // Categorical data for y-axis
        },
      ],
      height: 400, // Adjust height to fit labels
      layout: 'horizontal', // Horizontal layout for bar chart
      margin: { left: 250 }, // Add margin to the left for labels
    }),
    [seriesData]
  );

  return (
    <Box>
      <Box mt={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h6" gutterBottom>
          Potencjalne zarobki per status raportu
        </Typography>
        <BarChart {...horizontalBarChartConfig} />
      </Box>
      <Box mt={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h6" gutterBottom>
          Liczba wykonanych raportów według statusu
        </Typography>
        <BarChart {...horizontalCountBarChartConfig} />
      </Box>
    </Box>
  );
};

import { ArrowForward as ArrowRightIcon } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

interface NextStepIndicatorProps {
  nextStep?: string;
}

export function NextStepIndicator({ nextStep }: NextStepIndicatorProps) {
  if (!nextStep) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        mt: { xs: 2, sm: 3 },
        py: 2,
        px: 3,
        borderRadius: '12px',
        bgcolor: 'rgba(59, 130, 246, 0.05)',
        border: '1px dashed',
        borderColor: 'primary.main',
        opacity: 0.8,
      }}
    >
      <ArrowRightIcon sx={{ fontSize: 20, color: 'primary.main' }} />
      <Typography
        sx={{
          fontSize: '0.875rem',
          color: 'primary.main',
          fontWeight: 500,
        }}
      >
        Następny krok: {nextStep}
      </Typography>
    </Box>
  );
}

import AccessTimeIcon from '@mui/icons-material/AccessTime'; // MUI equivalent of Clock icon
import { Box, Typography, useTheme } from '@mui/material';

export function ImmediatePaymentInfo() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box
      sx={{
        mt: 4,
        p: 2,
        border: 1,
        borderRadius: 2,
        backgroundColor: isDarkMode ? 'success.dark' : 'success.light',
        borderColor: isDarkMode ? 'success.main' : 'success.light',
      }}
    >
      <Box display="flex" alignItems="flex-start" gap={2}>
        <AccessTimeIcon
          sx={{
            fontSize: 20,
            color: 'success.main',
            mt: 0.5,
          }}
        />
        <Typography
          variant="body2"
          sx={{
            color: isDarkMode ? 'success.light' : 'success.dark',
          }}
        >
          Płatność wymagana jest natychmiast po złożeniu zamówienia. Dokumentacja zostanie przetworzona i wysłana do
          klienta po zaksięgowaniu wpłaty.
        </Typography>
      </Box>
    </Box>
  );
}

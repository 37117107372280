import { Box, Button, Typography, useTheme } from '@mui/material';
import React from 'react';
import { CommunicationPreferences } from 'src/types/CommunicationPreferences.type';

import { ArrowForward, Info } from '@mui/icons-material';
import { Divider, Modal, Paper, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getUserDetailsFromLocalStorage } from 'src/common/roles';
import { Logo } from './app-toolbar/Logo';

interface CommunicationPreferencesModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: CommunicationPreferences) => Promise<boolean>;
}

const CommunicationPreferencesModal: React.FC<CommunicationPreferencesModalProps> = ({ isOpen, onClose, onSubmit }) => {
  const theme = useTheme();
  const partnerDetails = getUserDetailsFromLocalStorage();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleNavigateToMainPage = () => {
    if (partnerDetails?.email) {
      onSubmit({
        email: partnerDetails?.email,
        phone: '',
        emailEnabled: true,
        smsEnabled: false,
        consent1: true,
        consent2: false,
      });
    }

    navigate('/');
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      aria-labelledby="error-modal-title"
      aria-describedby="error-modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: isMobile ? 2 : 4,
      }}
    >
      <Paper
        elevation={8}
        sx={{
          maxWidth: 500,
          width: '100%',
          p: 0,
          borderRadius: 3,
          position: 'relative',
          outline: 'none',
          overflow: 'hidden',
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[10],
        }}
      >
        {/* Nagłówek */}
        <Box
          sx={{
            bgcolor: theme.palette.mode === 'dark' ? '#3e4396' : '#6366f1',
            color: '#ffffff',
            p: isMobile ? 2 : 3,
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Info sx={{ fontSize: isMobile ? 28 : 32 }} />
          <Typography id="error-modal-title" variant={isMobile ? 'h6' : 'h5'} component="h2" fontWeight="500">
            Błąd odczytu Raportu BIK
          </Typography>
        </Box>

        {/* Ikona */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            py: isMobile ? 3 : 4,
            background:
              theme.palette.mode === 'dark'
                ? 'linear-gradient(145deg, rgba(62, 67, 150, 0.1), rgba(99, 102, 241, 0.05))'
                : 'linear-gradient(145deg, rgba(99, 102, 241, 0.05), rgba(224, 231, 255, 0.2))',
          }}
        >
          <Logo />
        </Box>

        <Divider sx={{ opacity: 0.6 }} />

        <Box sx={{ p: isMobile ? 2.5 : 4 }}>
          <Typography
            id="error-modal-description"
            sx={{
              mt: 1,
              mb: 3,
              fontSize: isMobile ? '0.95rem' : '1rem',
            }}
          >
            Nastąpił błąd podczas odczytu Raportu BIK, co może wynikać z problemów konfiguracyjnych. Skontaktuj się z
            nami pod adresem
            <strong> kontakt@bikmaster.pl</strong>, aby uzyskać pomoc.
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <Button
              variant="contained"
              onClick={handleNavigateToMainPage}
              endIcon={<ArrowForward />}
              sx={{
                backgroundColor: theme.palette.mode === 'dark' ? '#3e4396' : '#6366f1',
                color: '#ffffff',
                px: isMobile ? 2 : 3,
                py: isMobile ? 1 : 1.2,
                fontWeight: 500,
                fontSize: isMobile ? '0.85rem' : '0.95rem',
                borderRadius: 2,
                textTransform: 'none',
                boxShadow: theme.shadows[3],
                '&:hover': {
                  backgroundColor: theme.palette.mode === 'dark' ? '#4d54c0' : '#4f46e5',
                  boxShadow: theme.shadows[5],
                },
              }}
            >
              Powrót do strony głównej
            </Button>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default CommunicationPreferencesModal;

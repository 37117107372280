import { FunctionComponent } from 'react';
import ObserveSize from 'react-observe-size';
import { CommissionStatus, TimelineCommissionStatus } from 'src/types/TimelineStatus';
import { CommissionTimelineSteps } from './CommissionTimelineSteps/CommissionTimelineSteps';
import { CommissionReportTimeline, CommissionStatusDetails } from './types';

interface TimelieProps {
  timeline: TimelineCommissionStatus[];
  commissionStatus: CommissionStatus;
}

const convertTimelineToReportTimeline = (timeline: TimelineCommissionStatus[]): CommissionReportTimeline => {
  const reportTimeline: CommissionReportTimeline = {};

  timeline.forEach(({ createdAt, status }) => {
    reportTimeline[CommissionStatusDetails[status].key] = createdAt;
  });

  return reportTimeline;
};

export const CommissionTimeline: FunctionComponent<TimelieProps> = ({ timeline, commissionStatus }) => {
  const mappedTimeline = convertTimelineToReportTimeline(timeline);

  return (
    <ObserveSize>
      {({ width }) => (
        <CommissionTimelineSteps
          commissionTimeline={mappedTimeline}
          commissionStatus={commissionStatus}
          width={width}
        />
      )}
    </ObserveSize>
  );
};

import { z } from 'zod';

const mainSchema = z.object({
  phone: z.string().min(1, 'Telefon jest wymagany').max(9, 'Numer telefonu powinien składać się z 9 cyfr.'),
  email: z.string().email('Nieprawidłowy format e-maila'),
});

const getPrivateDataSchema = () =>
  z
    .object({
      fullName: z.string().min(1, 'Imię i Nazwisko jest wymagane'),
      country: z.string().min(1, 'Kraj jest wymagany'),
      city: z.string().min(1, 'Miasto jest wymagane'),
      postalCode: z.string().regex(/^\d{2}-\d{3}$/, 'Kod pocztowy powinien być w formacie 00-000'),
      street: z.string().optional(),
      buildingNumber: z.string().min(1, 'Numer budynku/biura jest wymagany'),
      pesel: z.string().length(11, 'PESEL powinien mieć 11 cyfr'),
      bankAccountNumber: z.string().optional().nullable().refine(
        val => !val || /^\d{26}$/.test(val),
        {
          message: 'Numer konta bankowego powinien zawierać dokładnie 26 cyfr',
        }
      ),
      bankAccountName: z.string().optional().nullable()
      .refine(
        val => !val || val.trim().length > 0,
        {
          message: 'Nazwa konta bankowego jest wymagana',
        }
      ),
    
    })
    .optional();

const companyDataSchema = z.object({
  companyName: z.string().min(1, 'Nazwa firmy jest wymagana'),
  country: z.string().min(1, 'Kraj jest wymagany'),
  city: z.string().min(1, 'Miasto jest wymagane'),
  postalCode: z.string().regex(/^\d{2}-\d{3}$/, 'Kod pocztowy powinien być w formacie 00-000'),
  street: z.string().optional(),
  buildingNumber: z.string().min(1, 'Numer budynku/biura jest wymagany'),
  nip: z.string().length(10, 'NIP powinien mieć 10 cyfr'),
  regon: z.string().regex(/^\d{9}(\d{5})?$/, 'REGON powinien mieć 9 lub 14 cyfr'),
  type: z.string().nullable().optional(),
}).optional();

const supportDataSchema = z.object({
  fullName: z.string().min(1, 'Imię i Nazwisko jest wymagane'),
  phone: z.string().min(1, 'Telefon jest wymagany').max(9, 'Numer telefonu powinien składać się z 9 cyfr.'),
  email: z.string().email('Nieprawidłowy format e-maila'),
});

const marketingConsentsSchema = z.object({
  phoneConsent: z
    .boolean()
    .default(false)
    .refine((val) => val, 'Musisz zaakceptować zgodę marketingową'),
  emailConsent: z
    .boolean()
    .default(false)
    .refine((val) => val, 'Musisz zaakceptować zgodę marketingową'),
});

export const getDetailsSchema = () =>
  z.object({
    mainData: mainSchema,
    privateData: getPrivateDataSchema(),
    companyData: companyDataSchema,
    supportData: supportDataSchema,
    marketingConsents: marketingConsentsSchema,
  });

export type AddDetailsSchema = z.infer<ReturnType<typeof getDetailsSchema>>;

import { FunctionComponent } from 'react';
import { applyTaxRate, formatPrice } from 'src/utils/price';
import { StyledPrice } from './styles';

export interface PriceProps {
  value: string | number;
  currency?: string;
  discounted?: boolean;
  className?: string;
  'data-testid'?: string;
  taxRate?: number;
  isGrossPrice?: boolean;
  color?: string;
  isNegative?: boolean;
}

export const Price: FunctionComponent<PriceProps> = ({
  value,
  currency,
  discounted,
  className,
  'data-testid': dataTestId = 'price-component',
  taxRate,
  isGrossPrice,
  color,
  isNegative,
}) => {
  return (
    <StyledPrice className={className} discounted={discounted} data-testid={dataTestId} color={color} variant="h4">
      {isNegative && '-'}
      {formatPrice(isGrossPrice ? applyTaxRate(value, taxRate ?? 0) : value)}{' '}
      <span className="currency">{currency}</span>
    </StyledPrice>
  );
};

import { Box, Switch, Typography, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useSelectedService } from 'src/context/useSelectedService';
import { CommissionEntry } from 'src/types/CommissionResponse';
import { StructuredBIKDetails, StructuredService } from 'src/types/StructuredBIK';
import { ProductCard } from '../ProductCard';

export const CommissionCard = ({
  bikDetails,
  services,
  previouslySelected,
  isClientView,
  isDisabled,
}: {
  bikDetails?: StructuredBIKDetails;
  isDisabled: boolean;
  isClientView?: boolean;
  services: StructuredService[];
  previouslySelected: CommissionEntry[];
}) => {
  const theme = useTheme();
  const [isAllServicesSelected, setIsAllServicesSelected] = useState(false);
  const { selectedService, setSelectedService } = useSelectedService();
  const [selectedCheckboxIds, setSelectedCheckboxId] = useState<string[]>([]);

  const updateSelectedService = (actualIds: string[]) => {
    setSelectedCheckboxId(actualIds);

    const items = services
      .map((service) => service.items)
      .flat()
      .filter((item) => actualIds.includes((item as CommissionEntry).id)) as CommissionEntry[];
    const price = services.reduce(
      (sum, service) =>
        actualIds.includes((service.items[0] as CommissionEntry).id) ? sum + service.maximumPrice : sum,
      0
    );
    const discountPrice = services.reduce(
      (sum, service) =>
        actualIds.includes((service.items[0] as CommissionEntry).id) ? sum + (service.maximumDiscountPrice || 0) : sum,
      0
    );

    const partnerCommission = services.reduce(
      (sum, service) =>
        actualIds.includes((service.items[0] as CommissionEntry).id)
          ? sum + (service.maximumPartnerCommission || 0)
          : sum,
      0
    );

    const discountPartnerCommission = services.reduce(
      (sum, service) =>
        actualIds.includes((service.items[0] as CommissionEntry).id)
          ? sum + (service.maximumPartnerDiscountedCommission || 0)
          : sum,
      0
    );

    setSelectedService({
      left: 0,
      installment: 0,
      type: 'COMMISSION',
      discountPrice,
      partnerCommission,
      discountPartnerCommission,
      price,
      items: items,
      subType: 'CUSTOM',
    });
  };

  useEffect(() => {
    if (selectedService['COMMISSION']) {
      setSelectedCheckboxId(selectedService['COMMISSION'].items.map((item) => (item as CommissionEntry).id));
      return;
    }

    if (!previouslySelected.length) {
      return;
    }

    const actualIds = previouslySelected.map((item) => item.id);
    updateSelectedService(actualIds);
  }, [previouslySelected]);

  const onSelectAllServices = useCallback(
    (isChecked: boolean) => {
      setIsAllServicesSelected(isChecked);

      if (!isChecked) {
        updateSelectedService([]);
        return;
      }

      const ids = services.map((service) => service.items[0].id);
      updateSelectedService(ids);
    },
    [services]
  );

  const addMultiCheckboxId = (id: string) => {
    const getActualIdList = (selectedCheckboxIds: string[], newId: string) => {
      if (selectedCheckboxIds.includes(newId)) {
        return selectedCheckboxIds.filter((item) => item !== newId);
      }

      return [...selectedCheckboxIds, newId];
    };

    const actualIds = getActualIdList(selectedCheckboxIds, id);
    updateSelectedService(actualIds);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 2,
          py: 1,
          borderBottom: '1px solid',
          borderColor: theme.palette.divider,
          backgroundColor: isAllServicesSelected ? theme.palette.success.light : theme.palette.error.light, // Use theme colors for background
        }}
      >
        <Typography variant="body2" fontWeight={500}>
          Zaznacz wszystkie możliwe pozycje do zwrotu
        </Typography>
        <Switch
          disabled={isDisabled}
          checked={!!isAllServicesSelected}
          onChange={(e) => !isDisabled && onSelectAllServices(e.target.checked)}
          color="primary"
          sx={{
            '.MuiSwitch-thumb': {
              color: isAllServicesSelected ? theme.palette.success.light : theme.palette.error.light, // Dynamic thumb color
            },
          }}
        />
      </Box>
      {services.map((service, index) => (
        <Box key={index} mt={2}>
          <ProductCard
            bikDetails={bikDetails}
            isDisabled={isDisabled}
            isClientView={isClientView}
            data={service}
            type="COMMISSION"
            title={service.title}
            customMultiCheckbox={{
              selectedCheckboxIds,
              setSelectedCheckboxIds: addMultiCheckboxId,
              checkboxId: (service.items[0] as CommissionEntry).id,
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

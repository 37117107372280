import { ArrowForward, InsertDriveFile, Shield } from '@mui/icons-material';
import { Box, Button, Container, Typography, useColorScheme } from '@mui/material';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { ScoreGauge } from './ScoreGauge';

export function Hero() {
  const navigate = useNavigate();
  const { mode } = useColorScheme();
  const moveToHowItWorks = () => {
    const sectionElement = document.getElementById('how-it-works');
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      window.scrollTo({ top: targetScroll, behavior: 'smooth' });
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        pt: { xs: 12, md: 16 },
        pb: { xs: 6, md: 8 },
        background:
          mode === 'dark'
            ? 'linear-gradient(to bottom right, rgb(37 67 90 / 80%), #193f5b)'
            : 'linear-gradient(to bottom right, #eff6ff, #eef2ff, #ffffff)',
        overflow: 'hidden',
      }}
    >
      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
        <Box sx={{ textAlign: 'center', mb: { xs: 4, md: 6 } }}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <Typography
              variant="h1"
              sx={{
                mb: 2,
                background:
                  mode === 'dark'
                    ? 'linear-gradient(to right, #60a5fa, #818cf8)'
                    : 'linear-gradient(to right, #2563eb, #4f46e5)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                maxWidth: '800px',
                mx: 'auto',
              }}
            >
              Zrób analizę BIK w 30 sekund i zobacz, co możesz usunąć!
            </Typography>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <Typography
              variant="h5"
              color="text.secondary"
              sx={{
                maxWidth: '800px',
                mx: 'auto',
                mb: 4,
                px: { xs: 2, sm: 4 },
              }}
            >
              Prześlij swój raport BIK i obserwuj, jak nasz system przekształca skomplikowane dane w przejrzyste
              rekomendacje.
            </Typography>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.6 }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: 2,
                justifyContent: 'center',
                alignItems: 'center',
                mb: { xs: 4, md: 6 },
              }}
            >
              <Button
                component={motion.button}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                variant="contained"
                size="large"
                endIcon={<ArrowForward />}
                onClick={() => navigate('/process-bik')}
                sx={{
                  minWidth: 200,
                  height: 56,
                  background:
                    mode === 'dark'
                      ? 'linear-gradient(45deg, #60a5fa, #818cf8)'
                      : 'linear-gradient(45deg, #2563eb, #4f46e5)',
                  borderRadius: '28px',
                  textTransform: 'none',
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  boxShadow:
                    mode === 'dark' ? '0 4px 20px rgba(96, 165, 250, 0.3)' : '0 4px 20px rgba(37, 99, 235, 0.3)',
                  '&:hover': {
                    background:
                      mode === 'dark'
                        ? 'linear-gradient(45deg, #3b82f6, #6366f1)'
                        : 'linear-gradient(45deg, #1d4ed8, #4338ca)',
                    boxShadow:
                      mode === 'dark' ? '0 6px 24px rgba(96, 165, 250, 0.4)' : '0 6px 24px rgba(37, 99, 235, 0.4)',
                  },
                }}
              >
                Prześlij Raport BIK
              </Button>
              <Button
                component={motion.button}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={moveToHowItWorks}
                variant="outlined"
                size="large"
                sx={{
                  minWidth: 200,
                  height: 56,
                  borderRadius: '28px',
                  borderWidth: 2,
                  textTransform: 'none',
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  borderColor: mode === 'dark' ? 'primary.400' : 'primary.main',
                  color: mode === 'dark' ? 'primary.200' : 'primary.main',
                  '&:hover': {
                    borderWidth: 2,
                    borderColor: mode === 'dark' ? 'primary.300' : 'primary.dark',
                    backgroundColor: mode === 'dark' ? 'rgba(96, 165, 250, 0.1)' : 'rgba(37, 99, 235, 0.1)',
                  },
                }}
              >
                Dowiedz się więcej
              </Button>
            </Box>
          </motion.div>

          <ScoreGauge />

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.8 }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: { xs: 2, sm: 4 },
                justifyContent: 'center',
                alignItems: 'center',
                mt: 4,
              }}
            >
              <motion.div whileHover={{ scale: 1.05 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    bgcolor: mode === 'dark' ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.8)',
                    px: 3,
                    py: 1.5,
                    borderRadius: '20px',
                    boxShadow: mode === 'dark' ? '0 4px 12px rgba(0, 0, 0, 0.3)' : '0 4px 12px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <Shield sx={{ color: 'primary.main' }} />
                  <Typography color="text.secondary">Bezpieczeństwo Bankowe</Typography>
                </Box>
              </motion.div>
              <motion.div whileHover={{ scale: 1.05 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    bgcolor: mode === 'dark' ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.8)',
                    px: 3,
                    py: 1.5,
                    borderRadius: '20px',
                    boxShadow: mode === 'dark' ? '0 4px 12px rgba(0, 0, 0, 0.3)' : '0 4px 12px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <InsertDriveFile sx={{ color: 'primary.main' }} />
                  <Typography color="text.secondary">Analiza w 30 Sekund</Typography>
                </Box>
              </motion.div>
            </Box>
          </motion.div>
        </Box>
      </Container>

      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: 0,
          width: 256,
          height: 256,
          bgcolor: mode === 'dark' ? '#1e3a8a' : '#bfdbfe',
          borderRadius: '50%',
          mixBlendMode: 'multiply',
          filter: 'blur(70px)',
          opacity: mode === 'dark' ? 0.3 : 0.7,
          animation: 'blob 7s infinite',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          right: 0,
          width: 256,
          height: 256,
          bgcolor: mode === 'dark' ? '#312e81' : '#c7d2fe',
          borderRadius: '50%',
          mixBlendMode: 'multiply',
          filter: 'blur(70px)',
          opacity: mode === 'dark' ? 0.3 : 0.7,
          animation: 'blob 7s infinite',
          animationDelay: '2s',
        }}
      />
    </Box>
  );
}

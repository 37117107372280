import { AccountBalance, AttachMoney, BusinessCenter, CreditCard, Home, Receipt } from '@mui/icons-material';
import { Box, Button, Toolbar, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { InstitutionDetailsCard } from 'src/pages/PartnerReportPreview/InstitutionDetailsCard';
import { CompanyBIKSummary } from 'src/types/CompanyBIKSummary.type';
import { StructuredBIK, StructuredBIKDetails } from 'src/types/StructuredBIK';
import { ServiceBIKTile } from './ServiceBIKTile';
import { ServiceCommonTile } from './ServiceCommonTile';

type AvailableViews = 'HUB' | 'BIK' | 'SKD' | 'VIBOR' | 'COMMISSION';

const ServicesDashboard = ({
  bikCustomerDetails,
  bikCompanyDetails,
  bikData,
  skdData,
  viborData,
  commissionData,
  selectedService,
  setSelectedService,
}: {
  bikCustomerDetails?: StructuredBIKDetails;
  bikCompanyDetails?: StructuredBIKDetails;
  bikData?: CompanyBIKSummary;
  skdData?: StructuredBIK;
  viborData?: StructuredBIK;
  commissionData?: StructuredBIK;
  selectedService: AvailableViews;
  setSelectedService: (service: AvailableViews) => void;
}) => {
  const theme = useTheme();

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, my: 3 }}>
        <BusinessCenter sx={{ width: 40, height: 40, color: theme.palette.primary.main }} />
        <Box>
          <Typography variant="h3" component="h1" sx={{ fontWeight: 'bold', mb: 1 }}>
            Usługi BIK Master
          </Typography>
          <Typography variant="subtitle1" sx={{ color: theme.palette.text.secondary }}>
            Profesjonalne rozwiązania finansowe dla Twojego biznesu
          </Typography>
        </Box>
      </Box>
      {selectedService === 'HUB' ? (
        <Grid container spacing={3}>
          {bikCompanyDetails && (
            <Grid size={{ xs: 12, md: bikCustomerDetails ? 6 : 12 }}>
              <InstitutionDetailsCard details={bikCompanyDetails} type="COMPANY" />
            </Grid>
          )}
          {bikCustomerDetails && (
            <Grid size={{ xs: 12, md: bikCompanyDetails ? 6 : 12 }}>
              <InstitutionDetailsCard details={bikCustomerDetails} type="CUSTOMER" />
            </Grid>
          )}

          {bikData && (
            <Grid size={{ xs: 12, md: 6, lg: 4 }}>
              <ServiceBIKTile service={bikData} onClick={() => setSelectedService('BIK')} />
            </Grid>
          )}
          {skdData && !!skdData.maximumProfit && (
            <Grid size={{ xs: 12, md: 6, lg: 4 }}>
              <ServiceCommonTile service={skdData} onClick={() => setSelectedService('SKD')} />
            </Grid>
          )}
          {viborData && !!viborData.maximumProfit && (
            <Grid size={{ xs: 12, md: 6, lg: 4 }}>
              <ServiceCommonTile service={viborData} onClick={() => setSelectedService('VIBOR')} />
            </Grid>
          )}
          {commissionData && !!commissionData.maximumProfit && (
            <Grid size={{ xs: 12, md: 6, lg: 4 }}>
              <ServiceCommonTile service={commissionData} onClick={() => setSelectedService('COMMISSION')} />
            </Grid>
          )}
        </Grid>
      ) : (
        <Box>
          <Toolbar
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              background: theme.palette.common.background,
              borderRadius: 3,
              p: 2,
              boxShadow: 3,
            }}
          >
            {/* Scrollable container for buttons */}
            <Box
              sx={{
                display: 'flex',
                overflowY: 'auto', // Enable vertical scrolling
              }}
            >
              {bikData && (
                <Button
                  startIcon={<CreditCard />}
                  onClick={() => setSelectedService('BIK')}
                  sx={{
                    minWidth: 'auto',
                    fontWeight: 'bold',
                    textTransform: 'none',
                    whiteSpace: 'nowrap',
                    '&': {
                      color: selectedService === 'BIK' ? theme.palette.primary.main : theme.palette.text.primary,
                    },
                    '&:hover': { color: '#1976d2' },
                  }}
                >
                  Optymalizacja BIK
                </Button>
              )}
              {skdData && !!skdData.maximumProfit && (
                <Button
                  startIcon={<AttachMoney />}
                  onClick={() => setSelectedService('SKD')}
                  sx={{
                    minWidth: 'auto',
                    fontWeight: 'bold',
                    textTransform: 'none',
                    whiteSpace: 'nowrap',
                    '&': {
                      color: selectedService === 'SKD' ? theme.palette.primary.main : theme.palette.text.primary,
                    },
                    '&:hover': { color: '#1976d2' },
                  }}
                >
                  Sankcja Kredytu Darmowego
                </Button>
              )}
              {viborData && !!viborData.maximumProfit && (
                <Button
                  startIcon={<AccountBalance />}
                  onClick={() => setSelectedService('VIBOR')}
                  sx={{
                    minWidth: 'auto',
                    fontWeight: 'bold',
                    textTransform: 'none',
                    whiteSpace: 'nowrap',
                    '&': {
                      color: selectedService === 'VIBOR' ? theme.palette.primary.main : theme.palette.text.primary,
                    },
                    '&:hover': { color: '#1976d2' },
                  }}
                >
                  Odwiborowanie
                </Button>
              )}
              {commissionData && !!commissionData.maximumProfit && (
                <Button
                  startIcon={<Receipt />}
                  onClick={() => setSelectedService('COMMISSION')}
                  sx={{
                    minWidth: 'auto',
                    fontWeight: 'bold',
                    textTransform: 'none',
                    whiteSpace: 'nowrap',
                    '&': {
                      color: selectedService === 'COMMISSION' ? theme.palette.primary.main : theme.palette.text.primary,
                    },
                    '&:hover': { color: '#1976d2' },
                  }}
                >
                  Zwrot prowizji
                </Button>
              )}
            </Box>

            {/* Return Button */}
            <Button
              onClick={() => setSelectedService('HUB')}
              startIcon={<Home />}
              sx={{
                background: theme.palette.primary.main,
                color: 'white',
                fontWeight: 'bold',
                px: 4,
                py: 1.5,
                borderRadius: 2,
                boxShadow: 3,
                '&:hover': { background: '#1565c0' },
              }}
            >
              Powrót
            </Button>
          </Toolbar>
        </Box>
      )}
    </Box>
  );
};

export default ServicesDashboard;

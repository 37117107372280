import { DarkMode, LightMode } from '@mui/icons-material';
import { IconButton, Tooltip, useColorScheme } from '@mui/material';

export function DarkModeToggle() {
  const { mode, setMode } = useColorScheme();

  return (
    <Tooltip title={mode === 'dark' ? 'Tryb jasny' : 'Tryb ciemny'}>
      <IconButton
        onClick={() => setMode(mode === 'dark' ? 'light' : 'dark')}
        color="inherit"
        sx={{
          bgcolor: mode === 'dark' ? 'background.paper' : 'white',
          '&:hover': {
            bgcolor: mode === 'dark' ? 'background.default' : 'grey.100',
          },
        }}
      >
        {mode === 'dark' ? <LightMode /> : <DarkMode />}
      </IconButton>
    </Tooltip>
  );
}

import { useCallback, useMemo, useState } from 'react';

import { PartnerDetails } from 'src/types/PartnerDetails';
import { UserActions } from '../UserLayout/Actions/UserActions';
import { UserLayout } from '../UserLayout/UserLayout';

type useUserRowLayoutProps = {
  user?: PartnerDetails;
  options?: {
    refetch?: () => void;
    users?: PartnerDetails[];
  };
};
export const useUserRowLayout = ({ user, options }: useUserRowLayoutProps) => {
  const [isRowExpanded, setIsRowExpanded] = useState(false);

  const toggleExpanded = useCallback(() => {
    setIsRowExpanded(!isRowExpanded);
  }, [isRowExpanded]);

  const UserRowLayout = useMemo(() => user && <UserLayout user={user} />, [user]);
  const UserDetails = useMemo(() => user && <UserActions user={user} refetch={options?.refetch} />, [user]);

  if (!user) {
    return null;
  }

  const isMissingCommission = user.commissions?.length === 0;

  const loadingText = 'Wczytywanie szczegółów użytkownika...';

  return {
    rowLayout: UserRowLayout,
    rowDetails: UserDetails,
    isAttentionRequired: isMissingCommission,
    isRowExpanded,
    loadingText,
    toggleExpanded,
  };
};

import Container from '@mui/material/Container';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useBIKSummary } from 'src/api/bikApi';
import { Header } from 'src/components/app-toolbar/Header';
import Footer from 'src/components/Footer';
import ScrollToTop from 'src/components/ScrollToTop';
import SummaryDetails from 'src/components/SummaryDetails/SummaryDetails';
import BIKNotFound from 'src/sites/commonSites/BIKNotFound';
import LoadingSite from 'src/sites/commonSites/LoadingSite';

const Summary: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // UseParams typed
  const { data, isLoading, refetch } = useBIKSummary(id ?? '');

  if (isLoading) return <LoadingSite />;

  if (!data?.id)
    return (
      <>
        <BIKNotFound />
      </>
    );

  return (
    <>
      <ScrollToTop />
      <Header type="back" />
      <Container
        sx={{ pt: { xs: 12, sm: 12 }, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 6 }}
      >
        <SummaryDetails data={data} refetchData={refetch} />
      </Container>
      <Footer />
    </>
  );
};

export default Summary;

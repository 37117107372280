import { ErrorOutline } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { acceptReportConsentWithToken, useConsentTokenValidity } from 'src/api/bikApi';
import { useSnackbar } from 'src/common/useSnackbar';
import { Header } from 'src/components/app-toolbar/Header';
import { MainConsent } from 'src/components/MainConsent/MainConsent';
import { Footer } from 'src/pages/LandingPage/Footer';
import LoadingSite from '../commonSites/LoadingSite';

const UserConsentAcceptationPage = () => {
  const { showSnackbar } = useSnackbar();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [isAccepted, setIsAccepted] = useState(false);
  const [isConsentLoading, setIsConsentLoading] = useState(false);
  const { data, isLoading, isError } = useConsentTokenValidity(token ?? '');

  useEffect(() => {
    if (data?.status === 'ACCEPTED') {
      setIsAccepted(true);
    }
  }, [data]);

  if (isLoading) {
    return <LoadingSite />;
  }

  const handleAccept = async () => {
    setIsConsentLoading(true);

    try {
      await acceptReportConsentWithToken(token ?? '');
      setIsAccepted(true);

      showSnackbar('Regulamin został zaakceptowany pomyślnie', 'success');
    } catch (error: any) {
      if (error.responseBody.includes('Cannot find consent by incoming token')) {
        showSnackbar(
          'Link weryfikacyjny wygasł lub jest nieprawidłowy. Spróbuj ponownie lub skontaktuj się z partnerem prowadzącym proces w celu uzyskania nowego adresu strony.',
          'error'
        );
        return;
      }
      showSnackbar(
        'Wystąpił błąd podczas akceptowania regulaminu. Skontaktuj się z partnerem prowadzącym proces.',
        'error'
      );
    } finally {
      setIsConsentLoading(false);
    }
  };

  return (
    <Box sx={{ minHeight: '100vh', bgcolor: 'background.default' }}>
      <Header type="back" />
      <Box component="main" sx={{ justifyItems: 'center', mt: 8 }}>
        {isError || data?.status === 'EXPIRED' || data?.status === 'NOT_GENERATED' ? (
          <Box
            sx={{
              textAlign: 'center',
              p: 4,
              maxWidth: 600,
              mx: 'auto',
              minHeight: '50vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ErrorOutline sx={{ fontSize: 64, color: 'error.light', mb: 2 }} />
            <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', mb: 2 }}>
              Ups! Coś poszło nie tak
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
              Link weryfikacyjny wygasł lub jest nieprawidłowy. Spróbuj ponownie lub skontaktuj się z partnerem
              prowadzącym proces w celu uzyskania nowego adresu strony.
            </Typography>
          </Box>
        ) : (
          <MainConsent isLoading={isConsentLoading} hasRegulationsAccepted={isAccepted} onAccept={handleAccept} />
        )}
      </Box>
      <Footer />
    </Box>
  );
};

export default UserConsentAcceptationPage;

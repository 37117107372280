import { useQuery } from '@tanstack/react-query';
import { ReportStatus } from 'src/common/reports';
import { RoleType } from 'src/common/roles';
import { BIKComplete } from 'src/types/BIKComplete';
import { BIKReportUserData } from 'src/types/BIKReportUserData';
import { BIKStatistics } from 'src/types/BIKStatistics';
import { BIKSummary, RegulationsConsentStatus } from 'src/types/BIKSummary';
import { CommissionObject } from 'src/types/CommissionResponse';
import { CommunicationPreferences } from 'src/types/CommunicationPreferences.type';
import { Company, CompanyDetailsFromNip } from 'src/types/Company';
import { CompanyBIKSummary } from 'src/types/CompanyBIKSummary.type';
import { BikCreditReportResponse } from 'src/types/CreditCalculation';
import { Customer } from 'src/types/Customer';
import { ManagerReport } from 'src/types/ManagerReport';
import { Commission, CommissionProductType, PartnerDetails } from 'src/types/PartnerDetails';
import { PartnerReport } from 'src/types/PartnerReport';
import { PositionProView, ProView } from 'src/types/ProView';
import { ReportType } from 'src/types/ReportType';
import { SanitazedReport } from 'src/types/SanitazedReport';
import { StructuredBIK } from 'src/types/StructuredBIK';
import { TimelineCommissionStatus } from 'src/types/TimelineStatus';
import {
  transformBIKReport,
  transformCommissionReport,
  transformSKDReport,
  transformUnwiborReport,
} from 'src/utils/bikMapper';

const API_LINK = import.meta.env.VITE_API_URL;
const API_KEY = import.meta.env.VITE_API_KEY;

export class FetchError extends Error {
  status: number;
  statusText: string;
  responseBody: string | null;

  constructor(status: number, statusText: string, responseBody: string | null) {
    super(`Error ${status}: ${statusText}`);
    this.status = status;
    this.statusText = statusText;
    this.responseBody = responseBody;

    // Maintains proper stack trace for where our error was thrown (only available on V8 engines)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }
  }
}

const getDefaultHeaders = (): Record<string, string> | undefined => {
  const token = localStorage.getItem('authToken');

  if (!token && !API_KEY) {
    return undefined;
  }

  const headers: Record<string, string> = {};

  if (API_KEY) {
    headers['X-Api-Key'] = API_KEY;
  }

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  return headers;
};

const handleError = async (response: Response) => {
  if (!response.ok) {
    const errorBody = await response.text(); // Get the response body as text

    // Throw the custom FetchError object
    throw new FetchError(response.status, response.statusText, errorBody);
  }

  return response;
};

// Upload Functions
export const uploadCompanyBIKReport = async (reportId: string, file: File) => {
  const url = new URL(`${API_LINK}/bik-reports/${reportId}/upload-bik-company`);

  const formData = new FormData();
  formData.append('file', file);

  const response = await fetch(url, {
    headers: getDefaultHeaders(),
    method: 'PUT',
    body: formData,
  });

  await handleError(response); // Handle errors
  return response;
};

export const uploadCustomerBIKReport = async (reportId: string, file: File) => {
  const url = new URL(`${API_LINK}/bik-reports/${reportId}/upload-bik-customer`);

  const formData = new FormData();
  formData.append('file', file);

  const response = await fetch(url, {
    headers: getDefaultHeaders(),
    method: 'PUT',
    body: formData,
  });

  await handleError(response); // Handle errors
  return response;
};

// BIK Report Functions
export const createBIKReport = async (customerType: 'SUPPORT' | 'OWNER', ref?: string) => {
  const url = new URL(`${API_LINK}/bik-reports`);

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ customerType, ref }),
  });

  await handleError(response); // Handle errors
  return response.json();
};

// Entry Selection Functions
export const sendContactRequest = async (reportId: string, data: CommunicationPreferences) => {
  const url = new URL(`${API_LINK}/bik-reports/${reportId}/contacts`);

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      contactPersonEmailConsent: data.consent1,
      contactPersonEmail: data.email,
      contactPersonPhoneConsent: data.consent2,
      contactPersonPhone: data.phone,
    }),
  });

  await handleError(response); // Handle errors
  return response;
};

export const updateUserCommission = async (userId: string, commissions: Commission[]) => {
  const url = `${API_LINK}/users/${userId}/commissions`;

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(commissions),
  });

  await handleError(response); // Handle errors
  return response;
};

export const sendSummaryMessage = async (reportId: string) => {
  const url = new URL(`${API_LINK}/bik-reports/${reportId}/send-summary-message`);

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
  });

  await handleError(response); // Handle errors
  return response;
};

export const createBIKPayment = async (reportId: string) => {
  const url = new URL(`${API_LINK}/bik-reports/${reportId}/payments`);

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
  });

  await handleError(response); // Handle errors
  return response;
};

// Fetch a BIK Report
const getBIKReport = async (reportId: string) => {
  const url = new URL(`${API_LINK}/bik-reports/${reportId}`);

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

const getPartnersBIKReport = async (reportId: string) => {
  const url = new URL(`${API_LINK}/partners/details/bik-reports/${reportId}`);

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

// Fetch a BIK Report
const getBIKSummary = async (reportId: string) => {
  const url = new URL(`${API_LINK}/bik-reports/${reportId}/summary`);

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const useBIKSummary = (reportId: string, isEnabled?: boolean) => {
  return useQuery<BIKSummary>({
    enabled: isEnabled,
    queryKey: ['bik-summary', reportId, isEnabled],
    queryFn: () => getBIKSummary(reportId),
    initialData: undefined,
    staleTime: 500,
    gcTime: 0,
    retry: false,
    refetchOnWindowFocus: 'always',
    retryOnMount: false,
  });
};

export const useBIKReports = (reportId: string, isClientView: boolean, isEnabled?: boolean) => {
  return useQuery<CompanyBIKSummary>({
    enabled: isEnabled,
    queryKey: ['bik-report', reportId, isClientView, isEnabled],
    queryFn: () => (isClientView ? getBIKReport(reportId) : getPartnersBIKReport(reportId)),
    retry: false,
    retryOnMount: false,
  });
};

export const getSKDData = async (id: string) => {
  const url = `${API_LINK}/bik-reports/${id}/skd`;

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const getPartnersSKDData = async (id: string) => {
  const url = `${API_LINK}/partners/details/bik-reports/${id}/skd`;

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const getUnwiborData = async (id: string) => {
  const url = `${API_LINK}/bik-reports/${id}/unwibor`;

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const getPartnersUnwiborData = async (id: string) => {
  const url = `${API_LINK}/partners/details/bik-reports/${id}/unwibor`;

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const getCommissionData = async (id: string) => {
  const url = `${API_LINK}/bik-reports/${id}/commission`;

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const getPartnersCommissionData = async (id: string) => {
  const url = `${API_LINK}/partners/details/bik-reports/${id}/commission`;

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const useStructuredBIKReport = (reportId: string, isClientView: boolean, isEnabled: boolean) => {
  return useQuery<{ structuredReport: StructuredBIK[]; rawReport: CompanyBIKSummary }>({
    enabled: isEnabled,
    queryKey: ['structured-bik-report', reportId, isClientView],
    queryFn: async () => {
      const data = isClientView ? await getBIKReport(reportId) : await getPartnersBIKReport(reportId);
      return { structuredReport: transformBIKReport(data), rawReport: data };
    },
    retry: false,
    retryOnMount: false,
  });
};

export const useStructuredSKDReport = (reportId: string, isClientView: boolean, isEnabled: boolean) => {
  return useQuery<StructuredBIK>({
    enabled: isEnabled,
    queryKey: ['skd-report', reportId, isClientView],
    queryFn: async () => {
      const data = isClientView ? await getSKDData(reportId) : await getPartnersSKDData(reportId);
      return transformSKDReport(data);
    },
    retry: false,
    retryOnMount: false,
  });
};

export const useStructuredUnwiborReport = (reportId: string, isClientView: boolean, isEnabled: boolean) => {
  return useQuery<StructuredBIK>({
    enabled: isEnabled,
    queryKey: ['unwibor-report', reportId, isClientView],
    queryFn: async () => {
      const data = isClientView ? await getUnwiborData(reportId) : await getPartnersUnwiborData(reportId);
      return transformUnwiborReport(data);
    },
    retry: false,
    retryOnMount: false,
  });
};

export const useStructuredCommissionReport = (reportId: string, isClientView: boolean, isEnabled: boolean) => {
  return useQuery<StructuredBIK>({
    enabled: isEnabled,
    queryKey: ['commission-report', reportId, isClientView],
    queryFn: async () => {
      const data = isClientView ? await getCommissionData(reportId) : await getPartnersCommissionData(reportId);
      return transformCommissionReport(data);
    },
    retry: false,
    retryOnMount: false,
  });
};

const getPartnerReports = async (
  page = 0,
  perPage = 10,
  searchText = '',
  selectedStatus: ReportStatus | '',
  selectedBIKService: CommissionProductType | '',
  dateFrom: string,
  dateTo: string
) => {
  const url = new URL(`${API_LINK}/partners/details/bik-reports`);
  url.searchParams.append('page', page.toString());
  url.searchParams.append('per-page', perPage.toString());
  url.searchParams.append('search', searchText);
  url.searchParams.append('createdAtFrom', dateFrom);
  url.searchParams.append('createdAtTo', dateTo);

  if (selectedStatus) {
    url.searchParams.append('status', selectedStatus);
  }

  if (selectedBIKService) {
    url.searchParams.append('selectedProducts', selectedBIKService);
  }

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const usePartnerReports = (
  page: number,
  perPage: number,
  searchText: string,
  selectedStatus: ReportStatus | '',
  selectedBIKService: CommissionProductType | '',
  dateFrom: string,
  dateTo: string
) => {
  return useQuery<{ totalElements: number; items: PartnerReport[] }>({
    queryKey: ['partner-reports', page, perPage, searchText, selectedStatus, selectedBIKService, dateFrom, dateTo],
    queryFn: () => getPartnerReports(page, perPage, searchText, selectedStatus, selectedBIKService, dateFrom, dateTo),
    retry: false,
    retryOnMount: false,
  });
};

export const createCustomerForBIKReport = async (customer: Customer) => {
  const url = new URL(`${API_LINK}/customers`);

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...customer }),
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const createCompanyForBIKReport = async (company: Company) => {
  const url = new URL(`${API_LINK}/companies`);

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...company }),
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const updateBIKReportUserData = async (reportId: string, data: BIKReportUserData) => {
  const url = new URL(`${API_LINK}/bik-reports/${reportId}/update-user-data`);

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...data }),
  });

  await handleError(response); // Handle errors
  return response;
};

export const sendRegulationsConsentToCustomer = async (reportId: string) => {
  const url = new URL(`${API_LINK}/bik-reports/${reportId}/send-regulations-consent`);

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response;
};

export const updateBIKDiscount = async (reportId: string, code: string | null) => {
  const url = new URL(`${API_LINK}/bik-reports/${reportId}/discount`);

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ code }),
  });

  await handleError(response); // Handle errors
  return response;
};

export const complateBIKReport = async (reportId: string, data: BIKComplete) => {
  const url = new URL(`${API_LINK}/bik-reports/${reportId}/complete`);

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...data }),
  });

  await handleError(response); // Handle errors
  return response;
};

export const signInPartner = async (email: string, password: string) => {
  const url = `${API_LINK}/users/auth/login`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password }),
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const userAcceptRodoConsents = async (id: string) => {
  const url = new URL(`${API_LINK}/users/${id}/accept-rodo-consents`);

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
  });

  await handleError(response); // Handle errors
  return response;
};

export const userAcceptMarketingConsents = async (id: string) => {
  const url = new URL(`${API_LINK}/users/${id}/accept-marketing-consents`);

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
  });

  await handleError(response); // Handle errors
  return response;
};

export const resetPassword = async (email: string) => {
  const url = `${API_LINK}/users/auth/recovery-password`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  });

  await handleError(response); // Handle errors
  return response;
};

export const resetPasswordWithToken = async (token: string, password: string) => {
  const url = `${API_LINK}/users/auth/set-new-password`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token, password }),
  });

  await handleError(response); // Handle errors
  return response;
};

export const refreshToken = async () => {
  const url = `${API_LINK}/users/auth/refresh-token`;

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const getUserDetails = async () => {
  const url = new URL(`${API_LINK}/users/details`);

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const usePartnerDetails = () => {
  const isEnabled = Boolean(localStorage.getItem('authToken'));

  return useQuery<PartnerDetails>({
    queryKey: ['user-details'],
    enabled: isEnabled,
    staleTime: 3600,
    queryFn: () => getUserDetails(),
    retry: false,
    retryOnMount: false,
  });
};

export const getManagerPartners = async () => {
  const url = new URL(`${API_LINK}/managers/details/users`);

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const useManagerPartnersList = () => {
  return useQuery<{ totalElements: number; items: PartnerDetails[] }>({
    queryKey: ['managers-partners-details'],
    queryFn: () => getManagerPartners(),
    retry: false,
    retryOnMount: false,
  });
};

export const getReportsForManager = async (
  page = 0,
  perPage = 10,
  searchText = '',
  selectedStatus: ReportStatus | '',
  selectedBIKService: CommissionProductType | '',
  dateFrom: string,
  dateTo: string
) => {
  const url = new URL(`${API_LINK}/managers/details/bik-reports`);
  url.searchParams.append('page', page.toString());
  url.searchParams.append('per-page', perPage.toString());
  url.searchParams.append('search', searchText);
  url.searchParams.append('createdAtFrom', dateFrom);
  url.searchParams.append('createdAtTo', dateTo);

  if (selectedStatus) {
    url.searchParams.append('status', selectedStatus);
  }

  if (selectedBIKService) {
    url.searchParams.append('selectedProducts', selectedBIKService);
  }

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const useReportsForManager = (
  page: number,
  perPage: number,
  searchText: string,
  selectedStatus: ReportStatus | '',
  selectedBIKService: CommissionProductType | '',
  dateFrom: string,
  dateTo: string
) => {
  return useQuery<{ totalElements: number; items: ManagerReport[] }>({
    queryKey: ['reports-for-manager', page, perPage, searchText, selectedStatus, selectedBIKService, dateFrom, dateTo],
    queryFn: () =>
      getReportsForManager(page, perPage, searchText, selectedStatus, selectedBIKService, dateFrom, dateTo),
    retry: false,
    retryOnMount: false,
  });
};

// BIK Report Functions
export const createPartnerForManager = async (
  data: Omit<
    PartnerDetails,
    | 'id'
    | 'friendlyId'
    | 'hasMarketingConsentsAccepted'
    | 'hasRODOConsentsAccepted'
    | 'commissions'
    | 'regulationsConsentVersion'
    | 'regulationsConsentAcceptedAt'
  >
) => {
  const url = new URL(`${API_LINK}/managers/partners/signup`);

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...data }),
  });

  await handleError(response); // Handle errors
  return response;
};

export const updateReportAliasByManager = async (reportId: string, alias: string) => {
  const url = new URL(`${API_LINK}/managers/details/bik-reports/${reportId}/update-alias`);

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      alias,
    }),
  });

  await handleError(response); // Handle errors
  return response;
};

export const updateReportCommentByManager = async (reportId: string, comment: string) => {
  const url = new URL(`${API_LINK}/managers/details/bik-reports/${reportId}/update-comment`);

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      comment,
    }),
  });

  await handleError(response); // Handle errors
  return response;
};

export const updateReportPaidByManager = async (reportId: string, isPaid: boolean) => {
  const url = new URL(`${API_LINK}/managers/details/bik-reports/${reportId}/update-is-paid`);

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      isPaid,
    }),
  });

  await handleError(response); // Handle errors
  return response;
};

export const updateReportAliasByPartner = async (reportId: string, alias: string) => {
  const url = new URL(`${API_LINK}/partners/details/bik-reports/${reportId}/update-alias`);

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      alias,
    }),
  });

  await handleError(response); // Handle errors
  return response;
};

export const updateReportCommentByPartner = async (reportId: string, comment: string) => {
  const url = new URL(`${API_LINK}/partners/details/bik-reports/${reportId}/update-comment`);

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      comment,
    }),
  });

  await handleError(response); // Handle errors
  return response;
};

export const updateReportPaidByPartner = async (reportId: string, isPaid: boolean) => {
  const url = new URL(`${API_LINK}/partners/details/bik-reports/${reportId}/update-is-paid`);

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      isPaid,
    }),
  });

  await handleError(response); // Handle errors
  return response;
};

export const getCompanyDetailsFromNip = async (nip: string) => {
  const url = new URL(`${API_LINK}/companies/search/${nip}`);

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const useCompanyDetailsFromNip = (nip: string) => {
  return useQuery<{ company: CompanyDetailsFromNip }>({
    enabled: Boolean(nip),
    queryKey: ['company-details-from-nip', nip],
    queryFn: () => getCompanyDetailsFromNip(nip),
    retry: false,
    refetchOnWindowFocus: false,
    retryOnMount: false,
  });
};

export const getStaticticsForManager = async (from: string, to: string, type: ReportType | '', partnerId?: string) => {
  const url = new URL(`${API_LINK}/managers/details/bik-reports/statistics`);
  url.searchParams.append('from', from);
  url.searchParams.append('to', to);

  if (type) {
    url.searchParams.append('type', type);
  }

  if (partnerId) {
    url.searchParams.append('partnerId', partnerId);
  }

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const useStatisticsForManager = (from: string, to: string, type: ReportType | '', partnerId?: string) => {
  return useQuery<BIKStatistics>({
    queryKey: ['statistics-for-manager', from, to, type, partnerId],
    queryFn: () => getStaticticsForManager(from, to, type, partnerId),
    retry: false,
    retryOnMount: false,
  });
};

export const getStaticticsFoPartner = async (from: string, to: string, type: ReportType | '') => {
  const url = new URL(`${API_LINK}/partners/details/bik-reports/statistics`);
  url.searchParams.append('from', from);
  url.searchParams.append('to', to);

  if (type) {
    url.searchParams.append('type', type);
  }

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const useStatisticsForPartner = (from: string, to: string, type: ReportType | '') => {
  return useQuery<BIKStatistics>({
    queryKey: ['statistics-for-partner', from, to, type],
    queryFn: () => getStaticticsFoPartner(from, to, type),
    retry: false,
    retryOnMount: false,
  });
};

const getBIKStatuses = async (id: string) => {
  const url = new URL(`${API_LINK}/bik-reports/${id}/statuses`);

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const useBIKStatuses = (bikId: string | '', isEnabled: boolean) => {
  return useQuery<{ createdAt: string; status: ReportStatus }[]>({
    enabled: isEnabled && Boolean(bikId),
    queryKey: ['bik-statuses', bikId],
    queryFn: () => getBIKStatuses(bikId),
    retry: false,
    retryOnMount: false,
  });
};

const getProViewForPosition = async (id: string, positionId: string) => {
  const url = new URL(`${API_LINK}/bik-reports/${id}/credits/${positionId}/pro-view`);

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const usePositionProView = (id: string, positionId: string) => {
  return useQuery<PositionProView>({
    queryKey: ['bik-position-pro-view', id, positionId],
    queryFn: () => getProViewForPosition(id, positionId),
    retry: false,
    retryOnMount: false,
  });
};

const getProViewForReport = async (id: string) => {
  const url = new URL(`${API_LINK}/bik-reports/${id}/pro-view`);

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const useReportProView = (id: string) => {
  return useQuery<ProView>({
    queryKey: ['bik-report-pro-view', id],
    queryFn: () => getProViewForReport(id),
    retry: false,
    retryOnMount: false,
  });
};

export const createCommissionsServices = async (reportId: string, reportCommissionEntriesIds: string[]) => {
  const url = new URL(`${API_LINK}/commissions`);

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ reportId, reportCommissionEntriesIds }),
  });

  await handleError(response); // Handle errors
  return response;
};

export const getCreatedCommissions = async (reportId: string) => {
  const url = new URL(`${API_LINK}/commissions/bik-reports/${reportId}`);

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const getPartnerCreatedCommissions = async (reportId: string) => {
  const url = new URL(`${API_LINK}/partners/commissions/bik-reports/${reportId}`);

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const getManagerCreatedCommissions = async (reportId: string) => {
  const url = new URL(`${API_LINK}/managers/commissions/bik-reports/${reportId}`);

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const useCommissionsForBIK = (bikId: string, isEnabled: boolean, type: RoleType, isClientView = false) => {
  return useQuery<CommissionObject[]>({
    enabled: isEnabled,
    queryKey: ['bik-commissions-for-bik', bikId, isEnabled, isClientView, type],
    queryFn: () =>
      isClientView
        ? getCreatedCommissions(bikId)
        : type === 'ROLE_PARTNER'
          ? getPartnerCreatedCommissions(bikId)
          : getManagerCreatedCommissions(bikId),
    retry: false,
    retryOnMount: false,
  });
};

export const resendCustomerDocuments = async (connectedId: string, customerId: string) => {
  const url = new URL(`${API_LINK}/admin/commissions/${connectedId}/customers/${customerId}/resend-documents`);

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
  });

  await handleError(response);
  return response;
};

export const updateCommissionProcessing = async (commissionId: string, note: string) => {
  const url = new URL(`${API_LINK}/law-office/commissions/${commissionId}/processing`);

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ note }),
  });

  await handleError(response);
  return response;
};

export const updateCommissionProcessingSuccess = async (commissionId: string, note: string, finalProfit: number) => {
  const url = new URL(`${API_LINK}/law-office/commissions/${commissionId}/processing-success`);

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ finalProfit, note }),
  });

  await handleError(response);
  return response;
};

export const updateCommissionProcessingFail = async (commissionId: string, note: string) => {
  const url = new URL(`${API_LINK}/law-office/commissions/${commissionId}/processing-fail`);

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ note }),
  });

  await handleError(response);
  return response;
};

export const addCommissionNote = async (commissionId: string, note: string) => {
  const url = new URL(`${API_LINK}/commissions/${commissionId}/note`);

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ note }),
  });

  await handleError(response);
  return response;
};

const getPartnerCommissionList = async (page = 0, perPage = 10, searchText = '') => {
  const url = new URL(`${API_LINK}/partners/commissions`);
  url.searchParams.append('page', page.toString());
  url.searchParams.append('per-page', perPage.toString());
  url.searchParams.append('search', searchText);

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

const getManagerCommissionList = async (page = 0, perPage = 10, searchText = '') => {
  const url = new URL(`${API_LINK}/managers/commissions`);
  url.searchParams.append('page', page.toString());
  url.searchParams.append('per-page', perPage.toString());
  url.searchParams.append('search', searchText);

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

const getAdminCommissionList = async (page = 0, perPage = 10, searchText = '') => {
  const url = new URL(`${API_LINK}/admin/commissions`);
  url.searchParams.append('page', page.toString());
  url.searchParams.append('per-page', perPage.toString());
  url.searchParams.append('search', searchText);

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

const getLawOfficeCommissionList = async (page = 0, perPage = 10, searchText = '') => {
  const url = new URL(`${API_LINK}/law-office/commissions`);
  url.searchParams.append('page', page.toString());
  url.searchParams.append('per-page', perPage.toString());
  url.searchParams.append('search', searchText);

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

const commissionsListRecord = (type: RoleType) => {
  switch (type) {
    case 'ROLE_PARTNER':
      return getPartnerCommissionList;
    case 'ROLE_MANAGER':
      return getManagerCommissionList;
    case 'ROLE_ADMIN':
      return getAdminCommissionList;
    case 'ROLE_LAW_OFFICE':
      return getLawOfficeCommissionList;
    default:
      return getPartnerCommissionList;
  }
};

export const useCommissionsList = (page: number, perPage: number, searchText: string, type: RoleType) => {
  return useQuery<{ totalElements: number; content: CommissionObject[] }>({
    queryKey: ['commissions-list', page, perPage, searchText, type],
    queryFn: () => {
      const request = commissionsListRecord(type);
      return request(page, perPage, searchText);
    },
    retry: false,
    retryOnMount: false,
  });
};

export const getCommissionStatuses = async (commissionId: string) => {
  const url = new URL(`${API_LINK}/commissions/${commissionId}/statuses`);

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const useCommissionStatuses = (commissionId: string) => {
  return useQuery<TimelineCommissionStatus[]>({
    queryKey: ['commission-statuses', commissionId],
    queryFn: () => getCommissionStatuses(commissionId),
    retry: false,
    retryOnMount: false,
  });
};

export const updateCommissionIsPaidToPartner = async (commissionId: string, isPaid: boolean) => {
  const url = new URL(`${API_LINK}/admin/${commissionId}/partner-is-paid`);

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      isPaid,
    }),
  });

  await handleError(response); // Handle errors
  return response;
};

export const updateCommissionIsPaidToCustomer = async (commissionId: string, isPaid: boolean) => {
  const url = new URL(`${API_LINK}/law-office/commissions/${commissionId}/customer-is-paid`);

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      isPaid,
    }),
  });

  await handleError(response); // Handle errors
  return response;
};

export const getCustomerData = async (customerId: string) => {
  const url = new URL(`${API_LINK}/customers/${customerId}`);

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const useCustomerData = (customerId: string) => {
  return useQuery<Customer>({
    queryKey: ['customer-data', customerId],
    queryFn: () => getCustomerData(customerId),
    retry: false,
    retryOnMount: false,
  });
};

export const getPartnerData = async (partnerId: string) => {
  const url = new URL(`${API_LINK}/users/${partnerId}`);

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const usePartnerData = (partnerId: string) => {
  return useQuery<PartnerDetails>({
    queryKey: ['partner-data', partnerId],
    queryFn: () => getPartnerData(partnerId),
    retry: false,
    retryOnMount: false,
  });
};

export const acceptUserConsentWithVersion = async (version: string) => {
  const url = new URL(`${API_LINK}/users/details/accept-regulations-consent`);

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      version,
    }),
  });

  await handleError(response); // Handle errors
  return response;
};

export const checkConsentTokenValidity = async (token: string) => {
  const url = new URL(`${API_LINK}/bik-reports/check-regulations-consent/${token}`);

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const acceptReportConsentWithToken = async (token: string) => {
  const url = new URL(`${API_LINK}/bik-reports/accept-regulations-consent`);

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token,
    }),
  });

  await handleError(response); // Handle errors
  return response;
};

export const useConsentTokenValidity = (token: string) => {
  return useQuery<{ status: RegulationsConsentStatus }>({
    queryKey: ['token-validity', token],
    queryFn: () => checkConsentTokenValidity(token),
    retry: false,
    retryOnMount: false,
  });
};

export const getSanitazedBIKSummary = async (reportId: string) => {
  const url = new URL(`${API_LINK}/bik-reports/${reportId}/sanitized-preview`);

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const useSanitazedBIKSummary = (reportId: string, isEnabled?: boolean) => {
  return useQuery<SanitazedReport>({
    queryKey: ['sanitazed-bik-summary', reportId],
    queryFn: () => getSanitazedBIKSummary(reportId),
    enabled: isEnabled,
    retry: false,
    retryOnMount: false,
  });
};

export const getCreditBIKCalculations = async (reportId: string, multiplier: number) => {
  const url = new URL(`${API_LINK}/bik-reports/${reportId}/total-balances`);

  url.searchParams.append('multiplier', multiplier.toString());

  const response = await fetch(url, {
    headers: {
      ...getDefaultHeaders(),
    },
  });

  await handleError(response); // Handle errors
  return response.json();
};

export const useCreditBIKCalculations = (reportId: string, multiplier: number, isEnabled?: boolean) => {
  return useQuery<BikCreditReportResponse>({
    queryKey: ['credit-bik-calculations', reportId, multiplier],
    queryFn: () => getCreditBIKCalculations(reportId, multiplier),
    enabled: isEnabled,
    retry: false,
    retryOnMount: false,
  });
};

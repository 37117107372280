import { CircularProgress, Typography } from '@mui/material';
import { FunctionComponent, memo } from 'react';
import { useCommissionsForBIK } from 'src/api/bikApi';
import { RoleType } from 'src/common/roles';
import { Report } from 'src/types/Report';
import BIKCommissionItem from './BIKCommissionItem';

interface BIKCommissionsDetailsProps {
  bikReport: Report;
  type: RoleType;
}

const BIKCommissionsDetails: FunctionComponent<BIKCommissionsDetailsProps> = ({ bikReport, type }) => {
  const { data, isLoading: isCommissionsLoading, refetch } = useCommissionsForBIK(bikReport.id, true, type);

  const isLoading = isCommissionsLoading;

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!data || data.length === 0) {
    return <Typography variant="body1">Brak stworzonych zleceń prowizji tego raportu</Typography>;
  }

  return data.map((commission) => <BIKCommissionItem key={commission.id} commission={commission} refetch={refetch} />);
};

export default memo(BIKCommissionsDetails);

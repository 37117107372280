import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { getUserDetailsFromLocalStorage } from 'src/common/roles';
import { OrderSummaryVariant } from '../OrderSummaryVariant.types';
import { OrderSectionTypeItem } from './OrderSection.types';

interface OrderTableProps {
  items: OrderSectionTypeItem[];
  variant?: OrderSummaryVariant;
}

export function OrderTable({ items, variant = 'default' }: OrderTableProps) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isEstimated = variant === 'estimated';
  const roles = getUserDetailsFromLocalStorage()?.roles || [];
  const isTextVisible =
    (isEstimated && (roles.includes('ROLE_LAW_OFFICE') || roles.includes('ROLE_ADMIN'))) || !isEstimated;
  const priceLabel = isEstimated ? 'Orientacyjna cena netto' : 'Cena netto';

  return (
    <TableContainer
      sx={{
        backgroundColor: isDarkMode ? 'grey.800' : 'white',
        borderRadius: 2,
        overflowX: 'auto',
      }}
    >
      <Table>
        {/* Table Head */}
        <TableHead>
          <TableRow>
            {!isMobile && <TableCell sx={{ color: 'indigo.600', fontWeight: 'bold' }}>L.p.</TableCell>}
            <TableCell sx={{ color: 'indigo.600', fontWeight: 'bold' }}>
              {isEstimated ? 'Data podpisania' : 'Typ'}
            </TableCell>
            <TableCell sx={{ color: 'indigo.600', fontWeight: 'bold' }}>Instytucja</TableCell>
            <TableCell sx={{ color: 'indigo.600', fontWeight: 'bold' }}>Typ raportu</TableCell>
            {!isEstimated && (
              <TableCell sx={{ color: 'indigo.600', fontWeight: 'bold', textAlign: 'right' }}>{priceLabel}</TableCell>
            )}
          </TableRow>
        </TableHead>

        {/* Table Body */}
        <TableBody>
          {items.map((item, index) => (
            <TableRow
              key={item.id}
              sx={{
                '&:hover': {
                  backgroundColor: isDarkMode ? 'grey.700' : 'grey.50',
                  transition: 'background-color 0.3s',
                },
              }}
            >
              {!isMobile && <TableCell>{index + 1}</TableCell>}
              <TableCell>{isTextVisible ? item.type : '-'}</TableCell>
              <TableCell>{isTextVisible ? item.institution : '-'}</TableCell>
              <TableCell>
                {isTextVisible ? (item.reportType === 'CUSTOMER' ? 'BIK Prywatny' : 'BIK Firmowy') : '-'}
              </TableCell>
              {!isEstimated && (
                <TableCell sx={{ textAlign: 'right', fontWeight: 'medium' }}>
                  {item.discountPrice !== item.price ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                      <Typography
                        variant="body2"
                        sx={{
                          textDecoration: 'line-through',
                          color: 'text.secondary',
                        }}
                      >
                        {item.price.toFixed(2)} zł
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.primary' }}>
                        {item.discountPrice.toFixed(2)} zł
                      </Typography>
                    </Box>
                  ) : (
                    <Typography variant="body2" sx={{ color: 'text.primary' }}>
                      {item.price.toFixed(2)} zł
                    </Typography>
                  )}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import { Box, Paper, Typography, useColorScheme } from '@mui/material';
import { motion } from 'framer-motion';

export function ScoreGauge() {
  const { mode } = useColorScheme();

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '800px',
        mx: 'auto',
        mt: { xs: 4, md: 6 },
        mb: { xs: 6, md: 8 },
        px: 2,
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 1 }}
      >
        <Box
          sx={{
            position: 'relative',
            height: { xs: 250, sm: 300, md: 400 },
          }}
        >
          {/* Background Arc */}
          <svg
            viewBox="0 0 200 120"
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
            preserveAspectRatio="xMidYMid meet"
          >
            <defs>
              <linearGradient id="scoreGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stopColor={mode === 'dark' ? '#ef4444' : '#ef4444'} />
                <stop offset="50%" stopColor={mode === 'dark' ? '#eab308' : '#eab308'} />
                <stop offset="100%" stopColor={mode === 'dark' ? '#22c55e' : '#22c55e'} />
              </linearGradient>
            </defs>
            {/* Gray background arc */}
            <path
              d="M20 100 A 80 80 0 0 1 180 100"
              fill="none"
              stroke={mode === 'dark' ? '#27272a' : '#f1f5f9'}
              strokeWidth="12"
              strokeLinecap="round"
            />
            {/* Colored progress arc */}
            <motion.path
              d="M20 100 A 80 80 0 0 1 180 100"
              fill="none"
              stroke="url(#scoreGradient)"
              strokeWidth="12"
              strokeLinecap="round"
              strokeDasharray="251"
              initial={{ strokeDashoffset: 251 }}
              animate={{ strokeDashoffset: 70 }}
              transition={{ duration: 1.5, delay: 1.2, ease: 'easeOut' }}
            />
          </svg>

          {/* Arrow Indicator */}
          <motion.div
            initial={{ rotate: -90, opacity: 0 }}
            animate={{ rotate: 45, opacity: 1 }}
            transition={{ duration: 1.5, delay: 1.2, ease: 'easeOut' }}
            style={{
              position: 'absolute',
              left: '50%',
              bottom: '40%',
              width: '4px',
              height: 'calc(30% + 10px)',
              backgroundColor: mode === 'dark' ? '#60a5fa' : '#3b82f6',
              transformOrigin: 'bottom center',
              borderRadius: '4px',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                width: { xs: 10, md: 12 },
                height: { xs: 10, md: 12 },
                borderRadius: '50%',
                bgcolor: mode === 'dark' ? '#60a5fa' : '#3b82f6',
              }}
            />
          </motion.div>

          {/* Score Labels */}
          <Box
            sx={{
              position: 'absolute',
              bottom: '0%',
              width: '100%',
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              alignItems: 'center',
              justifyItems: 'center',
              px: 6,
            }}
          >
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.5 }}>
              <Typography
                variant="h4"
                sx={{ color: '#ef4444', fontWeight: 700, mb: 1, fontSize: { xs: '1.5rem', md: '2rem' } }}
              >
                54
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
                Przed
              </Typography>
            </motion.div>

            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.5 }}>
              <Typography
                variant="h4"
                sx={{ color: '#22c55e', fontWeight: 700, mb: 1, fontSize: { xs: '1.5rem', md: '2rem' } }}
              >
                80
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
                Po
              </Typography>
            </motion.div>
          </Box>

          {/* Improvement Indicator */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 2 }}
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Paper
              elevation={4}
              sx={{
                px: { xs: 2, md: 3 },
                py: { xs: 1, md: 1.5 },
                borderRadius: 2,
                bgcolor: mode === 'dark' ? 'background.paper' : 'white',
                textAlign: 'center',
                border: 1,
                borderColor: 'divider',
              }}
            >
              <Typography variant="body2" color="text.secondary">
                Poprawa o
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: mode === 'dark' ? '#60a5fa' : '#3b82f6',
                  fontWeight: 700,
                  fontSize: { xs: '1.2rem', md: '1.5rem' },
                }}
              >
                +26 punktów
              </Typography>
            </Paper>
          </motion.div>

          {/* Credit Score Labels */}
          <Box
            sx={{
              position: 'absolute',
              bottom: '-10%',
              width: '100%',
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              alignItems: 'center',
              justifyItems: 'center',
              px: 6,
            }}
          >
            <Typography
              variant="body2"
              color="text.secondary"
              align="center"
              sx={{ fontSize: { xs: '0.75rem', md: '0.875rem' } }}
            >
              <b>Niska Zdolność</b>
              <br />
              Kredytowa
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              align="center"
              sx={{ fontSize: { xs: '0.75rem', md: '0.875rem' } }}
            >
              <b>Wysoka Zdolność</b>
              <br />
              Kredytowa
            </Typography>
          </Box>
        </Box>
      </motion.div>
    </Box>
  );
}

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Tooltip } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MuiDrawer, { drawerClasses } from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserDetailsFromLocalStorage } from 'src/common/roles';
import { DarkModeToggle } from '../app-toolbar/DarkModeToggle';
import MenuContent from './MenuContent';

const drawerWidth = 240;
const collapsedWidth = 60;

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  width: open ? drawerWidth : collapsedWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  [`& .${drawerClasses.paper}`]: {
    width: open ? drawerWidth : collapsedWidth,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

const getInitialDrawerState = () => (localStorage.getItem('isDrawerOpen') ?? 'true') === 'true';

export default function SideMenu() {
  const navigate = useNavigate();
  const userData = getUserDetailsFromLocalStorage();
  const [open, setOpen] = useState(getInitialDrawerState());

  const toggleDrawer = () => {
    localStorage.setItem('isDrawerOpen', (!open).toString());
    setOpen((prevOpen) => !prevOpen);
  };

  const handleLogout = () => {
    navigate('/logout');
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        display: { xs: 'none', md: 'block' },
        [`& .${drawerClasses.paper}`]: {
          backgroundColor: 'background.paper',
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <IconButton onClick={toggleDrawer} sx={{ display: 'inline-flex', m: 1 }}>
          {open ? <ChevronLeftIcon /> : <MenuIcon />}
        </IconButton>
        <IconButton sx={{ display: 'inline-flex', m: 1 }}>
          <DarkModeToggle />
        </IconButton>
      </Box>

      <Divider />
      <MenuContent open={open} />
      <Stack
        direction="row"
        sx={{
          p: 2,
          gap: 1,
          alignItems: 'center',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Avatar sizes="small" src="/static/images/avatar/7.jpg" sx={{ width: 36, height: 36 }} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap', // Ensures wrapping of text if necessary
            width: '100%',
          }}
        >
          <Box
            sx={{
              mr: 'auto',
              flex: '1 1 auto', // Allows the text box to grow and wrap
              wordBreak: 'break-word', // Ensures long words break properly
            }}
          >
            {userData.firstName} {userData.lastName}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexShrink: 0, // Ensures icons don't shrink or disappear
              gap: 2, // Adds space between icons if more are added later
            }}
          >
            <Tooltip title="Logout">
              <LogoutIcon
                sx={{
                  cursor: 'pointer',
                  color: 'primary.main',
                  fontSize: 24,
                  '&:hover': {
                    color: 'primary.dark',
                  },
                }}
                onClick={handleLogout} // Replace with your logout handler
              />
            </Tooltip>
          </Box>
        </Box>
      </Stack>
    </Drawer>
  );
}

import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { complateBIKReport, useBIKSummary } from 'src/api/bikApi';
import { handleApiError } from 'src/api/handleApiError';
import { useSnackbar } from 'src/common/useSnackbar';
import { isBIKItem } from 'src/guards/isBIKItem';
import { isBIKRequest } from 'src/guards/isBIKRequest';
import { CommissionEntry } from 'src/types/CommissionResponse';
import { BIKItem, BIKRequest } from 'src/types/CompanyBIKSummary.type';
import { SKDEntry } from 'src/types/SDKResponse';
import { SelectedServiceType } from 'src/types/SelectableServiceType';
import { SelectedRow } from 'src/types/SelectedRow';
import { StructuredType } from 'src/types/StructuredBIK';
import { UnwiborEntry } from 'src/types/UnwiborReponse';
import { isBIKDisabledForEdit } from 'src/utils/bikUtils';

type FinalPricesProps = {
  totalPrice: number;
  totalLeft: number;
  totalInstallment: number;
  totalEstimatedPrice: number;
  totalDiscountPrice: number;
  totalPartnerCommission: number;
  totalEstimatedPartnerCommission: number;
};

export const useConfirmationOrder = ({
  reportId,
  customNavigationUrl,
  selectedService,
}: {
  reportId: string;
  customNavigationUrl?: string;
  selectedService: Record<StructuredType, SelectedServiceType | null>;
}) => {
  const { data: summaryData } = useBIKSummary(reportId);

  const queryClient = useQueryClient();

  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [prices, setPrices] = useState<FinalPricesProps>({
    totalPrice: 0,
    totalLeft: 0,
    totalInstallment: 0,
    totalEstimatedPrice: 0,
    totalEstimatedPartnerCommission: 0,
    totalDiscountPrice: 0,
    totalPartnerCommission: 0,
  });

  useEffect(() => {
    let totalPrice = 0;
    let totalEstimatedPrice = 0;
    let totalEstimatedPartnerCommission = 0;
    let totalDiscountPrice = 0;
    let totalPartnerCommission = 0;
    let totalLeft = 0;
    let totalInstallment = 0;

    Object.values(selectedService).forEach((service) => {
      if (service?.type === 'COMPANY' || service?.type === 'CUSTOMER') {
        totalPartnerCommission += service.partnerCommission;
        totalPrice += service.price;
        totalDiscountPrice += service.discountPrice;
        totalLeft += service.left || 0;
        totalInstallment += service.installment;
      } else if (service) {
        totalEstimatedPrice += service.price;
        totalEstimatedPartnerCommission += service.partnerCommission;
      }
    });

    setPrices({
      totalPrice,
      totalLeft,
      totalInstallment,
      totalEstimatedPrice,
      totalEstimatedPartnerCommission,
      totalDiscountPrice,
      totalPartnerCommission,
    });
  }, [selectedService]);

  const isBIKDisabled = isBIKDisabledForEdit(summaryData);

  const handleSubmitSelectedRows = useCallback(async () => {
    if (isBIKDisabled) {
      navigate(customNavigationUrl || `/process-bik/${reportId}/summary`);
      return;
    }

    setIsLoading(true);

    try {
      const companyItems = selectedService['COMPANY'] ? selectedService['COMPANY'].items : [];
      const customerItems = selectedService['CUSTOMER'] ? selectedService['CUSTOMER'].items : [];
      const skdItemsIds = selectedService['SKD']
        ? (selectedService['SKD'].items as SKDEntry[]).map((item) => item.id)
        : [];
      const wiborItemsIds = selectedService['VIBOR']
        ? (selectedService['VIBOR'].items as UnwiborEntry[]).map((item) => item.id)
        : [];
      const commissionItemsIds = selectedService['COMMISSION']
        ? (selectedService['COMMISSION'].items as CommissionEntry[]).map((item) => item.id)
        : [];

      const items = [
        ...(companyItems.filter((item) => isBIKItem(item)) as BIKItem[]),
        ...(customerItems.filter((item) => isBIKItem(item)) as BIKItem[]),
      ];

      const itemsToSend: Omit<SelectedRow, 'name'>[] = items.map((item) => ({
        id: item.id,
        packageName: item.packageName,
        creditorId: item.creditorId,
        bikReportType: item.bikReportType,
        longestDebt: item.longestDebt,
        longestDebtAt: item.longestDebtAt,
        type: item.isOpen ? 'OPEN' : 'CLOSE',
        price: item.price,
        discountPrice: item.discountPrice,
        signedOn: item.signedOn,
        finishedAt: item?.finishedAt,
        financingOption: item.typeDescription,
      }));

      const requests = [
        ...(companyItems.filter((item) => isBIKRequest(item)) as BIKRequest[]),
        ...(customerItems.filter((item) => isBIKRequest(item)) as BIKRequest[]),
      ];

      const requestsToSend: Omit<SelectedRow, 'name'>[] = requests.map((request) => ({
        id: request.id,
        packageName: request.packageName,
        creditorId: request.creditorId,
        bikReportType: request.bikReportType!,
        price: request.price,
        discountPrice: request.discountPrice,
        signedOn: request.date,
        financingOption: request.commitment,
      }));

      const isSKDReloadNotRequired =
        skdItemsIds.length === summaryData?.skd?.length &&
        skdItemsIds.every((id) => summaryData?.skd?.find((item) => item.id === id));
      const isWIBORReloadNotRequired =
        wiborItemsIds.length === summaryData?.unwibor?.length &&
        wiborItemsIds.every((id) => summaryData?.unwibor?.find((item) => item.id === id));
      const isCOMMISIONReloadNotRequired =
        commissionItemsIds.length === summaryData?.commission?.length &&
        commissionItemsIds.every((id) => summaryData?.commission?.find((item) => item.id === id));
      const isBIKItemsReloadNotRequired =
        items.length === summaryData?.items?.length &&
        items.every((item) => summaryData?.items?.find((summaryItem) => summaryItem.id === item.id));
      const isBIKRequestsReloadNotRequired =
        requests.length === summaryData?.requests?.length &&
        requests.every((request) => summaryData?.requests?.find((summaryRequest) => summaryRequest.id === request.id));

      const isReloadRequired =
        !isSKDReloadNotRequired ||
        !isWIBORReloadNotRequired ||
        !isCOMMISIONReloadNotRequired ||
        !isBIKItemsReloadNotRequired ||
        !isBIKRequestsReloadNotRequired;

      if (isReloadRequired) {
        await complateBIKReport(reportId, {
          selectedCredits: itemsToSend,
          selectedRequests: requestsToSend,
          selectedSKDIds: skdItemsIds,
          selectedUNVIBORIds: wiborItemsIds,
          selectedCOMMISSIONIds: commissionItemsIds,
          price: prices.totalPrice,
          discountPrice: prices.totalDiscountPrice,
        });
      }

      queryClient.removeQueries({
        queryKey: ['bik-summary', reportId],
      });

      if (customNavigationUrl) {
        navigate(customNavigationUrl);
        return;
      }

      navigate(`/process-bik/${reportId}/add-details`);
    } catch (error) {
      showSnackbar(handleApiError(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }, [selectedService, reportId, isBIKDisabled, summaryData, prices]);

  const isEmpty = useMemo(
    () =>
      Object.values(selectedService)
        .map((service) => service?.items)
        .filter(Boolean)
        .flat().length === 0,
    [selectedService]
  );

  return {
    isLoading,
    isBIKDisabled,
    prices,
    isEmpty,
    handleSubmitSelectedRows,
  };
};

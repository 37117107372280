import { Card, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React from 'react';
import { BIKItem } from 'src/types/CompanyBIKSummary.type';
import { formatDate } from 'src/utils/date';

interface BIKItemCardProps {
  item: BIKItem;
}
function daysUntilRenewal(signedOn: string): number {
  const signedDate = new Date(signedOn);
  const today = new Date();

  // Ustawienie godziny na 00:00:00, aby uniknąć błędów związanych z czasem
  today.setHours(0, 0, 0, 0);
  signedDate.setHours(0, 0, 0, 0);

  const renewalDate = new Date(signedDate);

  // Znalezienie następnego odnowienia
  while (renewalDate <= today) {
    renewalDate.setFullYear(renewalDate.getFullYear() + 1);
  }

  // Obliczenie dni do odnowienia
  const timeDiff = renewalDate.getTime() - today.getTime();
  const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  return daysDiff;
}

export const BIKItemCard: React.FC<BIKItemCardProps> = ({ item }) => {
  const isDebtOrCreditCard = (description: string) => {
    return (
      description === 'Kredyt odnawialny' ||
      description === 'Karta kredytowa' ||
      description === 'Bezumowny debet w koncie' ||
      description === 'Limit debetowy w ROR'
    );
  };

  return (
    <Card style={{ marginTop: 8 }}>
      <Grid container spacing={2} width="100%">
        {!!item.startDebt && (
          <Grid size={{ xs: 6, sm: 4, md: 3 }} flexDirection="column" display="flex" justifyContent="center">
            <Typography variant="body1" display="flex" alignItems="center">
              Saldo początkowe kredytu
            </Typography>
            <Typography variant="body2">
              <strong>
                {new Intl.NumberFormat('pl-PL', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(item.startDebt)}{' '}
                zł
              </strong>
            </Typography>
          </Grid>
        )}
        {!!item.left && (
          <Grid size={{ xs: 6, sm: 4, md: 3 }} flexDirection="column" display="flex" justifyContent="center">
            <Typography variant="body1" display="flex" alignItems="center">
              {isDebtOrCreditCard(item.typeDescription) ? 'Aktualna kwota kredytu' : 'Pozostałe saldo do spłaty'}
            </Typography>
            <Typography variant="body2">
              <strong>
                {new Intl.NumberFormat('pl-PL', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(item.left)}{' '}
                zł
              </strong>
            </Typography>
          </Grid>
        )}
        {!!item.left && !!item.startDebt && isDebtOrCreditCard(item.typeDescription) && (
          <Grid size={{ xs: 6, sm: 4, md: 3 }} flexDirection="column" display="flex" justifyContent="center">
            <Typography variant="body1" display="flex" alignItems="center">
              Wykorzystanie limitu
            </Typography>
            <Typography variant="body2">
              <strong>{((item.left / item.startDebt) * 100).toFixed(2)} %</strong>
            </Typography>
          </Grid>
        )}
        {!isDebtOrCreditCard(item.typeDescription) && !!item.installment && (
          <Grid size={{ xs: 6, sm: 4, md: 3 }} flexDirection="column" display="flex" justifyContent="center">
            <Typography variant="body1" display="flex" alignItems="center">
              Kwota raty
            </Typography>
            <Typography variant="body2">
              <strong>
                {new Intl.NumberFormat('pl-PL', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(item.installment)}{' '}
                zł
              </strong>
            </Typography>
          </Grid>
        )}
        <Grid size={{ xs: 6, sm: 4, md: 3 }} flexDirection="column" display="flex" justifyContent="center">
          <Typography variant="body1" display="flex" alignItems="center">
            Data uruchomienia kredytu
          </Typography>
          <Typography variant="body2">
            <strong>{formatDate(item.signedOn)}</strong>
          </Typography>
        </Grid>
        {isDebtOrCreditCard(item.typeDescription) && item.isOpen && (
          <Grid size={{ xs: 6, sm: 4, md: 3 }} flexDirection="column" display="flex" justifyContent="center">
            <Typography variant="body1" display="flex" alignItems="center">
              Odnowienie za
            </Typography>
            <Typography variant="body2">
              <strong>{daysUntilRenewal(item.signedOn)} dni</strong>
            </Typography>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

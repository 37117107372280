import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { ReportType } from 'src/types/ReportType';

import { useStatisticsForManager, useStatisticsForPartner } from 'src/api/bikApi';
import { RoleType } from 'src/common/roles';
import { useDateManagement } from 'src/common/useDateManagement';
import { Charts } from './Charts';
import { SummaryComponent } from './SummaryComponent';

const tranlatedReportTypes: Record<ReportType | 'ALL', string> = {
  PRIVATE: 'BIK Prywatny',
  COMPANY: 'BIK Firmowy',
  HYBRID: 'BIK Combo (Prywatny + Firmowy)',
  ALL: 'Wszystkie typy raportów BIK',
};

type ReportsDashboardProps = {
  type: RoleType;
  selectedPartnerId?: string;
};

const ReportsDashboard: React.FC<ReportsDashboardProps> = ({ type, selectedPartnerId }) => {
  const { DateContainer, formattedFromDate, formattedToDate } = useDateManagement();
  const [reportType, setReportType] = useState<ReportType | 'ALL'>('ALL');

  const { data: statisticsData, isLoading } =
    type === 'ROLE_MANAGER'
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useStatisticsForManager(
          formattedFromDate,
          formattedToDate,
          reportType === 'ALL' ? '' : reportType,
          selectedPartnerId
        )
      : // eslint-disable-next-line react-hooks/rules-of-hooks
        useStatisticsForPartner(formattedFromDate, formattedToDate, reportType === 'ALL' ? '' : reportType);

  const handleReportTypeChange = (event: SelectChangeEvent<string>) => {
    setReportType(event.target.value as ReportType | 'ALL');
  };

  if (isLoading || !statisticsData) {
    return <CircularProgress />;
  }

  return (
    <Box p={3} sx={{ width: '100%' }}>
      {DateContainer}
      <Box mt={4}>
        <FormControl fullWidth>
          <InputLabel>Typ raportu</InputLabel>
          <Select value={reportType} onChange={handleReportTypeChange} label="Typ raportu">
            <MenuItem value="HYBRID">{tranlatedReportTypes['HYBRID']}</MenuItem>
            <MenuItem value="PRIVATE">{tranlatedReportTypes['PRIVATE']}</MenuItem>
            <MenuItem value="COMPANY">{tranlatedReportTypes['COMPANY']}</MenuItem>
            <MenuItem value="ALL">{tranlatedReportTypes['ALL']}</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          Statystki dla: {tranlatedReportTypes[reportType]}
        </Typography>
        <SummaryComponent data={statisticsData} />
        <Charts data={statisticsData.bikReports} />
      </Box>
    </Box>
  );
};

export default ReportsDashboard;

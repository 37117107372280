import { Card, keyframes, styled } from '@mui/material';

const forbiddenProps = ['$glowColor'];

const glowWave = () => keyframes`
  0% { transform: translateY(-100%); opacity: 0.3; }
  50% { transform: translateY(50%); opacity: 0.6; }
  100% { transform: translateY(100%); opacity: 0.3; }
`;

export const StyledCard = styled(Card, { shouldForwardProp: (prop) => !forbiddenProps.includes(prop as string) })<{
  $glowColor: string;
}>(({ $glowColor, theme }) => ({
  borderRadius: 10,
  overflow: 'hidden',
  position: 'relative',
  transition: 'transform 0.2s',
  boxShadow: $glowColor ? `0 0 15px ${$glowColor}` : 'none',
  height: '100%',
  cursor: 'pointer',
  padding: '16px',
  transform: 'scale(1.05)',
  backgroundColor: theme.palette.common.background,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  textAlign: 'center',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '-100%',
    left: 0,
    width: '100%',
    height: '300%', // Ensures smooth movement from top to bottom
    background: `linear-gradient(to bottom, transparent 0%, ${$glowColor} 50%, transparent 100%)`,
    zIndex: -1,
    opacity: 0.5,
    animation: $glowColor ? `${glowWave()} 4s infinite linear` : 'none',
  },
}));

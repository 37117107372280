import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BarChartIcon from '@mui/icons-material/BarChart';
import EventIcon from '@mui/icons-material/Event';
import InfoIcon from '@mui/icons-material/Info';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useState } from 'react';
import { usePositionProView } from 'src/api/bikApi';
import Heatmap from '../Heatmap';
import LineChart from '../LineChart';
import { calculateDelays } from './calculateDelays';

const WPR_LABELS = [
  { range: [0, 1], level: 'Dobry', description: 'Brak opóźnień, płatności zawsze na czas.' },
  {
    range: [1, 30],
    level: 'Zły',
    description: 'Krótkotrwałe opóźnienia (1–30 dni), sporadyczne problemy z terminowością.',
  },
  {
    range: [30, 60],
    level: 'Bardzo zły',
    description: 'Znaczące opóźnienia (31–60 dni), wskazujące na poważniejsze problemy finansowe.',
  },
  {
    range: [60, Infinity],
    level: 'Ekstremalnie zły',
    description: 'Bardzo długie opóźnienia (powyżej 60 dni), wysokie ryzyko niewypłacalności.',
  },
];

const getColorForWprLevel = (level: string, theme: Theme) => {
  switch (level) {
    case 'Dobry':
      return theme.palette.success.main; // Green
    case 'Zły':
      return theme.palette.warning.main; // Yellow
    case 'Bardzo zły':
      return theme.palette.warning.dark; // Closest to Orange
    case 'Ekstremalnie zły':
      return theme.palette.error.main; // Red
    default:
      return theme.palette.text.primary; // Default text color
  }
};

interface ChartProps {
  title: string;
  startedAt: string;
  finishedAt?: string | null;
  chartData: any;
}

const ChartCard: React.FC<ChartProps> = ({ title, startedAt, finishedAt, chartData }) => {
  const theme = useTheme();
  const [chartType, setChartType] = useState<'heatmap' | 'line'>('heatmap');

  const handleChartChange = (_: React.MouseEvent<HTMLElement>, newChartType: 'heatmap' | 'line') => {
    if (newChartType !== null) {
      setChartType(newChartType);
    }
  };

  return (
    <Grid container spacing={2} justifyContent="center" width="100%">
      {/* Toolbar for toggling charts */}
      <Grid size={{ xs: 12 }} display="flex" justifyContent="center">
        <ToggleButtonGroup value={chartType} exclusive onChange={handleChartChange} aria-label="chart type">
          <ToggleButton value="heatmap" aria-label="Heatmap">
            <BarChartIcon />
          </ToggleButton>
          <ToggleButton value="line" aria-label="Line Chart">
            <ShowChartIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      {/* Dynamic Card based on the selected chart */}
      <Grid size={{ xs: 12 }}>
        <Card sx={{ backgroundColor: theme.palette.background.paper, border: 'none' }}>
          <CardContent>
            <Typography variant="h6" display="flex" alignItems="center">
              <BarChartIcon sx={{ mr: 1 }} /> Opóźnienia ratalne {title} [dni]
            </Typography>
            <Typography variant="body2">Data zawarcia kredytu: {startedAt}</Typography>
            {finishedAt && <Typography variant="body2">Data spłaty kredytu: {finishedAt}</Typography>}

            {/* Conditional Rendering for Charts */}
            {chartType === 'heatmap' ? <Heatmap data={chartData} width={500} /> : <LineChart data={chartData} />}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

const getWprLabel = (wpr: number) => {
  return WPR_LABELS.find(({ range }) => wpr >= range[0] && wpr < range[1]) || WPR_LABELS[3];
};

type PositionProViewProps = {
  startedAt: string;
  finishedAt?: string | null;
  bikId: string;
  positionId: string;
};

const PositionProView: React.FC<PositionProViewProps> = ({ bikId, positionId, startedAt, finishedAt }) => {
  const { data, isLoading, error } = usePositionProView(bikId, positionId);

  const theme = useTheme();

  if (isLoading) return <CircularProgress size={24} sx={{ marginLeft: 2 }} />;
  if (error) return <Typography>Błąd podczas wczytywania danych.</Typography>;

  if (!data) {
    return <Typography>Brak danych do wyświetlenia</Typography>;
  }

  const reportData = data;

  const maxDelayEntry = data.delay.reduce((max, entry) => (entry.numberOfDays > max.numberOfDays ? entry : max), {
    numberOfDays: 0,
    dateAt: '',
    longestDelayAt: '',
  });

  const worstDelays = reportData.delay.sort((a, b) => b.numberOfDays - a.numberOfDays).slice(0, 10);
  const WPR = reportData.wpr === 'NaN' ? 0 : reportData.wpr === 'Infinity' ? 9999 : reportData.wpr;

  const wprLabel = getWprLabel(WPR);

  const latestObject = reportData.delay.length
    ? reportData.delay.reduce((latest, current) =>
        new Date(current.dateAt) > new Date(latest.dateAt) ? current : latest
      )
    : undefined;

  const chartDataSet = calculateDelays(reportData.delay, startedAt, true, finishedAt || latestObject?.dateAt);

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12 }}>
        <Card sx={{ backgroundColor: theme.palette.background.paper, border: 'none' }}>
          <CardContent>
            <Typography variant="h6" display="flex" alignItems="center">
              <EventIcon sx={{ mr: 1 }} /> Liczba dni opóźnienia maksymalnego
            </Typography>

            {/* Flexbox for alignment */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h4" color="primary">
                {maxDelayEntry.numberOfDays} dni
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {maxDelayEntry.longestDelayAt}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Grid size={{ xs: 12, md: 6 }}>
        <Card sx={{ backgroundColor: theme.palette.background.paper, border: 'none' }}>
          <CardContent>
            <Typography variant="h6" display="flex" alignItems="center">
              <TrendingUpIcon sx={{ mr: 1 }} /> Wskaźnik Opóźnienia Rat (WOR)
              <Tooltip title="Procent miesięcy z opóźnieniami względem całkowitego okresu zobowiązania.">
                <InfoIcon fontSize="small" sx={{ ml: 1, cursor: 'pointer' }} />
              </Tooltip>
            </Typography>
            <Typography variant="h4" color="secondary">
              {(reportData.wor === 'NaN' ? 0 : reportData.wor === 'Infinity' ? 9999 : reportData.wor).toFixed(2)}%
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid size={{ xs: 12, md: 6 }}>
        <Card sx={{ backgroundColor: theme.palette.background.paper, border: 'none' }}>
          <CardContent>
            <Typography variant="h6" display="flex" alignItems="center">
              <AccessTimeIcon sx={{ mr: 1 }} /> Wskaźnik Punktualności Rat (WPR)
              <Tooltip title="Kompleksowy wskaźnik uwzględniający liczbę i długość opóźnień w spłatach, ich wagę względem całkowitego okresu zobowiązania oraz czas od jego spłaty – z malejącym wpływem na scoring bankowy.">
                <InfoIcon fontSize="small" sx={{ ml: 1, cursor: 'pointer' }} />
              </Tooltip>
            </Typography>
            <Typography variant="h4" sx={{ color: getColorForWprLevel(wprLabel.level, theme) }}>
              {WPR.toFixed(2)}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <ChartCard title="względem upływu czasu" startedAt={startedAt} finishedAt={finishedAt} chartData={chartDataSet} />

      <Grid size={{ xs: 12 }}>
        <Card sx={{ backgroundColor: theme.palette.background.paper, border: 'none' }}>
          <CardContent>
            <Typography variant="h6" display="flex" alignItems="center">
              <TrendingUpIcon sx={{ mr: 1 }} /> Top 10 opóźnionych płatności
            </Typography>
            <TableContainer component={Paper} sx={{ maxHeight: 250 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Dni opóźnienia</TableCell>
                    <TableCell>Opóźnienie za miesiąc</TableCell>
                    <TableCell>Najdłuższe opóźnienie w</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {worstDelays.map((delay, index) => (
                    <TableRow key={index}>
                      <TableCell>{delay.numberOfDays}</TableCell>
                      <TableCell>{delay.dateAt}</TableCell>
                      <TableCell>{delay.longestDelayAt}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PositionProView;

import { Tooltip, TooltipProps } from '@mui/material';
import { ReactNode } from 'react';
import { format } from 'src/utils/date';

interface DateTimeProps {
  date: Date | string;
  formatString?: string;
}

interface AlternatingTimeProps {
  date: string;
  testId?: string;
  beforeZonedDateTime?: ReactNode;
  tooltipPlacement?: TooltipProps['placement'];
  'data-testid': string;
}

export const DateTime = ({ date, formatString = 'Pp' }: DateTimeProps) => <>{format(new Date(date), formatString)}</>;

export const ZonedDateTime = ({ date, ...otherProps }: DateTimeProps) => <DateTime date={date} {...otherProps} />;

export const AlternatingTime = ({
  date,
  'data-testid': dataTestId,
  beforeZonedDateTime,
  tooltipPlacement,
}: AlternatingTimeProps) => (
  <Tooltip
    placement={tooltipPlacement}
    title={
      <>
        In my timezone: <DateTime date={date} />
      </>
    }
  >
    <div className="value" data-testid={dataTestId}>
      {beforeZonedDateTime}
      <ZonedDateTime date={date} />
    </div>
  </Tooltip>
);

import Grid from '@mui/material/Grid2';
import { FunctionComponent } from 'react';
import { BIKAgentData } from 'src/components/bik-report/RowLayout/BIKLayout/BIKAgentData';
import { BIKCreationTime } from 'src/components/bik-report/RowLayout/BIKLayout/BIKCreationTime';
import { BIKTotal } from 'src/components/bik-report/RowLayout/BIKLayout/BIKTotal';
import { Report } from 'src/types/Report';
import { BIKAdditionalData } from './BIKAdditionalData';
import { BIKComment } from './BIKComment';
import { BIKCommissionTotal } from './BIKCommissionTotal';
import { BIKGrid } from './BIKGrid';
import { BIKServicesSummary } from './BIKServicesSummary';
import { BIKSource } from './BIKSource';
import { BIKStatusProps } from './BIKStatusProps';

interface BIKMobileLayoutProps {
  bikReport: Report;
}

export const BIKLayout: FunctionComponent<BIKMobileLayoutProps> = ({ bikReport }) => (
  <>
    <Grid container spacing={2}>
      <BIKGrid
        bikCreationTimeComponent={<BIKCreationTime bikReport={bikReport} />}
        bikAgentDataComponent={<BIKAgentData bikReport={bikReport} />}
        bikTotalComponent={<BIKTotal bikReport={bikReport} />}
        bikTotalCommissionComponent={<BIKCommissionTotal bikReport={bikReport} />}
        bikCommentComponent={<BIKComment bikReport={bikReport} />}
        bikStatusProps={<BIKStatusProps bikReport={bikReport} />}
        bikSourceComponent={<BIKSource bikReport={bikReport} />}
        bikAdditionalDataComponent={<BIKAdditionalData bikReport={bikReport} />}
      />
    </Grid>
    <BIKServicesSummary bikReport={bikReport} />
  </>
);

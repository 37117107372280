import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FunctionComponent, memo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  addCommissionNote,
  resendCustomerDocuments,
  updateCommissionIsPaidToCustomer,
  updateCommissionIsPaidToPartner,
  updateCommissionProcessing,
  updateCommissionProcessingFail,
  updateCommissionProcessingSuccess,
  useBIKSummary,
  useCommissionStatuses,
  useCustomerData,
  usePartnerData,
} from 'src/api/bikApi';
import { isUserAdmin, isUserLawOffice, isUserManager } from 'src/common/roles';
import { useSnackbar } from 'src/common/useSnackbar';
import { CommissionObject } from 'src/types/CommissionResponse';
import { CommissionStatus } from 'src/types/TimelineStatus';
import { formatDate } from 'src/utils/date';
import SelectEdit from '../reports-management/SelectEdit';
import { DetailSection } from '../SummaryDetails/DetailsSection';
import SummaryDetails from '../SummaryDetails/SummaryDetails';
import BIKCommissionItemStatuses from './BIKCommissionItemStatuses';

interface BIKCommissionItemDetailsProps {
  commission: CommissionObject;
  refetchCommissions: () => void;
  setSearchText?: (text: string) => void;
}

const translationsActionInfoByStatus: Record<CommissionStatus | 'NOTE', string> = {
  NOTE: 'Czy na pewno chcesz dodać notatkę do zlecenia?',
  DOCUMENTS_SEND: 'Czy na pewno chcesz wysłać ponownie dokumenty dla klienta?',
  PROCESSING: 'Czy na pewno chcesz rozpocząć realizację zlecenia i potwierdzić otrzymanie dokumentów?',
  PROCESSING_FAIL: 'Czy na pewno chcesz oznaczyć zlecenie jako niepowodzenie?',
  PROCESSING_SUCCESSFUL: 'Czy na pewno chcesz zakończyć realizację zlecenia?',
  COMPLETE: 'Czy na pewno chcesz zakończyć proces zlecenia?',
  INITIATED: 'Czy na pewno chcesz rozpocząć realizację zlecenia?',
};

const translationsActionSuccessByStatus: Record<CommissionStatus | 'NOTE', string> = {
  NOTE: 'Pomyślnie dodano notatkę.',
  DOCUMENTS_SEND: 'Pomyślnie wysłano ponownie dokumenty dla klienta.',
  PROCESSING: 'Pomyślnie ustawiono zlecenie do realizacji.',
  PROCESSING_FAIL: 'Ustawiono zlecenie jako niepowodzenie.',
  PROCESSING_SUCCESSFUL: 'Zakończono realizację zlecenia z sukcesem.',
  COMPLETE: 'Zakończono proces zlecenia.',
  INITIATED: 'rozpoczęto realizację zlecenia.',
};

const translationsActionFailedByStatus: Record<CommissionStatus | 'NOTE', string> = {
  NOTE: 'Wystąpił błąd podczas dodawania notatki.',
  DOCUMENTS_SEND: 'Wystąpił błąd podczas ponownego wysyłania dokumentów dla klienta.',
  PROCESSING: 'Wystąpił błąd podczas ustawiania zlecenia do realizacji.',
  PROCESSING_FAIL: 'Wystąpił błąd podczas ustawiania zlecenia jako niepowodzenie.',
  PROCESSING_SUCCESSFUL: 'Wystąpił błąd podczas zakończenia realizacji zlecenia.',
  COMPLETE: 'Wystąpił błąd podczas zakończenia procesu zlecenia.',
  INITIATED: 'Wystąpił błąd podczas rozpoczynania realizacji zlecenia.',
};

const BIKCommissionItemDetails: FunctionComponent<BIKCommissionItemDetailsProps> = ({
  commission,
  refetchCommissions,
  setSearchText,
}) => {
  const { showSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState<{
    open: boolean;
    actionKey: CommissionStatus | 'NOTE';
    action: (note: string, finalProfit?: number) => Promise<Response>;
    requireProfit: boolean;
  } | null>(null);
  const [isEditInProgress, setIsEditInProgress] = useState<boolean>(false);
  const [finalProfit, setFinalProfit] = useState<number | ''>('');
  const location = useLocation();
  const [notes, setNotes] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const { data, isLoading, refetch } = useCommissionStatuses(commission.id);
  const { data: customerData } = useCustomerData(commission.customerId);
  const { data: partnerData } = usePartnerData(commission.partnerId);

  const isSummaryEnabled = location.pathname.includes('commissions');
  const { data: summaryData } = useBIKSummary(commission.bikReportId || '', isSummaryEnabled);

  const isAdmin = isUserAdmin();
  const isLawOffice = isUserLawOffice();

  const handleOpenDialog = (
    action: (notes: string, finalProfit?: number) => Promise<Response>,
    actionKey: CommissionStatus | 'NOTE',
    requireProfit: boolean = false
  ) => {
    setOpenDialog({ open: true, actionKey, action, requireProfit });
  };

  const handleCloseDialog = () => {
    setOpenDialog(null);
    setFinalProfit('');
    setNotes('');
  };

  const handleAction = async () => {
    if (!openDialog?.action) return;

    if (openDialog.requireProfit && (finalProfit === '' || finalProfit < 0)) {
      showSnackbar('Kwota końcowa jest wymagana i musi być liczbą dodatnią.', 'error');
      return;
    }

    setLoading(true);
    try {
      await openDialog.action(notes, finalProfit !== '' ? finalProfit : undefined);
      showSnackbar(`${translationsActionSuccessByStatus[openDialog.actionKey]}`, 'success');
      refetchCommissions();
      refetch();
      handleCloseDialog();
    } catch {
      showSnackbar(translationsActionFailedByStatus[openDialog.actionKey], 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleIsPaidToPartnerEdit = async (id: string, value: boolean) => {
    setIsEditInProgress(true);
    try {
      const response = await updateCommissionIsPaidToPartner(id, value);
      await addCommissionNote(id, 'Partner otrzymał pieniądze za zlecenie zwrotu prowizji.');

      if (response?.status !== 204) {
        setIsEditInProgress(false);
        showSnackbar('Błąd podczas aktualizowania płatności.', 'error');
        return;
      }

      refetchCommissions();
      refetch();
      showSnackbar('Pomyślnie zaktualizowno płatność.', 'success');
      setIsEditInProgress(false);
    } catch (error: any) {
      const errorMessage =
        typeof error?.responseBody === 'string' &&
        error.responseBody.includes('{') &&
        JSON.parse(error.responseBody).message;
      setIsEditInProgress(false);
      showSnackbar(errorMessage || 'Błąd podczas aktualizowania płatności.', 'error');
    }
  };

  const handleIsPaidToCustomerEdit = async (id: string, value: boolean) => {
    setIsEditInProgress(true);
    try {
      const response = await updateCommissionIsPaidToCustomer(id, value);
      await addCommissionNote(id, 'Klient otrzymał pieniądze za zlecenie zwrotu prowizji.');

      if (response?.status !== 204) {
        setIsEditInProgress(false);
        showSnackbar('Błąd podczas aktualizowania płatności.', 'error');
        return;
      }

      refetchCommissions();
      refetch();
      showSnackbar('Pomyślnie zaktualizowno płatność.', 'success');
      setIsEditInProgress(false);
    } catch (error: any) {
      const errorMessage =
        typeof error?.responseBody === 'string' &&
        error.responseBody.includes('{') &&
        JSON.parse(error.responseBody).message;
      setIsEditInProgress(false);
      showSnackbar(errorMessage || 'Błąd podczas aktualizowania płatności.', 'error');
    }
  };

  const isManagerOrAdmin = isUserManager() || isUserAdmin();
  const isAdminOrLawOffice = isUserLawOffice() || isUserAdmin();

  return (
    <Box p={2}>
      {/* Przycisk akcji */}
      <Box textAlign="right">
        {isLawOffice && (
          <>
            {!!setSearchText && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setSearchText(commission.customerName)}
                sx={{ ml: 1 }}
              >
                Wyszukaj zlecenia tego klienta
              </Button>
            )}
            {commission.status === 'DOCUMENTS_SEND' && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() =>
                  handleOpenDialog((notes) => updateCommissionProcessing(commission.id, notes), 'PROCESSING')
                }
                sx={{ ml: 1 }}
              >
                Rozpocznij realizację
              </Button>
            )}
            {commission.status === 'PROCESSING' && (
              <Button
                variant="contained"
                color="success"
                onClick={() =>
                  handleOpenDialog(
                    (notes, profit) => updateCommissionProcessingSuccess(commission.id, notes, profit!),
                    'PROCESSING_SUCCESSFUL',
                    true
                  )
                }
                sx={{ ml: 1 }}
              >
                Zatwierdź pomyślną realizację zlecenia
              </Button>
            )}
            {commission.status === 'PROCESSING' && (
              <Button
                variant="contained"
                color="error"
                onClick={() =>
                  handleOpenDialog((notes) => updateCommissionProcessingFail(commission.id, notes), 'PROCESSING_FAIL')
                }
                sx={{ ml: 1 }}
              >
                Oznacz jako niepowodzenie
              </Button>
            )}
          </>
        )}
        {isAdmin && (commission.status === 'DOCUMENTS_SEND' || commission.status === 'INITIATED') && (
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              handleOpenDialog(
                () => resendCustomerDocuments(commission.connectedId, commission.customerId),
                'DOCUMENTS_SEND'
              )
            }
            sx={{ ml: 1 }}
          >
            Wyślij ponownie dokumenty do klienta
          </Button>
        )}

        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleOpenDialog((notes) => addCommissionNote(commission.id, notes), 'NOTE')}
          sx={{ ml: 1 }}
        >
          Dodaj notatkę
        </Button>
      </Box>

      <Grid container spacing={2} sx={{ padding: 3 }}>
        {customerData && (
          <Grid size={{ xs: 12, md: 6 }}>
            <DetailSection title="Dane klienta" details={customerData} />
          </Grid>
        )}

        {partnerData && (
          <Grid size={{ xs: 12, md: 6 }}>
            <DetailSection title="Dane partnera" details={partnerData} />
          </Grid>
        )}
      </Grid>

      <BIKCommissionItemStatuses data={data || []} isLoading={isLoading} commissionStatus={commission.status} />

      <Grid container spacing={2}>
        {isAdminOrLawOffice && (
          <Grid size={{ xs: 12, sm: 6 }}>
            <Typography variant="subtitle1">Czy zapłacono za zlecenie klientowi?</Typography>
            {!commission.isCustomerPaid ? (
              <SelectEdit
                isDisabled={commission.status !== 'PROCESSING_SUCCESSFUL'}
                isLoading={isEditInProgress}
                defaultValue={commission.isCustomerPaid ? 'Tak' : 'Nie'}
                onSelectChange={(id, value) => handleIsPaidToCustomerEdit(id, value === 'Tak')}
                reportId={commission.id}
              >
                <MenuItem value="Tak">Tak</MenuItem>
                <MenuItem value="Nie">Nie</MenuItem>
              </SelectEdit>
            ) : (
              'Tak'
            )}
          </Grid>
        )}
        {isManagerOrAdmin && (
          <Grid size={{ xs: 12, sm: 6 }}>
            <Typography variant="subtitle1">Czy zapłacono za zlecenie partnerowi?</Typography>
            {!commission.isPartnerPaid ? (
              <SelectEdit
                isDisabled={commission.status !== 'PROCESSING_SUCCESSFUL'}
                isLoading={isEditInProgress}
                defaultValue={commission.isPartnerPaid ? 'Tak' : 'Nie'}
                onSelectChange={(id, value) => handleIsPaidToPartnerEdit(id, value === 'Tak')}
                reportId={commission.id}
              >
                <MenuItem value="Tak">Tak</MenuItem>
                <MenuItem value="Nie">Nie</MenuItem>
              </SelectEdit>
            ) : (
              'Tak'
            )}
          </Grid>
        )}
        {commission.notes.length > 0 &&
          commission.notes.map((note) => (
            <Grid size={{ xs: 12 }} key={note.createdAt}>
              <Card sx={{ p: 1 }}>
                <Typography variant="subtitle1">Notatka z dnia {formatDate(note.createdAt, true)}</Typography>
                <Typography>{note.note}</Typography>
              </Card>
            </Grid>
          ))}
      </Grid>

      {isSummaryEnabled && (
        <Grid size={{ xs: 12 }} sx={{ mt: 1 }}>
          {isLoading ? <CircularProgress /> : <SummaryDetails data={summaryData} type="ROLE_LAW_OFFICE" isPreview />}
        </Grid>
      )}

      {/* Modal potwierdzenia */}
      {openDialog && (
        <Dialog open={openDialog.open} onClose={handleCloseDialog} fullWidth>
          <DialogTitle>
            {openDialog.actionKey === 'NOTE' ? 'Dodaj notatkę do zlecenia' : 'Potwierdzenie akcji'}
          </DialogTitle>
          <DialogContent>
            {openDialog.actionKey !== 'NOTE' && (
              <Box mt={2}>{translationsActionInfoByStatus[openDialog.actionKey]}</Box>
            )}

            {openDialog.requireProfit && (
              <TextField
                fullWidth
                label="Kwota końcowa"
                type="number"
                variant="outlined"
                value={finalProfit}
                onChange={(e) => setFinalProfit(e.target.value === '' ? '' : Number(e.target.value))}
                error={openDialog.requireProfit && (finalProfit === '' || finalProfit < 0)}
                helperText={
                  openDialog.requireProfit && (finalProfit === '' || finalProfit < 0)
                    ? 'Kwota końcowa jest wymagana i musi być dodatnia'
                    : ''
                }
                sx={{ mt: 2 }}
              />
            )}
            <TextField
              fullWidth
              label="Opcjonalna notatka"
              type="text"
              variant="outlined"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              sx={{ mt: 2 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="secondary" disabled={loading}>
              Anuluj
            </Button>
            <Button onClick={handleAction} color="primary" variant="contained" disabled={loading}>
              {loading ? 'Wykonywanie...' : 'Potwierdź'}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default memo(BIKCommissionItemDetails);

import Box from '@mui/material/Box';
import { Header } from 'src/components/app-toolbar/Header';
import ScrollToTop from 'src/components/ScrollToTop';
import { Benefits } from 'src/pages/LandingPage/Benefits';
import BenefitsCalculator from 'src/pages/LandingPage/BenefitsCalculator';
import { Footer } from 'src/pages/LandingPage/Footer';
import { Hero } from 'src/pages/LandingPage/Hero';
import { Process } from 'src/pages/LandingPage/Process';
import { Security } from 'src/pages/LandingPage/Security';
import { VideoDemo } from 'src/pages/LandingPage/VideoDemo';

export default function LandingPage() {
  return (
    <>
      <ScrollToTop />
      <MainContent />
    </>
  );
}

const MainContent: React.FC = () => (
  <Box sx={{ minHeight: '100vh', bgcolor: 'background.default' }}>
    <Header type="main" />
    <Box className="fs-unmask" component="main">
      <Hero />
      <BenefitsCalculator />
      <VideoDemo />
      <Process />
      <Benefits />
      <Security />
    </Box>
    <Footer />
  </Box>
);

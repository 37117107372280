import { Box, Typography, useTheme } from '@mui/material';
import { OrderSummaryVariant } from '../OrderSummaryVariant.types';
import { OrderSectionTypeItem } from './OrderSection.types';
import { OrderTable } from './OrderTable';

interface OrderSectionProps {
  title?: string;
  items: OrderSectionTypeItem[];
  variant?: OrderSummaryVariant;
}

export function OrderSection({ title, items, variant = 'default' }: OrderSectionProps) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box sx={{ mb: 4, '&:last-child': { mb: 0 } }}>
      {title && (
        <Box display="flex" alignItems="center" mb={2}>
          <Box
            sx={{
              width: 8,
              height: 8,
              backgroundColor: 'indigo.500',
              borderRadius: '50%',
              mr: 2,
            }}
          />
          <Typography variant="h6" fontWeight="bold" sx={{ color: isDarkMode ? 'grey.100' : 'text.primary' }}>
            {title}
          </Typography>
        </Box>
      )}
      <OrderTable items={items} variant={variant} />
    </Box>
  );
}

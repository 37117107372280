import { Box, Button, CircularProgress, Modal, Typography, useMediaQuery, useTheme } from '@mui/material';

interface LoadingScreenProps {
  open: boolean;
  errorMessage?: string;
  loadingMessage?: string;
  onClose: () => void;
  component?: React.ReactNode;
}

export function LoadingScreen({ loadingMessage, open, errorMessage, onClose, component }: LoadingScreenProps) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Modal
      open={open}
      onClose={errorMessage ? onClose : undefined} // Only allow closing when there's an error
      aria-labelledby="loading-modal"
      aria-describedby="loading-spinner"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: isMobile ? '90vw' : 400,
          height: isMobile ? '90vh' : 'auto',
          bgcolor: isDarkMode ? '#1b1b2cfa' : 'background.paper',
          borderRadius: isMobile ? 0 : 3,
          boxShadow: 24,
          p: 4,
        }}
      >
        {!errorMessage && component && component}
        {(!component || errorMessage) && (
          <>
            {errorMessage ? (
              <>
                <Typography variant="h6" textAlign="center" color="error">
                  {errorMessage.includes('Dziękujemy') ? 'Zgłoszonie wysłane' : 'Błąd przetwarzania'}
                </Typography>
                <Typography variant="body1" textAlign="center" mt={2}>
                  {errorMessage}
                </Typography>
                <Button variant="contained" onClick={onClose} sx={{ mt: 2 }}>
                  Zamknij
                </Button>
              </>
            ) : (
              <>
                <CircularProgress size={60} thickness={5} />
                <Typography variant="h6" mt={2} textAlign="center">
                  {loadingMessage}
                </Typography>
              </>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
}

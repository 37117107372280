import BoltIcon from '@mui/icons-material/Bolt';
import DescriptionIcon from '@mui/icons-material/Description';

import { Box, keyframes } from '@mui/material';

const flash = keyframes`
  0%, 100% { opacity: 0; transform: scale(1); }
  50% { opacity: 1; transform: scale(1.1); }
`;

const shimmer = keyframes`
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
`;

const subtleBounce = keyframes`
  0%, 100% { transform: translateY(0) scale(1); }
  50% { transform: translateY(-2px) scale(1.02); }
`;

const glow = keyframes`
  0%, 100% { filter: drop-shadow(0 0 2px rgba(33, 150, 243, 0.2)); }
  50% { filter: drop-shadow(0 0 10px rgba(33, 150, 243, 0.6)); }
`;

export const ProcessingIcon = ({ size = 48, className = '' }: { size?: number; className?: string }) => {
  return (
    <Box
      position="relative"
      width={size}
      height={size}
      className={className}
      sx={{
        animation: `${glow} 2s ease-in-out infinite`,
      }}
    >
      {/* Base document with shimmer effect */}
      <Box position="absolute" color="primary.main">
        <Box
          sx={{
            animation: `${subtleBounce} 2s ease-in-out infinite`,
            transform: 'perspective(1000px) rotateY(0deg)',
            transition: 'transform 0.3s ease',
            '&:hover': {
              transform: 'perspective(1000px) rotateY(10deg)',
            },
          }}
        >
          <DescriptionIcon sx={{ fontSize: size }} />
        </Box>
        <Box
          position="absolute"
          sx={{
            background: 'linear-gradient(90deg, transparent, rgba(144, 202, 249, 0.4), transparent)',
            animation: `${shimmer} 1s linear infinite`,
          }}
        />
      </Box>

      {/* Lightning bolt indicators */}
      <Box position="absolute">
        <Box
          position="absolute"
          top={0}
          right={0}
          sx={{
            color: '#fbbf24',
            opacity: 0.8,
            animation: `${flash} 0.8s ease-in-out infinite`,
          }}
        >
          <BoltIcon sx={{ fontSize: size * 0.3 }} />
        </Box>
        <Box
          position="absolute"
          bottom={0}
          left={0}
          sx={{
            color: '#fbbf24',
            opacity: 0.8,
            animation: `${flash} 0.8s ease-in-out infinite 0.4s`,
          }}
        >
          <BoltIcon sx={{ fontSize: size * 0.25 }} />
        </Box>
      </Box>
    </Box>
  );
};

import { Box, Typography, useTheme } from '@mui/material';

export function SummaryHeader() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box
      sx={{
        px: 3,
        py: 3,
        borderBottom: 1,
        borderColor: isDarkMode ? 'grey.800' : 'indigo.100',
        background: isDarkMode
          ? 'linear-gradient(to right, #1e3a8a, #6d28d9)' // Dark Indigo to Purple gradient
          : 'linear-gradient(to right, #3f51b5, #9c27b0)', // Light Indigo to Purple gradient
      }}
    >
      <Typography
        variant="h4"
        fontWeight="bold"
        sx={{
          color: isDarkMode ? 'grey.100' : 'white',
          textAlign: 'center',
        }}
      >
        BIK Master - Podsumowanie zakupu usług
      </Typography>
    </Box>
  );
}

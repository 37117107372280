import { zodResolver } from '@hookform/resolvers/zod';
import InfoIcon from '@mui/icons-material/Info'; // MUI equivalent of Info icon
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Link,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from '@mui/material';

import Container from '@mui/material/Container';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  createBIKReport,
  sendContactRequest,
  uploadCompanyBIKReport,
  uploadCustomerBIKReport,
  usePartnerDetails,
} from 'src/api/bikApi';
import { handleApiError } from 'src/api/handleApiError';
import { getUserCommission } from 'src/common/roles';
import { useSnackbar } from 'src/common/useSnackbar';
import { Header } from 'src/components/app-toolbar/Header';
import { BIKUploadLoader } from 'src/components/BIKUploadLoader/BIKUploadLoader';
import CommunicationPreferencesModal from 'src/components/CommunicationPreferencesModal';
import Footer from 'src/components/Footer';
import { LoadingScreen } from 'src/components/LoadingScreen';
import ScrollToTop from 'src/components/ScrollToTop';
import CompanyPart from 'src/pages/bik-upload/CompanyPart';
import CustomerPart from 'src/pages/bik-upload/CustomerPart';
import { bikUploadSchema } from 'src/pages/bik-upload/form.zod';
import { CommunicationPreferences } from 'src/types/CommunicationPreferences.type';
import { BIKUploadValidationSchema } from 'src/types/FormField.type';

export default function ProcessBIK() {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [customerType, setCustomerType] = useState<'OWNER' | 'SUPPORT'>('SUPPORT');
  const [failedReportId, setFailedReportId] = useState('');
  const { showSnackbar } = useSnackbar();
  const userCommissions = getUserCommission();

  const isProcessDisabled = userCommissions.length === 0;

  const isDarkMode = theme.palette.mode === 'dark';

  useEffect(() => {
    if (isProcessDisabled) {
      showSnackbar('Brak przypisanych usług do konta. Skontaktuj się z administratorem.', 'error');
    }
  }, [isProcessDisabled]);

  const { data } = usePartnerDetails();

  const handleClose = () => {
    setError(null);
    window.location.reload();
  };

  const handleClosePreferences = () => {
    setFailedReportId('');
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<BIKUploadValidationSchema>({
    resolver: zodResolver(bikUploadSchema),
  });

  const onSubmit = async (data: BIKUploadValidationSchema) => {
    setIsLoading(true);
    setProgress(1);
    let reportId;

    try {
      const createBIKReportResponse = await createBIKReport(
        data.type as 'OWNER' | 'SUPPORT',
        searchParams.get('ref') ?? undefined
      );
      setProgress(25);

      reportId = createBIKReportResponse.reportId;
      if (data.attachments.companyPdf) {
        await uploadCompanyBIKReport(createBIKReportResponse.reportId, data.attachments.companyPdf);
      }
      setProgress(50);

      if (data.attachments.customerPdf) {
        await uploadCustomerBIKReport(createBIKReportResponse.reportId, data.attachments.customerPdf);
      }
      setProgress(100);

      setTimeout(() => {
        navigate(`/process-bik/${createBIKReportResponse.reportId}/process`);
      }, 500);
    } catch (error: any) {
      setIsLoading(false);
      setProgress(0);

      if (error.status === 422) {
        const message = error?.responseBody.includes('company')
          ? 'Do pola BIK firmowego został dodany BIK osobisty właściciela firmy.'
          : 'Do pola BIK osobistego został dodany BIK firmowy.';
        setError(message);
        return;
      }
      setError(handleApiError(error));
      if (reportId) {
        setFailedReportId(reportId);
      }
    }
  };

  const handlePreferencesSubmit = async (data: CommunicationPreferences) => {
    try {
      await sendContactRequest(failedReportId, data);
    } catch (error) {
      setError(handleApiError(error));
      return false;
    } finally {
      setFailedReportId('');
    }

    setError('Dziękujemy za zgłoszenie.');
    return true;
  };

  return (
    <>
      <ScrollToTop />
      <Header type="back" />
      <LoadingScreen
        component={<BIKUploadLoader progress={progress} />}
        open={isLoading || !!error}
        errorMessage={error || undefined}
        onClose={handleClose}
      />
      <Container
        id="process-bik"
        className="fs-unmask"
        sx={{
          pt: { xs: 12, sm: 12 },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 6, sm: 6 },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box m={4}>
            <FormControl component="fieldset" sx={{ marginBottom: 3 }}>
              <FormLabel component="legend">Jestem:</FormLabel>
              <Controller
                name="type"
                control={control}
                defaultValue={customerType}
                render={({ field }) => (
                  <Box>
                    <RadioGroup
                      {...field}
                      onChange={(event) => {
                        setCustomerType(event.target.value as 'OWNER' | 'SUPPORT');
                        field.onChange(event.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="SUPPORT"
                        control={<Radio color={isDarkMode ? 'info' : 'success'} />}
                        label="Przedstawicielem osoby fizycznej, jak i prawnej, w tym osoby fizycznej prowadzącej jednoosobową działalność gospodarczą (JDG), działam w jego imieniu aby przeprowadzić analizę BIK"
                      />
                      {!data?.hasMarketingConsentsAccepted ||
                        (!data?.hasRODOConsentsAccepted && (
                          <FormControlLabel
                            value="OWNER"
                            control={<Radio color={isDarkMode ? 'info' : 'success'} />}
                            label="Przedsiębiorcą, chcę przeprowadzić analizę BIK"
                          />
                        ))}
                    </RadioGroup>
                    {errors.type && <FormHelperText error>{errors.type.message}</FormHelperText>}
                  </Box>
                )}
              />
            </FormControl>
            {errors.attachments?.message && <FormHelperText error>{errors.attachments?.message}</FormHelperText>}

            <Typography
              variant="body2"
              sx={{
                my: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                padding: 2,
                borderRadius: 2,
                backgroundColor: 'background.paper', // Jaśniejszy odcień dla lepszej widoczności
                boxShadow: 2, // Subtelny cień dla efektu głębi
              }}
            >
              <InfoIcon
                sx={{
                  fontSize: 20,
                  mt: 0.5,
                }}
              />
              System <strong>BIKMaster</strong> obsługuje wyłącznie raporty pobrane ze strony
              <Link href="https://www.bik.pl" target="_blank" color="secondary">
                www.bik.pl
              </Link>
              .
            </Typography>

            <CompanyPart setValue={setValue} control={control} type={customerType} />
            <CustomerPart setValue={setValue} control={control} type={customerType} />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '10px',
              }}
            >
              <Button
                data-testid="analyze-bik-button"
                variant="contained"
                color="primary"
                type="submit"
                disabled={isProcessDisabled}
                sx={{
                  minWidth: '300px',
                  background: isDarkMode
                    ? 'linear-gradient(to right, #60a5fa, #818cf8)'
                    : 'linear-gradient(to right, #2563eb, #4f46e5)',
                  color: 'white',
                  '&:disabled': {
                    background: isDarkMode
                      ? 'linear-gradient(to right,rgba(96, 165, 250, 0.39),rgba(129, 141, 248, 0.56))'
                      : 'linear-gradient(to right,rgba(37, 100, 235, 0.58),rgba(78, 70, 229, 0.57))',
                  },
                  '&:hover': {
                    background: isDarkMode
                      ? 'linear-gradient(to right, #3b82f6, #6366f1)'
                      : 'linear-gradient(to right, #1d4ed8, #4338ca)',
                  },
                }}
              >
                {isProcessDisabled ? 'Brak przypisanych usług do konta' : 'Zrób Analizę!'}
              </Button>
            </Box>
            {errors.attachments?.message && <FormHelperText error>{errors.attachments?.message}</FormHelperText>}
          </Box>
        </form>
      </Container>
      <Footer />

      {/* Show the Communication Preferences Modal on error */}
      <CommunicationPreferencesModal
        isOpen={!!failedReportId}
        onClose={handleClosePreferences}
        onSubmit={handlePreferencesSubmit}
      />
    </>
  );
}

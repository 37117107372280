import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {'Copyright © '}
      <Link href="https://www.bikmaster.pl/">BIKMaster&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'right' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <div>
          <Link color="text.secondary" target="_blank" href="/polityka-prywatnosci.pdf">
            Polityka prywatności
          </Link>
        </div>
        <div>
          <Link color="text.secondary" target="_blank" href="/Regulamin_BIKmaster.pdf">
            Regulamin usługi
          </Link>
          <Copyright />
        </div>
      </Box>
    </Container>
  );
}

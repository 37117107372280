import InfoIcon from '@mui/icons-material/Info';
import { Box, Tooltip } from '@mui/material';
import { FunctionComponent } from 'react';
import { Report } from 'src/types/Report';

interface BIKAdditionalDataProps {
  bikReport: Report;
}

export const BIKAdditionalData: FunctionComponent<BIKAdditionalDataProps> = ({ bikReport }) => {
  return (
    <>
      {!!bikReport.company?.name && (
        <Box className="additional-info">
          <div className="label">Firma</div>
          <div className="value" data-test-id="bik-company-name">
            {bikReport.company?.name}
          </div>
        </Box>
      )}
      {!!bikReport.company?.nip && (
        <Box className="additional-info">
          <div className="label">NIP</div>
          <div className="value" data-test-id="bik-company-nip">
            {bikReport.company?.nip}
          </div>
        </Box>
      )}

      {!!bikReport.company?.mainPkd.code && (
        <Box className="additional-info" display="flex" alignItems="center" data-testid="bik-pkd">
          <Tooltip title={<>Opis szczegółowy PKD: {bikReport.company?.mainPkd.name}</>} arrow>
            <Box mt={0.5} display="flex" alignItems="center" className="value">
              <div className="label">PKD</div>
              <div className="value" data-test-id="bik-company-nip">
                {bikReport.company?.mainPkd.code}
              </div>
              <Box ml={1} display="flex" alignItems="center">
                <InfoIcon fontSize="small" htmlColor={'primary'} />
              </Box>
            </Box>
          </Tooltip>
        </Box>
      )}

      {!!bikReport.customer?.fullName && (
        <Box className="additional-info">
          <div className="label">Klient</div>
          <div className="value" data-test-id="bik-customer-name">
            {bikReport.customer?.fullName}
          </div>
        </Box>
      )}

      {!!bikReport.customer?.pesel && (
        <Box className="additional-info">
          <div className="label">PESEL</div>
          <div className="value" data-test-id="bik-customer-pesel">
            {bikReport.customer?.pesel}
          </div>
        </Box>
      )}
    </>
  );
};

import { AssignmentRounded, Calculate, CalculateTwoTone, DashboardRounded, GroupRounded } from '@mui/icons-material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ChatIcon from '@mui/icons-material/Chat';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import {
  isBIKEnabledForLoggedUser,
  isRefundOfCommissionEnabledForLoggedUser,
  isUserLawOffice,
  isUserManager,
  isUserPartner,
} from 'src/common/roles';
import { isUserLogged } from '../utils';
export default function MenuContent({ open = true }: { open?: boolean }) {
  const getManagerViews = () =>
    isUserManager()
      ? [
          {
            text: 'Zarządzaj partnerami',
            groupedBy: 'manager',
            icon: <GroupRounded />,
            path: '/manager/partner-manage',
          }, // Icon for managing partners
          isRefundOfCommissionEnabledForLoggedUser() && {
            text: 'Zlecenia prowizji partnerów',
            icon: <ChatOutlinedIcon />,
            path: '/manager/commissions',
          }, // Icon for reports or tasks
          {
            text: 'Zlecenia BIK partnerów',
            icon: <AssignmentRounded />,
            path: '/manager/reports',
          }, // Icon for reports or tasks
          isBIKEnabledForLoggedUser() && {
            text: 'Statystyki globalne',
            icon: <Calculate />,
            path: '/manager/statistics',
          }, // Icon for reports or tasks
        ].filter(Boolean)
      : [];

  const getPartnerViews = () =>
    isUserPartner()
      ? [
          isRefundOfCommissionEnabledForLoggedUser() && {
            text: 'Moje zlecenia prowizji',
            groupedBy: 'partner',
            icon: <ChatIcon />,
            path: '/partner/commissions',
          },
          {
            text: 'Moje zlecenia BIK',
            groupedBy: 'partner',
            icon: <DashboardRounded />,
            path: '/partner/dashboard',
          },
          isBIKEnabledForLoggedUser() && {
            text: 'Moje statystyki',
            groupedBy: 'partner',
            icon: <AssessmentIcon />,
            path: '/partner/statistics',
          },
        ].filter(Boolean)
      : [];

  const getLawOfficeViews = () =>
    isUserLawOffice()
      ? [
          {
            text: 'Zlecenia prowizji',
            groupedBy: 'law-office',
            icon: <ChatIcon />,
            path: '/law-office/commissions',
          },
        ]
      : [];

  const getUserLoggedViews = () =>
    isUserLogged() ? [{ text: 'Zgody', icon: <NewspaperIcon />, path: '/user/consents' }] : [];

  const getUserTopLoggedViews = () =>
    isUserPartner() || isUserManager()
      ? [{ text: 'Zrób analizę BIK', icon: <CalculateTwoTone />, path: '/process-bik', dataTestId: 'process-bik' }]
      : [];

  const mainListItems = [
    ...getUserTopLoggedViews(),
    ...getLawOfficeViews(),
    ...getManagerViews(),
    ...getPartnerViews(),
    ...getUserLoggedViews(),
  ];

  const menuItems = mainListItems.filter(Boolean) as {
    text: string;
    icon: React.ReactNode;
    groupedBy?: string;
    dataTestId?: string;
    path: string;
  }[];

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
      <List dense>
        {menuItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              data-testid={item.dataTestId}
              component={Link}
              to={item.path}
              selected={window.location.pathname === item.path}
              sx={{
                justifyContent: !open ? 'center' : 'flex-start', // Center content when menu is closed
              }}
            >
              <ListItemIcon
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: 0, // Remove default padding for icons
                  mr: open ? 1.5 : 0, // Add spacing when menu is open
                }}
              >
                {item.icon}
              </ListItemIcon>
              {open && <ListItemText sx={{ textWrap: 'wrap' }} primary={item.text} />}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}

import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Typography } from '@mui/material';

interface StyledPriceProps {
  discounted?: boolean;
  color?: string;
}

export const StyledPrice = styled(Typography, {
  shouldForwardProp: prop => prop !== 'discounted' && prop !== 'color',
})<StyledPriceProps>`
  color: ${props => props.color ?? '#ff9800'};
  position: relative;
  display: inline-block;
  margin-right: 0.5rem;
  font-size: 1.2rem;
  ${props =>
    props.discounted &&
    css`
      opacity: 0.6;
      text-decoration: line-through;
    `}
  .currency {
    font-weight: 300;
  }
`;

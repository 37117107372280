import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { BIKStatistics } from 'src/types/BIKStatistics';

interface SummaryProps {
  data: BIKStatistics;
}

export const SummaryComponent: React.FC<SummaryProps> = ({ data }) => {
  const summary = useMemo(() => {
    const initialSummary = {
      earnedMoney: 0,
      earnedDiscountedMoney: 0,
      earnedCommissionMoney: 0,
      earnedCount: 0,
      inProgressMoney: 0,
      inProgressDiscountedMoney: 0,
    };

    return data.bikReports.reduce((acc, report) => {
      const isEarned = report.status === 'DOCUMENTS_SENT' || report.status === 'PAYMENT_COMPLETED';
      const isInProgress =
        report.status === 'DOCUMENTS_TO_BE_SENT' ||
        (report.status.startsWith('PAYMENT') && report.status !== 'PAYMENT_COMPLETED');

      return {
        earnedMoney: acc.earnedMoney + (isEarned ? report.money : 0),
        earnedDiscountedMoney: acc.earnedDiscountedMoney + (isEarned ? report.discountMoney : 0),
        earnedCommissionMoney: acc.earnedCommissionMoney + (isEarned ? report.partnerCommissionValue : 0),
        earnedCount: acc.earnedCount + (isEarned ? report.count : 0),
        inProgressMoney: acc.inProgressMoney + (isInProgress ? report.money : 0),
        inProgressDiscountedMoney: acc.inProgressDiscountedMoney + (isInProgress ? report.discountMoney : 0),
      };
    }, initialSummary);
  }, [data]);
  return (
    <Box mt={2} p={2} border="1px solid lightgray" borderRadius="8px">
      <Typography>
        <strong>Transakcje wykonane na kwotę w wybranym okresie: </strong>{' '}
        {summary.earnedDiscountedMoney.toLocaleString()} PLN
      </Typography>
      <Typography>
        <strong>Kwota prowizji w wybranym okresie: </strong> {summary.earnedCommissionMoney.toLocaleString()} PLN
      </Typography>
      <Typography>
        <strong>Liczba zakończonych raportów: </strong> {summary.earnedCount.toLocaleString()} szt.
      </Typography>
      <Typography>
        <strong> Kwota w oczekiwaniu na płatność w wybranym okresie: </strong>{' '}
        {summary.inProgressDiscountedMoney.toLocaleString()} PLN
      </Typography>
      <Typography>
        <strong>Łączna liczba przesłanych raportów: </strong> {data.totalBiks.toLocaleString()}
      </Typography>
    </Box>
  );
};

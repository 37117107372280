import { orderBy } from 'lodash';
import { getUserDetailsFromLocalStorage } from 'src/common/roles';
import { getDebtInfo } from 'src/components/bik-report/utils';
import { OrderSectionTypeItem } from 'src/components/SummaryDetails/OrderSection/OrderSection.types';
import { isBIKItem } from 'src/guards/isBIKItem';
import { BIKReportType } from 'src/types/BIKReport.type';
import { CommissionEntry, CommissionResponse } from 'src/types/CommissionResponse';
import { BIKItem, BIKRequest, CompanyBIKSummary } from 'src/types/CompanyBIKSummary.type';
import { SKDEntry, SKDResponse } from 'src/types/SDKResponse';
import { StructuredBIK, StructuredService } from 'src/types/StructuredBIK';
import { UnwiborEntry, UnwiborResponse } from 'src/types/UnwiborReponse';
import { convertDate } from './date';

export function convertSKDToOrderSectionTypeItems(allItems: SKDEntry[]): OrderSectionTypeItem[] {
  return allItems.map((item) => {
    return {
      id: item.id,
      type: item.startedAt,
      institution: item.creditor,
      reportType: 'CUSTOMER',
      price: item.possibleSKDProfit / 2,
      discountPrice: item.possibleSKDProfit / 2,
    };
  });
}

export function convertUnwiborToOrderSectionTypeItems(allItems: UnwiborEntry[]): OrderSectionTypeItem[] {
  return allItems.map((item) => {
    return {
      id: item.id,
      type: item.startedAt,
      institution: item.creditor,
      reportType: 'CUSTOMER',
      price: item.possibleUnwiborProfit / 2,
      discountPrice: item.possibleUnwiborProfit / 2,
    };
  });
}

export function convertCommissionToOrderSectionTypeItems(allItems: CommissionEntry[]): OrderSectionTypeItem[] {
  return allItems.map((item) => {
    return {
      id: item.id,
      type: item.startedAt,
      institution: item.creditor,
      reportType: 'CUSTOMER',
      price: item.possibleCommissionProfit / 2,
      discountPrice: item.possibleCommissionProfit / 2,
    };
  });
}

const insertAdditionalProps = (
  items: (BIKItem | BIKRequest)[],
  type: BIKReportType,
  customId?: string
): (BIKItem | BIKRequest)[] => {
  let tmpItems = [...items];

  if (tmpItems.length && tmpItems.every((item) => isBIKItem(item))) {
    tmpItems = orderBy(items as BIKItem[], 'isOpen', 'desc');
  }

  return orderBy(
    tmpItems.map(
      (item) =>
        ({
          ...item,
          packageName: customId || 'CUSTOM',
          bikReportType: type,
          debtRecommendation: isBIKItem(item) ? getDebtInfo(item) : null,
        }) as BIKItem | BIKRequest
    ),
    ['isOpen', 'enableToRemove'],
    ['asc', 'desc']
  );
};

export function transformSKDReport(skdData: SKDResponse): StructuredBIK {
  const eligibleSKDEntries = skdData.entries.filter((entry) => entry.qualifyForSKD);

  const maximumPrice = eligibleSKDEntries.reduce((sum, entry) => sum + entry.possibleSKDProfit / 2, 0);
  const maximumProfit = eligibleSKDEntries.reduce((sum, entry) => sum + entry.possibleSKDProfit, 0);
  const maximumPartnerCommission = eligibleSKDEntries.reduce((sum, entry) => sum + (entry.partnerCommission || 0), 0);

  const services: StructuredService[] = [];

  eligibleSKDEntries.forEach((entry) => {
    services.push({
      maximumLeft: 0,
      minimumLeft: 0,
      maximumProfit: entry.possibleSKDProfit,
      minimumProfit: entry.possibleSKDProfit,
      maximumInstallment: 0,
      minimumInstallment: 0,
      type: 'SKD',
      showMinMax: false,
      showMinMaxCustomer: false,
      maximumPrice: entry.possibleSKDProfit / 2,
      minimumPrice: entry.possibleSKDProfit / 2,
      maximumDiscountPrice: entry.possibleSKDProfit / 2,
      minimumDiscountPrice: entry.possibleSKDProfit / 2,
      title: `[${convertDate(entry.startedAt)}] ${entry.typeDescription} - ${entry.currentAmount} zł`,
      maximumPartnerCommission: entry.partnerCommission || 0,
      minimumPartnerCommission: entry.partnerCommission || 0,
      items: [entry],
    });
  });

  const minimumPrice = services.length > 0 ? Math.min(...services.map((s) => s.maximumPrice)) : 0;
  const minimumProfit = services.length > 0 ? Math.min(...services.map((s) => s.maximumProfit)) : 0;
  const minimumPartnerCommission =
    services.length > 0 ? Math.min(...services.map((s) => s.maximumPartnerCommission)) : 0;

  return {
    maximumLeft: 0,
    minimumLeft: 0,
    maximumProfit,
    minimumProfit,
    maximumInstallment: 0,
    minimumInstallment: 0,
    services,
    type: 'SKD',
    maximumPrice,
    minimumPrice,
    maximumPartnerCommission: maximumPartnerCommission,
    minimumPartnerCommission: minimumPartnerCommission,
    showMinMaxCustomer: maximumProfit !== minimumProfit,
    showMinMax: maximumPrice !== minimumPrice,
    allItems: skdData.entries,
  };
}

export function transformUnwiborReport(unwiborData: UnwiborResponse): StructuredBIK {
  const eligibleUnbiworEntries = unwiborData.entries.filter((entry) => entry.qualifyForUnwibor);

  const maximumPrice = eligibleUnbiworEntries.reduce((sum, entry) => sum + entry.possibleUnwiborProfit / 2, 0);
  const maximumProfit = eligibleUnbiworEntries.reduce((sum, entry) => sum + entry.possibleUnwiborProfit, 0);
  const maximumPartnerCommission = eligibleUnbiworEntries.reduce(
    (sum, entry) => sum + (entry.partnerCommission || 0),
    0
  );

  const services: StructuredService[] = [];

  eligibleUnbiworEntries.forEach((entry) => {
    services.push({
      maximumLeft: 0,
      minimumLeft: 0,
      minimumProfit: entry.possibleUnwiborProfit,
      maximumProfit: entry.possibleUnwiborProfit,
      maximumInstallment: 0,
      minimumInstallment: 0,
      type: 'VIBOR',
      showMinMax: false,
      showMinMaxCustomer: false,
      minimumPrice: entry.possibleUnwiborProfit / 2,
      maximumPrice: entry.possibleUnwiborProfit / 2,
      maximumDiscountPrice: entry.possibleUnwiborProfit / 2,
      minimumDiscountPrice: entry.possibleUnwiborProfit / 2,
      title: `[${convertDate(entry.startedAt)}] ${entry.typeDescription} - ${entry.currentAmount} zł`,
      maximumPartnerCommission: entry.partnerCommission || 0,
      minimumPartnerCommission: entry.partnerCommission || 0,
      items: [entry],
    });
  });

  const minimumPrice = services.length > 0 ? Math.min(...services.map((s) => s.maximumPrice)) : 0;
  const minimumProfit = services.length > 0 ? Math.min(...services.map((s) => s.maximumProfit)) : 0;
  const minimumPartnerCommission =
    services.length > 0 ? Math.min(...services.map((s) => s.maximumPartnerCommission)) : 0;

  return {
    maximumLeft: 0,
    minimumLeft: 0,
    minimumProfit,
    maximumProfit,
    maximumInstallment: 0,
    minimumInstallment: 0,
    services,
    maximumPrice,
    minimumPrice,
    maximumPartnerCommission: maximumPartnerCommission,
    minimumPartnerCommission: minimumPartnerCommission,
    type: 'VIBOR',
    showMinMaxCustomer: maximumProfit !== minimumProfit,
    showMinMax: maximumPrice !== minimumPrice,
    allItems: unwiborData.entries,
  };
}

export function transformCommissionReport(unwiborData: CommissionResponse): StructuredBIK {
  const eligibleCommissionEntries = unwiborData.entries
    .filter((entry) => entry.qualifyForCommission)
    .sort((a, b) => {
      const creditorA = a.creditor.toLowerCase();
      const creditorB = b.creditor.toLowerCase();

      if (creditorA < creditorB) {
        return -1;
      }
      if (creditorA > creditorB) {
        return 1;
      }
      return 0;
    });

  const maximumPrice = eligibleCommissionEntries.reduce((sum, entry) => sum + entry.possibleCommissionProfit / 2, 0);
  const maximumProfit = eligibleCommissionEntries.reduce((sum, entry) => sum + entry.possibleCommissionProfit, 0);
  const testMaximumProfit = eligibleCommissionEntries.reduce((sum, entry) => sum + entry.wpsProfit, 0);
  const maximumPartnerCommission = eligibleCommissionEntries.reduce(
    (sum, entry) => sum + (entry.partnerCommission || 0),
    0
  );

  const services: StructuredService[] = [];

  const roles = getUserDetailsFromLocalStorage()?.roles || [];
  const isCommissionDetailsVisible = roles.includes('ROLE_LAW_OFFICE') || roles.includes('ROLE_ADMIN');

  eligibleCommissionEntries.forEach((entry, index) => {
    services.push({
      maximumProfit: entry.possibleCommissionProfit,
      minimumProfit: entry.possibleCommissionProfit,
      testMaximumProfit: entry.wpsProfit,
      testMinimumProfit: entry.wpsProfit,
      maximumLeft: 0,
      minimumLeft: 0,
      maximumInstallment: 0,
      minimumInstallment: 0,
      type: 'COMMISSION',
      showMinMax: false,
      showMinMaxCustomer: false,
      minimumPrice: entry.possibleCommissionProfit / 2,
      maximumPrice: entry.possibleCommissionProfit / 2,
      maximumDiscountPrice: entry.possibleCommissionProfit / 2,
      minimumDiscountPrice: entry.possibleCommissionProfit / 2,
      title: isCommissionDetailsVisible
        ? `[od ${convertDate(entry.startedAt)} do ${entry.lastInstallmentDate}] ${entry.creditor} - ${entry.startingAmount} zł`
        : `Pozycja nr ${index + 1}`,
      maximumPartnerCommission: entry.partnerCommission || 0,
      minimumPartnerCommission: entry.partnerCommission || 0,
      items: [entry],
    });
  });

  const minimumPrice = services.length > 0 ? Math.min(...services.map((s) => s.maximumPrice)) : 0;
  const minimumPartnerCommission =
    services.length > 0 ? Math.min(...services.map((s) => s.maximumPartnerCommission)) : 0;
  const minimumProfit = services.length > 0 ? Math.min(...services.map((s) => s.maximumProfit)) : 0;
  const testMinimumProfit = services.length > 0 ? Math.min(...services.map((s) => s?.testMaximumProfit || 0)) : 0;

  return {
    maximumLeft: 0,
    minimumLeft: 0,
    maximumProfit,
    minimumProfit,
    testMinimumProfit,
    testMaximumProfit,
    maximumInstallment: 0,
    minimumInstallment: 0,
    services,
    maximumPrice,
    minimumPrice,
    maximumPartnerCommission: maximumPartnerCommission,
    minimumPartnerCommission: minimumPartnerCommission,
    type: 'COMMISSION',
    showMinMaxCustomer: maximumProfit !== minimumProfit || testMaximumProfit !== testMinimumProfit,
    showMinMax: maximumPrice !== minimumPrice,
    allItems: unwiborData.entries,
  };
}

export function transformBIKReport(data: CompanyBIKSummary): StructuredBIK[] {
  const serviceTypes: StructuredService['type'][] = ['BANK', 'NON_BANK', 'LEASING', 'FACTORING', 'REQUEST'];

  const customerItemsWithInternlIds = insertAdditionalProps(data.customer?.items || [], 'CUSTOMER') as BIKItem[];
  const customerRequestsWithInternlIds = insertAdditionalProps(
    data.customer?.requests || [],
    'CUSTOMER'
  ) as BIKRequest[];

  const companyItemsWithInternlIds = insertAdditionalProps(data.company?.items || [], 'COMPANY') as BIKItem[];

  const companyRequestsWithInternlIds = insertAdditionalProps(data.company?.requests || [], 'COMPANY') as BIKRequest[];

  const companyAllItems: (BIKItem | BIKRequest)[] = [...companyItemsWithInternlIds, ...companyRequestsWithInternlIds];
  const customersAllItems: (BIKItem | BIKRequest)[] = [
    ...customerItemsWithInternlIds,
    ...customerRequestsWithInternlIds,
  ];

  const bikTypes: BIKReportType[] = ['COMPANY', 'CUSTOMER'];

  const structuredBIK: StructuredBIK[] = [];

  const structuredRawData = {
    'CUSTOMER-REQUEST': customerRequestsWithInternlIds,
    'CUSTOMER-ITEMS': customerItemsWithInternlIds,
    'COMPANY-ITEMS': companyItemsWithInternlIds,
    'COMPANY-REQUEST': companyRequestsWithInternlIds,
    'CUSTOMER-ALL': insertAdditionalProps(customersAllItems, 'CUSTOMER'),
    'COMPANY-ALL': insertAdditionalProps(companyAllItems, 'COMPANY'),
  };

  bikTypes.forEach((bikType) => {
    const services: StructuredService[] = serviceTypes
      .map((type) => {
        const items: (BIKItem | BIKRequest)[] =
          type === `${bikType}-REQUEST`
            ? structuredRawData[`${bikType}-REQUEST`]
            : structuredRawData[`${bikType}-ITEMS`].filter((item) => item.type === type);

        const eligibleItems = insertAdditionalProps(items, bikType, type).filter((item) =>
          isBIKItem(item) ? item.debtRecommendation?.isRecommendedToRemove : item.enableToRemove
        );

        const maximumPrice = eligibleItems.reduce((sum, item) => sum + item.price, 0);
        const maximumDiscountPrice = eligibleItems.reduce((sum, item) => sum + item.discountPrice, 0);
        const maximumLeft = eligibleItems.reduce((sum, item) => sum + (isBIKItem(item) ? item.left : 0), 0);
        const maximumInstallment = eligibleItems.reduce(
          (sum, item) => sum + (isBIKItem(item) ? item.installment : 0),
          0
        );

        const partnerCommission = eligibleItems.reduce((sum, item) => sum + (item.partnerCommission || 0), 0);

        if (eligibleItems.length === 0) return null;

        return {
          type,
          minimumLeft: 0,
          maximumLeft,
          minimumInstallment: 0,
          maximumInstallment,
          maximumPrice,
          maximumDiscountPrice,
          maximumPartnerCommission: partnerCommission,
          maximumPartnerDiscountedCommission: partnerCommission,
          items: eligibleItems,
        } as StructuredService;
      })
      .filter((service): service is StructuredService => service !== null);

    let maximumPrice = 0;
    let minimumPrice = 0;
    let maximumDiscountPrice = 0;
    let minimumDiscountPrice = 0;

    let maximumLeft = 0;
    let minimumLeft = 0;
    let maximumInstallment = 0;
    let minimumInstallment = 0;
    let partnerCommission = 0;
    let minPartnerCommission = 0;

    // Add the CUSTOM service
    if (structuredRawData[`${bikType}-ALL`].length > 0) {
      const removableItems = structuredRawData[`${bikType}-ALL`].filter((item) => item.enableToRemove);

      maximumPrice = removableItems.reduce((sum, item) => {
        if (minimumPrice === 0 || item.price < minimumPrice) {
          minimumPrice = item.price;
        }
        return sum + item.price;
      }, 0);

      partnerCommission = removableItems.reduce((sum, item) => {
        const commission = item.partnerCommission || 0;

        if (minPartnerCommission === 0 || item.price < minPartnerCommission) {
          minPartnerCommission = commission;
        }
        return sum + commission;
      }, 0);

      maximumDiscountPrice = removableItems.reduce((sum, item) => {
        if (minimumDiscountPrice === 0 || item.discountPrice < minimumDiscountPrice) {
          minimumDiscountPrice = item.discountPrice;
        }

        return sum + item.discountPrice;
      }, 0);

      maximumLeft = removableItems.reduce((sum, item) => {
        if (!isBIKItem(item)) {
          return sum;
        }

        if (minimumLeft === 0 || item.left < minimumLeft) {
          minimumLeft = item.left;
        }

        return sum + item.left;
      }, 0);

      maximumInstallment = removableItems.reduce((sum, item) => {
        if (!isBIKItem(item)) {
          return sum;
        }

        if (minimumInstallment === 0 || item.installment < minimumInstallment) {
          minimumInstallment = item.installment;
        }

        return sum + item.installment;
      }, 0);

      services.push({
        type: 'CUSTOM',
        maximumProfit: 0,
        minimumProfit: 0,
        minimumLeft,
        maximumLeft,
        minimumInstallment,
        maximumInstallment,
        showMinMaxCustomer: minimumLeft !== maximumLeft,
        showMinMax: maximumPrice !== minimumPrice,
        minimumPrice,
        maximumPrice,
        minimumDiscountPrice,
        maximumDiscountPrice,
        minimumPartnerCommission: minPartnerCommission,
        maximumPartnerCommission: partnerCommission,
        maximumPartnerDiscountedCommission: partnerCommission,
        minimumPartnerDiscountedCommission: minPartnerCommission,
        items: structuredRawData[`${bikType}-ALL`],
      });
    }

    structuredBIK.push({
      details: {
        genaratedAt: (bikType === 'COMPANY' ? data.company?.generatedAt : data.customer?.generatedAt) || '',
        name: (bikType === 'COMPANY' ? data.company?.name : data.customer?.name) || '',
        identifier: (bikType === 'COMPANY' ? data.company?.nip : data.customer?.pesel) || '',
        scoring: bikType === 'CUSTOMER' ? data.customer?.scoring : undefined,
      },
      maximumProfit: 0,
      minimumProfit: 0,
      services,
      minimumLeft,
      maximumLeft,
      showMinMaxCustomer: minimumLeft !== maximumLeft,
      minimumInstallment,
      maximumInstallment,
      maximumPrice,
      maximumDiscountPrice,
      minimumPrice,
      minimumDiscountPrice,
      minimumPartnerCommission: minPartnerCommission,
      maximumPartnerCommission: partnerCommission,
      maximumPartnerDiscountedCommission: partnerCommission,
      minimumPartnerDiscountedCommission: minPartnerCommission,
      showMinMax: maximumPrice !== minimumPrice,
      type: bikType,
      allItems: bikType === 'COMPANY' ? companyAllItems : customersAllItems,
    });
  });

  return structuredBIK;
}

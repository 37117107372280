import { differenceInSeconds } from 'date-fns';
import { FunctionComponent } from 'react';
import { ZonedDateTime } from 'src/common/DateTime/DateTime';
import { Duration, secondsToDuration } from 'src/utils/date';
import { CommissionReportTimeline, CommissionStatusDetails } from '../types';
import { Arrow, StepIndicator, TimelineStepContainer } from './styles';

const getTimeDifference = (fromTime: string, toTime: string): void | Duration => {
  if (!fromTime || !toTime) {
    return;
  }

  const fromTimeDate = new Date(fromTime);
  const toTimeDate = new Date(toTime);

  return secondsToDuration(differenceInSeconds(fromTimeDate, toTimeDate));
};

export interface TimelineStepProps {
  stepName: string;
  stepTime?: string;
  previousStepTime?: string;
  stepsCount: number;
}
const SCALE = 0.8;
function getStepNameByKey(key: keyof CommissionReportTimeline): string | undefined {
  const entry = Object.values(CommissionStatusDetails).find((detail) => detail.key === key);
  return entry?.translation;
}

export const CommissionTimelineStep: FunctionComponent<TimelineStepProps> = ({
  stepName,
  stepTime,
  previousStepTime,
  stepsCount,
}) => {
  const timeDifference = stepTime && previousStepTime && getTimeDifference(stepTime, previousStepTime);

  return (
    <TimelineStepContainer
      stepsCount={stepsCount}
      scale={SCALE}
      isCancelled={stepName === 'documentsUploadFailedAt'}
      data-testid="timeline-step"
    >
      <StepIndicator active={!!stepTime} scale={SCALE} isCancelled={stepName === 'documentsUploadFailedAt'} />
      <div className="step-name" data-testid="timeline-step-name">
        {getStepNameByKey(stepName as keyof CommissionReportTimeline)}
      </div>
      {stepTime && (
        <div data-testid="timeline-date-time-tooltip">
          <div className="step-date" data-testid="timeline-step-date">
            <ZonedDateTime date={stepTime} formatString="dd-MM-y" />
          </div>
          <div className="step-time" data-testid="timeline-step-time">
            <ZonedDateTime date={stepTime} formatString="HH:mm" />
          </div>
        </div>
      )}
      {!!timeDifference && (
        <>
          <Arrow scale={SCALE} />
        </>
      )}
    </TimelineStepContainer>
  );
};

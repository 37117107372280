import { Box, MenuItem, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FunctionComponent, memo, useState } from 'react';
import {
  updateReportAliasByManager,
  updateReportAliasByPartner,
  updateReportCommentByManager,
  updateReportCommentByPartner,
  updateReportPaidByManager,
  updateReportPaidByPartner,
} from 'src/api/bikApi';
import { RoleType } from 'src/common/roles';
import { useSnackbar } from 'src/common/useSnackbar';
import { Report } from 'src/types/Report';
import SelectEdit from '../reports-management/SelectEdit';
import TextEdit from '../reports-management/TextEdit';

interface BIKEditableDetailsProps {
  bikReport: Report;
  type: RoleType;
  refetchData: () => void;
}

const customEndpoints: Record<
  'ALIAS' | 'COMMENT' | 'IS_PAID',
  Record<RoleType, ((id: string, value: any) => Promise<Response>) | undefined>
> = {
  ALIAS: {
    ROLE_LAW_OFFICE: undefined,
    ROLE_ADMIN: undefined,
    ROLE_PARTNER: updateReportAliasByPartner,
    ROLE_MANAGER: updateReportAliasByManager,
  },
  COMMENT: {
    ROLE_LAW_OFFICE: undefined,
    ROLE_ADMIN: undefined,
    ROLE_PARTNER: updateReportCommentByPartner,
    ROLE_MANAGER: updateReportCommentByManager,
  },
  IS_PAID: {
    ROLE_LAW_OFFICE: undefined,
    ROLE_ADMIN: undefined,
    ROLE_PARTNER: updateReportPaidByPartner,
    ROLE_MANAGER: updateReportPaidByManager,
  },
};

const BIKEditableDetails: FunctionComponent<BIKEditableDetailsProps> = ({ bikReport, type, refetchData }) => {
  const [isEditInProgress, setIsEditInProgress] = useState(false);
  const { showSnackbar } = useSnackbar();

  // Handle alias edit
  const handleAliasEdit = async (id: string, value: string) => {
    const saveEndpoint = customEndpoints.ALIAS[type];

    if (!saveEndpoint) {
      showSnackbar('Nie masz uprawnień do edycji aliasu.', 'error');
      return;
    }

    try {
      setIsEditInProgress(true);
      const response = await saveEndpoint(id, value);

      if (response?.status !== 204) {
        setIsEditInProgress(false);
        showSnackbar('Błąd podczas aktualizowania nazwy własnej.', 'error');
        return;
      }

      refetchData();
      setIsEditInProgress(false);
      showSnackbar('Pomyślnie zaktualizowno nazwę własną.', 'success');
    } catch {
      showSnackbar('Błąd podczas aktualizowania nazwy własnej.', 'error');
    }
  };

  // Handle comment edit
  const handleCommentEdit = async (id: string, value: string) => {
    const saveEndpoint = customEndpoints.COMMENT[type];

    if (!saveEndpoint) {
      showSnackbar('Nie masz uprawnień do edycji komentarza.', 'error');
      return;
    }

    try {
      setIsEditInProgress(true);
      const response = await saveEndpoint(id, value);

      if (response?.status !== 204) {
        setIsEditInProgress(false);
        showSnackbar('Błąd podczas aktualizowania komentarza.', 'error');
        return;
      }

      refetchData();
      setIsEditInProgress(false);
      showSnackbar('Pomyślnie zaktualizowno komentarz.', 'success');
    } catch {
      setIsEditInProgress(false);
      showSnackbar('Błąd podczas aktualizowania komentarza.', 'error');
    }
  };

  // Handle payment status edit
  const handleIsPaidEdit = async (id: string, value: boolean) => {
    const saveEndpoint = customEndpoints.IS_PAID[type];

    if (!saveEndpoint) {
      showSnackbar('Nie masz uprawnień do edycji płatności.', 'error');
      return;
    }

    setIsEditInProgress(true);
    try {
      const response = await saveEndpoint(id, value);

      if (response?.status !== 204) {
        setIsEditInProgress(false);
        showSnackbar('Błąd podczas aktualizowania płatności.', 'error');
        return;
      }

      refetchData();
      showSnackbar('Pomyślnie zaktualizowno płatność.', 'success');
      setIsEditInProgress(false);
    } catch (error: any) {
      const errorMessage =
        typeof error?.responseBody === 'string' &&
        error.responseBody.includes('{') &&
        JSON.parse(error.responseBody).message;
      setIsEditInProgress(false);
      showSnackbar(errorMessage || 'Błąd podczas aktualizowania płatności.', 'error');
    }
  };

  return (
    <Grid container spacing={2}>
      {/* Alias editing */}
      <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
        <Typography variant="subtitle1">Alias</Typography>
        <TextEdit
          disabled={isEditInProgress}
          placeholder="Edycja aliasu"
          field={bikReport.alias || ''}
          reportId={bikReport.id}
          onTextChange={handleAliasEdit}
        />
      </Grid>

      {/* Comment editing */}
      <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
        <Typography variant="subtitle1">Komentarz</Typography>
        <TextEdit
          disabled={isEditInProgress}
          placeholder="Edycja komentarza"
          field={bikReport.comment || ''}
          reportId={bikReport.id}
          onTextChange={handleCommentEdit}
        />
      </Grid>

      {/* Payment status editing */}
      <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
        <Typography variant="subtitle1">Czy zapłacono za zlecenie?</Typography>
        {(bikReport.status !== 'SUMMARY_INFORMATION_SENT' &&
          bikReport.status !== 'DOCUMENTS_TO_BE_SENT' &&
          bikReport.status !== 'PAYMENT_PROCESS_INITIATED') ||
        bikReport.isPaid ? (
          <Box display="flex" alignItems="center" height="42px">
            <Typography variant="body1">{bikReport.isPaid ? 'Tak' : 'Nie'}</Typography>
          </Box>
        ) : (
          <SelectEdit
            isLoading={isEditInProgress}
            defaultValue={bikReport.isPaid ? 'Tak' : 'Nie'}
            onSelectChange={(id, value) => handleIsPaidEdit(id, value === 'Tak')}
            reportId={bikReport.id}
          >
            <MenuItem value="Tak">Tak</MenuItem>
            <MenuItem value="Nie">Nie</MenuItem>
          </SelectEdit>
        )}
      </Grid>
    </Grid>
  );
};

export default memo(BIKEditableDetails);

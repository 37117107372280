import { styled } from '@mui/material/styles';

interface TimelineStepContainerProps {
  stepsCount: number;
  isCancelled?: boolean;
  scale?: number; // Scale factor to adjust dimensions and font sizes
}

interface StepIndicatorProps {
  active?: boolean;
  isCancelled?: boolean;
  scale?: number; // Scale factor to adjust dimensions
}

export const TimelineStepContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'stepsCount' && prop !== 'isCancelled' && prop !== 'scale',
})<TimelineStepContainerProps>(({ theme, stepsCount, isCancelled, scale = 1 }) => ({
  width: `calc(100% / ${stepsCount})`,
  position: 'relative',
  display: 'inline-block',
  verticalAlign: 'top',
  height: `${11 * scale}rem`,
  whiteSpace: 'nowrap',
  color: isCancelled ? theme.palette.error.main : theme.palette.text.primary,

  '.step-name': {
    fontSize: `${0.75 * scale}rem`,
    position: 'absolute',
    top: '50%',
    left: '50%',
    textTransform: 'uppercase',
    transform: `translate(-52%, ${-350 * scale}%)`,
  },

  '.step-date': {
    fontFamily: "'Roboto Mono', monospace",
    color: theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.6)'
      : 'rgba(0, 0, 0, 0.6)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, ${140 * scale}%)`,
    fontSize: `${0.75 * scale}rem`,
  },

  '.step-time': {
    fontFamily: "'Roboto Mono', monospace",
    textAlign: 'center',
    fontSize: `${1.5 * scale}rem`,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, ${163 * scale}%)`,
  },

  '.time-diff': {
    position: 'absolute',
    top: `${22 * scale}%`,
    left: '50%',
    fontFamily: "'Roboto Mono', monospace",
    transform: 'translate(-50%, 0)',
    background: theme.palette.background.paper,
    padding: `${0.2 * scale}rem`,
    color: theme.palette.text.secondary,
    fontSize: `${0.9 * scale}rem`,
  },
}));

export const StepIndicator = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'isCancelled' && prop !== 'scale',
})<StepIndicatorProps>(({ theme, active, isCancelled, scale = 1 }) => ({
  borderRadius: '50%',
  width: `${2 * scale}rem`,
  height: `${2 * scale}rem`,
  border: `${3 * scale}px solid ${
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.9)'
      : 'rgba(0, 0, 0, 0.9)'
  }`,
  margin: '0 auto',
  background: theme.palette.background.default,
  outline: `${4 * scale}px solid ${theme.palette.background.default}`,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  ...(active && {
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      background: isCancelled
        ? theme.palette.error.main
        : theme.palette.warning.main,
      width: `${1 * scale}rem`,
      height: `${1 * scale}rem`,
      borderRadius: '50%',
      transform: 'translate(-50%, -50%)',
    },
  }),
}));

export const Arrow = styled('div', {
  shouldForwardProp: (prop) => prop !== 'scale',
})<{ scale?: number }>(({ theme, scale = 1 }) => ({
  width: 0,
  height: 0,
  borderTop: `${1 * scale}rem solid transparent`,
  borderBottom: `${1 * scale}rem solid transparent`,
  borderLeft: `${1 * scale}rem solid ${
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.9)'
      : 'rgba(0, 0, 0, 0.9)'
  }`,
  background: theme.palette.background.default,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: `translate(-${220 * scale}%, -50%) scaleY(${0.3 * scale})`,
  zIndex: 2,
}));

import { ReportStatus } from "src/common/reports";
import { BIKReportType } from "./BIKReport.type";
import { CommissionEntry } from "./CommissionResponse";
import { SKDEntry } from "./SDKResponse";
import { UnwiborEntry } from "./UnwiborReponse";

export type BIKSummaryItem = {
    id: string;
    packageName?: string;
    subject: string;
    financingOption: string;
    bikReportType: BIKReportType;
    price: number;
    type: string;
    discountPrice?: number;
}

export type BIKSummaryRequest = {
  id: string;
  packageName?: string;
  companyName: string;
  commitment: string;
  bikReportType: BIKReportType;
  price: number;
  discountPrice?: number;
}

export type RegulationsConsentStatus = 'NOT_GENERATED' | 'EXPIRED' | 'SENT' | 'ACCEPTED';

export const REGULATIONS_CONSENT_STATUS_LABELS: Record<RegulationsConsentStatus, string> = {
    NOT_GENERATED: 'Nie wygenerowano adresu email',
    EXPIRED: 'Link do regulaminu wygasł',
    SENT: 'Wysłano email z linkiem do klienta',
    ACCEPTED: 'Regulamin zaakceptowany przez klienta',
}

export type BIKSummary = {
    id: string;
    orderId?: string;
    discountCode?: string;
    status: ReportStatus;
    regulationsConsentStatus: RegulationsConsentStatus;
    price: number;
    discountPrice?: number;
    discountValue?: number;
    items: BIKSummaryItem[];
    skd: SKDEntry[];
    unwibor: UnwiborEntry[];
    commission: CommissionEntry[];
    requests: BIKSummaryRequest[];
    company?: CompanyDetails;
    customer?: CustomerDetails;
    contact: ContactDetails;
  };
  
  export type ContactDetails = {
    email: string;
    phone: string;
    fullName: string;
  };
  
export type CompanyDetails = {
    companyName: string;
    invoiceEmail: string;
    phone: string;
    country: string;
    city: string;
    postalCode: string;
    street: string;
    buildingNumber: string;
    nip: string;
    regon: string;
  };
  
export type CustomerDetails = {
    bankAccountName: string;
    bankAccountNumber: string;
    fullName: string;
    phone: string;
    email: string;
    country: string;
    city: string;
    postalCode: string;
    street: string;
    buildingNumber: string;
    pesel: string;
  };
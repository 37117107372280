import { Box, Switch, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import ScoringChart from 'src/components/ScoringChart';
import { BIKReportType } from 'src/types/BIKReport.type';
import { ReportTopDateComponent } from './ReportTopDateComponent';

const SelectAllComponent = ({
  isSelectedAll,
  isDisabled,
  setIsSelectedAll,
}: {
  isSelectedAll: boolean;
  isDisabled: boolean;
  setIsSelectedAll: (isSelectedAll: boolean) => void;
}) => {
  const theme = useTheme(); // Access the current theme

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000', // Adjust text color based on theme
        backgroundColor: isSelectedAll ? theme.palette.success.main : theme.palette.error.main, // Use theme colors for background
        borderRadius: '8px',
        boxShadow: theme.palette.mode === 'dark' ? '0 4px 8px rgba(0, 0, 0, 0.5)' : '0 2px 4px rgba(0, 0, 0, 0.1)', // Dynamic shadow
        flexWrap: 'wrap',
        height: '100%',
        width: { xs: '100%', sm: '80%' },
        p: 1,
        minHeight: '50px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease, box-shadow 0.3s ease', // Smooth transition for interactivity
      }}
      onClick={() => !isDisabled && setIsSelectedAll(!isSelectedAll)}
    >
      <Typography
        variant="body1"
        sx={{
          fontWeight: 600,
          marginRight: '10px',
          fontSize: { xs: '0.75em', sm: '1em' },
          textAlign: 'center',
        }}
      >
        {isSelectedAll ? 'Odznacz' : 'Zaznacz'} wszystkie pozycje rekomendowane do usunięcia
      </Typography>

      <Switch
        checked={isSelectedAll}
        disabled={isDisabled}
        color="primary"
        sx={{
          '.MuiSwitch-thumb': {
            color: isSelectedAll ? theme.palette.success.light : theme.palette.error.light, // Dynamic thumb color
          },
        }}
      />
    </Box>
  );
};

export const SummaryTopTitle = ({
  isSelectedAll,
  scoring,
  generatedAt,
  type,
  showSelectAll,
  isDisabled,
  setIsSelectedAll,
}: {
  isSelectedAll: boolean;
  generatedAt: string;
  scoring?: number;
  type: BIKReportType;
  showSelectAll: boolean;
  isDisabled: boolean;
  setIsSelectedAll: (isSelectedAll: boolean) => void;
}) => {
  const theme = useTheme(); // Access the current theme

  return (
    <>
      <Grid container spacing={2} width="100%" mt={!showSelectAll ? 2 : 0}>
        <ReportTopDateComponent generatedAt={generatedAt} />
        <Grid
          size={{ xs: 12 }}
          sx={{
            color: theme.palette.mode === 'dark' ? theme.palette.primary.light : '#000b9dbd', // Adjust text color
            borderRadius: '12px',
            textAlign: 'center',
            fontSize: '2em',
            fontWeight: '600',
            mb: 1,
          }}
        >
          Wynik raportu BIK {type === 'CUSTOMER' ? 'Prywatnego' : 'Firmowego'}
          {isDisabled && ' - pozycje wybrane'}
        </Grid>

        {showSelectAll && (
          <Grid
            size={{ xs: 12, sm: scoring ? 9 : 12, md: scoring ? 10 : 12 }}
            mb={3}
            display="flex"
            justifyContent="center"
            alignSelf="center"
            maxHeight="50px"
          >
            <SelectAllComponent
              isSelectedAll={isSelectedAll}
              isDisabled={isDisabled}
              setIsSelectedAll={setIsSelectedAll}
            />
          </Grid>
        )}
        {scoring && (
          <Grid
            size={{ xs: 12, sm: showSelectAll ? 3 : 12, md: showSelectAll ? 2 : 12 }}
            display="flex"
            mb={3}
            justifyContent="center"
          >
            <ScoringChart width={100} height={100} scoring={scoring} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CancelIcon from '@mui/icons-material/Cancel';
import DescriptionIcon from '@mui/icons-material/Description';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Box, Button, Card, CardContent, Divider, Paper, Stack, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { sendSummaryMessage, useBIKSummary } from 'src/api/bikApi';
import { Header } from 'src/components/app-toolbar/Header';
import Footer from 'src/components/Footer';
import ScrollToTop from 'src/components/ScrollToTop';
import { isUserLogged } from 'src/components/utils';
import LoadingSite from '../commonSites/LoadingSite';

let isSent = false;
export default function ThankYou() {
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;

  const [finishInfo, setFinishInfo] = useState({ status: 'loading', message: '' });
  const { data: bikData, isLoading: isBIKReportLoading, isError } = useBIKSummary(id || 'wrong_id');

  const goToDashboard = () => {
    navigate('/check-default-page');
  };

  const goToSummary = () => {
    navigate(`/process-bik/${id}/summary`);
  };

  const isLogged = isUserLogged();

  useEffect(() => {
    const isOnlyCommission = bikData?.commission.length && !bikData?.items.length && !bikData?.requests.length;
    if (!id || isSent || isBIKReportLoading) {
      return;
    }

    if (isError) {
      setFinishInfo({
        status: 'error',
        message:
          'Wystąpił problem z połączeniem z naszym serwerem. Spróbuj ponownie za chwilę. Jeśli problem nadal występuje, skontaktuj się z naszym działem wsparcia.',
      });

      return;
    }

    if (
      bikData?.status === 'SUMMARY_INFORMATION_SENT' ||
      bikData?.status === 'DOCUMENTS_SENT' ||
      bikData?.status === 'PAYMENT_COMPLETED' ||
      bikData?.status === 'DOCUMENTS_TO_BE_SENT' ||
      isOnlyCommission
    ) {
      setFinishInfo({ status: 'success', message: '' });
      return;
    }

    if (bikData?.status !== 'USER_DATA_ENTERED' && bikData?.status !== 'PAYMENT_PROCESS_INITIATED') {
      setFinishInfo({ status: 'success', message: '' });
      return;
    }

    isSent = true;

    sendSummaryMessage(id)
      .then(() => {
        setFinishInfo({ status: 'success', message: '' });
      })
      .catch((error) => {
        if (error.responseBody.includes('Recipient address rejected: Domain not found')) {
          setFinishInfo({
            status: 'error',
            message:
              'Wystąpił problem z wysłaniem wiadomości e-mail. Sprawdź poprawność adresu e-mail i spróbuj ponownie. Jeśli problem nadal występuje, skontaktuj się z naszym działem wsparcia.',
          });
          return;
        }
        setFinishInfo({ status: 'error', message: '' });
      });

    return () => {
      isSent = false;
    };
  }, [params, bikData, isBIKReportLoading, isError]);

  if (finishInfo.status === 'loading') {
    return <LoadingSite />;
  }

  if (finishInfo.status === 'error') {
    return (
      <>
        <ScrollToTop />
        <Header type="back" />
        <Container
          id="thank-you"
          sx={{
            pt: { xs: 4, sm: 12 },
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: { xs: 3, sm: 6 },
          }}
        >
          <Box display="flex" justifyContent="center" alignItems="center" className="fs-unmask">
            <Card sx={{ maxWidth: 600, padding: 4, borderRadius: 4, boxShadow: 3 }}>
              <CardContent>
                {/* Error icon */}
                <Box display="flex" justifyContent="center" mb={2}>
                  <CancelIcon sx={{ fontSize: '3.75em', color: 'red' }} />
                </Box>

                {/* Error message */}
                <Typography variant="h5" align="center" gutterBottom>
                  Przepraszamy, wystąpił błąd
                </Typography>

                <Divider sx={{ my: 2 }} />

                {finishInfo.message && (
                  <Typography variant="body1" align="justify" paragraph>
                    {finishInfo.message}
                  </Typography>
                )}
                {!finishInfo.message && (
                  <>
                    <Typography variant="body1" align="justify" paragraph>
                      Niestety, nie udało się przetworzyć Twojego żądania. Prosimy spróbować ponownie później lub
                      skontaktować się z naszym zespołem wsparcia, aby uzyskać pomoc.
                    </Typography>

                    <Typography variant="body1" align="justify" paragraph>
                      Wystąpił błąd podczas przetwarzania dokumentów BIK. Sprawdź swoje połączenie internetowe i spróbuj
                      ponownie. Jeśli problem się powtarza, skontaktuj się z naszym wsparciem.
                    </Typography>
                  </>
                )}

                {/* Signature */}
                <Box mt={4} textAlign="center">
                  <Typography variant="subtitle1" fontWeight="bold">
                    Zespół BIK Master
                  </Typography>

                  <Button variant="contained" color="primary" onClick={goToSummary}>
                    Powrót do podsumowania
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Container>
        <Footer />
      </>
    );
  }

  const isCommissionService = !!bikData?.commission.length;
  const isBIKService = !!bikData?.items.length || !!bikData?.requests.length;

  return (
    <>
      <ScrollToTop />
      <Header type="back" />
      <Container
        id="thank-you"
        sx={{
          pt: { xs: 4, sm: 12 },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Paper
          elevation={3}
          sx={{
            backgroundColor: (theme) => theme.palette.common.background,
            maxWidth: 700,
            width: '100%',
            borderRadius: 2,
            p: 4,
          }}
        >
          <Box textAlign="center" mb={4}>
            <Box display="flex" justifyContent="center" mb={3}>
              <TaskAltIcon sx={{ fontSize: '4em', color: 'success.main' }} />
            </Box>
            <Typography variant="h4" fontWeight="bold" color="text.primary" gutterBottom>
              Działamy!
            </Typography>
            <Typography color="text.secondary">Twoje zamówienie zostało przyjęte do realizacji</Typography>
          </Box>

          <Stack spacing={2} mb={4}>
            <Box
              display="flex"
              alignItems="flex-start"
              gap={2}
              p={2}
              sx={{ backgroundColor: 'success.light', borderRadius: 2 }}
            >
              <DescriptionIcon sx={{ fontSize: 32, color: 'success.main', flexShrink: 0, mt: 0.5 }} />
              <Box>
                <Typography variant="subtitle1" fontWeight="bold" color="success.dark" gutterBottom>
                  Dokumenty klienta są gotowe
                </Typography>
                <Typography variant="body2" color="success.main">
                  Przygotowaliśmy wszystkie niezbędne dokumenty do rozpoczęcia procesu
                </Typography>
              </Box>
            </Box>

            {isCommissionService && (
              <Box
                display="flex"
                alignItems="flex-start"
                gap={2}
                p={2}
                sx={{ backgroundColor: 'success.light', borderRadius: 2 }}
              >
                <MailOutlineIcon sx={{ fontSize: 32, color: 'success.main', flexShrink: 0, mt: 0.5 }} />
                <Box>
                  <Typography variant="subtitle1" fontWeight="bold" color="success.dark" gutterBottom>
                    Dokumenty do rozpoczęcia procesu Zwrotu Prowizji wysłano e-mailem
                  </Typography>
                  <Typography variant="body2" color="success.main">
                    Podpisane dokumenty należy jak najszybciej wysłać e-mailem i pocztą do Kancelarii Creo – oba kroki
                    są niezbędne do rozpoczęcia postępowania wobec banków.
                  </Typography>
                </Box>
              </Box>
            )}

            {isBIKService && (
              <Box
                display="flex"
                alignItems="flex-start"
                gap={2}
                p={2}
                sx={{ backgroundColor: 'success.light', borderRadius: 2 }}
              >
                <MailOutlineIcon sx={{ fontSize: 32, color: 'success.main', flexShrink: 0, mt: 0.5 }} />
                <Box>
                  <Typography variant="subtitle1" fontWeight="bold" color="success.dark" gutterBottom>
                    Dokumenty do rozpoczęcia procesu Optymalizacji BIK zostaną wysłane mailowo
                  </Typography>
                  <Typography variant="body2" color="success.main">
                    Po potwierdzeniu płatności, dokumenty zostaną przesłane na podany adres e-mail
                  </Typography>
                </Box>
              </Box>
            )}

            <Box
              display="flex"
              alignItems="flex-start"
              gap={2}
              p={2}
              sx={{ backgroundColor: 'success.light', borderRadius: 2 }}
            >
              <ArrowForwardIcon sx={{ fontSize: 32, color: 'success.main', flexShrink: 0, mt: 0.5 }} />
              <Box>
                <Typography variant="subtitle1" fontWeight="bold" color="success.dark" gutterBottom>
                  Jasne instrukcje
                </Typography>
                <Typography variant="body2" color="success.main">
                  {isBIKService && !isCommissionService
                    ? 'Wiadomość zawiera instrukcje dotyczące obsługi dokumentów dla skutecznej realizacji Optymalizacji BIK.'
                    : isCommissionService && !isBIKService
                      ? 'Wiadomość zawiera instrukcje dotyczące obsługi dokumentów dla skutecznej realizacji usługi zwrotu prowizji.'
                      : 'Wiadomości zawierają instrukcje dotyczące obsługi dokumentów dla skutecznej realizacji wybranych usług.'}
                </Typography>
              </Box>
            </Box>
          </Stack>

          <Divider sx={{ mb: 4 }} />
          <Box textAlign="center">
            <Typography fontWeight="bold" color="text.primary">
              Zespół BIK Master
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              Dziękujemy za zaufanie
            </Typography>
            {isLogged && (
              <Button variant="contained" color="primary" onClick={goToDashboard}>
                Przejdź do Master Panelu
              </Button>
            )}
          </Box>
        </Paper>
      </Container>
      <Footer />
    </>
  );
}

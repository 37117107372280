import { CreditEntry } from '../types/credit';

export const calculateBasePoints = (creditScore: number): number => {
  // Base points are calculated on a scale from 1-10
  return Math.max(1, Math.min(10, Math.floor(creditScore / 10)));
};

export const calculateBonusPoints = (creditScore: number): number => {
  // Additional random bonus points (1-3) for larger impacts
  return creditScore > 30 ? Math.floor(Math.random() * 3) + 1 : 0;
};

export const calculateTotalPoints = (entry: CreditEntry): number => {
  const basePoints = calculateBasePoints(entry.impact.creditScore);
  const bonusPoints = calculateBonusPoints(entry.impact.creditScore);
  return basePoints + bonusPoints;
};
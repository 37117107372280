import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Avatar, Box, Stack, Typography, alpha, useTheme } from '@mui/material';
import React from 'react';
import { RoleType, getUserCommission, getUserDetailsFromLocalStorage } from 'src/common/roles';
import { CommissionTranslation } from 'src/components/bik-report/RowLayout/UserLayout/translations';

type UserDetailsProps = {
  type: RoleType;
  rightBox?: React.ReactNode;
};

const title: Record<RoleType, string> = {
  ROLE_LAW_OFFICE: 'Pracownik Kancelarii CREO',
  ROLE_ADMIN: 'Admin',
  ROLE_MANAGER: 'Manager',
  ROLE_PARTNER: 'Partner',
};

export const UserDetails = ({ rightBox }: UserDetailsProps) => {
  const theme = useTheme();

  const isDarkMode = theme.palette.mode === 'dark';
  const userDetails = getUserDetailsFromLocalStorage();

  const commissions = getUserCommission();

  return (
    <Box
      m={1}
      p={3}
      sx={{
        width: '100%',
        bgcolor: theme.palette.background.paper,
        borderRadius: 3,
        boxShadow: `0 4px 8px ${alpha(theme.palette.primary.dark, 0.2)}`,
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: { xs: 2, sm: 0 },
      }}
    >
      <Box
        sx={{
          width: '100%',

          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: { xs: 2, sm: 0 },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: { xs: '100%', sm: 'auto' } }}>
          <Avatar
            sx={{
              bgcolor: theme.palette.primary.dark,
              width: { xs: 48, sm: 64 },
              height: { xs: 48, sm: 64 },
              mr: { xs: 2, sm: 3 },
            }}
          >
            <AccountCircleIcon />
          </Avatar>

          <Box sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', fontSize: { xs: '1.2em', sm: '1.5em' } }}>
              {`${userDetails.roles.map((role) => title[role]).join(', ')}: ${userDetails?.firstName} ${userDetails?.lastName}`}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: { xs: '0.9em', sm: '1em' } }}>
              {userDetails?.email}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: { xs: '0.9em', sm: '1em' } }}>
              {`Telefon: ${userDetails?.phone || 'N/A'}`}
            </Typography>
          </Box>
        </Box>

        {rightBox && rightBox}
      </Box>
      <Box mt={2}>
        <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
          Aktywne produkty:
        </Typography>
        <Stack direction="row" spacing={2} flexWrap="wrap">
          {!!commissions.length &&
            commissions.map((commission, key) => (
              <Typography
                key={key}
                variant="body2"
                sx={{
                  fontSize: { xs: '0.9em', sm: '1em' },
                  padding: '0.5em',
                  backgroundColor: isDarkMode ? '#278896' : '#f9f9f9',
                  borderRadius: '8px',
                  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                  marginRight: '8px',
                  marginBottom: '8px',
                }}
              >
                {/* {`${CommissionTranslation[commission.productType]} ${commission.value ? `[${commission.value}${commission.type === 'PERCENTAGE' ? '%' : 'PLN'}]` : ''}`} */}
                {`${CommissionTranslation[commission.productType]}`}
              </Typography>
            ))}
          {!commissions.length && (
            <Typography
              variant="body2"
              sx={{
                fontSize: { xs: '0.9em', sm: '1em' },
                padding: '0.5em',
                backgroundColor: isDarkMode ? '#278896' : '#f9f9f9',
                borderRadius: '8px',
                boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                marginRight: '8px',
                marginBottom: '8px',
              }}
            >
              Brak aktywnych produktów
            </Typography>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

import Grid from '@mui/material/Grid2';
import { FunctionComponent } from 'react';
import { PartnerDetails } from 'src/types/PartnerDetails';
import { RowGrid } from '../RowGrid';
import { UserCommission } from './UserCommission';
import { UserConsents } from './UserConsents';
import { UserEmail } from './UserEmail';
import { UserType } from './UserType';

interface UserLayoutProps {
  user: PartnerDetails;
}

export const UserLayout: FunctionComponent<UserLayoutProps> = ({ user }) => (
  <Grid container spacing={2}>
    <RowGrid
      creationTimeComponent={<UserType user={user} />}
      dataComponent={<UserConsents user={user} />}
      commentComponent={<UserEmail user={user} />}
      additionalDataComponent={<UserCommission user={user} />}
    />
  </Grid>
);

import { AccessTime, CalendarToday, Money } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GavelIcon from '@mui/icons-material/Gavel';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import {
  Box,
  Card,
  Chip,
  Collapse,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { green, grey } from '@mui/material/colors';
import { useState } from 'react';
import ProDetailsToggle from 'src/components/ProView/ProDetailsToogle';
import { BIKItemCard } from 'src/components/bik-report/BIKItemCard';
import { BIKRequestCard } from 'src/components/bik-report/BIKRequestCard';
import { isBIKItem } from 'src/guards/isBIKItem';
import { isBIKRequest } from 'src/guards/isBIKRequest';
import { BIKItem, BIKRequest } from 'src/types/CompanyBIKSummary.type';

const RecommendationChip = ({ isRecommended }: { isRecommended: boolean }) => {
  return (
    <Chip
      label={isRecommended ? 'Rekomendowane do usunięcia' : 'Nie rekomendowane'}
      color={isRecommended ? 'success' : 'error'}
      size="small"
      icon={isRecommended ? <CheckCircleIcon /> : <ErrorOutlineIcon />}
      sx={{
        backgroundColor: isRecommended ? green[50] : grey[100],
        color: isRecommended ? green[700] : grey[700],
        fontWeight: 'bold',
      }}
    />
  );
};

const BIKPosition = ({
  item,
  bikId,
  isFirstOccurrence,
  isDisabled,
  isSelectable,
  isClientView,
  isChecked,
  onCheckboxChange,
}: {
  item: BIKItem | BIKRequest;
  bikId: string;
  isFirstOccurrence: boolean;
  isDisabled: boolean;
  isSelectable: boolean;
  isClientView?: boolean;
  onCheckboxChange?: (item: BIKItem | BIKRequest, isChecked: boolean) => void;
  isChecked: boolean;
}) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded((prev) => !prev);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onCheckboxChange) {
      onCheckboxChange(item, event.target.checked);
    }
  };

  const renderAdditionalDetails = () => {
    if (isBIKItem(item)) {
      return (
        <Grid
          container
          spacing={2}
          sx={{
            p: 2,
            borderTop: `1px solid ${theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[300]}`,
            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)',
          }}
        >
          <Grid size={{ xs: 12 }}>
            <BIKItemCard item={item} />
          </Grid>
          {/* Status */}
          <Grid size={{ xs: 12 }}>
            <Chip
              label={item.status}
              color={item.status === 'GREEN' ? 'success' : item.status === 'RED' ? 'error' : 'warning'}
              size="small"
              icon={item.status === 'GREEN' ? <CheckCircleIcon /> : <ErrorOutlineIcon />}
              sx={{ fontWeight: 'bold' }}
            />
          </Grid>

          {isBIKItem(item) && (
            <Grid size={{ xs: 12 }} display="flex" alignItems="center" gap={1}>
              <GavelIcon fontSize="small" color="action" />
              <Typography variant="body2" color="text.secondary">
                {item.consentText || 'Brak informacji o aktualnej zgodzie na przetwarzanie informacji o zobowiązaniu.'}
              </Typography>
            </Grid>
          )}

          {Boolean(isClientView && isBIKRequest(item)) && (
            <Grid size={{ xs: 12, sm: 6 }} display="flex" alignItems="center" gap={1}>
              <TrendingUpIcon fontSize="small" color="action" />
              <Typography variant="body2" color="text.secondary">
                Większa wiarygodność bez wielu zapytań.
              </Typography>
            </Grid>
          )}
          {Boolean(isClientView && isBIKItem(item) && !item.isOpen) && (
            <Grid size={{ xs: 12, sm: 6 }} display="flex" alignItems="center" gap={1}>
              <TrendingUpIcon fontSize="small" color="action" />
              <Typography variant="body2" color="text.secondary">
                Wyższa ocena kredytowa bez opóźnień.
              </Typography>
            </Grid>
          )}

          {Boolean(isClientView && isBIKItem(item) && item.isOpen && item.left) && (
            <Grid size={{ xs: 12, sm: 6 }} display="flex" alignItems="center" gap={1}>
              <TrendingUpIcon fontSize="small" color="action" />
              <Typography variant="body2" color="text.secondary">
                Aktywne zobowiązania:
              </Typography>
              <Typography variant="body2" fontWeight="bold">
                {(item as BIKItem).left.toFixed(2)} zł
              </Typography>
            </Grid>
          )}

          {Boolean(isClientView && isBIKItem(item) && item.isOpen && item.installment) && (
            <Grid size={{ xs: 12, sm: 6 }} display="flex" alignItems="center" gap={1}>
              <QueryStatsIcon fontSize="small" color="action" />
              <Typography variant="body2" color="text.secondary">
                Miesięczne zobowiązania:
              </Typography>
              <Typography variant="body2" fontWeight="bold">
                {(item as BIKItem).installment.toFixed(2)} zł
              </Typography>
            </Grid>
          )}

          <Grid size={{ xs: 12, sm: 6 }} display="flex" alignItems="center" gap={1}>
            <CalendarToday fontSize="small" color="action" />
            <Typography variant="body2" color="text.secondary">
              Data uruchomienia:
            </Typography>
            <Typography variant="body2" fontWeight="bold">
              {new Date(item.signedOn).toLocaleDateString('pl-PL')}
            </Typography>
          </Grid>

          {/* Najdłuższe zadłużenie */}
          <Grid size={{ xs: 12, sm: 6 }} display="flex" alignItems="center" gap={1}>
            <AccessTime fontSize="small" color="action" />
            <Typography variant="body2" color="text.secondary">
              Najdłuższe zadłużenie:
            </Typography>
            <Typography variant="body2" fontWeight="bold">
              {item.longestDebt} dni
            </Typography>
          </Grid>

          {/* Kwota zadłużenia */}
          {item.debt !== 0 && (
            <Grid size={{ xs: 12, sm: 6 }} display="flex" alignItems="center" gap={1}>
              <Money fontSize="small" color="action" />
              <Typography variant="body2" color="text.secondary">
                Kwota zadłużenia:
              </Typography>
              <Typography variant="body2" fontWeight="bold">
                {item.debt} zł
              </Typography>
            </Grid>
          )}

          <Grid size={{ xs: 12 }}>
            <ProDetailsToggle
              isExpanded={expanded}
              bikId={bikId}
              positionId={item.id}
              startedAt={item.signedOn}
              finishedAt={item.finishedAt}
            />
          </Grid>
        </Grid>
      );
    } else if (isBIKRequest(item)) {
      return (
        <Grid
          container
          spacing={2}
          sx={{
            p: 2,
            borderTop: `1px solid ${theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[300]}`,
            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)',
          }}
        >
          <BIKRequestCard request={item} />
          {/* Kwota */}
          <Grid size={{ xs: 12, sm: 6 }} display="flex" alignItems="center" gap={1}>
            <Money fontSize="small" color="action" />
            <Typography variant="body2" color="text.secondary">
              Kwota:
            </Typography>
            <Typography variant="body2" fontWeight="bold">
              {item.amount} {item.currency}
            </Typography>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <>
      {isFirstOccurrence && (
        <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
          {isBIKItem(item)
            ? item.isOpen
              ? 'Zobowiązania kredytowe w trakcie spłaty:'
              : 'Zobowiązania kredytowe - zamknięte (w ciągu ostatnich 60 miesięcy):'
            : `Zapytania kredytowe do BIK:`}
        </Typography>
      )}
      <Card
        variant="outlined"
        sx={{
          borderRadius: 2,
          boxShadow:
            theme.palette.mode === 'dark' ? '0px 4px 12px rgba(255, 255, 255, 0.1)' : '0px 4px 12px rgba(0, 0, 0, 0.1)',
          backgroundColor: theme.palette.mode === 'dark' ? grey[900] : grey[50],
          p: 0,
        }}
      >
        {/* Main Content */}
        <Grid container p={2} alignItems="center">
          {/* Left Section */}
          <Grid size={{ xs: 12, sm: 8 }} display="flex" alignItems="center" gap={2}>
            <Box
              sx={{
                minWidth: 32,
                width: 32,
                height: 32,
                backgroundColor: theme.palette.mode === 'dark' ? grey[900] : grey[200],
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <WorkOutlineOutlinedIcon />
            </Box>
            <Box>
              <Typography variant="subtitle1" fontWeight="bold">
                {isBIKItem(item) ? item.subject : item.companyName}
              </Typography>
              <RecommendationChip isRecommended={!!item.enableToRemove} />
            </Box>
          </Grid>
          {/* Right Section */}
          <Grid size={{ xs: 11, sm: 3 }} display="flex" flexDirection="column" alignItems="center">
            {item.enableToRemove ? (
              <>
                {isSelectable && (
                  <Box fontWeight="bold" display="flex" alignItems="center" sx={{ opacity: isDisabled ? 0.5 : 1 }}>
                    Wybierz
                    <FormControlLabel
                      sx={{ ml: 0.1 }}
                      control={
                        <Switch
                          disabled={isDisabled}
                          checked={isChecked}
                          onChange={handleChange}
                          color="success"
                          size="medium"
                        />
                      }
                      label=""
                    />
                  </Box>
                )}
                <Stack>
                  {/* {!isClientView && item.partnerCommission && (
                    <Typography variant="body1" fontWeight="bold" color="primary">
                      Prowizja: {(item.partnerCommission || 0).toFixed(2)} zł
                    </Typography>
                  )} */}
                  <Typography variant="body1" fontWeight="bold" color="success">
                    Cena: {item.discountPrice.toFixed(2)} zł
                    <Box
                      sx={{
                        display: 'inline',
                        textDecoration: 'line-through',
                        fontWeight: 'bold',
                        color: 'error.main',
                      }}
                    >
                      {item.discountPrice !== item.price && ` ${item.price.toFixed(2)} zł`}
                    </Box>
                  </Typography>
                </Stack>
              </>
            ) : (
              <Chip
                label={'Nieusuwalne'}
                color={'error'}
                size="small"
                icon={<ErrorOutlineIcon />}
                sx={{ fontWeight: 'bold' }}
              />
            )}
          </Grid>
          {/* Expand Button */}
          <Grid size={{ xs: 1 }} display="flex" justifyContent="flex-end">
            <IconButton onClick={handleExpandClick} size="small">
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Grid>
        </Grid>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {renderAdditionalDetails()}
        </Collapse>
      </Card>
    </>
  );
};

export default BIKPosition;

import { ShieldOutlined } from '@mui/icons-material';
import { Box, Container, Link, Typography, useColorScheme } from '@mui/material';
import Grid from '@mui/material/Grid2';

export function Footer() {
  const { mode } = useColorScheme();

  return (
    <Box
      sx={{
        bgcolor: mode === 'dark' ? 'background.paper' : 'grey.900',
        color: mode === 'dark' ? 'text.primary' : 'grey.300',
      }}
    >
      <Container maxWidth="lg" sx={{ py: 6 }}>
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, md: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
              <ShieldOutlined sx={{ fontSize: 32, color: 'primary.main' }} />
              <Typography variant="h6" sx={{ color: mode === 'dark' ? 'text.primary' : 'common.white' }}>
                BIK Master
              </Typography>
            </Box>
            <Typography variant="body2">
              Poprawa zdolności kredytowej poprzez inteligentną analizę i praktyczne rekomendacje.
            </Typography>
          </Grid>

          <Grid size={{ xs: 12, md: 3 }}>
            <Typography variant="h6" sx={{ color: mode === 'dark' ? 'text.primary' : 'common.white', mb: 2 }}>
              Prawne
            </Typography>
            <Box component="ul" sx={{ listStyle: 'none', p: 0, m: 0 }}>
              <Box component="li" sx={{ mb: 1 }}>
                <Link
                  target="_blank"
                  href="/polityka-prywatnosci.pdf"
                  sx={{
                    color: 'inherit',
                    '&:hover': {
                      color: mode === 'dark' ? 'primary.main' : 'primary.light',
                    },
                  }}
                >
                  Polityka Prywatności
                </Link>
              </Box>
              <Box component="li" sx={{ mb: 1 }}>
                <Link
                  target="_blank"
                  href="/Regulamin_BIKmaster.pdf"
                  sx={{
                    color: 'inherit',
                    '&:hover': {
                      color: mode === 'dark' ? 'primary.main' : 'primary.light',
                    },
                  }}
                >
                  Regulamin usługi
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{
            borderTop: 1,
            borderColor: mode === 'dark' ? 'divider' : 'grey.800',
            mt: 6,
            pt: 4,
            textAlign: 'center',
          }}
        >
          <Typography variant="body2">
            &copy; {new Date().getFullYear()} BIKMaster. Wszelkie prawa zastrzeżone.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

import { useEffect } from 'react';
import { useSelectedService } from 'src/context/useSelectedService';
import { isBIKItem } from 'src/guards/isBIKItem';
import { isBIKRequest } from 'src/guards/isBIKRequest';
import { BIKItem, BIKRequest } from 'src/types/CompanyBIKSummary.type';
import { StructuredBIK, StructuredBIKItem } from 'src/types/StructuredBIK';

const mapBIKsToRecordById = (items: StructuredBIKItem[]): Record<string, BIKItem | BIKRequest> => {
  return items.reduce(
    (acc, item) => {
      if (isBIKItem(item) || isBIKRequest(item)) {
        acc[item.id] = item;
      }

      return acc;
    },
    {} as Record<string, BIKItem | BIKRequest>
  );
};

export const useBIKDiscountRefresh = (bikData: StructuredBIK[]) => {
  const { setGlobalSelectedService } = useSelectedService();

  useEffect(() => {
    setGlobalSelectedService((prev) => {
      const services = { ...prev };

      bikData.forEach((structuredBIK) => {
        if (structuredBIK.type === 'COMPANY' || structuredBIK.type === 'CUSTOMER') {
          const service = services[structuredBIK.type];

          if (!service) {
            return;
          }

          const mappedItems = mapBIKsToRecordById(structuredBIK.allItems);

          const newItems = service.items.map((item) =>
            isBIKItem(item) || isBIKRequest(item) ? mappedItems[item.id] || item : item
          );

          services[structuredBIK.type] = {
            ...service,
            partnerCommission: newItems.reduce(
              (sum, item) => (isBIKItem(item) || isBIKRequest(item) ? sum + (item?.partnerCommission || 0) : sum),
              0
            ),
            discountPartnerCommission: newItems.reduce(
              (sum, item) => (isBIKItem(item) || isBIKRequest(item) ? sum + (item?.partnerCommission || 0) : sum),
              0
            ),
            discountPrice: newItems.reduce(
              (sum, item) => (isBIKItem(item) || isBIKRequest(item) ? sum + item.discountPrice : sum),
              0
            ),
            price: newItems.reduce((sum, item) => (isBIKItem(item) || isBIKRequest(item) ? sum + item.price : sum), 0),
            items: newItems,
          };
        }
      });

      return services;
    });
  }, [bikData]);
};

import {
  Business as BuildingIcon,
  Article as FileTextIcon,
  Email as MailIcon,
  LocationOn as MapPinIcon,
} from '@mui/icons-material';
import { Box, Grid, Paper, Typography, useTheme } from '@mui/material';
import { PersonalInfo } from 'src/types/onboarding';
import { Button } from '../ui/Button';
import { StepNavigation } from './StepNavigation';

interface ContractPreviewProps {
  data: PersonalInfo;
  onConfirm: () => void;
  onEdit: () => void;
  onBack: () => void;
  isLoading?: boolean;
}

interface InfoSectionProps {
  icon: React.ElementType;
  title: string;
  children: React.ReactNode;
}

function InfoSection({ icon: Icon, title, children }: InfoSectionProps) {
  const theme = useTheme();

  return (
    <Box sx={{ mb: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, mb: 2 }}>
        <Icon sx={{ color: theme.palette.primary.main, fontSize: 20 }} />
        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
          {title}
        </Typography>
      </Box>
      {children}
    </Box>
  );
}

function InfoField({ label, value }: { label: string; value: string }) {
  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="caption" color="text.secondary" sx={{ mb: 0.5, display: 'block' }}>
        {label}
      </Typography>
      <Typography>{value}</Typography>
    </Box>
  );
}

export function ContractPreview({ data, onConfirm, onEdit, onBack, isLoading }: ContractPreviewProps) {
  const theme = useTheme();

  return (
    <div className="space-y-6">
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <FileTextIcon sx={{ fontSize: 48, color: theme.palette.primary.main, mb: 1 }} />
        <Typography variant="h5" sx={{ fontWeight: 600, mb: 1 }}>
          Podgląd umowy
        </Typography>
        <Typography color="text.secondary">Sprawdź poprawność swoich danych przed wysłaniem</Typography>
      </Box>

      <Paper
        variant="outlined"
        sx={{
          p: 4,
          bgcolor: theme.palette.background.default,
          border: '1px dashed',
          borderColor: theme.palette.divider,
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <InfoSection icon={BuildingIcon} title="Dane firmowe">
              <InfoField label="Firma" value={data.company} />
              <InfoField label="NIP" value={data.nip} />
            </InfoSection>

            <InfoSection icon={MapPinIcon} title="Dane adresowe">
              <InfoField label="Ulica" value={data.street} />
              <InfoField label="Miasto i kod" value={`${data.postalCode} ${data.city}`} />
              <InfoField label="Kraj" value={data.country} />
            </InfoSection>
          </Grid>

          <Grid item xs={12} md={6}>
            <InfoSection icon={MailIcon} title="Dane kontaktowe">
              <InfoField label="Imię i nazwisko" value={`${data.firstName} ${data.lastName}`} />
              <InfoField label="Email" value={data.email} />
              <InfoField label="Telefon" value={data.phone} />
            </InfoSection>
          </Grid>
        </Grid>
      </Paper>

      <StepNavigation onBack={onBack}>
        <Button
          onClick={onConfirm}
          isLoading={isLoading}
          startIcon={<FileTextIcon />}
          sx={{
            background:
              theme.palette.mode === 'dark'
                ? 'linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%)'
                : 'linear-gradient(135deg, #3b82f6 0%, #2563eb 100%)',
            color: 'white',
          }}
        >
          Potwierdź i wyślij
        </Button>
        <Button
          variant="outline"
          onClick={onEdit}
          disabled={isLoading}
          startIcon={<MailIcon />}
          sx={{
            borderColor: theme.palette.divider,
            color: theme.palette.text.primary,
          }}
        >
          Edytuj dane
        </Button>
      </StepNavigation>
    </div>
  );
}

import { Box, Collapse, FormControlLabel, Switch, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useEffect, useState } from 'react';
import { BIKRequest } from 'src/types/CompanyBIKSummary.type';
import { BIKRequestCard } from './BIKRequestCard';
import { RowDetailsIcon } from './RowDetailsIcon';
import { BIKSection } from './styles';

interface BIKRequestContentProps {
  request: BIKRequest;
  isChecked: boolean;
  isDisabled: boolean;
  dataTestId: string;
  onCheckboxChange: (bikRequest: BIKRequest) => void;
}

export const BIKRequestContent: React.FC<BIKRequestContentProps> = ({
  request,
  isChecked,
  isDisabled,
  dataTestId,
  onCheckboxChange,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const theme = useTheme(); // Access the theme for dark mode support

  const isRemovable = request.enableToRemove;
  const alignCenter = { alignContent: 'center', textAlign: 'center' };

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    const parsedText = `${request.commitment}${request.companyName}`;
    const FS = (window as any).FS as any;

    if (parsedText.length > 150 && FS) {
      FS('trackEvent', {
        name: 'Check long parsed text',
        properties: {
          text: parsedText,
          type: 'creditItem',
          index: request.id,
        },
      });
    }
  }, [request]);

  return (
    <BIKSection
      className="fs-unmask"
      container
      size={{ xs: 12 }}
      key={request.id}
      spacing={0}
      sx={{
        borderColor: isChecked ? theme.palette.success.main : theme.palette.divider, // Dynamic border color
        p: '0.8em',
        position: 'relative',
        backgroundColor: theme.palette.background.paper, // Adaptive background color for dark mode
        color: theme.palette.text.primary, // Text color adapts to the theme
      }}
      $isRemovable={!isExpanded && isRemovable && !isDisabled}
      data-testid={dataTestId}
      onClick={(ev) => {
        const target = ev.target as HTMLElement;
        const element = target.parentElement;

        if (element?.dataset?.testid === 'bik-info-action') {
          return;
        }

        if (isExpanded) {
          return;
        }

        return !isDisabled && isRemovable && onCheckboxChange(request);
      }}
    >
      <Grid size={{ xs: 5, md: 7 }}>
        <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
          {request.companyName}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {request.commitment}
        </Typography>
      </Grid>

      <Grid size={{ xs: 3, md: 3 }} sx={alignCenter}>
        {isRemovable ? 'Tak' : 'Nie'}
      </Grid>

      {isRemovable && (
        <>
          <Grid size={{ xs: 2, md: 1 }} sx={alignCenter}>
            <FormControlLabel
              control={
                <Switch
                  disabled={!isRemovable || isDisabled}
                  checked={isChecked}
                  onChange={() => onCheckboxChange(request)}
                  color="success"
                  size="medium"
                  inputProps={{ 'aria-label': `checkbox for request ${request.id}` }}
                />
              }
              label=""
            />
          </Grid>

          <Grid size={{ xs: 2, md: 1 }} sx={alignCenter}>
            {request.discountPrice && request.discountPrice !== request.price ? (
              <div>
                <Typography
                  variant="body2"
                  sx={{ textDecoration: 'line-through', color: theme.palette.text.disabled }} // Strike-through text for original price
                >
                  {request.price} zł
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 'bold', color: theme.palette.error.main }} // Discounted price in error/main color
                >
                  {request.discountPrice} zł
                </Typography>
              </div>
            ) : (
              <Typography variant="body1">{request.price} zł</Typography>
            )}
          </Grid>
        </>
      )}
      <Grid size={{ xs: 12 }}>{RowDetailsIcon({ isExpanded, toggleExpand })}</Grid>

      <Grid size={{ xs: 12 }}>
        <Collapse in={isExpanded}>
          <Box
            sx={{
              marginTop: 1,
              paddingTop: 1,
              borderTop: `2px solid ${theme.palette.divider}`, // Adaptive border
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
              Szczegółowe informacje o zapytaniu:
            </Typography>

            <BIKRequestCard request={request} />
          </Box>
        </Collapse>
      </Grid>
    </BIKSection>
  );
};

import { z } from 'zod';

export const phoneRegex = /^\+?[1-9]\d{1,14}$/;
export const nipRegex = /^\d{10}$/;

export const personalInfoSchema = z.object({
  firstName: z.string().min(2, 'Imię musi mieć co najmniej 2 znaki'),
  lastName: z.string().min(2, 'Nazwisko musi mieć co najmniej 2 znaki'),
  email: z.string().email('Nieprawidłowy adres email'),
  phone: z.string().regex(phoneRegex, 'Nieprawidłowy numer telefonu'),
  company: z.string().min(2, 'Nazwa firmy musi mieć co najmniej 2 znaki'),
  nip: z.string().regex(nipRegex, 'NIP musi mieć 10 cyfr'),
  street: z.string().min(2, 'Ulica musi mieć co najmniej 2 znaki'),
  city: z.string().min(2, 'Miasto musi mieć co najmniej 2 znaki'),
  postalCode: z.string().min(5, 'Kod pocztowy jest wymagany'),
  country: z.string().min(2, 'Kraj musi mieć co najmniej 2 znaki'),
});

export const otpSchema = z.object({
  code: z.string().length(6, 'Kod musi mieć 6 cyfr'),
});

export type PersonalInfo = z.infer<typeof personalInfoSchema>;
export type OTPInput = z.infer<typeof otpSchema>;
import CloseIcon from '@mui/icons-material/Close';
import { Backdrop, Box, Button, Fade, IconButton, Modal, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useManagerPartnersList } from 'src/api/bikApi'; // Custom hook to fetch manager's partners
import DashboardContainer from 'src/common/DashboardContainer';
import RowElement from 'src/components/bik-report/RowLayout/RowElement';
import { UserDetails } from 'src/pages/user-details/UserDetails';
import { PartnerRegistrationForm } from './PartnerRegistrationForm'; // Import the form component

const ManagerPartnersList: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data, refetch } = useManagerPartnersList();
  const partners = data?.items || [];

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  return (
    <DashboardContainer title="Zarządzaj partnerami">
      <UserDetails
        type="ROLE_MANAGER"
        rightBox={
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <Button variant="contained" color="primary" onClick={toggleModal}>
              Dodaj nowego partnera
            </Button>
          </Box>
        }
      />

      <Box sx={{ width: '100%', overflow: 'hidden', p: 3 }}>
        {partners.map((partner) => (
          <RowElement type="ROLE_MANAGER" key={partner.id} refetch={refetch} data={partner} allUsers={partners} />
        ))}
      </Box>

      <Modal
        open={isModalOpen}
        onClose={toggleModal}
        aria-labelledby="add-partner-modal"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isModalOpen}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              borderRadius: 2,
              outline: 'none', // Optional: Remove focus outline on the box
            }}
          >
            <Paper elevation={3} sx={{ p: 4, maxWidth: 400, width: '100%', position: 'relative' }}>
              {/* Close Button */}
              <IconButton
                onClick={toggleModal}
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>

              <Typography variant="h6" align="center" gutterBottom>
                Dodaj nowego partnera
              </Typography>
              <PartnerRegistrationForm onSuccess={refetch} users={partners} />
            </Paper>
          </Box>
        </Fade>
      </Modal>
    </DashboardContainer>
  );
};

export default ManagerPartnersList;

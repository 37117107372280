import { zodResolver } from '@hookform/resolvers/zod';
import { Security as ShieldIcon } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import { OTPInput, otpSchema } from 'src/types/onboarding';
import { Button } from '../ui/Button';
import { Input } from '../ui/Input';
import { StepNavigation } from './StepNavigation';

interface OTPVerificationProps {
  type: 'email' | 'phone';
  contact: string;
  onVerify: (code: string) => void;
  onResend: () => void;
  onBack: () => void;
  isLoading?: boolean;
}

export function OTPVerification({ type, contact, onVerify, onResend, onBack, isLoading }: OTPVerificationProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<OTPInput>({
    resolver: zodResolver(otpSchema),
  });

  const theme = useTheme();

  const handleVerify = (data: OTPInput) => {
    onVerify(data.code);
  };

  const typeInPolish = type === 'email' ? 'email' : 'telefon';

  return (
    <div className="space-y-6">
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <ShieldIcon sx={{ fontSize: 48, color: theme.palette.primary.main, mb: 2 }} />
        <Typography variant="h5" sx={{ fontWeight: 600, mb: 1 }}>
          Zweryfikuj swój {typeInPolish}
        </Typography>
        <Typography color="text.secondary">Wysłaliśmy kod weryfikacyjny na {contact}</Typography>
      </Box>

      <form onSubmit={handleSubmit(handleVerify)} className="space-y-4">
        <Input label="Wprowadź kod weryfikacyjny" {...register('code')} error={errors.code?.message} />
        <StepNavigation onBack={onBack}>
          <Button
            type="submit"
            isLoading={isLoading}
            className="flex-1"
            sx={{
              background:
                theme.palette.mode === 'dark'
                  ? 'linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%)'
                  : 'linear-gradient(135deg, #3b82f6 0%, #2563eb 100%)',
              color: 'white',
              '&:disabled': {
                color: theme.palette.background.default,
              },
              '&:hover': {
                background:
                  theme.palette.mode === 'dark' ? 'grey' : 'linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%)',
              },
            }}
          >
            Weryfikuj
          </Button>
          <Button
            type="button"
            variant="outline"
            onClick={onResend}
            disabled={isLoading}
            className="flex-1"
            sx={{
              borderColor: theme.palette.divider,
              color: theme.palette.text.primary,
            }}
          >
            Wyślij ponownie
          </Button>
        </StepNavigation>
      </form>
    </div>
  );
}

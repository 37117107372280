import { Email } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { PartnerDetails } from 'src/types/PartnerDetails';

interface UserEmailProps {
  user: PartnerDetails;
}

export const UserEmail: FunctionComponent<UserEmailProps> = ({ user }) => (
  <Typography fontSize="0.9rem" lineHeight={1}>
    <Box
      display="flex"
      alignItems="center"
      className="agent-name"
      sx={{
        overflowWrap: 'anywhere',
      }}
    >
      <Email sx={{ mr: 1 }} />
      <div>{user.email}</div>
    </Box>
  </Typography>
);

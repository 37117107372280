import { Box, CircularProgress } from '@mui/material';
import Container from '@mui/material/Container';
import Footer from 'src/components/Footer';
import ScrollToTop from 'src/components/ScrollToTop';

export default function LoadingSite() {
  return (
    <>
      <ScrollToTop />
      <Container
        sx={{
          pt: { xs: 12, sm: 12 },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 6, sm: 6 },
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      </Container>
      <Footer />
    </>
  );
}

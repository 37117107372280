import { Tooltip } from '@mui/material';
import { FunctionComponent } from 'react';

import { Report } from 'src/types/Report';

interface BIKCommentProps {
  bikReport: Report;
}

export const BIKComment: FunctionComponent<BIKCommentProps> = ({ bikReport }) => (
  <>
    {!!bikReport.comment && (
      <div className="additional-info">
        <div className="label">Komentarz</div>
        <div className="value" data-test-id="bik-comment">
          <Tooltip title={bikReport.comment} placement="top" arrow>
            <span>{bikReport.comment.length > 120 ? `${bikReport.comment.slice(0, 120)}...` : bikReport.comment}</span>
          </Tooltip>
        </div>
      </div>
    )}
  </>
);

import { Box, Stack, SvgIcon, Typography } from '@mui/material';
import { Commission, PartnerDetails } from 'src/types/PartnerDetails';
import { CommissionTranslation } from './translations';

interface UserCommissionProps {
  user: PartnerDetails;
}

type CustomIconProps = {
  text: string;
  [key: string]: any;
};

const CustomIcon = ({ text, ...props }: CustomIconProps) => (
  <Box
    display="inline-flex"
    alignItems="center"
    justifyContent="center"
    sx={{
      minWidth: 30,
      width: 30,
      height: 30,
      backgroundColor: '#2a9cab',
      borderRadius: '50%',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    }}
    {...props}
  >
    <SvgIcon>
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dominantBaseline="central"
        fontSize="13"
        fontFamily="Arial, Helvetica, sans-serif"
      >
        {text}
      </text>
    </SvgIcon>
  </Box>
);

export const UserCommission = ({ user }: UserCommissionProps) => {
  const getFinishedIcon = (value: Commission) => {
    if (value.type === 'PERCENTAGE') {
      return <CustomIcon fontSize="small" text="%" />;
    }

    return <CustomIcon text="PLN" />;
  };

  const commissions = user?.commissions || [];

  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
        Aktywne produkty [Prowizja]:
      </Typography>
      <Stack direction="row" flexWrap="wrap" justifyContent="space-between">
        {commissions.map((commission, key) => (
          <Box mt={1} key={key} display="flex" alignItems="center" gap={2} sx={{ textAlign: 'center' }}>
            {getFinishedIcon(commission)}
            <Typography variant="body1" component="span" fontWeight="bold">
              {CommissionTranslation[commission.productType]}:
            </Typography>
            {/* <Typography variant="body2" component="span">
              {commission.value}
              {commission.type === 'PERCENTAGE' ? '%' : 'PLN'}
            </Typography> */}
          </Box>
        ))}
        {commissions.length === 0 && <Typography>Brak aktywnych produktów</Typography>}
      </Stack>
    </Box>
  );
};

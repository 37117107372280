import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import { Logo } from 'src/components/app-toolbar/Logo';

const items = [
  {
    icon: <SettingsSuggestRoundedIcon sx={{ color: 'text.secondary' }} />,
    title: 'Skuteczna analiza',
    description:
      'Nasze narzędzia dokładnie analizują raporty BIK, identyfikując wpisy, które mogą zostać usunięte, dzięki czemu optymalizujemy Twoje szanse na poprawę historii kredytowej.',
  },
  {
    icon: <ConstructionRoundedIcon sx={{ color: 'text.secondary' }} />,
    title: 'Profesjonalne wsparcie',
    description:
      'Zapewniamy kompleksowe wsparcie w procesie usuwania negatywnych wpisów, od analizy po kontakt z instytucjami finansowymi.',
  },
  {
    icon: <ThumbUpAltRoundedIcon sx={{ color: 'text.secondary' }} />,
    title: 'Łatwość użytkowania',
    description:
      'Nasza platforma jest prosta w obsłudze, co pozwala użytkownikom szybko i bezproblemowo przystąpić do analizy i usuwania wpisów z BIK.',
  },
  {
    icon: <AutoFixHighRoundedIcon sx={{ color: 'text.secondary' }} />,
    title: 'Innowacyjne rozwiązania',
    description:
      'Korzystamy z nowoczesnych technologii, aby zapewnić najlepsze narzędzia do analizy i usuwania wpisów z BIK, dostosowując się do zmieniających się potrzeb rynku finansowego.',
  },
];

export default function Content() {
  return (
    <Stack sx={{ flexDirection: 'column', alignSelf: 'center', gap: 4, maxWidth: 450 }}>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Logo />
      </Box>
      {items.map((item, index) => (
        <Stack key={index} direction="row" sx={{ gap: 2 }}>
          {item.icon}
          <div>
            <Typography gutterBottom sx={{ fontWeight: 'medium' }}>
              {item.title}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {item.description}
            </Typography>
          </div>
        </Stack>
      ))}
    </Stack>
  );
}

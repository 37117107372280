import { Box, BoxProps, CircularProgress, CircularProgressProps } from '@mui/material';

interface LoaderProps extends BoxProps {
  isLoading: boolean;
  'data-testid'?: string;
  circularProgressProps?: CircularProgressProps;
  fullWidth?: boolean;
  label?: string;
  size?: number;
}

export const Loader = ({
  isLoading,
  'data-testid': dataTestId = 'loader',
  circularProgressProps,
  fullWidth,
  textAlign = 'center',
  label,
  size = 20,
  ...rest
}: LoaderProps) => {
  if (!isLoading) {
    return null;
  }

  return (
    <Box textAlign={textAlign} width={fullWidth ? '100%' : rest.width || 'auto'} {...rest} data-testid={dataTestId}>
      <CircularProgress
        sx={{
          animationDuration: '0.5s',
        }}
        size={size}
        {...circularProgressProps}
        color={circularProgressProps?.color || 'inherit'}
      />
      {label && <Box mt={2}>{label}</Box>}
    </Box>
  );
};

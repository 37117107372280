import { styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { CreditCalculation } from 'src/types/CreditCalculation';

interface BikReportTableProps {
  credits: CreditCalculation[];
}

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(3),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const formatCurrency = (amount: number): string => {
  return new Intl.NumberFormat('pl-PL', {
    style: 'currency',
    currency: 'PLN',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
};

const BIKCalculationsTable: React.FC<BikReportTableProps> = ({ credits }) => {
  return (
    <StyledTableContainer>
      <Table aria-label="credit table">
        <TableHead>
          <TableRow>
            <StyledTableCell>KREDYTODAWCA</StyledTableCell>
            <StyledTableCell>OPIS</StyledTableCell>
            <StyledTableCell align="right">LIMIT</StyledTableCell>
            <StyledTableCell align="right">RATA</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {credits.map((credit, index) => (
            <StyledTableRow key={index}>
              <TableCell component="th" scope="row">
                {credit.creditorName}
              </TableCell>
              <TableCell>{credit.description}</TableCell>
              <TableCell align="right">{credit.limit ? formatCurrency(credit.limit) : '-'}</TableCell>
              <TableCell align="right">{formatCurrency(credit.installment)}</TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default BIKCalculationsTable;

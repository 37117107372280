import { CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FunctionComponent, memo } from 'react';
import { useBIKSummary } from 'src/api/bikApi';
import { DarkPanel } from 'src/common/DarkPanel';
import { RoleType } from 'src/common/roles';
import { Report } from 'src/types/Report';
import { TimelineStatus } from 'src/types/TimelineStatus';
import SummaryDetails from '../SummaryDetails/SummaryDetails';
import { Timeline } from '../Timeline/Timeline';
import BIKCommissionsDetails from './BIKCommissionsDetails';
import BIKEditableDetails from './BIKEditableDetails';

interface BIKDetailsProps {
  bikReport: Report;
  type: RoleType;
  bikStatuses: TimelineStatus[];
  refetchData: () => void;
}

const BIKDetails: FunctionComponent<BIKDetailsProps> = ({ bikReport, type, bikStatuses, refetchData }) => {
  const { data, isLoading, refetch } = useBIKSummary(bikReport.id);

  const isCommissionsEnabled = !!data?.commission.length;

  return (
    <Grid container spacing={2}>
      {isCommissionsEnabled && (
        <Grid size={{ xs: 12 }}>
          <DarkPanel>
            <BIKCommissionsDetails bikReport={bikReport} type={type} />
          </DarkPanel>
        </Grid>
      )}
      <Grid size={{ xs: 12 }}>
        <DarkPanel>
          <BIKEditableDetails bikReport={bikReport} type={type} refetchData={refetchData} />
        </DarkPanel>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <DarkPanel>
          <Timeline bikStatus={bikReport.status} timeline={bikStatuses} />
        </DarkPanel>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <DarkPanel>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <SummaryDetails data={data} type={type} refetchData={refetch} isPreview />
          )}
        </DarkPanel>
      </Grid>
    </Grid>
  );
};

export default memo(BIKDetails);

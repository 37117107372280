import { Commission, PartnerDetails } from 'src/types/PartnerDetails';

export type RoleType = 'ROLE_ADMIN' | 'ROLE_MANAGER' | 'ROLE_PARTNER' | 'ROLE_LAW_OFFICE';

export const USER_DETAILS = 'userDetails';

export const ROLES: Record<RoleType, RoleType> = {
  ROLE_PARTNER: 'ROLE_PARTNER',
  ROLE_MANAGER: 'ROLE_MANAGER',
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_LAW_OFFICE: 'ROLE_LAW_OFFICE',
};

export const ROLE_OPTIONS = [
  { value: ROLES.ROLE_ADMIN, label: 'Admin' },
  { value: ROLES.ROLE_MANAGER, label: 'Manager' },
  { value: ROLES.ROLE_PARTNER, label: 'Partner' },
  { value: ROLES.ROLE_LAW_OFFICE, label: 'Pracownik Kancelarii CREO' },
];

export const getUserDetailsFromLocalStorage = (): PartnerDetails =>
  JSON.parse(localStorage.getItem(USER_DETAILS) || '{}');

const getUserRoles = (): string[] => {
  return getUserDetailsFromLocalStorage()?.roles;
};
export const getUserCommission = (): Commission[] => {
  return getUserDetailsFromLocalStorage()?.commissions || [];
};

export const isSKDEnabledForLoggedUser = (): boolean => {
  return getUserCommission().some((commission) => commission.productType === 'SKD');
};

export const isWIBOREnabledForLoggedUser = (): boolean => {
  return getUserCommission().some((commission) => commission.productType === 'UNWIBOR');
};

export const isRefundOfCommissionEnabledForLoggedUser = (): boolean => {
  return getUserCommission().some((commission) => commission.productType === 'REFUND_OF_COMMISSION');
};

export const isBIKEnabledForLoggedUser = (): boolean => {
  return getUserCommission().some((commission) => commission.productType === 'BIK_CLEANING');
};

export const isUserPartner = () => {
  return getUserRoles()?.includes(ROLES.ROLE_PARTNER);
};

export const isNotPartner = () => {
  return getUserRoles()?.includes(ROLES.ROLE_PARTNER) === false;
};

export const isUserLawOffice = () => {
  return getUserRoles()?.includes(ROLES.ROLE_LAW_OFFICE);
};

export const isUserManager = () => {
  return getUserRoles()?.includes(ROLES.ROLE_MANAGER);
};

export const isUserAdmin = () => {
  return getUserRoles()?.includes(ROLES.ROLE_ADMIN);
};

// src/components/ManagerReportsList.tsx

import { Box, CircularProgress, Pagination, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useState } from 'react';
import { useReportsForManager } from 'src/api/bikApi';
import DashboardContainer from 'src/common/DashboardContainer';
import { useDateManagement } from 'src/common/useDateManagement';
import RowElement from 'src/components/bik-report/RowLayout/RowElement';
import { useReportSearch } from 'src/pages/searches/useReportSearch';
import { UserDetails } from 'src/pages/user-details/UserDetails';

const ROWS_PER_PAGE = 10;

const ManagerReportsList: React.FC = () => {
  const [page, setPage] = useState(0);
  const { DateContainer, formattedFromDate, formattedToDate } = useDateManagement(setPage, true);

  const { searchText, SearchField, StatusSelect, selectedStatus, selectedBIKService, ServiceSelect } =
    useReportSearch(setPage);
  const { data, isLoading, isError, refetch } = useReportsForManager(
    page + 1,
    ROWS_PER_PAGE,
    searchText,
    selectedStatus,
    selectedBIKService,
    formattedFromDate,
    formattedToDate
  );

  const reports = data?.items || [];
  const totalReports = data?.totalElements || 0;

  return (
    <DashboardContainer title="Zlecenia partnerów">
      <UserDetails type="ROLE_MANAGER" />

      {DateContainer}
      <Grid container spacing={2} sx={{ width: '100%' }}>
        <Grid size={{ xs: 12, sm: 6 }}>{SearchField}</Grid>
        <Grid size={{ xs: 6, sm: 3 }}>{StatusSelect}</Grid>
        <Grid size={{ xs: 6, sm: 3 }}>{ServiceSelect}</Grid>
      </Grid>

      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Box sx={{ m: 2, display: 'flex', justifyContent: 'center' }}>
            <Pagination
              count={Math.ceil(totalReports / ROWS_PER_PAGE)}
              page={page + 1} // Display 1-based index for pagination
              onChange={(_, value) => setPage(value - 1)}
              color="primary"
            />
          </Box>
          {isLoading ? (
            <Typography>Loading...</Typography>
          ) : isError ? (
            <Typography color="error">Error fetching reports.</Typography>
          ) : (
            <>
              {reports.length === 0 && (
                <Typography variant="h6" align="center">
                  Brak zleceń dla podanych parametrów
                </Typography>
              )}
              {reports.map((bikReport) => (
                <RowElement type="ROLE_MANAGER" key={bikReport.id} refetch={refetch} data={bikReport} />
              ))}
            </>
          )}
          <Box sx={{ m: 2, display: 'flex', justifyContent: 'center' }}>
            <Pagination
              count={Math.ceil(totalReports / ROWS_PER_PAGE)}
              page={page + 1} // Display 1-based index for pagination
              onChange={(_, value) => setPage(value - 1)}
              color="primary"
            />
          </Box>
        </>
      )}
    </DashboardContainer>
  );
};

export default ManagerReportsList;

import React from 'react';
import DashboardContainer from 'src/common/DashboardContainer';
import ReportsDashboard from 'src/components/reports-dashboard/ReportsDashboard';

const PartnerStatistics: React.FC = () => {
  return (
    <DashboardContainer title="Moje stystyki">
      <ReportsDashboard type="ROLE_PARTNER" />
    </DashboardContainer>
  );
};

export default PartnerStatistics;

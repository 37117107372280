import Grid from '@mui/material/Grid2';
import { DarkPanel } from 'src/common/DarkPanel';
import { PartnerDetails } from 'src/types/PartnerDetails';
import AssignCommission from '../AssignCommission/AssignCommission';

export interface UserActionsProps {
  user: PartnerDetails;
  refetch?: () => void;
}

export const UserActions = ({ user, refetch }: UserActionsProps) => {
  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12 }}>
        <DarkPanel>
          <AssignCommission user={user} refetch={refetch} />
        </DarkPanel>
      </Grid>
    </Grid>
  );
};

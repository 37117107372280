import { zodResolver } from '@hookform/resolvers/zod';
import BusinessIcon from '@mui/icons-material/Business';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import {
  Alert,
  Box,
  Breakpoint,
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import Container from '@mui/material/Container';
import Grid, { GridSize } from '@mui/material/Grid2';
import { useQueryClient } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Controller, ControllerRenderProps, FieldErrors, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createCompanyForBIKReport,
  createCustomerForBIKReport,
  updateBIKReportUserData,
  useBIKSummary,
  useCompanyDetailsFromNip,
  usePartnerDetails,
} from 'src/api/bikApi';
import { handleApiError } from 'src/api/handleApiError';
import { Header } from 'src/components/app-toolbar/Header';
import Footer from 'src/components/Footer';
import { LoadingScreen } from 'src/components/LoadingScreen';
import ScrollToTop from 'src/components/ScrollToTop';
import { AddDetailsSchema, getDetailsSchema } from 'src/pages/add-details/form.zod';
import { AutocompleteDataProps, useAutocomplateData } from 'src/pages/add-details/useAutocompleteData';
import { BIKSummary } from 'src/types/BIKSummary';
import BIKNotFound from '../commonSites/BIKNotFound';
import LoadingSite from '../commonSites/LoadingSite';

const DEFAULT_BANK_NUMBER = '_________________________________________';

// Utility type for nested keys
type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: NonNullable<ObjectType[Key]> extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<NonNullable<ObjectType[Key]>>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

type ResponsiveStyleValue<T> =
  | T
  | Array<T | null>
  | {
      [key in Breakpoint]?: T | null;
    };

const initialData: AddDetailsSchema = {
  mainData: {
    phone: '',
    email: '',
  },
  privateData: {
    fullName: '',
    country: '',
    city: '',
    postalCode: '',
    street: '',
    buildingNumber: '',
    pesel: '',
    bankAccountNumber: '',
    bankAccountName: '',
  },
  companyData: {
    companyName: '',
    country: '',
    city: '',
    postalCode: '',
    street: '',
    buildingNumber: '',
    nip: '',
    regon: '',
  },
  supportData: { fullName: '', phone: '', email: '' },
  marketingConsents: { phoneConsent: true, emailConsent: true },
};

const getTestData = (isRequired: boolean): AddDetailsSchema => ({
  privateData: {
    fullName: 'Jan Kowalski',
    country: 'Polska',
    city: 'Krakow',
    postalCode: '33-333',
    street: 'Krakowska',
    buildingNumber: '1',
    pesel: '99999999999',
    bankAccountName: isRequired ? 'Test bank account' : '',
    bankAccountNumber: isRequired ? '12123412341234123412341234' : '',
  },
  companyData: {
    companyName: 'Kowalski SA',
    country: 'Polska',
    city: 'Krakow',
    postalCode: '33-333',
    street: 'Krakowska',
    buildingNumber: '3',
    nip: '9730884217',
    regon: '123123123',
  },
  mainData: {
    phone: '111111111',
    email: 'jan.kowalski@niematkiegomaila.pl',
  },
  supportData: { fullName: 'Jan Kowalski', phone: '123123123', email: 'jan.kowalski@niematkiegomaila.pl' },
  marketingConsents: { phoneConsent: true, emailConsent: true },
});

let timer: NodeJS.Timeout;

const checkIsCustomerFormEnabled = (summaryRawData?: BIKSummary) => {
  if (!summaryRawData) return false;

  const isAnyItemFromCustomer = summaryRawData?.items.some((item) => item.bikReportType === 'CUSTOMER');
  const isAnyRequestFromCustomer = summaryRawData?.requests.some((request) => request.bikReportType === 'CUSTOMER');

  return (
    !!summaryRawData?.commission.length ||
    !!summaryRawData?.skd.length ||
    !!summaryRawData?.unwibor.length ||
    isAnyItemFromCustomer ||
    isAnyRequestFromCustomer
  );
};

const checkIsCompanyFormEnabled = (summaryRawData?: BIKSummary) => {
  if (!summaryRawData) return false;

  const isAnyItemFromCompany = summaryRawData?.items.some((item) => item.bikReportType === 'COMPANY');
  const isAnyRequestFromCompany = summaryRawData?.requests.some((request) => request.bikReportType === 'COMPANY');

  return isAnyItemFromCompany || isAnyRequestFromCompany;
};

const AddDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // UseParams typed
  const [nip, setNip] = useState('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [clickCount, setClickCount] = useState<number>(0);

  const { data: summaryRawData, isLoading: isSummaryLoading } = useBIKSummary(id ?? 'Wrong_param');
  const queryClient = useQueryClient();

  const isCustomerFormEnabled = useMemo(() => checkIsCustomerFormEnabled(summaryRawData), [summaryRawData]);
  const isCompanyFormEnabled = useMemo(() => checkIsCompanyFormEnabled(summaryRawData), [summaryRawData]);

  const isEditable =
    !summaryRawData ||
    summaryRawData.status === 'DOCUMENTS_UPLOADED' ||
    summaryRawData.status === 'ITEMS_SELECTED_FOR_REMOVAL' ||
    summaryRawData.status === 'USER_DATA_ENTERED' ||
    summaryRawData.status === 'PAYMENT_PROCESS_INITIATED' ||
    summaryRawData.status === 'SUMMARY_INFORMATION_SENT';

  const isBankAccountRequired = !!summaryRawData?.commission?.length;
  const schema = getDetailsSchema();

  const {
    control,
    handleSubmit,
    setValue,

    formState: { errors },
    getValues,
  } = useForm<AddDetailsSchema>({
    resolver: zodResolver(schema),
    disabled: !isEditable,
    defaultValues: initialData,
  });

  const { data: partnerDetails } = usePartnerDetails();
  const { data: companyData, error: companyDetailsError, isFetching } = useCompanyDetailsFromNip(nip);

  useEffect(() => {
    if (summaryRawData?.status === 'DOCUMENTS_SENT') {
      navigate(`/process-bik/${summaryRawData.id}/summary`);
      return;
    }
  }, [summaryRawData?.status]);

  const { autocompleteMessage, clearMessage } = useAutocomplateData({
    summaryData: summaryRawData,
    setData: (autocompleteData: AutocompleteDataProps) => {
      if (autocompleteData.nip) {
        setValue('companyData.nip', autocompleteData.nip);
      }
      if (autocompleteData.fullName) {
        setValue('privateData.fullName', autocompleteData.fullName);
      }
      if (autocompleteData.pesel) {
        setValue('privateData.pesel', autocompleteData.pesel);
      }

      if (autocompleteData.nip) {
        setNip(autocompleteData.nip);
      }
    },
    setCompleteData: (details: AddDetailsSchema) => {
      if (details.companyData) setValue('companyData', details.companyData);
      if (details.mainData) setValue('mainData', details.mainData);
      if (details.privateData)
        setValue('privateData', {
          ...details.privateData,
          bankAccountName:
            details?.privateData?.bankAccountName === DEFAULT_BANK_NUMBER ? '' : details?.privateData?.bankAccountName,
          bankAccountNumber:
            details?.privateData?.bankAccountNumber === DEFAULT_BANK_NUMBER
              ? ''
              : details?.privateData?.bankAccountNumber,
        });
      if (details.supportData) {
        setValue('supportData', details.supportData);
        setValue('marketingConsents', details.marketingConsents);
      }
    },
  });

  useEffect(() => {
    if (companyDetailsError) {
      setError('Brak danych dla podanego adresu NIP.');
      return;
    }

    if (companyData) {
      setValue('companyData', { ...companyData.company });
    }
  }, [companyData, companyDetailsError]);

  const handleSearchClick = () => {
    const tmpNip = getValues('companyData.nip');

    if (tmpNip.length === 10) {
      setNip(tmpNip);
    } else {
      setError('Proszę podać poprawny NIP, aby wyszukać firmę.');
    }
  };

  useEffect(() => {
    if (!partnerDetails || isSummaryLoading || summaryRawData?.contact?.email) {
      return;
    }

    setValue('supportData', {
      ...partnerDetails,
      fullName: `${partnerDetails.firstName} ${partnerDetails.lastName}`,
    });
  }, [partnerDetails, isSummaryLoading, summaryRawData]);

  const copyMainDataToContact = () => {
    const mainData = getValues('mainData');

    if (mainData.email) {
      setValue('supportData.email', mainData.email);
    }
    if (mainData.phone) {
      setValue('supportData.phone', mainData.phone);
    }
  };
  // Handle click count and test data setting
  useEffect(() => {
    if (!isEditable) {
      return;
    }

    if (clickCount === 6) {
      if (isCustomerFormEnabled) {
        setValue('privateData', getTestData(isBankAccountRequired).privateData);
      }
      if (isCompanyFormEnabled) {
        setValue('companyData', getTestData(isBankAccountRequired).companyData);
      }

      setValue('marketingConsents', getTestData(isBankAccountRequired).marketingConsents);
      setValue('supportData', getTestData(isBankAccountRequired).supportData);
      setValue('mainData', getTestData(isBankAccountRequired).mainData);
      setClickCount(0);
    }

    if (clickCount > 0) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => setClickCount(0), 1000);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [clickCount, isCustomerFormEnabled, isCompanyFormEnabled, isBankAccountRequired, setValue]);

  useEffect(() => {
    if (summaryRawData?.id && !isCustomerFormEnabled) {
      setValue('privateData', undefined);
    }
  }, [summaryRawData, isCustomerFormEnabled, setValue]);

  useEffect(() => {
    if (summaryRawData?.id && !isCompanyFormEnabled) {
      setValue('companyData', undefined);
    }
  }, [summaryRawData, isCompanyFormEnabled, setValue]);

  // Submit handler
  const onSubmit: SubmitHandler<AddDetailsSchema> = async (data) => {
    if (!isEditable) {
      navigate(`/process-bik/${id}/summary`);
      return;
    }

    setIsLoading(true);

    if (!id) {
      setError('Brak podanego poprawnego identyfikatora.');
      setIsLoading(false);
      return;
    }

    try {
      const companyResponse =
        data.companyData &&
        isCompanyFormEnabled &&
        (await createCompanyForBIKReport({
          ...data.companyData,
          invoiceEmail: data.mainData.email,
          phone: data.mainData.phone,
          bikReportId: id,
        }));

      const customerResponse =
        data.privateData &&
        isCustomerFormEnabled &&
        (await createCustomerForBIKReport({
          ...data.privateData,
          bankAccountName: isBankAccountRequired
            ? data?.privateData?.bankAccountName || DEFAULT_BANK_NUMBER
            : undefined,
          bankAccountNumber: isBankAccountRequired
            ? data?.privateData?.bankAccountNumber || DEFAULT_BANK_NUMBER
            : undefined,
          email: data.mainData.email,
          phone: data.mainData.phone,
          bikReportId: id,
        }));

      await updateBIKReportUserData(id, {
        companyId: companyResponse?.id,
        customerId: customerResponse?.id,
        contactPersonEmail: data.supportData.email,
        contactPersonFullName: data.supportData.fullName,
        contactPersonPhone: data.supportData.phone,
        contactPersonEmailConsent: data.marketingConsents.emailConsent,
        contactPersonPhoneConsent: data.marketingConsents.phoneConsent,
      });

      queryClient.removeQueries({
        queryKey: ['bik-report', id],
      });
      queryClient.removeQueries({
        queryKey: ['bik-summary', id],
      });
      navigate(`/process-bik/${id}/summary`);
    } catch (error) {
      setError(handleApiError(error));
    } finally {
      setIsLoading(false);
    }
  };

  // Close error
  const handleClose = () => setError(null);

  const handleNumberField = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: ControllerRenderProps<FieldValues, NestedKeyOf<AddDetailsSchema>>
  ) => {
    const value = event.target.value.replace(/\D/g, '');
    field.onChange(value);
  };

  if (isSummaryLoading) {
    return <LoadingSite />;
  }

  if (!summaryRawData?.id) {
    return <BIKNotFound />;
  }

  return (
    <>
      <ScrollToTop />
      <Header type="back" />
      <LoadingScreen
        loadingMessage="Dodawanie danych osobowych do raportu. Proszę czekać..."
        open={isLoading || !!error}
        errorMessage={error || undefined}
        onClose={handleClose}
      />
      <Container
        id="faq"
        sx={{ pt: { xs: 12, sm: 12 }, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 6 }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <>
            <SectionTitle
              icon={<PersonIcon />}
              title="Dane klienta:"
              handleClick={() => setClickCount(clickCount + 1)}
            />
            <Grid container spacing={4} mb={2}>
              <FormField
                name="mainData.phone"
                label="Telefon"
                control={control}
                errors={errors}
                onChange={handleNumberField}
              />
              <FormField name="mainData.email" label="Adres Email" type="email" control={control} errors={errors} />
            </Grid>
          </>

          {isCompanyFormEnabled && (
            <>
              <SectionTitle
                icon={<BusinessIcon />}
                title="Dane Firmy niezbędne do uzupełnienia dokumentów dla optymalizacji BIK:"
                handleClick={() => setClickCount(clickCount + 1)}
              />
              <Grid container spacing={4} mb={2}>
                <FormField
                  name="companyData.nip"
                  label="Numer NIP"
                  control={control}
                  errors={errors}
                  maxLength={10}
                  onChange={handleNumberField}
                  size={{ xs: 6, sm: 3 }}
                />
                <Grid size={{ xs: 6, sm: 3 }} alignContent="center">
                  <Button
                    onClick={handleSearchClick}
                    color="primary"
                    size="small"
                    variant="outlined"
                    disabled={isFetching || !isEditable}
                    sx={{
                      marginLeft: '8px',
                      whiteSpace: 'nowrap',
                      height: '100%',
                    }}
                  >
                    {isFetching ? <CircularProgress size={20} color="inherit" /> : 'Pobierz dane z GUS'}
                  </Button>
                </Grid>
                <FormField
                  name="companyData.regon"
                  label="REGON"
                  control={control}
                  errors={errors}
                  maxLength={14}
                  onChange={handleNumberField}
                />

                <FormField name="companyData.companyName" label="Nazwa firmy" control={control} errors={errors} />
                <FormField name="companyData.country" label="Kraj" control={control} errors={errors} />
                <FormField name="companyData.city" label="Miasto" control={control} errors={errors} />
                <FormField
                  name="companyData.postalCode"
                  label="Kod pocztowy"
                  control={control}
                  errors={errors}
                  onChange={(event, field) => {
                    let value = event.target.value.replace(/\D/g, '');
                    if (value.length > 2) {
                      value = value.slice(0, 2) + '-' + value.slice(2);
                    }
                    field.onChange(value);
                  }}
                  maxLength={6}
                />
                <FormField name="companyData.street" label="Ulica" control={control} errors={errors} />
                <FormField
                  name="companyData.buildingNumber"
                  label="Numer budynku/biura"
                  control={control}
                  errors={errors}
                />
              </Grid>
            </>
          )}

          {isCustomerFormEnabled && (
            <>
              {/* Private Data Section */}
              <SectionTitle
                icon={<VpnKeyIcon />}
                title="Dane Prywatne niezbędne do uzupełnienia dokumentów:"
                handleClick={() => setClickCount(clickCount + 1)}
              />
              <Grid container spacing={4}>
                {!!summaryRawData?.commission.length && (
                  <>
                    <FormField
                      name="privateData.bankAccountName"
                      label="Nazwa banku"
                      size={{ xs: 12 }}
                      control={control}
                      errors={errors}
                    />
                    <FormField
                      name="privateData.bankAccountNumber"
                      isBankFormat
                      label="Numer konta bankowego"
                      size={{ xs: 12 }}
                      control={control}
                      errors={errors}
                      maxLength={32}
                      onChange={handleNumberField}
                    />
                  </>
                )}

                <FormField name="privateData.fullName" label="Imię i Nazwisko" control={control} errors={errors} />
                <FormField
                  name="privateData.pesel"
                  label="PESEL"
                  control={control}
                  errors={errors}
                  maxLength={11}
                  onChange={handleNumberField}
                />
                <FormField name="privateData.country" label="Kraj" control={control} errors={errors} />
                <FormField name="privateData.city" label="Miasto" control={control} errors={errors} />
                <FormField
                  name="privateData.postalCode"
                  label="Kod pocztowy"
                  control={control}
                  errors={errors}
                  maxLength={6}
                  onChange={(event, field) => {
                    let value = event.target.value.replace(/\D/g, '');
                    if (value.length > 2) {
                      value = value.slice(0, 2) + '-' + value.slice(2);
                    }
                    field.onChange(value);
                  }}
                />
                <FormField name="privateData.street" label="Ulica" control={control} errors={errors} />
                <FormField
                  name="privateData.buildingNumber"
                  label="Numer budynku/biura"
                  control={control}
                  errors={errors}
                />
              </Grid>
            </>
          )}

          {/* Support Data Section */}
          <SectionTitle
            icon={<LockIcon />}
            button={{ label: 'Kopiuj dane klienta', onClick: copyMainDataToContact }}
            isDisabled={!isEditable}
            title="Proszę podać dane kontaktowe klienta do obsługi zamówienia (adres e-mail oraz numer telefonu):"
          />
          <Grid container spacing={4}>
            <FormField name="supportData.fullName" label="Imię i Nazwisko" control={control} errors={errors} />
            <FormField
              name="supportData.phone"
              label="Telefon"
              control={control}
              errors={errors}
              onChange={handleNumberField}
            />
            <FormField name="supportData.email" label="Adres Email" type="email" control={control} errors={errors} />
          </Grid>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              sx={{ fontSize: '1em', borderRadius: '8px' }}
            >
              Przejdź do podsumowania
            </Button>
          </Box>
        </form>
        {autocompleteMessage && (
          <Snackbar open anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert onClose={clearMessage} severity="info" variant="filled" sx={{ width: '100%' }}>
              {autocompleteMessage}
            </Alert>
          </Snackbar>
        )}
      </Container>
      <Footer />
    </>
  );
};

interface SectionTitleProps {
  title: string;
  icon?: React.ReactNode;
  isDisabled?: boolean;
  handleClick?: () => void;
  button?: {
    label: string;
    onClick: () => void;
  };
}

const SectionTitle: React.FC<SectionTitleProps> = ({ title, handleClick, isDisabled, icon, button }) => (
  <Box display="flex" alignItems="center" justifyContent="space-between" my={3}>
    {icon && <IconButton>{icon}</IconButton>}
    <Typography onClick={handleClick} variant="h5" gutterBottom m={0} sx={{ flexGrow: 1 }}>
      {title}
    </Typography>
    {button && (
      <Button
        variant="outlined"
        size="medium"
        disabled={isDisabled}
        onClick={button.onClick}
        style={{ minWidth: 150, fontSize: '0.8rem' }}
      >
        {button.label}
      </Button>
    )}
  </Box>
);
function getNestedError<T>(errors: T, name: string): any {
  return name.split('.').reduce((acc, key) => {
    if (acc && typeof acc === 'object') {
      return (acc as Record<string, any>)[key]; // Casting acc to a Record to ensure type safety
    }
    return undefined;
  }, errors);
}
interface FormFieldProps {
  name: NestedKeyOf<AddDetailsSchema>; // Updated name type
  label: string;
  isBankFormat?: boolean;
  maxLength?: number;
  control: any; // react-hook-form control type
  errors: FieldErrors<AddDetailsSchema>;
  onChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: ControllerRenderProps<FieldValues, NestedKeyOf<AddDetailsSchema>>
  ) => void;
  type?: string;
  size?: ResponsiveStyleValue<GridSize>;
}

const FormField: React.FC<FormFieldProps> = ({
  name,
  label,
  control,
  type = 'text',
  size,
  errors,
  isBankFormat,
  maxLength,
  onChange,
}) => {
  const errorMessage = getNestedError(errors, name)?.message; // Access nested error messages

  // Function to format Polish bank account number (first space after 2 digits, then every 4 digits)
  const formatBankAccountNumber = (value: string) => {
    // Remove all non-numeric characters
    const numericValue = value.replace(/\D/g, '');

    // Format with first 2 digits separated, then groups of 4
    return numericValue
      .replace(/^(\d{2})(\d{4})?(\d{4})?(\d{4})?(\d{4})?(\d{4})?(\d{2})?/, (_, g1, g2, g3, g4, g5, g6, g7) =>
        [g1, g2, g3, g4, g5, g6, g7].filter(Boolean).join(' ')
      )
      .trim();
  };

  return (
    <Grid size={size ? size : { xs: 12, sm: 6 }}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={label}
            type={type}
            fullWidth
            value={isBankFormat ? formatBankAccountNumber(field.value || '') : field.value || ''} // Apply formatting
            onChange={(event) => {
              const rawValue = isBankFormat ? event.target.value.replace(/\s/g, '') : event.target.value; // Remove spaces before storing
              if (onChange) {
                onChange(event, { ...field, value: rawValue }); // Pass raw value to form
              } else {
                field.onChange(rawValue);
              }
            }}
            error={!!errorMessage} // Set red border if error exists
            helperText={errorMessage || ' '} // Reserve space for error message
            slotProps={{
              input: {
                style: {
                  minWidth: 'auto',
                },
              },
              formHelperText: {
                style: {
                  margin: 0,
                  height: 0,
                },
              },
              htmlInput: {
                maxLength: maxLength, // Set maxLength restriction for input
                inputMode: type === 'number' ? 'numeric' : undefined, // Set input mode to numeric
                pattern: type === 'number' ? '[0-9]*' : undefined, // Allow only digits (0-9)
              },
            }}
          />
        )}
      />
    </Grid>
  );
};

export default AddDetails;

import { Box, useMediaQuery, useTheme } from '@mui/material';
import Container from '@mui/material/Container';
import { useEffect, useState } from 'react';
import { mockedBIKResponse } from 'src/common/mockedBIKResponse';
import { Header } from 'src/components/app-toolbar/Header';
import BIKReportContent from 'src/components/bik-report/BIKReportContent';
import Footer from 'src/components/Footer';
import ScrollToTop from 'src/components/ScrollToTop';
import { SelectedServiceProvider } from 'src/context/SelectedServiceProvider';
import { PromoCode } from 'src/pages/report-preview/report-summary/PromoCode';
import { ReportSummary } from 'src/pages/report-preview/report-summary/ReportSummary';
import { SummaryTopTitle } from 'src/pages/report-preview/report-summary/SummaryTopTitle';
import { BIKReportType } from 'src/types/BIKReport.type';
import { BIKCompany, BIKPrivate } from 'src/types/CompanyBIKSummary.type';
import { PromoCodeType } from 'src/types/PromoCode.type';
import { isBIKDisabledForEdit } from 'src/utils/bikUtils';

export default function DemoProcess() {
  const data = mockedBIKResponse;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [isSelectedAll, setIsSelectedAll] = useState<boolean | undefined>(undefined);
  const [selectedPromoCode, setSelectedPromoCode] = useState<PromoCodeType>({ code: data?.discountCode || '' });

  useEffect(() => {
    if (data?.discountCode && data?.discountCode !== selectedPromoCode.code) {
      setSelectedPromoCode({ code: data.discountCode });
    }
  }, [data?.discountCode]);

  // Helper component to render BIK report content based on type
  const renderBIKReportContent = (
    dataItem: BIKCompany | BIKPrivate | undefined,
    type: BIKReportType,
    isDisabled: boolean
  ) => {
    return dataItem ? (
      <BIKReportContent
        bikId={data.id}
        isDisabled={isDisabled}
        data={dataItem}
        type={type}
        isSelectedAll={isSelectedAll}
        selectedIds={[]}
      />
    ) : null;
  };

  const isReportDisabled = isBIKDisabledForEdit(data);

  const { company, customer } = data;

  return (
    <SelectedServiceProvider>
      <ScrollToTop />
      <Header type="back" />

      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          gap: 4,
          p: 2,
          position: 'relative',
        }}
      >
        <Container
          id="show-bik-raport"
          sx={{
            pt: { xs: 12, sm: 12 },
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            width: isMobile ? '100%' : '70%',
          }}
          data-testid="bik-report"
        >
          {company && (
            <SummaryTopTitle
              type={'COMPANY'}
              generatedAt={company.generatedAt}
              isDisabled={isReportDisabled}
              isSelectedAll={Boolean(isSelectedAll)}
              setIsSelectedAll={setIsSelectedAll}
              showSelectAll
            />
          )}
          {renderBIKReportContent(company, 'COMPANY', isReportDisabled)}
          {customer && (
            <SummaryTopTitle
              isDisabled={isReportDisabled}
              generatedAt={customer.generatedAt}
              showSelectAll={!company}
              type={'CUSTOMER'}
              isSelectedAll={Boolean(isSelectedAll)}
              setIsSelectedAll={setIsSelectedAll}
            />
          )}
          {renderBIKReportContent(customer, 'CUSTOMER', isReportDisabled)}
          <PromoCode
            isDisabled={isReportDisabled}
            setSelectedPromoCode={setSelectedPromoCode}
            reportPromoCode={data.discountCode || ''}
            reportId={data.id}
          />
        </Container>

        <Box
          sx={{
            position: isMobile ? 'static' : 'sticky',
            top: 80,
            width: isMobile ? '100%' : '30%',
            borderRadius: 2,
            boxShadow: theme.shadows[2],
          }}
        >
          <ReportSummary data={data} isDisabled={isReportDisabled} />
        </Box>
      </Box>
      <Footer />
    </SelectedServiceProvider>
  );
}

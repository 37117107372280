import { BIKSummary } from 'src/types/BIKSummary';
import { CompanyBIKSummary } from 'src/types/CompanyBIKSummary.type';

export const isBIKDisabledForEdit = (bikData?: CompanyBIKSummary | BIKSummary): boolean => {
  return Boolean(
    bikData &&
      bikData.status !== 'DOCUMENTS_UPLOADED' &&
      bikData.status !== 'ITEMS_SELECTED_FOR_REMOVAL' &&
      bikData.status !== 'USER_DATA_ENTERED'
  );
};

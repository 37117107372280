import { styled } from '@mui/material/styles';

interface TimelineStepsContainerInnerProps {
  minTimelineWidth: number;
}

export const TimelineStepsContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '12rem',
  overflowX: 'auto',
  paddingBottom: '2rem',
  transition: 'ease-in all 0.3s',
  backgroundColor: theme.palette.background.default, // Optional: match the container background to the theme
}));

export const TimelineStepsContainerInner = styled('div', {
  shouldForwardProp: (prop) => prop !== 'minTimelineWidth',
})<TimelineStepsContainerInnerProps>(({ theme, minTimelineWidth }) => ({
  minWidth: `${minTimelineWidth}px`,
  margin: 'auto',
  position: 'relative',
  '&::before': {
    content: '""',
    display: 'block',
    height: '2px',
    width: '100%',
    background: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.6)' 
      : 'rgba(0, 0, 0, 0.2)', // Adjust the line color based on the mode
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));

import { Box, Divider, Link, styled, Typography } from '@mui/material';
import React from 'react';

const ScrollableContent = styled(Box)(({ theme }) => ({
  maxHeight: '400px',
  overflowY: 'auto',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.grey[50],
  borderRadius: theme.shape.borderRadius,
  border: `2px solid ${theme.palette.grey[200]}`,
  marginBottom: theme.spacing(4),
}));

const MainConsentText = ({ handleScroll }: { handleScroll: (e: React.UIEvent<HTMLDivElement>) => void }) => {
  return (
    <ScrollableContent onScroll={handleScroll}>
      <Box sx={{ mb: 4, textAlign: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', mb: 3 }}>
          Regulamin Serwisu BIKmaster.pl
        </Typography>
      </Box>

      <Section title="§ 1 Postanowienia Ogólne">
        <NumberedItem number={1}>
          Niniejszy Regulamin określa warunki świadczenia przez Usługodawcę Usług drogą elektroniczną za pośrednictwem
          Serwisu BIKmaster.pl
        </NumberedItem>
        <NumberedItem number={2}>
          Serwis oferuje usługę analizy raportu BIK Klienta w celu określenia najbardziej dopasowanych rekomendacji
          dotyczących możliwości i sposobów optymalizacji danych Klienta w BIK.
        </NumberedItem>
        <NumberedItem number={3}>
          Zawierając Umowę w ramach Serwisu, Klient oświadcza, że zapoznał się i akceptuje postanowienia Regulaminu oraz
          zobowiązuje się do jego przestrzegania.
        </NumberedItem>
        <NumberedItem number={4}>
          Usługodawca udostępnia nieodpłatnie Klientowi Regulamin przed zawarciem Umowy, a także na jego żądanie - w
          sposób umożliwiający pozyskanie, odtwarzanie i utrwalanie treści Regulaminu za pomocą systemu
          teleinformatycznego, którym posługuje się Klient.
        </NumberedItem>
      </Section>

      <Section title="§ 2 Definicje">
        <Definition term="BIK">
          Biuro Informacji Kredytowej S.A., oznacza instytucję działającą w Polsce, której głównym zadaniem jest
          gromadzenie, przechowywanie i udostępnianie informacji o historii kredytowej klientów indywidualnych oraz
          firm;
        </Definition>
        <Definition term="Kancelaria">
          CREO Kancelaria Prawna sp. z o.o. z siedzibą w Krakowie, ul. Karmelicka 32/20, 31-128 Kraków, wpisana do
          rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy dla Krakowa Śródmieścia w Krakowie, XI Wydział
          Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0001135299;
        </Definition>
        <Definition term="Klient">
          podmiot (osoba fizyczna lub prawna) korzystający z Usług oferowanych w Serwisie za pośrednictwem Partnera;
        </Definition>
        <Definition term="Panel partnerski">
          funkcjonalność Serwisu umożliwiająca Partnerom zalogowanie się do wydzielonej strefy w Serwisie w celu
          umożliwienia dokonywania zamówień w imieniu i na rzecz Klienta za pośrednictwem Serwisu;
        </Definition>
        <Definition term="Partner">
          osoba prawna lub osoba fizyczna prowadząca działalność gospodarczą, której zakres obejmuje pośrednictwo
          finansowe, w tym m.in. brokerzy kredytowi oraz inni pośrednicy finansowi, którzy w ramach swojej działalności
          mogą uzyskiwać dostęp do raportów Biura Informacji Kredytowej (BIK) dotyczących podmiotów ubiegających się o
          kredyt lub inne produkty finansowe, działająca w imieniu i na rzecz Klienta;
        </Definition>
        <Definition term="Raport BIK">
          dokument wydawany przez Biuro Informacji Kredytowej, zawierający dane o historii kredytowej i finansowej,
          służący instytucjom finansowym do oceny zdolności kredytowej oraz analizy wiarygodności płatniczej;
        </Definition>
        <Definition term="Regulamin">
          niniejszy Regulamin określony przez Usługodawcę, który precyzuje warunki świadczenia usług w rozumieniu
          przepisów Ustawy;
        </Definition>
        <Definition term="Serwis">
          serwis internetowy Usługodawcy znajdujący się pod adresem{' '}
          <Link href="http://www.bikmaster.pl" underline="hover">
            http://www.bikmaster.pl
          </Link>
          ;
        </Definition>
        <Definition term="Umowa">
          umowa zawierana pomiędzy Usługodawcą a Klientem za pośrednictwem Serwisu, na podstawie której Usługodawca
          zobowiązuje się do świadczenia Usług na rzecz Klienta.
        </Definition>
        <Definition term="Umowa partnerska">umowa o współpracę zawarta między Usługodawcą a Partnerem;</Definition>
        <Definition term="Usługi">
          usługi świadczone drogą elektroniczną przez Usługodawcę na rzecz Klienta za pośrednictwem Serwisu;
        </Definition>
        <Definition term="Usługodawca">
          Kamil Derendarz, prowadzący działalność gospodarczą pod firmą: BIKFIRMOWY.PL KAMIL DERENDARZ, pod adresem: ul.
          Dobrego Pasterza nr 118 D, lok. 51, 31-416 Kraków, NIP: 7712703730, kontaktowy adres e-mail:{' '}
          <Link href="mailto:kontakt@bikmaster.pl" underline="hover">
            kontakt@bikmaster.pl
          </Link>{' '}
          Zmiana którejkolwiek z ww. danych nie stanowi zmiany Regulaminu.
        </Definition>
        <Definition term="Ustawa">ustawa z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną;</Definition>
      </Section>

      <Section title="§ 3 Warunki świadczenia Usług">
        <NumberedItem number={1}>
          Klient jest świadomy i akceptuje, że Usługi oferowane przez Usługodawcę polegają na przygotowaniu
          personalizowanej dokumentacji umożliwiającej natychmiastowe samodzielnie rozpoczęcie procesu optymalizacji
          Raportu BIK przez Klienta albo zaoferowaniu Klientowi skorzystania z usług partnerskiej Kancelarii.
        </NumberedItem>
        <NumberedItem number={2}>
          Dokonywanie zamówień w imieniu i na rzecz Klienta możliwe jest za pośrednictwem Partnera, z którym Usługodawca
          posiada zawartą Umowę partnerską oraz wyłącznie po przekazaniu Partnerowi przez Klienta Raportu BIK Klienta
          mającego być przedmiotem analizy w Serwisie.
        </NumberedItem>
        <NumberedItem number={3}>
          W celu skorzystania z Usługi, niezbędne jest zalogowanie się Partnera do Panelu partnerskiego, przesłanie do
          analizy Raportu BIK Klienta oraz wybór odpowiedniego rozwiązania z oferty Usługodawcy.
        </NumberedItem>
        <NumberedItem number={4}>
          Aby skorzystać z Usług, Klient musi prawidłowo umocować Partnera do działania w jego imieniu i na jego rzecz
          oraz wyrazić wymagane przepisami prawa zgody.
        </NumberedItem>
        <NumberedItem number={5}>
          Do zawarcia Umowy o świadczenie Usług dochodzi w momencie złożenia zamówienia, tj. zaznaczenia przez Partnera
          pola zatwierdzającego zamówienie i przyjęcia tym samym Umowy do realizacji przez Usługodawcę.
        </NumberedItem>
        <NumberedItem number={6}>
          Zawarcie Umowy zostaje potwierdzone wiadomością wysłaną na adres e-mail Klienta, zawierającą podsumowanie
          złożonego zamówienia oraz informację o należnym Usługodawcy wynagrodzeniu.
        </NumberedItem>
        <NumberedItem number={7}>
          W zależności od wybranego produktu, zaproponowanego na podstawie analizy raportu BIK, Klient otrzymuje
          alternatywnie: personalizowane formularze wymagane do rozpoczęcia procesu optymalizacji Raportu BIK (po
          prawidłowym opłaceniu zamówienia) albo ofertę udzielenia pomocy przez Kancelarię, wraz z niezbędnymi
          dokumentami umożliwiającymi Klientowi nawiązanie bezpośredniej współpracy z Kancelarią. W tym przypadku,
          opłata wystąpi dopiero, gdy Klient zdecyduje się skorzystać z usług Kancelarii.
        </NumberedItem>
      </Section>

      <Section title="§ 4 Prawa i Obowiązki">
        <NumberedItem number={1}>
          Usługodawca zobowiązany jest do świadczenia Usług zgodnie z Umową oraz postanowieniami Regulaminu.
        </NumberedItem>
        <NumberedItem number={2}>
          Usługodawca nie ponosi odpowiedzialności za prawidłowość danych Klienta zawartych w Raporcie BIK przekazanym
          przez Partnera.
        </NumberedItem>
        <NumberedItem number={3}>
          Usługodawca nie gwarantuje uzyskania pozytywnych wyników optymalizacji raportu BIK, ani skuteczności działań
          podejmowanych przez Kancelarię.
        </NumberedItem>
        <NumberedItem number={4}>
          W przypadku niewykonania lub nienależytego wykonania przez Usługodawcę postanowień Regulaminu lub Umowy,
          Usługodawca ponosi odpowiedzialność za szkodę wyrządzoną Klientowi do wysokości szkody rzeczywistej, w
          zakresie, w jakim takie ograniczenie odpowiedzialności jest dopuszczalne w oparciu o bezwzględnie obowiązujące
          przepisy prawa.
        </NumberedItem>
        <NumberedItem number={5}>
          Usługi nie mogą być wykorzystane w celu dalszej odsprzedaży lub nieodpłatnego udostępnienia innym Klientom, co
          oznacza, że Usługi mogą być świadczone wyłącznie na rzecz i na indywidualny użytek danego Klienta.
        </NumberedItem>
      </Section>

      <Section title="§ 5 Wymagania techniczne">
        <NumberedItem number={1}>
          Prawidłowe korzystanie z Usług świadczonych za pośrednictwem Serwisu wymaga:
          <Box component="ol" type="a" sx={{ paddingLeft: 3 }}>
            <Box component="li" sx={{ mt: 1 }}>
              połączenia z siecią Internet,
            </Box>
            <Box component="li" sx={{ mt: 1 }}>
              zainstalowania najnowszej wersji przeglądarki internetowej umożliwiającej wyświetlanie na ekranie
              komputera dokumentów hipertekstowych (HTML),
            </Box>
            <Box component="li" sx={{ mt: 1 }}>
              włączonej obsługi plików cookies oraz JavaScript,
            </Box>
            <Box component="li" sx={{ mt: 1 }}>
              braku zainstalowanych wtyczek lub rozszerzeń w przeglądarce internetowej ingerujących w treść strony
              internetowej,
            </Box>
            <Box component="li" sx={{ mt: 1 }}>
              posiadania konta poczty elektronicznej;
            </Box>
          </Box>
        </NumberedItem>
        <NumberedItem number={2}>
          Usługodawca nie ponosi odpowiedzialności za odpowiedni stan techniczny lub właściwą konfigurację sprzętu
          używanego w celu skorzystania z Usług.
        </NumberedItem>
        <NumberedItem number={3}>
          Usługodawca nie ponosi odpowiedzialności za przerwy w świadczeniu Usług wynikające z awarii lub przypadków
          wadliwego funkcjonowania systemów teleinformatycznych będących poza jego wpływem lub kontrolą.
        </NumberedItem>
      </Section>

      <Section title="§ 6 Płatności">
        <NumberedItem number={1}>
          Usługi oferowane przez Usługodawcę są płatne, a wynagrodzenie za nie jest uiszczane przez Klienta.
        </NumberedItem>
        <NumberedItem number={2}>
          Podstawą do określenia wynagrodzenia za Usługi jest obowiązujący cennik znajdujący się w Serwisie. Informacja
          o należnym Usługodawcy wynagrodzeniu jest również wskazana w wiadomości e-mail do Klienta potwierdzającej
          złożenie zamówienia.
        </NumberedItem>
        <NumberedItem number={3}>
          Klient opłaca zamówione Usługi tradycyjnym przelewem bankowym lub za pośrednictwem bramki płatniczej TPay.
        </NumberedItem>
        <NumberedItem number={4}>
          Dokonanie płatności jest warunkiem koniecznym do prawidłowego wykonania Usługi przez Usługodawcę i warunkuje
          otrzymanie przez Klienta dopasowanych rekomendacji dotyczących możliwości i sposobów optymalizacji danych
          Klienta w BIK.
        </NumberedItem>
      </Section>

      <Section title="§ 7 Reklamacje">
        <NumberedItem number={1}>
          Klient może składać reklamacje w sprawach związanych z korzystaniem z Usług określonych w niniejszym
          Regulaminie.
        </NumberedItem>
        <NumberedItem number={2}>
          Reklamacje należy składać na adres poczty elektronicznej:{' '}
          <Link href="mailto:kontakt@bikmaster.pl" underline="hover">
            kontakt@bikmaster.pl
          </Link>
          , wpisując w temacie wiadomości: „Reklamacja" lub na piśmie, na adres siedziby Usługodawcy wskazany w § 2
          niniejszego Regulaminu.
        </NumberedItem>
        <NumberedItem number={3}>
          Usługodawca zobowiązany jest rozpatrzyć reklamację w terminie 14 (czternaście) dni od jej otrzymania.
        </NumberedItem>
        <NumberedItem number={4}>
          W przypadku, gdy złożona reklamacja zawiera braki uniemożliwiające jej rozpatrzenie, Usługodawca może wezwać
          do jej uzupełnienia, określając termin i zakres tego uzupełnienia z pouczeniem, że nieuzupełnienie reklamacji
          w określonym terminie spowoduje pozostawienie reklamacji bez rozpoznania.
        </NumberedItem>
        <NumberedItem number={5}>
          Po bezskutecznym upływie wyznaczonego terminu reklamację pozostawia się bez rozpoznania.
        </NumberedItem>
        <NumberedItem number={6}>
          Odpowiedź na reklamację Usługodawca kieruje na adres poczty elektronicznej wskazany w treści reklamacji lub
          pisemnie na adres nadawcy.
        </NumberedItem>
      </Section>

      <Section title="§ 8 Prawo odstąpienia od Umowy">
        <NumberedItem number={1}>
          Klient, będący konsumentem albo osobą fizyczną, zawierającą Umowę związaną z jej działalnością gospodarczą,
          gdy z treści tej Umowy wynika, że nie posiada ona dla Klienta charakteru zawodowego, może w terminie 14 dni od
          dnia zawarcia Umowy odstąpić od niej bez podawania przyczyny, z zastrzeżeniem ust. 2 poniżej. Oświadczenie o
          odstąpieniu powinno zostać złożone pisemnie na adres Usługodawcy wskazany w § 2 niniejszego Regulaminu.
        </NumberedItem>
        <NumberedItem number={2}>
          Prawo do odstąpienia od Umowy nie przysługuje, jeżeli Usługodawca wykonał w pełni Usługę za wyraźną zgodą
          Klienta, który został poinformowany przed rozpoczęciem świadczenia Usługi, że po spełnieniu świadczenia przez
          Usługodawcę utraci prawo do odstąpienia od Umowy.
        </NumberedItem>
      </Section>

      <Section title="§ 9 Przetwarzanie danych osobowych">
        <NumberedItem number={1}>
          Administratorem danych osobowych Klienta przy realizacji Usług świadczonych w Serwisie jest Usługodawca.
        </NumberedItem>
        <NumberedItem number={2}>
          Dane osobowe pozyskane w związku z zawarciem i realizacją Usług będą przetwarzane w celu zawarcia, prawidłowej
          realizacji Umowy, wymiany informacji niezbędnych do tego celu, w tym wykonania obowiązków podatkowych i
          rachunkowych związanych z prawidłową realizacją Umowy, a także ustalenia, dochodzenia lub obrony przed
          roszczeniami wynikającymi z niewykonania lub nienależytego wykonania Umowy.
        </NumberedItem>
        <NumberedItem number={3}>
          Usługobiorca ma prawo dostępu do treści swoich danych osobowych oraz prawo ich sprostowania, usunięcia,
          ograniczenia przetwarzania, prawo wniesienia sprzeciwu wobec przetwarzania, a także prawo wniesienia skargi do
          Prezesa Urzędu Ochrony Danych Osobowych. Żądania wskazane w zdaniu poprzedzającym można zgłaszać poprzez
          kontakt pod adresem:{' '}
          <Link href="mailto:rodo@bikmaster.pl" underline="hover">
            rodo@bikmaster.pl
          </Link>
        </NumberedItem>
        <NumberedItem number={4}>
          Szczegółowa informacja dotycząca przetwarzania danych osobowych przez Usługodawcę znajduje się w Polityce
          Prywatności Serwisu.
        </NumberedItem>
      </Section>

      <Section title="§ 10 Postanowienia Końcowe">
        <NumberedItem number={1}>Regulamin wchodzi w życie z dniem 14.03.2025r.</NumberedItem>
        <NumberedItem number={2}>
          Usługodawca zastrzega sobie prawo do zmiany w każdym czasie treści niniejszego Regulaminu. Zmiany będą
          komunikowane przez ich publikację w Serwisie oraz będą wchodzić w życie wraz z dniem ich publikacji.
        </NumberedItem>
        <NumberedItem number={3}>
          W sprawach nieuregulowanych niniejszym Regulaminem mają zastosowanie przepisy prawa polskiego, w szczególności
          Kodeks cywilny oraz Ustawa.
        </NumberedItem>
        <NumberedItem number={4}>
          Wszelkie ewentualne spory wynikłe na tle realizacji Umowy i w związku z nią, będą rozpatrywane przez sąd
          właściwy miejscowo dla siedziby Usługodawcy.
        </NumberedItem>
      </Section>
    </ScrollableContent>
  );
};

// Helper components for structure and styling
const Section: React.FC<{ title: string; children: React.ReactNode }> = ({ title, children }) => (
  <Box sx={{ mb: 4 }}>
    <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center', mb: 2 }}>
      {title}
    </Typography>
    <Box sx={{ ml: 0 }}>{children}</Box>
    <Divider sx={{ mt: 3 }} />
  </Box>
);

const NumberedItem: React.FC<{ number: number; children: React.ReactNode }> = ({ number, children }) => (
  <Box sx={{ display: 'flex', mb: 2 }}>
    <Typography sx={{ mr: 1, fontWeight: 'medium' }}>{number}.</Typography>
    <Typography>{children}</Typography>
  </Box>
);

const Definition: React.FC<{ term: string; children: React.ReactNode }> = ({ term, children }) => (
  <Box sx={{ mb: 2 }}>
    <Typography sx={{ display: 'inline', fontWeight: 'medium' }}>{term}</Typography>
    <Typography sx={{ display: 'inline' }}> – {children}</Typography>
  </Box>
);

export default MainConsentText;

import { useTheme } from '@mui/material/styles';
import React from 'react';

export const Logo = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <svg
      width="237"
      height="61"
      viewBox="0 0 237 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={
        {
          '--color-primary': isDarkMode ? '#FFFFFF' : '#2D007A', // Adjust dark and light colors
          '--color-secondary': isDarkMode ? '#AAAAAA' : '#24CCE7',
          '--color-accent': isDarkMode ? '#FFD700' : '#2D007A',
          height: '3em',
          width: '10em',
        } as React.CSSProperties
      }
    >
      <g clipPath="url(#clip0_2_23)" id="svg_2">
        <path
          d="m214.3,38.59c-0.61,0 -1.08,-0.17 -1.41,-0.51c-0.33,-0.34 -0.5,-0.83 -0.5,-1.47c0,-0.59 0.18,-1.08 0.54,-1.44c0.35,-0.37 0.81,-0.56 1.38,-0.56c0.6,0 1.08,0.17 1.42,0.52c0.34,0.35 0.51,0.84 0.51,1.48c0,0.58 -0.17,1.05 -0.53,1.43c-0.36,0.37 -0.83,0.55 -1.4,0.55l-0.01,0z"
          fill="var(--color-secondary)"
          id="svg_9"
        />
        <path
          d="m218.75,43.24l0,-16.71l3.39,0l0.13,2.65l-0.67,-0.2c0.11,-0.49 0.35,-0.94 0.76,-1.34c0.4,-0.39 0.91,-0.72 1.52,-0.96c0.62,-0.24 1.27,-0.37 1.95,-0.37c1,0 1.9,0.26 2.69,0.79c0.78,0.52 1.4,1.24 1.86,2.16c0.44,0.91 0.67,1.97 0.67,3.17c0,1.2 -0.23,2.24 -0.67,3.16c-0.46,0.92 -1.08,1.64 -1.88,2.17c-0.79,0.53 -1.7,0.79 -2.72,0.79c-0.67,0 -1.31,-0.13 -1.91,-0.38c-0.61,-0.25 -1.12,-0.59 -1.54,-1.02c-0.42,-0.43 -0.69,-0.9 -0.83,-1.4l0.83,-0.32l0,7.81l-3.58,0l0,0zm6.17,-7.7c0.53,0 1,-0.13 1.4,-0.39c0.4,-0.26 0.71,-0.62 0.93,-1.08c0.23,-0.46 0.34,-1 0.34,-1.65c0,-0.64 -0.11,-1.16 -0.34,-1.63c-0.22,-0.46 -0.53,-0.83 -0.92,-1.09c-0.4,-0.26 -0.87,-0.39 -1.41,-0.39c-0.55,0 -1.02,0.13 -1.43,0.38c-0.4,0.25 -0.71,0.62 -0.93,1.09c-0.23,0.48 -0.34,1.02 -0.34,1.65c0,0.63 0.11,1.19 0.34,1.65c0.22,0.46 0.53,0.82 0.93,1.08c0.4,0.25 0.88,0.38 1.43,0.38l0,0z"
          fill="var(--color-secondary)"
          id="svg_10"
        />
        <path d="m233.44,38.34l0,-16.47l3.56,0l0,16.47l-3.56,0z" fill="var(--color-secondary)" id="svg_11" />
        <path
          d="m0,60.59l0,-60.54l26.72,0c4.16,0 7.67,0.59 10.55,1.77c2.89,1.18 5.06,2.88 6.53,5.1c1.48,2.22 2.21,4.89 2.21,8.01c0,3.45 -0.85,6.37 -2.55,8.73c-1.7,2.37 -4.17,3.95 -7.39,4.76l-0.18,-1.65c2.71,0.58 5.06,1.59 7.05,3.03c1.99,1.44 3.53,3.22 4.63,5.32c1.09,2.1 1.65,4.49 1.65,7.14c0,3.05 -0.53,5.72 -1.56,8c-1.04,2.27 -2.54,4.19 -4.5,5.75c-1.96,1.56 -4.25,2.71 -6.87,3.46c-2.63,0.75 -5.52,1.12 -8.7,1.12l-27.59,0l0,0zm13.84,-36.24l10.81,0c1.5,0 2.78,-0.25 3.85,-0.74c1.07,-0.49 1.87,-1.17 2.42,-2.07c0.55,-0.89 0.82,-1.97 0.82,-3.24c0,-1.85 -0.64,-3.3 -1.9,-4.37c-1.27,-1.07 -3.17,-1.6 -5.71,-1.6l-10.29,0l0,12.02l0,0zm0,23.96l13.23,0c1.61,0 2.98,-0.26 4.11,-0.78c1.12,-0.52 2,-1.29 2.64,-2.29c0.63,-1.01 0.95,-2.24 0.95,-3.68c0,-1.33 -0.34,-2.45 -1,-3.37c-0.66,-0.93 -1.6,-1.63 -2.81,-2.12c-1.21,-0.49 -2.66,-0.74 -4.33,-0.74l-12.8,0l0,12.98l0.01,0z"
          fill="var(--color-primary)"
          id="svg_12"
        />
        <path d="m55.31,16.22l14.08,0l0,44.37l-14.08,0l0,-44.37z" fill="var(--color-primary)" id="svg_13" />
        <path d="m69.45,0.02l-14.21,-0.02l-0.01,11.19l14.22,-11.17z" fill="var(--color-secondary)" id="svg_14" />
        <path
          d="m115.15,0l-18.7,0l-20.59,25.4l0,11.95l20.76,23.34l18.69,0l-25.94,-29.16l25.78,-31.53z"
          fill="var(--color-primary)"
          id="svg_15"
        />
        <text
          fill="var(--color-primary)"
          fontFamily="Sans-serif"
          fontSize="24"
          fontWeight="bold"
          id="svg_16"
          strokeWidth="0"
          textAnchor="middle"
          transform="matrix(1.23615 0 0 1.09563 15.6806 -84.6075)"
          x="119.98"
          y="112.14"
        >
          master
        </text>
      </g>
      <defs>
        <clipPath id="clip0_2_23">
          <rect fill="white" height="60.59" id="svg_1" width="237" />
        </clipPath>
      </defs>
    </svg>
  );

  return (
    <svg
      width="237"
      height="61"
      viewBox="0 0 237 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={
        {
          '--color-primary': isDarkMode ? '#FFFFFF' : '#2D007A', // Adjust dark and light colors
          '--color-secondary': isDarkMode ? '#AAAAAA' : '#24CCE7',
          '--color-accent': isDarkMode ? '#FFD700' : '#2D007A',
          height: '3em',
          width: '10em',
        } as React.CSSProperties
      }
    >
      <g clipPath="url(#clip0_2_23)">
        <path
          d="M119.39 29.7995V26.861H127.113V29.7995H119.39ZM121.105 38.3418V25.9456C121.105 25.1768 121.272 24.4789 121.605 23.8517C121.939 23.2296 122.404 22.734 123.006 22.3698C123.608 22.0057 124.306 21.8236 125.11 21.8236C125.672 21.8236 126.193 21.9045 126.668 22.0664C127.143 22.2282 127.563 22.4609 127.938 22.7542L126.916 25.3589C126.724 25.2527 126.537 25.1718 126.36 25.1161C126.183 25.0554 126.016 25.0251 125.869 25.0251C125.601 25.0251 125.378 25.0706 125.201 25.1566C125.024 25.2476 124.893 25.3842 124.812 25.5663C124.731 25.7483 124.691 25.9759 124.691 26.244V38.3266H121.11L121.105 38.3418ZM131.093 24.1906C130.426 24.1906 129.905 24.0287 129.526 23.7101C129.146 23.3915 128.959 22.9363 128.959 22.3395C128.959 21.8034 129.151 21.3684 129.536 21.0245C129.92 20.6857 130.441 20.5137 131.093 20.5137C131.746 20.5137 132.282 20.6755 132.651 20.9942C133.02 21.3128 133.208 21.7629 133.208 22.3395C133.208 22.9161 133.02 23.3359 132.641 23.6747C132.262 24.0186 131.746 24.1855 131.093 24.1855V24.1906ZM129.313 38.3418V26.5272H132.874V38.3418H129.313Z"
          fill="var(--color-primary)"
        />
        <path
          d="M136.212 38.3418V26.5272H139.595L139.752 30.3761L139.085 29.6427C139.262 29.0054 139.555 28.4339 139.965 27.9282C140.374 27.4224 140.85 27.0229 141.401 26.7245C141.947 26.4261 142.534 26.2794 143.161 26.2794C143.429 26.2794 143.677 26.2996 143.904 26.3451C144.132 26.3907 144.344 26.4412 144.537 26.5019L143.555 30.4418C143.394 30.3407 143.176 30.2547 142.898 30.1839C142.62 30.1131 142.337 30.0827 142.043 30.0827C141.714 30.0827 141.411 30.1383 141.133 30.2496C140.85 30.3609 140.612 30.5227 140.42 30.7301C140.228 30.9375 140.076 31.1853 139.965 31.4634C139.853 31.7467 139.798 32.0653 139.798 32.4193V38.3367H136.217L136.212 38.3418Z"
          fill="var(--color-primary)"
        />
        <path
          d="M146.317 38.3418V26.5272H149.7L149.812 28.6615L149.346 28.6818C149.523 28.2974 149.751 27.9535 150.024 27.6601C150.297 27.3617 150.611 27.1139 150.96 26.9166C151.309 26.7143 151.673 26.5626 152.052 26.4615C152.432 26.3603 152.811 26.3047 153.2 26.3047C153.777 26.3047 154.308 26.3957 154.778 26.5727C155.254 26.7497 155.668 27.038 156.022 27.4275C156.376 27.822 156.66 28.353 156.867 29.0206L156.311 28.9751L156.488 28.6211C156.68 28.267 156.928 27.9433 157.231 27.6551C157.535 27.3668 157.874 27.1189 158.243 26.9217C158.612 26.7194 159.001 26.5677 159.401 26.4665C159.801 26.3654 160.185 26.3097 160.559 26.3097C161.495 26.3097 162.274 26.4918 162.896 26.856C163.518 27.2201 163.988 27.7663 164.307 28.4896C164.625 29.2179 164.787 30.0979 164.787 31.1398V38.3519H161.207V31.4331C161.207 30.9577 161.141 30.5632 161.004 30.2547C160.868 29.9462 160.675 29.7085 160.412 29.5517C160.155 29.3949 159.831 29.319 159.446 29.319C159.133 29.319 158.85 29.3696 158.592 29.4758C158.334 29.582 158.111 29.7186 157.924 29.9006C157.737 30.0777 157.595 30.285 157.489 30.5227C157.383 30.7604 157.332 31.0285 157.332 31.3218V38.3519H153.752V31.4078C153.752 30.9627 153.686 30.5834 153.549 30.2749C153.418 29.9613 153.215 29.7287 152.947 29.5618C152.679 29.3999 152.371 29.319 152.012 29.319C151.698 29.319 151.415 29.3696 151.157 29.4758C150.899 29.582 150.677 29.7186 150.489 29.9006C150.302 30.0777 150.161 30.285 150.054 30.5227C149.948 30.7604 149.898 31.0184 149.898 31.3016V38.357H146.317V38.3418Z"
          fill="var(--color-primary)"
        />
        <path
          d="M173.309 38.5694C172.075 38.5694 170.983 38.3013 170.027 37.7703C169.071 37.2342 168.312 36.511 167.756 35.5905C167.2 34.67 166.922 33.618 166.922 32.4295C166.922 31.2409 167.2 30.1889 167.756 29.2684C168.312 28.348 169.071 27.6197 170.027 27.0886C170.983 26.5576 172.075 26.2895 173.309 26.2895C174.543 26.2895 175.636 26.5576 176.592 27.0886C177.548 27.6247 178.301 28.348 178.853 29.2684C179.399 30.1889 179.677 31.2409 179.677 32.4295C179.677 33.618 179.404 34.67 178.853 35.5905C178.301 36.511 177.553 37.2392 176.592 37.7703C175.636 38.3064 174.543 38.5694 173.309 38.5694ZM173.309 35.4944C173.846 35.4944 174.316 35.3629 174.736 35.0948C175.155 34.8268 175.479 34.4626 175.717 34.0024C175.955 33.5421 176.066 33.0161 176.051 32.4244C176.066 31.8327 175.955 31.3016 175.717 30.8312C175.479 30.3659 175.15 29.9967 174.736 29.7287C174.321 29.4606 173.846 29.3291 173.309 29.3291C172.773 29.3291 172.298 29.4606 171.873 29.7287C171.448 29.9967 171.119 30.3609 170.882 30.8312C170.644 31.2965 170.533 31.8276 170.548 32.4244C170.533 33.0161 170.644 33.5472 170.882 34.0024C171.119 34.4626 171.448 34.8268 171.873 35.0948C172.298 35.3629 172.773 35.4944 173.309 35.4944Z"
          fill="var(--color-primary)"
        />
        <path
          d="M184.148 38.3418L180.254 26.5272H184.016L186.196 34.2047L185.731 34.1136L188.179 28.1507H190.273L192.852 34.1389L192.341 34.1844L194.521 26.5272H198.284L194.258 38.3418H191.633L189.074 31.9996L189.342 32.0451L186.783 38.3418H184.158H184.148Z"
          fill="var(--color-primary)"
        />
        <path
          d="M201.617 43.4652L204.308 37.1229L204.353 38.9487L198.658 26.5323H202.684L205.177 32.475C205.309 32.7734 205.435 33.092 205.557 33.4309C205.673 33.7697 205.764 34.0985 205.825 34.412L205.334 34.7003C205.425 34.4778 205.521 34.1996 205.633 33.8658C205.744 33.532 205.865 33.1729 206.002 32.7885L208.182 26.5373H212.233L207.206 38.3519L205.157 43.4702H201.617V43.4652Z"
          fill="var(--color-primary)"
        />
        <path
          d="M214.301 38.5896C213.695 38.5896 213.224 38.4177 212.89 38.0788C212.557 37.7399 212.39 37.2494 212.39 36.6121C212.39 36.0204 212.567 35.5348 212.926 35.1656C213.28 34.7964 213.74 34.6093 214.306 34.6093C214.913 34.6093 215.389 34.7812 215.733 35.1302C216.072 35.4792 216.244 35.9748 216.244 36.6121C216.244 37.1887 216.067 37.6641 215.707 38.0383C215.353 38.4076 214.883 38.5947 214.306 38.5947L214.301 38.5896Z"
          fill="var(--color-secondary)"
        />
        <path
          d="M218.752 43.2426V26.5323H222.136L222.267 29.1825L221.6 28.9802C221.706 28.4896 221.954 28.0445 222.358 27.6449C222.763 27.2454 223.269 26.9217 223.881 26.6789C224.498 26.4362 225.145 26.3097 225.828 26.3097C226.834 26.3097 227.734 26.5727 228.518 27.0987C229.302 27.6247 229.924 28.3429 230.375 29.2583C230.825 30.1687 231.052 31.2257 231.052 32.4295C231.052 33.6332 230.825 34.67 230.375 35.5905C229.919 36.511 229.297 37.2342 228.503 37.7602C227.709 38.2862 226.799 38.5492 225.777 38.5492C225.11 38.5492 224.472 38.4227 223.865 38.1698C223.258 37.917 222.748 37.5781 222.328 37.1482C221.913 36.7183 221.64 36.253 221.503 35.7472L222.328 35.4337V43.2426H218.747H218.752ZM224.917 35.5399C225.453 35.5399 225.919 35.4084 226.318 35.1504C226.718 34.8925 227.031 34.5334 227.254 34.0732C227.477 33.6129 227.588 33.0667 227.588 32.4244C227.588 31.7821 227.477 31.2561 227.254 30.7908C227.031 30.3255 226.723 29.9613 226.328 29.6983C225.934 29.4404 225.464 29.3089 224.917 29.3089C224.371 29.3089 223.896 29.4353 223.491 29.6882C223.092 29.9411 222.778 30.3053 222.555 30.7807C222.333 31.2561 222.222 31.8023 222.222 32.4295C222.222 33.0566 222.333 33.618 222.555 34.0782C222.778 34.5385 223.092 34.8976 223.491 35.1555C223.891 35.4134 224.366 35.5449 224.917 35.5449V35.5399Z"
          fill="var(--color-secondary)"
        />
        <path d="M233.439 38.3418V21.8742H237V38.3418H233.439Z" fill="var(--color-secondary)" />
        <path
          d="M0 60.5852V0.0455189H26.7245C30.8768 0.0455189 34.3918 0.63726 37.2746 1.82074C40.1575 3.00423 42.3323 4.70359 43.804 6.92388C45.2758 9.14417 46.0092 11.8095 46.0092 14.925C46.0092 18.3844 45.1595 21.2976 43.4601 23.6595C41.7608 26.0265 39.2926 27.6095 36.0659 28.4188L35.8939 26.775C38.6048 27.3516 40.9515 28.3631 42.9442 29.7995C44.9319 31.2409 46.4745 33.0161 47.572 35.1201C48.6644 37.2241 49.2157 39.6062 49.2157 42.2564C49.2157 45.3112 48.6948 47.9816 47.6579 50.2576C46.6211 52.5335 45.119 54.4503 43.1617 56.0081C41.1994 57.5658 38.9083 58.719 36.2884 59.4675C33.6635 60.216 30.7655 60.5903 27.5944 60.5903H0V60.5852ZM13.8376 24.3474H24.6508C26.1479 24.3474 27.4325 24.1046 28.4997 23.614C29.5668 23.1234 30.371 22.4356 30.9223 21.5404C31.4685 20.6452 31.7416 19.5679 31.7416 18.2985C31.7416 16.4524 31.1044 15.0009 29.84 13.9287C28.5705 12.8615 26.6688 12.3305 24.1299 12.3305H13.8376V24.3524V24.3474ZM13.8376 48.3053H27.0684C28.6818 48.3053 30.0524 48.0474 31.1752 47.5264C32.298 47.0055 33.178 46.2418 33.8152 45.2353C34.4474 44.2289 34.7661 42.9999 34.7661 41.5585C34.7661 40.2334 34.4323 39.1106 33.7697 38.185C33.1072 37.2645 32.1715 36.5565 30.9577 36.0659C29.7489 35.5753 28.3024 35.3325 26.6334 35.3325H13.8326V48.3053H13.8376Z"
          fill="var(--color-primary)"
        />
        <path d="M55.3101 16.2198H69.3905V60.5852H55.3101V16.2198Z" fill="var(--color-primary)" />
        <path d="M69.4462 0.0151729L55.2393 0L55.2343 11.1875L69.4462 0.0151729Z" fill="var(--color-secondary)" />
        <path
          d="M115.147 0H96.4494L75.8643 25.3951V37.3526L96.616 60.6914H115.314L89.3741 31.5325L115.147 0Z"
          fill="var(--color-primary)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_23">
          <rect width="237" height="60.5852" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import { Box, Typography } from '@mui/material';
import React from 'react';
import InstructionStep from './InstructionStep';
import StepImage from './StepImage';

const PersonalInstructions: React.FC = () => {
  return (
    <Box>
      <Typography variant="h4" component="h1" sx={{ mb: 4, textAlign: 'center' }}>
        Instrukcja przesłania dokumentu do profilu indywidualnego na bik.pl
      </Typography>

      <InstructionStep number={1} title="Wejdź na stronę bik.pl.">
        <Typography>
          Otwórz przeglądarkę i wprowadź adres{' '}
          <a href="https://www.bik.pl" target="_blank" rel="noopener noreferrer">
            bik.pl
          </a>
          .
        </Typography>{' '}
      </InstructionStep>

      <InstructionStep number={2} title="Wybierz profil.">
        <Typography sx={{ mb: 1 }}>- Kliknij opcję "Twój profil główny".</Typography>
        <StepImage src="/instructions/customer1.png" alt="Wybór profilu" />
      </InstructionStep>

      <InstructionStep number={3} title="Kliknij ikonę koperty w prawym górnym rogu.">
        <StepImage src="/instructions/customer2.png" alt="Ikona koperty" />
      </InstructionStep>

      <InstructionStep number={4} title="Wybierz temat kontaktu.">
        <Typography sx={{ mb: 1 }}>- Tematy: "Zgody i oświadczenia w portalu BIK".</Typography>
        <StepImage src="/instructions/customer3.png" alt="Wybór tematu" />
      </InstructionStep>

      <InstructionStep number={5} title='Załącz podpisany skan dokumentu "Kowalski Jan – pismo do BIK profil"'>
        <Typography sx={{ mb: 1 }}>- Kliknij "Dodaj załącznik" i wybierz podpisany skan z urządzenia.</Typography>
        <Typography sx={{ mb: 1 }}>- W polu "Treść" wpisz: Proszę o realizację dyspozycji z załącznika.</Typography>
        <Typography sx={{ mb: 1 }}>- Kliknij "Wyślij", aby przesłać zgłoszenie.</Typography>
        <StepImage src="/instructions/customer4.png" alt="Załączanie dokumentu" />
      </InstructionStep>
    </Box>
  );
};

export default PersonalInstructions;

import { CancelOutlined, CheckCircleOutline } from '@mui/icons-material';
import { Box, Card, CardContent, Container, IconButton, Stack, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import type { CreditEntry } from 'src/types/credit';
import { calculateTotalPoints } from 'src/utils/creditScoreUtils';

const initialEntries: CreditEntry[] = [
  {
    id: '1',
    name: 'Opóźniona Płatność - Karta Kredytowa (2021)',
    impact: {
      creditScore: 30,
      maxLoan: 50000,
      interestRate: 0.5,
    },
    selected: false,
  },
  {
    id: '2',
    name: 'Kredyt opóźniony 30+ dni',
    impact: {
      creditScore: 45,
      maxLoan: 75000,
      interestRate: 0.75,
    },
    selected: false,
  },
  {
    id: '3',
    name: 'Duża Ilość Zapytań Kredytowych',
    impact: {
      creditScore: 15,
      maxLoan: 25000,
      interestRate: 0.25,
    },
    selected: false,
  },
  {
    id: '4',
    name: 'Leasing z Opóźnieniami w Spłatach',
    impact: {
      creditScore: 35,
      maxLoan: 60000,
      interestRate: 0.6,
    },
    selected: false,
  },
];

export default function BenefitsCalculator() {
  const [entries, setEntries] = useState<CreditEntry[]>([]);
  const theme = useTheme();

  useEffect(() => {
    setEntries(
      initialEntries.map((entry) => ({
        ...entry,
        points: calculateTotalPoints(entry),
      }))
    );
  }, []);

  const toggleEntry = (id: string) => {
    setEntries(entries.map((entry) => (entry.id === id ? { ...entry, selected: !entry.selected } : entry)));
  };

  const totalImpact = entries
    .filter((entry) => entry.selected)
    .reduce(
      (acc, entry) => ({
        creditScore: acc.creditScore + (entry.points || 0),
        maxLoan: acc.maxLoan + entry.impact.maxLoan,
        interestRate: acc.interestRate + entry.impact.interestRate,
      }),
      { creditScore: 0, maxLoan: 0, interestRate: 0 }
    );

  return (
    <Container
      id="benefits"
      maxWidth="lg"
      sx={{
        bgcolor: theme.palette.mode === 'light' ? '#f9fbfd' : 'background.default',
        py: 6,
      }}
    >
      <motion.div initial={{ opacity: 0, y: 20 }} whileInView={{ opacity: 1, y: 0 }} viewport={{ once: true }}>
        <Stack spacing={3} alignItems="center" mb={3}>
          <Typography
            variant="h3"
            align="center"
            sx={{
              color: theme.palette.mode === 'light' ? '#3d54e7' : 'primary.main',
              fontWeight: 800,
            }}
          >
            Oblicz Swoje Potencjalne Korzyści
          </Typography>
          <Typography fontSize="1.1em" color="text.secondary" align="center" sx={{ maxWidth: '800px' }}>
            Zobacz, jak usunięcie negatywnych wpisów może poprawić Twoją sytuację finansową
          </Typography>
        </Stack>
      </motion.div>

      <Grid container spacing={1}>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Card
            elevation={0}
            sx={{
              height: '100%',
              bgcolor: theme.palette.mode === 'light' ? '#ffffff' : '#1e1e1e', // Dark gray background for dark mode
              borderRadius: 2,
              border: 'none',
              boxShadow:
                theme.palette.mode === 'light'
                  ? '0px 4px 16px rgba(0, 0, 0, 0.15)'
                  : '0px 4px 16px rgba(0, 0, 0, 0.35)', // Slightly heavier shadow in dark mode
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                fontWeight="600"
                color={theme.palette.mode === 'light' ? '#0d47a1' : '#ffffff'} // White text in dark mode
                mb={2}
              >
                Wybierz Wpisy do Usunięcia
              </Typography>
              <Stack spacing={2}>
                {entries.map((entry) => (
                  <Box
                    key={entry.id}
                    onClick={() => toggleEntry(entry.id)}
                    sx={{
                      cursor: 'pointer',
                      p: 1,
                      borderRadius: 1,
                      border: `2px solid ${
                        entry.selected ? (theme.palette.mode === 'light' ? '#2c2c2c42' : '#64b5f642') : '#2c2c2c42'
                      }`, // Bright border for selected state in dark mode
                      bgcolor: entry.selected
                        ? theme.palette.mode === 'light'
                          ? '#e3f2fd'
                          : '#292929' // Slightly lighter background for selected items in dark mode
                        : theme.palette.mode === 'light'
                          ? '#ffffff'
                          : '#1e1e1e', // Default dark card background
                      '&:hover': {
                        borderColor: theme.palette.mode === 'light' ? '#1976d2' : '#64b5f6', // Brighter hover effect in dark mode
                      },
                    }}
                  >
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: theme.palette.mode === 'light' ? '#000000' : '#ffffff', // Ensure text visibility
                        }}
                      >
                        {entry.name}
                      </Typography>
                      <IconButton
                        size="small"
                        sx={{
                          color: entry.selected
                            ? theme.palette.mode === 'light'
                              ? '#1976d2'
                              : '#64b5f6' // Selected icon color in dark mode
                            : theme.palette.mode === 'light'
                              ? '#9e9e9e'
                              : '#7c7c7c', // Default icon color in dark mode
                        }}
                      >
                        {entry.selected ? <CheckCircleOutline /> : <CancelOutlined />}
                      </IconButton>
                    </Stack>
                    {entry.selected && entry.points && (
                      <Typography
                        color={theme.palette.mode === 'light' ? 'primary' : '#64b5f6'} // Accent color in dark mode
                        sx={{ mt: 1 }}
                      >
                        +{entry.points} punktów do zdolności kredytowej
                      </Typography>
                    )}
                  </Box>
                ))}
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid size={{ xs: 12, lg: 6 }}>
          <Card
            elevation={0}
            sx={{
              height: '100%',
              bgcolor: theme.palette.mode === 'light' ? '#ffffff' : 'background.paper',
              borderRadius: 2,
              border: 'none',
              boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.15)',
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                fontWeight="600"
                color={theme.palette.mode === 'light' ? '#0d47a1' : 'text.primary'}
                mb={2}
              >
                Potencjalne Ulepszenia
              </Typography>
              <Stack spacing={2}>
                <Box
                  sx={{
                    p: 3,
                    borderRadius: 4,
                    bgcolor: theme.palette.mode === 'light' ? '#e3f2fd' : '#2d57a94d',
                  }}
                >
                  <Typography variant="subtitle2" fontWeight="700">
                    Wzrost Zdolności Kredytowej
                  </Typography>
                  <Typography variant="h5" color="primary.main">
                    +{totalImpact.creditScore} punktów
                  </Typography>
                </Box>
                <Box
                  sx={{
                    p: 3,
                    borderRadius: 4,
                    bgcolor: theme.palette.mode === 'light' ? '#effdf4' : '#0f600f78',
                  }}
                >
                  <Typography variant="subtitle2" fontWeight="700">
                    Zwiększenie Maksymalnego Kredytu
                  </Typography>
                  <Typography variant="h5" color="success.main">
                    +{totalImpact.maxLoan.toLocaleString()} zł
                  </Typography>
                </Box>
                <Box
                  sx={{
                    p: 3,
                    borderRadius: 4,
                    bgcolor: theme.palette.mode === 'light' ? '#eef2ff' : '#a563dc36',
                  }}
                >
                  <Typography variant="subtitle2" fontWeight="700">
                    Obniżenie Oprocentowania
                  </Typography>
                  <Typography variant="h5" color="#5248e4">
                    -{totalImpact.interestRate.toFixed(2)}%
                  </Typography>
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

import styled from '@emotion/styled';
import { Box, Chip } from '@mui/material';
import { FunctionComponent } from 'react';
import { ReportStatusDetails } from 'src/common/reports';
import { Report } from 'src/types/Report';
import { ReportPricing } from 'src/types/ReportPricing';
import { ReportType, tranlatedReportTypes } from 'src/types/ReportType';
interface BIKStatusProps {
  bikReport: Report;
}

const typeColors: Record<ReportType, 'primary' | 'warning' | 'success'> = {
  COMPANY: 'primary',
  HYBRID: 'warning',
  PRIVATE: 'success',
} as const;

const StyledChip = styled(Chip)`
  text-transform: uppercase;
  display: inline;
  margin-right: 4px;
`;

const generateColorByString = (str: keyof ReportPricing): 'warning' | 'secondary' | 'error' | 'info' | 'success' => {
  switch (str) {
    case 'bikRemovalDiscountPrice':
      return 'warning';
    case 'bikRemovalPrice':
      return 'secondary';
    case 'commitmentsPossibleProfit':
      return 'error';
    case 'skdPossibleProfit':
      return 'info';
    case 'unwiborPossibleProfit':
      return 'success';
  }
};

const getTextBySelectedService = (str: keyof ReportPricing, profit: number) => {
  switch (str) {
    case 'bikRemovalDiscountPrice':
      return 'OPTYMALIZACJA BIK';
    case 'commitmentsPossibleProfit':
      return `ZWROT PROWIZJI (${profit} PLN)`;
    case 'skdPossibleProfit':
      return `SKD (${profit} PLN)`;
    case 'unwiborPossibleProfit':
      return `ODWIBOROWANIE (${profit} PLN)`;
    default:
      return undefined;
  }
};

const getSelectedServicesChip = (selectedServices: (keyof ReportPricing)[], pricing: ReportPricing) => {
  return selectedServices.map((service) => (
    <Box mt={1}>
      <StyledChip
        key={service}
        color={generateColorByString(service)}
        label={getTextBySelectedService(service, pricing?.[service] || 0)}
        size="small"
        variant="filled"
        data-testid="selected-service-chip"
      />
    </Box>
  ));
};

export const BIKStatusProps: FunctionComponent<BIKStatusProps> = ({ bikReport }) => {
  const status = bikReport.status;

  const selectedServices = Object.keys(bikReport.pricing).filter(
    (key) => !!bikReport.pricing[key as keyof typeof bikReport.pricing] && key !== 'bikRemovalPrice'
  ) as (keyof ReportPricing)[];

  const chips = getSelectedServicesChip(selectedServices, bikReport.pricing);

  return (
    <>
      <Box className="status" mb={0.5}>
        Status: <b>{ReportStatusDetails[status]?.translation}</b>
      </Box>

      <div className="order-type">
        {!!bikReport.reportType && (
          <Box my={0.5} mr={0.5} display="inline-block">
            <StyledChip
              variant="filled"
              color={typeColors[bikReport.reportType]}
              label={`${tranlatedReportTypes[bikReport.reportType]}`}
              size="small"
              data-testid="order-type-chip"
            />
          </Box>
        )}

        {bikReport.isPaid && (
          <Box>
            <StyledChip color="secondary" label="opłacono" size="small" data-testid="bik-is-paid-chip" />
          </Box>
        )}

        {(bikReport.status === 'DOCUMENTS_SENT' ||
          bikReport.status === 'DOCUMENTS_TO_BE_SENT' ||
          bikReport.status === 'PAYMENT_COMPLETED') && (
          <Box>
            <StyledChip color="info" label="zafakturowano" size="small" data-testid="bik-status-chip" />
          </Box>
        )}

        {chips.length > 0 && <Box>Wybrane usługi (Szacowana wartość przedmiotu sporu): {chips}</Box>}
      </div>
    </>
  );
};
